import { Price } from 'src/app/map/charge-boxes/charge-box.model';

export interface Invoice {
  createdAt: Date;
  currency: string;
  _id: string;
  id: string;
  orderNumber: string;
  paymentStatus: string;
  receiptCreated: boolean;
  receiptSent: boolean;
  receiptUploaded: boolean;
  reminderEmail: boolean;
  totalPrice: InvoicePrice;
  transactions: InvoiceTransaction[];
  transactionId: string;
  updatedAt: Date;
  userId: string;
  paymentLink: any;
}

export interface InvoicePrice {
  gross: number;
  net: number;
  vat: number;
}

export interface InvoiceTransaction {
  _id: string;
  timeStampStart: Date;
  timeStampStop: Date;
  price: TotalPrice;
  chargingPrice: Object;
  energy: Number;
  duration: Number;
  evseId?: String;
  chargePointId?: String;
  chargeBoxId?: String;
  chargePointName: String;
  // ID of Kesko transaction.
  transactionId?: String;
  // ID of Hubject transaction.
  sessionId?: String;
}
export interface TotalPrice {
  total: number;
  taxFree?: number;
  base?: number;
  vat?: number;
  commission?: number;
  currency?: string;
}

function createInvoiceTransaction(params: Partial<InvoiceTransaction>) {
  return {
    ...params,
  } as InvoiceTransaction;
}

/**
 * A factory function that creates Invoice
 */
export function createInvoice(params: Partial<Invoice>) {
  return {
    ...params,
    id: params._id,
  } as Invoice;
}

export enum INVOICE_STATUSES {
  UNPAID = 'unpaid',
  SUCCEEDED = 'succeeded',
  PENDING = 'pending',
  FAILED = 'failed',
}
