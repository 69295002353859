import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore, ActiveState, EntityUIStore } from '@datorama/akita';
import { Transaction } from './transaction.model';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import * as moment from 'moment';

export type TransactionsUI = {
  selectedMonth: moment.Moment;
}

export interface TransactionsState extends EntityState<Transaction>, ActiveState { ui: TransactionsUI }

const initialState = { 
  ui: {
    selectedMonth: null
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'transactions', resettable: true })
export class TransactionsStore extends EntityStore<TransactionsState, Transaction> {

  constructor(private chargeBoxesQuery: ChargeBoxesQuery, private chargePointsQuery: ChargePointsQuery) {
    super(initialState);
  }

  setTransactions(transactions: Transaction[]) {
    transactions = transactions.map(m => this.parseTransaction(m)).filter(t => t.chargePoint);
    this.add(transactions);
  }

  parseTransaction(transaction) {
    const chargeBox = this.chargeBoxesQuery.getEntity(transaction.chargeBoxId);
    let chargePoint;
    if (!transaction.chargePointId && !chargeBox) {
      chargePoint = undefined;
    } else {
      chargePoint = this.chargePointsQuery.getEntity(transaction.chargePointId || chargeBox.chargePointId ||  null);
    }
    transaction.socketType = chargeBox ? chargeBox.socketType : 'undefined';
    transaction.chargePoint = chargePoint ? chargePoint.name : undefined;
    transaction.id = transaction.id || transaction._id;
    /* transaction.discount = transaction.price && transaction.price.discount ? transaction.price.discount : null;
    transaction.price = transaction.price && transaction.price.price ? transaction.price.price.total : 0; */
    return transaction;
  }

}
