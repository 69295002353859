<div class="gradient"></div>
  <google-map height="100vh"
  width="100vw" [zoom]="mapCenter.zoom" [options]="options" (mapInitialized)="mapReady($event)"
  (mapClick)="mapClick()" (zoomChanged)="onZoomChange()" (idle)="onIdle()">
  <ng-container *ngIf="userPosition$ | async as userPosition">
    <map-marker 
    [position]="{lat: userPosition.coords.latitude, lng: userPosition.coords.longitude}"
    [options]="userMarkerOptions">
    </map-marker>
  </ng-container>
  <map-marker-clusterer [averageCenter]="true" [zoomOnClick]="true" [styles]="clusterStyle" [maxZoom]="14">
    <ng-container *ngFor="let chargePoint of chargePoints$ | async; trackBy: trackByFn">
      <map-marker [position]="{lat: chargePoint.coordinate.lat, lng: chargePoint.coordinate.lng}"
        [options]="{ icon: { url: getIconUrl(chargePoint, activeChargePoint), scaledSize: {height: 48, width: 38} } }"
        (mapClick)="chargePointClicked(chargePoint.id)">
      </map-marker>
    </ng-container>
  </map-marker-clusterer>
  <map-marker-clusterer [averageCenter]="true" [zoomOnClick]="true" [styles]="futureClusterStyle" [maxZoom]="14">
    <ng-container *ngFor="let futureChargePoint of futureChargePoints$ | async; trackBy: trackByFn">
      <map-marker [position]="{lat: futureChargePoint.coordinate.lat, lng: futureChargePoint.coordinate.lng}"
        [options]="{ icon: { url: getIconUrl(futureChargePoint, activeChargePoint), scaledSize: {height: 48, width: 38} } }"
        (mapClick)="chargePointClicked(futureChargePoint.id)">
      </map-marker>
    </ng-container>
  </map-marker-clusterer>
</google-map>