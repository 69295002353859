import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import * as moment from 'moment';
import { LanguageService } from 'src/app/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { DiscountService } from 'src/app/routes/discounts/state/discount.service';
import { DiscountQuery } from 'src/app/routes/discounts/state/discount.query';
import { Discount } from 'src/app/routes/discounts/state/discount.model';
import DiscountHelper from 'src/app/helpers/discount-helper';

@Component({
  selector: 'kc-discount-dialog',
  templateUrl: './discount-dialog.component.html',
  styleUrls: ['./discount-dialog.component.scss'],
})
export class DiscountDialogComponent implements OnInit {
  // switchState$ = new BehaviorSubject<boolean>(false);
  discount$: Observable<Discount>;
  discount: Discount;
  locations$: Observable<any>;
  locationsOpen: boolean;
  defaultLoading: boolean;

  constructor(
    @Inject(PORTAL_DATA) public data,
    private languageService: LanguageService,
    private translate: TranslateService,
    private discountService: DiscountService,
    private discountQuery: DiscountQuery
  ) {}

  ngOnInit() {
    this.locationsOpen = false;
    this.defaultLoading = false;
    this.discount$ = this.discountQuery.selectEntity(this.data.data._id);
    this.locations$ = this.discountService.getLocations(this.data.data._id);
    // this.switchState$.next(this.data.data.defaultDiscount);
  }

  close() {
    this.data.close();
  }

  floor(number: number) {
    return Math.floor(number);
  }

  expires(discount: Discount) {
    return DiscountHelper.getExpirationDate(discount);
  }

  canExpire(discount: Discount) {
    return DiscountHelper.canExpire(discount);
  }

  usagePercentage(out: number, max: number) {
    const usage = max - out;
    return (usage / max) * 100;
  }

  dayPercentage(discount: Discount) {
    const expirationDate = DiscountHelper.getExpirationDate(discount);

    if (!expirationDate) { return false; }

    const timespan = moment(expirationDate).diff(
      discount.campaign.begins,
      'days'
    );
    const timePassed = expirationDate.diff(moment(), 'days');
    return 100 - (timePassed / timespan) * 100;
  }

  getRemainingTime(endTime: string): string {
    const remainingTime = moment(endTime).diff(moment(), 'days') + 1;
    return remainingTime + ' ' + this.translate.instant('days');

    /* if (remainingTime > 30) {
      const months = moment(endTime).diff(moment(), 'months');
      if (months > 1) {
        return months + ' ' + this.translate.instant('months');
      } else {
        return months + ' ' + this.translate.instant('month');
      }
    } else {
      return remainingTime + ' ' + this.translate.instant('days');
    } */
  }

  switchChange(value: boolean) {
    if (!this.defaultLoading) {
      this.defaultLoading = true;
      if (value) {
        this.discountService.setDefault(this.data.data._id).subscribe(() => {
          this.discountService.get().subscribe(() => {});
          this.defaultLoading = false;
        });
      } else {
        this.discountService.clearDefault().subscribe(() => {
          this.discountService.updateDiscount(this.data.data._id, {
            defaultDiscount: false,
          });
          this.defaultLoading = false;
        });
      }
    }
  }

  toggleLocations(value: boolean) {
    this.locationsOpen = !this.locationsOpen;
  }
}
