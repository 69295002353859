<div class="socketStatusContainer">
  <div class="status" [ngClass]="socketStatus(socket.status)" *ngIf="!socket.roaming; else roamingSocketStatusTpl">
    <span class="socket">{{socket['uniqueId']}}</span>
    <span class="info">{{socket['socketType'] | translate }} • {{socket['power'] / 1000}} kW</span>
  </div>
  <img
    src="{{ socket.roaming ? '../../../../assets/icons/png/icon-arrow-s-right@2x_blue.png' : '../../../../assets/icons/png/icon-arrow-s-right@2x.png' }}"
    alt="">
  <div class="status" [ngClass]="isCharging ? '--delayed' : ''">
    <img
      src="{{ socket.roaming ? '../../../../assets/icons/png/icon-car@2x_blue.png' : '../../../../assets/icons/png/icon-car@2x.png'}}"
      alt="">
    <span class="info">{{'yourCar' | translate}}</span>
  </div>
</div>
<ng-template #roamingSocketStatusTpl>
  <div class="status" [ngClass]="socketStatus(socket.status)">
    <img src="../../../../../assets/icons/png/icon-pumpElectricity@2x_blue.png" alt="">
    <span class="info">{{ socket.uniqueId }}</span>
  </div>
</ng-template>
