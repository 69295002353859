import { Pipe, PipeTransform } from '@angular/core';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';

@Pipe({
  name: 'chargePointAutoFill'
})
export class ChargePointAutoFillPipe implements PipeTransform {

  constructor(private chargePointsQuery: ChargePointsQuery, private chargeBoxesQuery: ChargeBoxesQuery) {

  }

  transform(text: string, chargePoints: ChargePoint[]): ChargePoint[] {
    if (text && text.length > 0) {
      text = text.toLowerCase();
      let possibleChargePoints = [];
      possibleChargePoints = chargePoints.filter((chargePoint) => {
        const chargeBoxes = this.chargeBoxesQuery.getAll({ filterBy: entity => entity.chargePointId === chargePoint.id });
        return chargePoint.name.toLowerCase().includes(text) ||
        chargePoint.streetAddress.toLowerCase().includes(text) ||
        chargePoint.postalCode.includes(text) ||
        chargePoint.city.toLowerCase().includes(text) ||
        chargeBoxes.some(chargeBox => chargeBox.uniqueId && chargeBox.uniqueId.toString() === text);
      });
      return possibleChargePoints;
    } else {
      return [];
    }
  }
}
