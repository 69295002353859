import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { environment } from 'src/environments/environment';
import * as storage from './payment-info-dialog.storage';

@Component({
  selector: 'kc-payment-info-dialog',
  templateUrl: './payment-info-dialog.component.html',
  styleUrls: ['./payment-info-dialog.component.scss']
})
export class PaymentInfoDialogComponent implements OnInit {

  environment = environment;

  constructor(@Inject(PORTAL_DATA) public data
  ) { }

  ngOnInit() {}

  /**
   * Close edit app settings overlay dialog
   */
  close() {
    storage.setPaymentInfoDialog(true);
    this.data.close();
  }
}
