import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { environment } from 'src/environments/environment';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';

@Component({
  selector: 'kc-session-expired-dialog',
  templateUrl: './session-expired-dialog.component.html',
  styleUrls: ['./session-expired-dialog.component.scss']
})
export class SessionExpiredDialogComponent implements OnInit {

  environment = environment;

  constructor(@Inject(PORTAL_DATA) public data,
  private kIDChargeProfilervice: KChargeProfileService) { }

  ngOnInit() {}

  /**
   * Close edit app settings overlay dialog
   */
  close() {
    this.kIDChargeProfilervice.logout();
    this.data.close();
  }
}
