import { Component, Inject, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Price } from 'src/app/map/charge-boxes/charge-box.model';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';

@Component({
  selector: 'kc-pricing-dialog',
  templateUrl: './pricing-dialog.component.html',
  styleUrls: ['./pricing-dialog.component.scss']
})
export class PricingDialogComponent implements OnInit {

  schedules: Price[];

  constructor(@Inject(PORTAL_DATA) public data,
  private translate: TranslateService, private kidSessionQuery: KIDSessionQuery) { }

  ngOnInit() {
    this.schedules = JSON.parse(JSON.stringify(this.data.data));
    this.schedules.sort((a, b) => a.from > b.from ? 1 : -1);
  }

  close() {
    this.data.close();
  }

  getTimePriceTitle(price): string {
    let text: string;
    if (price.freeMinutes) {
      const formattedTime = this.getFormattedTime(price.freeMinutes);
      const titlePart1 = this.translate.instant('priceTitlePart1');
      const titlePart2 = this.translate.instant('priceTitlePart2');
      let units: string;
      if (formattedTime.value === 1) {
        units = formattedTime.unit === 'hours' ? this.translate.instant('titleHour') : this.translate.instant('titleMinute');
      } else {
        units = formattedTime.unit === 'hours' ? this.translate.instant('titleHours') : this.translate.instant('titleMinutes');
      }
      text = `${titlePart1} ${formattedTime.value} ${units} ${titlePart2}`;
    } else if (!price.energy) {
      text = this.translate.instant('price');
    } else {
      text = this.translate.instant('timeBasedAdditionalFee');
    }
    return text;
  }

  getTimePriceSubtitle(price): string {
    let text: string;

    if (price.freeMinutes) {
      const formattedTime = this.getFormattedTime(price.freeMinutes);
      let units: string;
      if (formattedTime.value === 1) {
        units = formattedTime.unit === 'hours' ? this.translate.instant('subtitleHour') : this.translate.instant('subtitleMinute');
      } else {
        units = formattedTime.unit === 'hours' ? this.translate.instant('subtitleHours') : this.translate.instant('subtitleMinutes');
      }
      text = `${this.translate.instant('doesNotApply')} ${formattedTime.value > 1 ? formattedTime.value : ''} ${units}`;
    } else {
      text = this.translate.instant('appliesUntil');
    }
    return text;
  }

  getTimePricePrinciple(price): string {
    let text: string;
    if (price.minuteStep) {
      const formattedTime = this.getFormattedTime(price.minuteStep);
      if (formattedTime.value === 1) {
        text = formattedTime.unit === 'hours' ? this.translate.instant('startingHour') : this.translate.instant('startingMinute');
      } else {
        text = `${this.translate.instant('starting')} ${formattedTime.value} ${formattedTime.unit === 'hours' ? 'h' : 'min'}`;
      }
    } else {
      text = 'min';
    }

    return text;
  }

  getFormattedTime(duration: number): {value: number, unit: string} {
    let time = { value: 0, unit: 'minutes'};
    if (duration % 60 === 0) {
      time = { value: duration / 60, unit: 'hours'};
    } else {
      time = { value: duration, unit: 'minutes'};
    }
    return time;
  }

}
