import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Discount } from './discount.model';
import { DiscountStore } from './discount.store';

@Injectable({ providedIn: 'root' })
export class DiscountService {

  constructor(private discountStore: DiscountStore,
    private http: HttpClient) {
  }

  get() {
    return this.http.get<Discount[]>(`${environment.BACKEND_URL}/discounts`, { withCredentials: true }).pipe(
      tap(discounts => {
        this.discountStore.set(discounts);
      })
    );
  }

  setActive(_id: string) {
    this.discountStore.setActive(_id);
  }


  getLocations(discountId: string) {
    return this.http.get(`${environment.BACKEND_URL}/discounts/${discountId}/locations`, { withCredentials: true });
  }

  getEligibleDiscounts(chargePointId) {
    return this.http.get(`${environment.BACKEND_URL}/discounts?chargePointId=${chargePointId}`, { withCredentials: true });
  }

  setDefault(discountId: string) {
    return this.http.post(`${environment.BACKEND_URL}/discounts/${discountId}/set-default`, {}, { withCredentials: true });
  }

  setSelectedDiscount(discountId: string) {
    return this.http.post(`${environment.BACKEND_URL}/discounts/${discountId}/select`, {}, { withCredentials: true });
  }

  clearSelectedDiscount() {
    return this.http.post(`${environment.BACKEND_URL}/clear-selected-discount`, {}, { withCredentials: true });
  }

  /* addUserToDiscount(discountCode) {
    return this.http.post(`${environment.BACKEND_URL}/discounts/${discountCode}/users`, {}, { withCredentials: true });
  } */

  clearDefault() {
    return this.http.post(`${environment.BACKEND_URL}/clear-default-discount`, {}, { withCredentials: true });
  }

  updateDiscount(id: string, updateObject: {}) {
    this.discountStore.update(id, updateObject);
  }

  redeemDiscountCode(code: string) {
    return this.http.post(`${environment.BACKEND_URL}/discounts/redeem`, { discountCode: code }, { withCredentials: true });
  }
}
