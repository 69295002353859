import { InjectionToken } from '@angular/core';

/**
 * This storage handles getting, setting and removing overlay dialog state
 */

export const PORTAL_DATA = new InjectionToken<{}>('PortalData');
const ACTIVE_DIALOG = 'openOverlayDialog';

/**
 * Get active overlay dialog from local storage
 * If there is no active dialog, then return null.
 */
export function getActiveDialog() {
    let activeDialog = localStorage.getItem(ACTIVE_DIALOG);
    if (activeDialog) {
        try {
            activeDialog = JSON.parse(activeDialog);
        } catch (err) {
            activeDialog = null;
        }
    } else {
        activeDialog = null;
    }
    // To fix expired session loop.
    if (activeDialog && activeDialog['dialog'] === 'SessionExpiredDialogComponent') {
        this.removeActiveDialog();
        activeDialog = null;
    }
    return activeDialog;
}

/**
 * Sets new Active overlay dialog to local storage
 * @param dialog Active overlay dialog
 * @param data Active dialog data
 */
export function setActiveDialog(dialog, data) {
    localStorage.setItem(ACTIVE_DIALOG, JSON.stringify({dialog, data}));
}

/**
 * Removes any active overlay dialog from local storage.
 */
export function removeActiveDialog() {
    localStorage.removeItem(ACTIVE_DIALOG);
}

/**
 * Parses local storage string to object.
 * @param item item to parse.
 */
export function parseLocalStorageItem(item: string) {
    if (item) {
        try {
            item = JSON.parse(item);
        } catch (err) {
            item = null;
        }
    } else {
        item = null;
    }

    return item;
}
