// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const backend_url = "/backend";

export const environment = {
  local: false,
  development: false,
  production: true,
  BACKEND_URL: backend_url,
  SOCKET_URL: "https://socket.plugitcloud.com",
  GOOGLE: {
    MAPS_API_KEY: "AIzaSyB47s1nipf4Hn84Shx7Ef93fLXVJv0V_Ps",
    ANALYTICS_CODE: "UA-135196092-1",
  },
  PSP: {
    URL: "https://epayment1.point.fi",
    URL2: "https://epayment2.point.fi",
    REDIRECT_URL: "https://app.k-lataus.fi",
  },
  K_ID: {
    CLIENT_ID: "k-lataus-pwa",
    VERSION: "1535", // Latest working version 1521
    ENV: "prod",
    LOGIN_URL: `${backend_url}/login`,
    LOGOUT_URL: `${backend_url}/logout`,
    SECURE_URL: `${backend_url}/userinfo`,
    PROFILE_URL: "https://k-tunnus.fi/#/details",
    AUTHORIZATION_URL:
      "https://authentication.kesko.fi/oic/v1/public/authorize?response_type=code&client_id=k-lataus-pwa&redirect_uri=",
    AUTHORIZATION_REDIRECTION_URL: "https://app.k-lataus.fi/backend/login",
    K_ID_LOGOUT_URL:
      "https://authentication.kesko.fi/oic/v1/public/logout?redirect=true",
  },
  K_LATAUS: {
    BASE_URL: "https://k-lataus.fi/",
    TOS_URL: "https://k-lataus.fi/sovelluksen_kayttoehdot/",
    FAQ_URL: "https://k-lataus.fi/ohjeet/",
    SERVICE_AGREEMENT_URL: "https://k-lataus.fi/sopimusehdot-14-9-alkaen/",
  },
  LOGGING: {
    PROJECT_NAME: "banded-earth-141710",
    SERVICE_NAME: "K-LATAUS-PWA",
    WEB_KEY: "AIzaSyADlYLwK4gmt7FXW5P_Y4B5mNFaIaYgARE",
    ANDROID_KEY: "",
    IOS_KEY: "",
  },
  ROAMING: {
    HUBJECT_URL: `${backend_url}/roaming/evse`,
    BASE_URL: `${backend_url}/roaming`,
  },
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
