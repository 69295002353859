import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { environment } from 'src/environments/environment';
import { MonthlyInvoicingAgreementInvoice } from './invoicing-agreement-invoice.model';
import { InvoicingAgreementInvoiceStore } from './invoicing-agreement-invoice.store';

@Injectable({ providedIn: 'root' })
export class InvoicingAgreementInvoiceService {

  constructor(
    private invoicingAgreementInvoiceStore: InvoicingAgreementInvoiceStore,
    private http: HttpClient,
    private overlayDialogService: OverlayDialogService,
  ) {}

  getInvoicingAgreementInvoices() {
    this.http.get<MonthlyInvoicingAgreementInvoice>(`${environment.BACKEND_URL}/profile/invoicing-agreement-monthly-invoice`)
    .subscribe((res) => {
      if(res !== null) {
        this.invoicingAgreementInvoiceStore.set([res]);
        this.invoicingAgreementInvoiceStore.setLoading(false);
      }
      
    }, err => {
      if (err.status === 403) {
        this.overlayDialogService.openOverlayDialog('SessionExpiredDialogComponent');
      } else if (!err['message'].endsWith('0 Unknown Error')) {
        this.overlayDialogService.openOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo',
        'error': err, 'source': { 'component': 'invoicingAgreementInvoiceService', 'function': 'get'}});
        return throwError(err);
      }
    }); 
  }
  
}
