import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PORTAL_DATA } from '../../../overlay-dialog/overlay-dialog.storage';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { KChargeProfile } from 'src/app/k-charge-profile/k-charge-profile.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { requestStatus } from 'src/app/helpers/requestStatuses';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kc-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  environment = environment;

  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);
  // user contact info form
  userContactInfoForm: UntypedFormGroup;
  kChargeProfile$: Observable<KChargeProfile>;
  forceUpdate = false;
  // contactInfoRequired = true;
  updateName = false;
  updateEmail = false;

  constructor(@Inject(PORTAL_DATA) public data,
    private kChargeProfileService: KChargeProfileService,
    private kIDSessionQuery: KIDSessionQuery,
    public formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.initForm();

    // Get user current contact information
    this.kChargeProfile$ = this.kIDSessionQuery.kChargeProfile$;
    this.kChargeProfileService.get();

    this.kChargeProfile$.subscribe(profile => {
      this.userContactInfoForm.patchValue(profile);
    });
  }

  private initForm() {
    this.forceUpdate = this.data.data.forced;
    this.updateName = this.data.data.updateName;
    this.updateEmail = this.data.data.updateEmail;
    const requiredValidator = Validators.required;

    this.userContactInfoForm = this.formBuilder.group({
      givenName: ['', [Validators.required]],
      familyName:  ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      contactInfo: (!this.updateName && !this.updateEmail) ? this.formBuilder.group({
        streetAddress: ['', [requiredValidator]],
        co: [''],
        postalCode: [
          '',
          [Validators.maxLength(10),
          Validators.pattern('^[0-9]*$'),
          requiredValidator]
        ],
        city: ['', [requiredValidator]],
        phoneNumber: [
          '',
          [Validators.pattern('\\+[\\d\\s]+'),
          Validators.minLength(5),
          Validators.maxLength(20),
          requiredValidator]
        ],
        
      }) : undefined,
    }, {});

    if (this.updateName || this.updateEmail) {
      this.userContactInfoForm.removeControl('contactInfo');
    } else {
      this.userContactInfoForm.removeControl('givenName');
      this.userContactInfoForm.removeControl('familyName');
    }

    if (!this.updateEmail) {
      this.userContactInfoForm.removeControl('email');
    }
  }

  get givenName() { return this.userContactInfoForm.get('givenName'); }
  get familyName() { return this.userContactInfoForm.get('familyName'); }
  get email() { return this.userContactInfoForm.get('email'); }
  get streetAddress() { return this.userContactInfoForm.get('contactInfo').get('streetAddress'); }
  get co() { return this.userContactInfoForm.get('contactInfo').get('co'); }
  get postalCode() { return this.userContactInfoForm.get('contactInfo').get('postalCode'); }
  get city() { return this.userContactInfoForm.get('contactInfo').get('city'); }
  get phoneNumber() { return this.userContactInfoForm.get('contactInfo').get('phoneNumber'); }

  

  /**
   * Update K-Charge profile information
   */
  save(form) {
    this.requestStatus$.next(requestStatus.SEND);

    this.kChargeProfileService.updateProfile(form.value).subscribe(res => {
      this.requestStatus$.next(requestStatus.ACCEPTED);

      if (res['tags'] && res['tags'].length === 0 && !res['tagOrdered']) {
        this.data.openNewOverlayDialog('ManageTagsComponent');
      } else {
        // this.close();
      }
    }, err => {
      this.requestStatus$.next(requestStatus.PENDING);
      this.data.openNewOverlayDialog('ErrorDialogComponent', { 'message': 'somethingWentWrongInfo', 'error': err,
        'source': { 'component': 'EditUserComponent', 'function': 'updateProfile'}});
      throw err;
    });
  }

  /**
   * If there's no phone number, helps out by filling in the plus sign.
   */
  insertPlusSign() {
    if (this.phoneNumber.value.length === 0) {
      this.phoneNumber.patchValue('+');
    }
  }

  /**
   * Close edit contact information overlay dialog
   */
  close() {
    this.data.close();
  }

  /**
   * Log user out.
   */
  logout() {
    this.data.close();
    this.kChargeProfileService.logout();
  }
}
