import * as Moment from "moment";
import { Discount } from "../routes/discounts/state/discount.model";

export default class DiscountHelper {
  /**
   * Get expiration date from campaign & discount, compare which is before
   * and return it.
   */
  static getExpirationDate(discount: Discount) {
    if (discount.expirationDate && discount.campaign.ends) {
      const discountExpirationDate = Moment(discount.expirationDate);
      const campaignExpirationDate = Moment(discount.campaign.ends);

      if (discountExpirationDate.isBefore(campaignExpirationDate)) {
        return discountExpirationDate.subtract(1, "days");
      } else {
        // Quick fix before dates are corrected from Management Tool
        return campaignExpirationDate.subtract(1, "days");
      }
    }

    if (discount.expirationDate) {
      return Moment(discount.expirationDate).subtract(1, "days");
    } else {
      // Quick fix before dates are corrected from Management Tool
      return Moment(discount.campaign.ends).subtract(1, "days");
    }

    /* if (discount.campaign.ends) {
      return Moment(discount.campaign.ends).subtract(1, 'days');
    } */

    return false;
  }

  static canExpire(discount: Discount) {  
    if (discount.expirationDate || discount.campaign.ends) {
      return true;
    }
    return false;
  }
}
