import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SinglePaymentService {

  constructor(private http: HttpClient) {
  }

  /*≈
   * Get blueCommerce single payment meta data for credit card page
   * @param email user email, used for sending receipts
   * @param chargePointId selected charge point id
   */
  getSinglePaymentInitData(email: string, chargePointId: string, chargeBoxId): Observable<string> {
    // tslint:disable-next-line:max-line-length
    return this.http.post<any>(`${environment.BACKEND_URL}/single-payment/init-data?chargepointid=${chargePointId}&chargeboxid=${chargeBoxId}&email=${email}`, '').pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      }));
  }

  /**
   * Get active single payment session from backend
   */
  getSinglePaymentSession() {
    return this.http.get(`${environment.BACKEND_URL}/single-payment/has-active-session`).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  /**
   * Get active single payment session from backend. Ignores activity state.
   */
  getHasSinglePaymentSession() {
    return this.http.get(`${environment.BACKEND_URL}/single-payment/has-session`).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }
}
