<div class="overlayContainer__wrapper" [ngClass]="updateName ? '--noMinHeight' : ''">
  <ng-container *ngIf="(requestStatus$ | async) !== 2; else requestSendTpl">
    <div class="overlayContainer__header">
      <span class="title">{{ 'editContactInfo' | translate }}</span>
      <span class="info">{{ 'editContactInfoText' | translate }}</span>
    </div>
    <div class="overlayContainer__contentWrapper">
      <form #form class="overlayContainer__contentTemplate" [formGroup]="userContactInfoForm"
        (ngSubmit)="save(userContactInfoForm)">
        <ng-container formGroupName="contactInfo">
          <div class="inputContainer">
            <label for="co">{{ 'careOfWithInfo' | translate }}</label>
            <input type="text" id="co" formControlName="co">
            <div *ngIf="co?.valid" class="validation --ok">
              <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
            </div>
          </div>
        </ng-container>
        <div class="inputContainer">
            <label for="givenName">{{ 'givenName' | translate }}</label>
            <input type="text" id="givenName" formControlName="givenName">
            <div *ngIf="givenName?.invalid && (givenName?.dirty || givenName?.touched)" class="validation --alert">
              <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
            </div>
            <div *ngIf="givenName.valid" class="validation --ok">
              <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
            </div>
          </div>
          <div class="inputContainer">
            <label for="familyName">{{ 'familyName' | translate }}</label>
            <input type="text" id="familyName" formControlName="familyName">
            <div *ngIf="familyName?.invalid && (familyName?.dirty || familyName?.touched)" class="validation --alert">
              <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
            </div>
            <div *ngIf="familyName?.valid" class="validation --ok">
              <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
            </div>
          </div>
        <div formGroupName="contactInfo">
          <div class="inputContainer">
            <label for="phoneNumber">{{ 'phoneNumber' | translate }}</label>
            <input #phoneNumberInput type="tel" id="phoneNumber" placeholder="+358xx xxxxxxx"
              formControlName="phoneNumber" (click)="insertPlusSign()">
            <div *ngIf="phoneNumber?.invalid && (phoneNumber?.dirty || phoneNumber?.touched)" class="validation --alert">
              <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
            </div>
            <div *ngIf="phoneNumber?.valid" class="validation --ok">
              <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
            </div>
          </div>
          <div class="inputContainer">
            <label for="streetAddress">{{ 'streetAddress' | translate }}</label>
            <input type="text" id="streetAddress" formControlName="streetAddress">
            <div *ngIf="streetAddress.invalid && (streetAddress.dirty || streetAddress.touched)"
              class="validation --alert">
              <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
            </div>
            <div *ngIf="streetAddress?.valid" class="validation --ok">
              <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
            </div>
          </div>
          <div class="doubleRow">
            <div class="inputContainer --w30">
              <label for="postalCode">{{ 'postalCode' | translate }}</label>
              <input type="tel" id="postalCode" formControlName="postalCode">
              <div *ngIf="postalCode.invalid && (postalCode.dirty || postalCode.touched)" class="validation --alert">
                <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
              </div>
              <div *ngIf="postalCode?.valid" class="validation --ok">
                <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
              </div>
            </div>
            <div class="inputContainer --w55">
              <label for="city">{{ 'city' | translate }}</label>
              <input type="text" id="city" formControlName="city">
              <div *ngIf="city.invalid && (city.dirty || city.touched)" class="validation --alert">
                <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
              </div>
              <div *ngIf="city?.valid" class="validation --ok">
                <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
              </div>
            </div>
          </div>
        </div>

        <div class="overlayContainer__actionContainer">
          <button *ngIf="!forceUpdate" class="overlayContainer__actionButton --white --left" (click)="close()">
            <span>{{ 'close' | translate | uppercase}}</span>
          </button>
          <button *ngIf="forceUpdate" class="overlayContainer__actionButton --white --left" (click)="logout()">
            <span>{{ 'logout' | translate | uppercase}}</span>
          </button>
          <button type="submit" class="overlayContainer__actionButton --orange --right"
            [disabled]="!userContactInfoForm.valid || (requestStatus$ | async) === 1">
            <ng-container *ngIf="(requestStatus$ | async) === 1; else sendTpl">
              <div class="spinner --small">
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
                <div class="--small"></div>
              </div>
              <span>{{ 'saving' | translate | uppercase}}</span>
            </ng-container>
            <ng-template #sendTpl>
              <span>{{ 'order' | translate | uppercase}}</span>
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-template #requestSendTpl>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="overlayContainer__contentCentered">
          <div class="iconContainer">
            <div class="-white">
              <img src="../../../../assets/logos/k-charge-tag.png" alt="">
            </div>
          </div>
          <div>
            <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
          </div>
          <div class="overlayContainer__header">
            <span class="title">{{ 'tagOrderSendTitle' | translate }}</span>
          </div>
        </div>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ 'close' | translate | uppercase}}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
