<div class="overlayContainer__wrapper --h100 --scrollable">
  <div class="overlayContainer__contentWrapper --h100">
    <div class="overlayContainer__contentTemplate">
      <ng-container *ngIf="!feedbackSent; else feedbackSentTpl">
        <div class="closeButtonContainer" (click)="close()">
          <i class="fak fa-k-close"></i>
        </div>
        <div class="headerContainer">
          <span class="title"> {{ 'feedbackTitle' | translate }} </span>
          <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
        </div>
        <div class="infoContainer">
          <div class="feedbackContainer">
            <div class="thumbsContainer">
              <i class="fak fa-k-thumb-up" (click)="sendPositiveFeedback()"></i>
              <div class="separator"></div>
              <div class="iconContainer">
                <i class="fak fa-k-thumb-down"></i>
              </div>
            </div>
          </div>
          <div class="options">
            <span class="optionTitle">{{ 'howDidChargingGo' | translate }}</span>
            <div class="optionContainer">
              <div class="problemOption" [ngClass]="feedbackForm.get('negativeCategory').value == 'cannotStartCharging' ? '-selected' : ''" (click)="setNegativeFeedbackCategory('cannotStartCharging')">
                <span class="problemText">{{'cannotStartCharging' | translate}}</span>
              </div>
              <div class="problemOption" [ngClass]="feedbackForm.get('negativeCategory').value == 'chargingInterrupted' ? '-selected' : ''" (click)="setNegativeFeedbackCategory('chargingInterrupted')">
                <span class="problemText">{{'chargingInterrupted' | translate}}</span>
              </div>
              <div class="problemOption" [ngClass]="feedbackForm.get('negativeCategory').value == 'pricingProblem' ? '-selected' : ''" (click)="setNegativeFeedbackCategory('pricingProblem')">
                <span class="problemText">{{'pricingProblem' | translate}}</span>
              </div>
              <div class="problemOption" [ngClass]="feedbackForm.get('negativeCategory').value == 'insufficientPower' ? '-selected' : ''" (click)="setNegativeFeedbackCategory('insufficientPower')">
                <span class="problemText">{{'insufficientPower' | translate}}</span>
              </div>
              <div class="problemOption" [ngClass]="feedbackForm.get('negativeCategory').value == 'other' ? '-selected' : ''" (click)="setNegativeFeedbackCategory('other')">
                <span class="problemText">{{'other' | translate}}</span>
              </div>
            </div>
          </div>
          <form [formGroup]="feedbackForm">
            <div class="freeFeedbackContainer" [ngClass]="feedbackForm.get('negativeCategory').value == 'other' ? '' : '-hidden'">
              <span>{{'pleaseDescribeIssue' | translate}}</span>
              <textarea type="textArea" formControlName="freeFeedback" maxlength="250"></textarea>
            </div>
          </form>
        </div>
      </ng-container>
      <ng-container *ngIf="showErrors">
        <p>{{'feedbackError' | translate}}</p>
      </ng-container>
      <div class="overlayContainer__actionContainer">
        <ng-container *ngIf="!isSending; else loaderTemplate">
          <button class="overlayContainer__actionButton --white" (click)="submitButtonClicked()">
            <span>{{ (feedbackSent ? 'close' : 'send') | translate | uppercase}}</span>
          </button>
        </ng-container>
        <ng-template #loaderTemplate>
          <kc-local-loader></kc-local-loader>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #feedbackSentTpl>
  <div class="headerContainer" style="margin-bottom: 80px;">
    <span class="title"> {{ 'sent' | translate }} </span>
  </div>
  <div class="infoContainer">
    <div class="tickContainer">
      <span class="sentTitle">{{ 'thankYou' | translate }}</span>
      <i class="fak fa-k-tick"></i>
      <span class="sentInfo">{{ 'sentInfo' | translate}}</span>
    </div>
    <div class="linkContainer">
      <span>{{ 'youCanLeaveFeedback' | translate}}</span>
      <span class="feedbackLink" (click)="customerSupportClicked()"> {{ 'fromThisLink' | translate }}</span>
    </div>
  </div>
</ng-template>
