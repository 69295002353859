
<div class="overlayContainer__wrapper">
  <div class="overlayContainer__header">
    <span class="title -marginBottom -px20">{{ 'customerSupport' | translate }}</span>
    <span class="info -marginBottom -px10">{{ 'roamingStationInfoText1' | translate }} {{ chargePoint.operatorName | uppercase }} {{ 'roamingStationInfoText2' | translate }}</span>
    <span class="info -marginBottom -px10">{{ 'inCaseOfProblems' | translate }}</span>
  </div>

  <ng-container>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentPane">
        <div class="overlayContainer__contentTemplateLabel">
          <!-- <span>{{ 'chargingStationInfo' | translate | uppercase }}</span> -->
        </div>

        <div class="overlayContainer__header">
          <span class="info -bold -rem11">{{ chargePoint.operatorName }}</span>
          <span class="info -bold -rem11">{{ 'customerSupport' | translate }}</span>
        </div>
        <div class="overlayContainer__header">
            <ng-container *ngIf="socket.HotlinePhoneNumber">
                <div class="iconContainer">
                  <img src="../../../../../assets/icons/png/icon-phone@2x_blue.png" alt="">
                </div>
                <span class="info -marginBottom -px10 -bold -blue -rem12">{{ socket.HotlinePhoneNumber}}</span>
                <span class="info -blue -phoneNumberInfo">{{ 'customerSupportNumberInfo' | translate }}</span>
            </ng-container>
            <span *ngIf="!socket.HotlinePhoneNumber" class="info -marginBottom -px10">{{ 'numberNotAvailable' | translate }}</span>
        </div>

        <div class="overlayContainer__actionContainer">
          <kc-dialog-button [buttonText]="'close'" [color]="'--white --left --blue'" (onClick)="close()"></kc-dialog-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
