import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DebugService {
  constructor(private http: HttpClient) {}

  /**
   * Compares localization JSONs and logs missing keys and file codes (fi, en and sv).
   */
  compareLocalizationJSONs() {
    try {
      forkJoin([
        this.getJSON('./../assets/i18n/fi.json'),
        this.getJSON('./../assets/i18n/en.json'),
        this.getJSON('./../assets/i18n/sv.json')])
        .subscribe(jsons => {
          const missingKeys = [];
          jsons.forEach(json1 => {
            Object.keys(json1).forEach(key1 => {
              let i = 0;
              jsons.forEach(json2 => {
                if (json2 !== json1) {
                  if (!Object.keys(json2).find(key2 => key2 === key1)) {
                    const foundIndex = missingKeys.findIndex(k => k.key === key1);
                    const countryCode = this.parseIndexToCountry(i);
                    if (foundIndex === -1) {
                      missingKeys.push({ key: key1, indexes: [countryCode] });
                    } else {
                      if (!missingKeys[foundIndex].indexes.find(code => code === countryCode)) {
                        missingKeys[foundIndex].indexes.push(countryCode);
                      }
                    }
                  }
                }
                i++;
              });
            });
          });
          missingKeys.forEach(key => {
            console.error(`LOCALIZATION KEY: "${key.key}" IS MISSING FROM: ${key.indexes}`);
          });
        });
    } catch (err) {
      console.error(err);
    }
  }

  private parseIndexToCountry(index) {
    let countryCode = 'fi';
    switch (index) {
      case 0: {
        countryCode = 'fi';
        break;
      }
      case 1: {
        countryCode = 'en';
        break;
      }
      case 2: {
        countryCode = 'sv';
        break;
      }
      default: {
        countryCode = 'unknown';
        break;
      }
    }
    return countryCode;
  }

  private getJSON(url: string): Observable<any> {
    return this.http.get<any>(url);
  }
}
