import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ChargePointAutoFillPipe } from './charge-point-auto-fill.pipe';
import { GetSocketFromChargePointPipe } from './get-socket-from-charge-point.pipe';
import { ClickOutsideDirective } from 'src/app/helpers/click-outside.directive';

@NgModule({
  imports: [ CommonModule, FormsModule, ReactiveFormsModule, TranslateModule ],
  declarations: [ SearchBarComponent, GetSocketFromChargePointPipe, ClickOutsideDirective ],
  exports: [SearchBarComponent]
})
export class SearchBarModule { }
