import { toBoolean } from '@datorama/akita';

/**
 * This storage handles getting, setting and removing route container state on local storage
 */

const ROUTE_CONTAINER = 'isRouterOpen';

/**
 * Get route container stored state
 * If not found, then return false (route container closed)
 */
export function getRouteContainerState() {
    const routeContainerState = localStorage.getItem(ROUTE_CONTAINER);
    return routeContainerState ? toBoolean(routeContainerState) : false;
}

/**
 * Change route container state
 * @param routeContainerState current state of route container
 */
export function changeRouteContainerState(routeContainerState: Boolean) {
    localStorage.setItem(ROUTE_CONTAINER, routeContainerState.toString());
}

/**
 * Removes route container state from local storage
 */
export function clearRouteContainerState() {
    localStorage.removeItem(ROUTE_CONTAINER);
}
