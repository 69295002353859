import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';;
import { environment } from 'src/environments/environment';
import { InvoicingAgreement } from '../k-charge-profile/k-charge-profile.model';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InvoicingAgreementService {

  // TODO: Remove this after a store for invoicing agreements is done. This workaround is needed to prevent circular dependency error.
  // Implemented due to lack of time.
  public invitationAccepted$: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) { }

  public checkInvoicingAgreement() {
    try {
      this.http.get<InvoicingAgreement>(`${environment.BACKEND_URL}/profile/invoicing-agreement`).subscribe(res => {
        return true;
      });
    } catch(error) {
      return false;
    } 
  }

  public acceptInvitation(userId: string, invitationId: string) {
      return this.http.patch<InvoicingAgreement>(`${environment.BACKEND_URL}/invitations/${invitationId}/accept`, {}).pipe(tap(_ => this.invitationAccepted$.next(true)));
  }

  public rejectInvitation(invitationId: string) {
    return this.http.patch<InvoicingAgreement>(`${environment.BACKEND_URL}/invitations/${invitationId}/reject`, {});
  }

  
}
