import { Injectable, ErrorHandler, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { OverlayDialogService } from "../overlay-dialog/overlay-dialog.service";
import { ErrorsService } from "./errors.service";

@Injectable()
export class ErrorsHandler extends ErrorHandler {
  constructor(private injector: Injector) {
    super()
  }

    // private router = this.injector.get(Router);
    private _overlayDialogService: OverlayDialogService;
    private _errorsService: ErrorsService;

    private get overlayDialogService(): OverlayDialogService {
        if(!this._overlayDialogService){
            this._overlayDialogService = this.injector.get(OverlayDialogService);
        }
        return this._overlayDialogService;
    }

    // This getter ensures the existence of errorsService.
    private get errorsService(): ErrorsService {
        if (!this._errorsService) {
            this._errorsService = this.injector.get(ErrorsService);
        }
        return this._errorsService;
  }

  /**
   * Handles errors depending on the error type.
   */
  handleError(error: Error | HttpErrorResponse) {
    this.handleChunkLoadError(error);
    if (error instanceof HttpErrorResponse) {
      // Server or connection error happened
      if (!navigator.onLine) {
        // Handle offline error
        this.overlayDialogService.openOverlayDialog("ErrorDialogComponent", {
          message: "somethingWentWrongInfo",
          error: error,
          source: { component: "errorsHandler", function: "updateProfile" },
        });
      } else {
        // Don't report errors from cloud error reporting urls. This could cause an infinite loop.
        if (!error.url.includes("clouderrorreporting")) {
          // Handle Http Error (error.status === 403, 404...)
          this.errorsService.logErrorToStackdrive(error);
        } else {
          console.error("UNABLE TO POST THE ERROR TO CLOUD");
        }
      }
    } else {
      // Handle Client Error (Angular Error, ReferenceError...)
      this.errorsService.logErrorToStackdrive(error);
    }
    // Log the error anyway
    console.error("Error: ", error);
  }

  handleChunkLoadError(error: Error | HttpErrorResponse) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message) || (error.message.includes('Loading chunk') && error.message.includes('failed'))) {
      this.errorsService.logErrorToStackdrive(error);
      const secondsSinceReload = this.secondsSincePreviousReload();
      if (secondsSinceReload >= 30) {
        localStorage.setItem('prev_reload', new Date().toUTCString());
        window.location.reload();
      }
    }
  }

  secondsSincePreviousReload() {
    const prevReload = localStorage.getItem('prev_reload');
    if (!prevReload) return 9999;
    return ((new Date().getTime() - new Date(prevReload).getTime()) / 1000);
  }
}
