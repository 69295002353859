import { Injectable } from '@angular/core';
import { LoaderStore } from './loader.store';
import { Loader, createLoader } from './loader.model';

@Injectable({ providedIn: 'root' })
export class LoaderService {

  constructor(
    private loaderStore: LoaderStore,
  ) { }

  /**
   * Activates global spinner
   * @param name name of activated spinner
   */
  activateSpinner(name: string) {
    this.loaderStore.addNewLoader(createLoader(name));
  }

  /**
   * Deactivate global spinner
   * @param name name of deactivated spinner
   */
  deactivateSpinner(name: string) {
    this.loaderStore.removeLoader(name);
  }

}
