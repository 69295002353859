<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <div class="errorIcon">
          <img src="../../../../assets/icons/png/icon-tick@2x.png" alt="">
        </div>
        <span class="title">{{ 'lastMonthFeePayed' | translate }}</span>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <span class="infoText">{{ 'paymentSucceededInfo' | translate }}</span>
      </div>
      <div class="overlayContainer__actionContainer">
          <kc-dialog-button [buttonText]="'continue'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
