import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { KIDSessionQuery } from '../k-id-session/k-id-session.query';
import { AppVersionService } from '../services/app-version.service';

@Injectable({ providedIn: 'root' })
export class GaService {

  constructor(
    private appVersionService: AppVersionService,
    private kIDSessionQuery: KIDSessionQuery) {
  }

  /**
   * Send map interaction to GA
   * @param category map interaction category
   * @param action map interaction action
   * @param subject map interaction subject
   */
  public sendMapInteraction(category, action, subject?) {
    const gaEvent = {
      'event': 'mapInteraction',
      'mapInteractionCategory': category,
      'mapInteractionAction': action,
    };

    if (subject) {
      gaEvent['selectedChargePoint'] = subject;
    }

    this.gaSendEvent(gaEvent);
  }

  /**
   * Send user status to GA
   * @param category user category
   */
  public sendUserStatus(category) {
    const gaEvent = {
      'event': 'userStatus',
      'userStatusCategory': category,
      'userStatusUserId': this.kIDSessionQuery.isLoggedIn() ? 'Kirjautunut käyttäjä' : 'Kirjautumaton käyttäjä',
      'userStatusUserDevice': this.appVersionService.getVersion()
    };

    this.gaSendEvent(gaEvent);
  }

  /**
   * Send charging event to GA
   * @param category charge category
   * @param selectedChargingSocket selected charging socket (Unique ID)
   * @param paymentMethod registered user or single payment?
   */
  public sendChargeEvent(category, selectedChargingSocket, paymentMethod?) {
    const gaEvent = {
      'event': 'Charge',
      'chargeCategory': category,
      'selectedChargingSocket': selectedChargingSocket
    };

    if (paymentMethod) {
      gaEvent['paymentMethod'] = paymentMethod;
    }

    this.gaSendEvent(gaEvent);
  }

  public sendUserProfileEvent(category) {
    const gaEvent = {
      'event': 'userProfile',
      'userCategory': category,
    };

    this.gaSendEvent(gaEvent);
  }

  private gaSendEvent(event) {
    if (environment.production) {
      if (window['dataLayer']) {
        window['dataLayer'].push(event);
      }
    } else {
      // console.log('GA EVENT', event);
    }
  }
}
