import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState, ActiveState, guid } from '@datorama/akita';
import { ChargePoint, createChargePoint } from './charge-point.model';
import { createChargeBox, createRoamingChargeBox } from '../charge-boxes/charge-box.model';
import { ChargeBoxesStore } from '../charge-boxes/charge-boxes.store';
import { ChargeBoxesQuery } from '../charge-boxes/charge-boxes.query';
import * as moment from 'moment';

export interface ChargePointsState extends EntityState<ChargePoint>, ActiveState { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'charge-points' })
export class ChargePointsStore extends EntityStore<ChargePointsState, ChargePoint> {

  private ionityOperatorId = 'DE*ION';
  private ionityOperatorId2 = 'DE*IOY';

  constructor(private chargeBoxesStore: ChargeBoxesStore,
    private chargeBoxesQuery: ChargeBoxesQuery) {
    super();
  }

  /**
   * Add multiple charge points to the store
   * @param chargePoints array of charge point objects
   */
  addChargePoints(chargePoints: Partial<ChargePoint>[]) {
    const chargeBoxes = [];
    chargePoints = chargePoints.filter(
      chargePoint => chargePoint.chargeBoxes || (moment(chargePoint.installationDate).isAfter(moment()) && chargePoint.operative === false)
      ).map(chargePoint => {

      if (!chargePoint.id) {
        chargePoint.id = 'roaming-' + guid();
        chargePoint.roaming = true;
      }

      if(chargePoint.chargeBoxes) {
        chargePoint.chargeBoxes = chargePoint.chargeBoxes.filter(chargeBox => chargeBox.operative || chargePoint.roaming);
        chargePoint.chargeBoxes.forEach(chargeBox => {
          // Format charge box values if it's a roaming box.
          if (chargePoint.roaming) {
            chargeBox.chargePointId = chargePoint.id;
            // The power of charge box can be found inside of ChargingFacilities array. Set if exists.
            if (chargePoint.ChargingFacilities && chargePoint.ChargingFacilities.length === 1 && chargePoint.ChargingFacilities[0].Power) {
              chargeBox.power = chargePoint.ChargingFacilities[0].Power;
            }
            // Set the power of Ionity charge boxes to fixed 350. Remove this after Ionity fixes their values.
            if (chargePoint.operatorID === this.ionityOperatorId || chargePoint.operatorID === this.ionityOperatorId2) {
              chargeBox.power = 350;
          }
            chargeBoxes.push(createRoamingChargeBox(chargeBox));
          } else {
            const oldCB = this.chargeBoxesQuery.getEntity(chargeBox.id);
            if (oldCB) {
              if (!chargeBox.price || Object.keys(chargeBox.price).length === 0) {
                chargeBox.price = oldCB.price;
              }
            }
            chargeBoxes.push(createChargeBox(chargeBox));
          }
        });
      }

      chargePoint = createChargePoint(chargePoint);
      return chargePoint;
    });

    this.chargeBoxesStore.setChargeBoxes(chargeBoxes);
    this.set(chargePoints as ChargePoint[]);
  }

  updateChargePoint(id, chargePoint) {

    if (chargePoint.chargeBoxes) {
      this.chargeBoxesStore.updateChargeBoxes(chargePoint.chargeBoxes);
      chargePoint.chargeBoxes = chargePoint.chargeBoxes.map(chargeBox => {
        return this.chargeBoxesQuery.getEntity(chargeBox.id);
      });
    }

    this.update(id, chargePoint);
  }

}

