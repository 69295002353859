<div class="overlayContainer__wrapper --h100 --scrollable">
  <div class="overlayContainer__contentWrapper --h100">
    <div class="overlayContainer__contentTemplate">
      <div class="headerContainer">
        <div class="errorIcon">
          <img src="../../../../assets/icons/png/icon-info@2x.png" alt="">
        </div>
        <span class="title">Kirjautumispalvelun päivitys</span>
        <span class="title">Login service update</span>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <span class="infoText">Uudistettu K-Tunnus-kirjautumispalvelu otetaan käyttöön <span class="bold">11.1.2022.</span> Kirjautumisessa voi esiintyä hetkellisiä häiriöitä päivän aikana.</span>
        <span class="infoText">K-Tunnus login service will be updated on <span class="bold">11th of January 2022.</span> This may cause temporary issues with signing in.</span>        
      </div>
      <div class="overlayContainer__actionContainer --w100">
        <button class="overlayContainer__actionButton --white" (click)="close()">
          <span>{{'close' | translate | uppercase}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
