import { Component, OnInit, Input } from '@angular/core';
import { Invoice } from '../invoice.model';
import { Router } from '@angular/router';
import { PaymentTimeDifferenceHelper } from 'src/app/helpers/payment-time-difference-helper';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LockStatus } from 'src/app/k-id-session/k-id-session.store';

@Component({
  selector: 'kc-invoice-balance',
  templateUrl: './invoice-balance.component.html',
  styleUrls: ['./invoice-balance.component.scss']
})
export class InvoiceBalanceComponent implements OnInit {

  @Input() monthlyInvoice: Invoice;

  lockStatus$: Observable<LockStatus>;

  constructor(private router: Router, private KIDSessionQuery: KIDSessionQuery) { }

  ngOnInit() {
    this.lockStatus$ = this.KIDSessionQuery.select(state => state.lockStatus);
  }

  selectMonthlyInvoice(monthlyInvoice) {
    this.router.navigate(['/invoice/', monthlyInvoice.id]);
  }

  calculateDifference(monthlyInvoiceDate, days) {
    const difference = PaymentTimeDifferenceHelper.calculateDifference(monthlyInvoiceDate, days);
    return Math.max(0, difference);
  }

  paymentStatus(monthlyInvoiceDate) {

    const daysTillPayment = PaymentTimeDifferenceHelper.calculateDifference(monthlyInvoiceDate, 1);
    const daysTillExpire = PaymentTimeDifferenceHelper.calculateDifference(monthlyInvoiceDate, 14);

    if (daysTillPayment > 1 && daysTillPayment < 7) {
      return '-notify';
    }
    if (daysTillExpire < 15 && this.monthlyInvoice.paymentStatus === 'failed') {
      return '-alert';
    }
  }
}
