import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../../../overlay-dialog/overlay-dialog.storage';

@Component({
  selector: 'kc-cant-remove-payment-card',
  templateUrl: './cant-remove-payment-card.component.html',
  styleUrls: ['./cant-remove-payment-card.component.scss']
})
export class CantRemovePaymentCardComponent implements OnInit {

  constructor(@Inject(PORTAL_DATA) public data) { }

  ngOnInit() {
  }

  close() {
    this.data.close();
  }

}
