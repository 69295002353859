
<div class="transactionsChargingTransaction clickAble" (click)="openOverlayDialog(chargingTransaction)">
  <span class="transactionsChargingTransaction__tabBorder" [ngClass]="setIcon(chargingTransaction) == '-defaultOrange' ? 'tabOrange' : 'tabBlue'"></span>
  <div class="transactionsChargingTransaction__socketIcon">
    <i class="fak" [ngClass]="setIcon(chargingTransaction) == '-defaultOrange' ? 'fa-k-battery fa-xs' : 'fa-k-icon-charging-station fa-lg'"></i>
  </div>
  <div class="transactionsChargingTransaction__textContainer flex">
    <span class="title">{{chargingTransaction['chargePoint']}}</span>
    <div style="display: flex">
        <span class="text">{{chargingTransaction['timestampStart'] | localDate:'dd.MM.yyyy'}} </span>
        <span class="text -open">{{ isUnpaid ? (' - ' + (('open' | translate) | lowercase)) : '' }}</span>
    </div>
  </div>
  <div class="transactionsChargingTransaction__textContainer -rightAligned">
    <span class="title">{{ chargingTransaction['price'] / 100 | localCurrency }}</span>
    <span class="text">{{ chargingTransaction['energy'] / 1000 | localNumber:'1.1-1' }} kWh</span>
  </div>
</div>
