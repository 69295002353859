import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateDialogService {
  isActive$: Observable<boolean>;
  displayClose = true;
  updateContent: UpdateContent;
  buttonEvent = new Subject<any>();
  private promise: Promise<any>;
  private subscription: Subscription;

  constructor(private translateService: TranslateService) {}

  /* activateUpdateDialog(updateContent: UpdateContent[], displayClose = false)
    : Observable<any> {
    this.displayClose = displayClose;
    // this.isActive$ = true;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.buttonEvent.subscribe(res => {
      if (res) {
        resolve(res);
      } else {
        resolve(res);
      }
      reject(false);
    });


    return of(); */

    }
/* // Simple pop up window event. Reject or resolve based on button click event.
this.promise = new Promise((resolve, reject) => {
  // To prevent memory leaking.

} */

/* close() {
  if (this.subscription) {
    this.buttonEvent.next(false);
    this.subscription.unsubscribe();
  }
  setTimeout(() => { this.isActive = false; }, 1);
} */

/**
   * Accept / decline button click event. true === accept click, false === decline click.
   * @param event click event (true or false)
   */
/* onButtonClick(event) {
  this.buttonEvent.next(event);
  this.close();
} */
// }

export interface UpdateContent {
  date: string;
  info: string;
  notes: string[];
  language: string;
  type?: string;
}
