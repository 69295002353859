import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { LocalNumberPipe } from './local-number.pipe';
import { LocalCurrencyPipe } from './local-currency.pipe';
import { LocalDatePipe } from './local-date.pipe';
import { PriceStringPipe } from './price-string.pipe';

@NgModule({
  declarations: [LocalNumberPipe, LocalDatePipe, LocalCurrencyPipe, PriceStringPipe],
  imports: [
    CommonModule
  ],
  exports: [ LocalNumberPipe, LocalDatePipe, LocalCurrencyPipe ],
  providers: [CurrencyPipe]
})
export class LocalPipesModule { }
