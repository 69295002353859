import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'kc-attention-box',
  templateUrl: './attention-box.component.html',
  styleUrls: ['./attention-box.component.scss']
})
export class AttentionBoxComponent implements OnInit {
  @Input() title;
  @Input() subtitle;
  
  constructor() { }

  ngOnInit() {
  }

}
