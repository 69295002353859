<div class="overlayContainer__wrapper --scrollable">
  <div class="overlayContainer__header">
    <span class="title">{{ 'reportProblem' | translate }}</span>
    <span class="info">{{ chargePoint.name }}</span>
  </div>
  <div class="overlayContainer__socketContainer">
    <div class="status">
      <span class="socket">{{socket.uniqueId}}</span>
      <span class="info">{{socket.socketType}} • {{socket.power / 1000}} kW</span>
    </div>
  </div>

  <ng-container *ngIf="(requestStatus$ | async) !== 2; else requestSendTpl">
    <div class="overlayContainer__contentWrapper">
      <form class="overlayContainer__contentTemplate" [formGroup]="chargerProblemForm" (ngSubmit)="sendSupportRequest(chargerProblemForm)">
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ 'callAndReportProblem' | translate | uppercase }}</span>
        </div>
        <div class="overlayContainer__textContainer">
          
          <a href="tel:0207-350-333">
              <img src="../../../../assets/icons/png/icon-phone@2x.png" alt="">{{ 'supportCallNumber' | translate }}</a>
          <label for="" class="callPriceLabel">{{ 'supportCallPrice' | translate }}</label>
        </div>
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ 'reportProblemForm' | translate | uppercase }}</span>
        </div>
        <div class="overlayContainer__textContainer">
          <div class="infoContainer">
            <img src="../../../../../assets/icons/png/icon-info@2x.png" alt="">
            <span>{{ 'reportProblemInfo' | translate}}</span>
          </div>
          <div class="infoContainer --paddingLeft">
            <!-- <img src="../../../../../assets/icons/png/icon-info@2x.png" alt=""> -->
            <div>
              <span>{{ 'reportChargerProblemInfo' | translate}}</span>
              <!-- <span>{{ 'supportCallNumber' | translate}}</span>
              <span>{{ 'supportCallPrice' | translate}}</span> -->
            </div>
          </div>
        </div>
        
        <div class="inputContainer">
          <label for="car">{{ 'car' | translate }}</label>
          <input class="car" type="text" id="car" placeholder="{{ 'carMakeAndModel' | translate }}" formControlName="car">
        </div>
        <div class="inputContainer">
          <label for="description">{{ 'description' | translate }}</label>
          <textarea class="textarea" rows="2" cols="20" wrap="hard" id="description" placeholder="{{ 'describeProblem' | translate }}" formControlName="description"></textarea>
        </div>
        <div class="radioGroup" *ngIf="isLoggedIn()">
          <div class="inputContainer">
            <label for="reason">{{ 'wantToBeContacted' | translate}}</label>
            <input type="checkbox" formControlName="wantToBeContacted">
            <span class="radioButton"></span>
          </div>
        </div>

        <div class="overlayContainer__actionContainer">
          <button class="overlayContainer__actionButton --white --left" (click)="close()">
            <span>{{ 'cancel' | translate | uppercase}}</span>
          </button>
          <button type="submit" class="overlayContainer__actionButton --orange --right"
            [disabled]="!chargerProblemForm.valid || (requestStatus$ | async) === 1">
            <ng-container *ngIf="(requestStatus$ | async) === 1; else sendTpl">
                <div class="spinner --small">
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                </div>
              <span>{{ 'sending' | translate | uppercase}}</span>
            </ng-container>
            <ng-template #sendTpl>
              <span>{{ 'send' | translate | uppercase}}</span>
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </ng-container>

  <ng-template #requestSendTpl>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="successfulIcon">
          <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
        </div>
        <div class="overlayContainer__header">
          <span class="title">{{ 'problemReportedTitle' | translate }}</span>
          <span class="info">{{ 'problemReportedInfo' | translate }}</span>
        </div>
        <div class="overlayContainer__actionContainer --w100 --alignBottom">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ 'close' | translate | uppercase}}</span>
          </button>
        </div>
      </div>

    </div>
  </ng-template>
</div>
