import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore, ActiveState } from '@datorama/akita';
import { MonthlyInvoicingAgreementInvoice } from './invoicing-agreement-invoice.model';


export interface InvoicingAgreementInvoiceState extends EntityState<MonthlyInvoicingAgreementInvoice>, ActiveState { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invoicingAgreementInvoice', idKey: '_id', resettable: true })
export class InvoicingAgreementInvoiceStore extends EntityStore<InvoicingAgreementInvoiceState, MonthlyInvoicingAgreementInvoice> {

  constructor() {
    super();
  }

}
