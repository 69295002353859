<div class="overlayContainer__wrapper --scrollable">
  <div class="overlayContainer__contentPane">
    <div class="overlayContainer__header">
      <span class="title">{{ 'addDiscount' | translate }}</span>
    </div>
    <div class="overlayContainer__contentWrapper">
      <div class="imageContainer">
        <img src="../../../assets/images/svg/add-discount.svg" alt="">
      </div>
      <div class="overlayContainer__textContainer">
        <span class="info">{{ 'addDiscountInfo' | translate }}</span>
      </div>
      <form [formGroup]="discountForm">
        <div class="inputContainer">
          <label for="input">{{ 'discountCode' | translate }}</label>
          <input #input type="text" formControlName="discountCode" [placeholder]="'addDiscountPlaceholder' | translate">
          <label for="input" *ngIf="redeemError" class="--errorText">{{ 'problemWithCodeInfo' | translate }}</label>
        </div>
      </form>
      <!-- <div class="overlayContainer__textContainer">
        <span>{{ 'addDiscountError' | translate }}</span>
      </div> -->
      <div class="overlayContainer__actionContainer">
        <button class="overlayContainer__actionButton --white --left"
          (click)="close()">{{ 'cancel' | translate }}</button>
        <button class="overlayContainer__actionButton --orange --right" [disabled]="loading"
          (click)="addDiscount()">
          <span *ngIf="!loading">{{ 'add' | translate }}</span>
          <kc-local-loader *ngIf="loading"></kc-local-loader>
        </button>
      </div>
    </div>
  </div>
</div>
