
import {
  Component, NgZone, ChangeDetectionStrategy,
  HostListener, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit
} from '@angular/core';
import { akitaDevtools } from '@datorama/akita';
import { environment } from 'src/environments/environment';
import { KIDSessionService } from './k-id-session/k-id-session.service';
import { WindowService } from './window-service/window.service';
import { TranslateService } from '@ngx-translate/core';
import { slideRouteContainer } from './animations';
import { LANGUAGES } from '../assets/i18n/languages.type';
import vhCheck from 'vh-check';
import { OverlayDialogService } from './overlay-dialog/overlay-dialog.service';
import { LoaderQuery } from './loader/loader.query';
import { ChannelType, WebsocketService } from './websocket-service/websocket.service';
import * as paymentInfoStorage from './overlay-dialog/payment-info-dialog/payment-info-dialog.storage';
import { ActiveTransactionService } from './routes/charge-point/active-transaction/active-transaction.service';
import { GaService } from './ga/ga.service';
import { DebugService } from './helpers/debug.service';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AuthSessionService } from './auth/auth-session.service';
import { AuthSessionQuery } from './auth/auth-session.query';
import { KIDSessionQuery } from './k-id-session/k-id-session.query';
import { LoaderService } from './loader/loader.service';
import { AppVersionService, Versions } from './services/app-version.service';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { InvoicingAgreementService } from './services/invoicing-agreement.service';
import { AppUpdateService } from './services/app-update.service';
import { StoreService } from './k-store/store.service';

vhCheck();

@Component({
  selector: 'kc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideRouteContainer('0px', '0px')]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public slideRouteContainer: 'down' | 'up' = 'down';
  public isLoading: boolean;
  apiLoaded: Observable<boolean>;

  public slideRouteContainer$ = this.windowService.isRouteContainerOpen$;
  public device$ = this.windowService.deviceTarget$;

  transaction = {
    chargePoint: 'Bestest point', energy: 1234, id: '5d1487f07025090018b896fe',
    price: 500, socketType: 'CCS', timestampStart: '2019-06-27T09:09:39.771Z', timestampStop: '2019-06-27T09:10:21.744Z', uniqueId: '1234'
  };

  environment = environment;
  inited = false;
  userDevice;
  roamingSlider;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowService.setScreenDimensions(window.innerWidth, window.innerHeight);
  }

  constructor(
    private ngZone: NgZone,
    translateService: TranslateService,
    public windowService: WindowService,
    private kIDSessionService: KIDSessionService,
    private overlayDialogService: OverlayDialogService,
    private websocketService: WebsocketService,
    private loaderQuery: LoaderQuery,
    private activeTransactionService: ActiveTransactionService,
    private gaService: GaService,
    private debugService: DebugService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private http: HttpClient,
    private appVersionService: AppVersionService,
    private authSessionService: AuthSessionService,
    private authSessionQuery: AuthSessionQuery,
    private kIDSessoinQuery: KIDSessionQuery,
    private loaderService: LoaderService,
    private invoicingInvitationService: InvoicingAgreementService,
    private storeService: StoreService,
    public appUpdateService: AppUpdateService
    ) {
      this.apiLoaded = this.http.jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.GOOGLE.MAPS_API_KEY}`, 'callback').pipe(
        map((res) => true),
        catchError(() => of(false)),
      );
    this.appVersionService.checkIfAndroidApp();
    this.userDevice = this.appVersionService.getVersion();

    if (environment.development) {
      this.debugService.compareLocalizationJSONs();
    }

    if (!environment.production) {
      akitaDevtools(this.ngZone);
    }

    if (!this.authSessionQuery.hasSessionCookie() && Date.now() < new Date(2022, 0, 12).getTime()) {
      this.overlayDialogService.openOverlayDialog('MaintenanceDialogComponent');
    }

    // Remove this after 14.9
    paymentInfoStorage.clearPaymentInfoDialog();

    // Set default language for APP
    translateService.addLangs([LANGUAGES.FI, LANGUAGES.EN, LANGUAGES.SV]);
    translateService.setDefaultLang(LANGUAGES.FI);
    translateService.use(localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : LANGUAGES.FI);

    this.slideRouteContainer$.subscribe(res => {
      this.slideRouteContainer = res ? 'up' : 'down';
    });

    this.gaService.sendUserStatus('Avasi palvelun');

    window.addEventListener('focus', () => {
      this.onResume();
      this.verifySessionStatus();
    });
  }

  ngOnInit() {
    window['appLoaded'] = true;
    window['appComponentRef'] = {
      component: this
    };

    this.websocketService.setupConnection();

    this.windowService.setScreenDimensions(window.innerWidth, window.innerHeight);
    this.windowService.setScreenDimensions(window.innerWidth, window.innerHeight);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event['url'] && event['url'].startsWith('/charge-point/roaming')) {
          this.roamingSlider = true;
        } else {
          this.roamingSlider = false;
        }
      }
    });

    // Every web socket messages for charge point are subscribed here
    this.websocketService.chargePointMessages$.subscribe(res => { });

    this.loaderQuery.activeLoaders$.subscribe(loaders => {
      if (loaders.length !== 0) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
      this.cdr.markForCheck();
    });

    // To check the version on init.
    if (this.appVersionService.getVersion() === Versions.IOS_APP || this.appVersionService.getVersion() === Versions.IOS_BROWSER) {
      this.onIOSResume();
    }

    // TODO: Remove this after a store for invoicing agreements is done. This workaround is needed to prevent circular dependency error.
    this.invoicingInvitationService.invitationAccepted$.subscribe(_ => this.kIDSessionService.getUserData());
  }

  ngAfterViewInit() {
    this.listenForAuthChanges();
    this.verifySessionStatus();

    setTimeout(() => this.appUpdateService.checkForUpdates());
  }

  toggleSlideRouteContainer() {
    this.windowService.setRouteContainerOpenState(this.slideRouteContainer === 'down');
  }

  /**
   * Called on application resume. Don't remove.
   */
  onResume() {
    this.activeTransactionService.getActiveTransaction();
    this.websocketService.isConnected();
    this.appUpdateService.checkForUpdates();
  }

  onIOSResume() {
    this.onResume();
  }

  // Called by Android and iOS after browser login.
  // Contains a token on success, contains either an error string or object in case of error.
  async onAuth(response: String) {
    this.loaderService.activateSpinner('loggingIn');
    this.cdr.detectChanges();
    let error = null;

    const hasError = !response || !String(response) || response.toLocaleLowerCase().includes('error');

    if (!hasError) {
      try {
        await this.authSessionService.registerSession(response);
      } catch (e) {
        error = e;
      }
    } else {
      const parsedError = {
        message: response || 'Error on Auth SDK'
      };
      error = parsedError;
      this.loaderService.deactivateSpinner('loggingIn');
    }

    // Pop an error dialog if an error is found, unless the error is whitelisted.
    if (error && !this.authSessionQuery.isErrorWhitelisted(error.message)) {
      this.overlayDialogService.openOverlayDialog('ErrorDialogComponent', {'message': 'loginError', 'error': error,
      'source': { 'component': 'appComponent', 'function': 'onAuth'}});
    }
  }

  // Called by Android and iOS after logout.
  async onLogout(response) {
    if (response === 'OK') {
      await this.authSessionService.removeSession();
      this.kIDSessionService.localLogout();
    }
    this.loaderService.deactivateSpinner('loggingOut');
    this.loaderService.deactivateSpinner('loggingIn');
  }

  /**
   * Verify the auth status of user on init.
   */
  private async verifySessionStatus() {
    await this.authSessionService.verifyActiveSession();
  }

  /**
   * Listen for auth state changes.
   */
  private listenForAuthChanges() {
    let inited = false;
    this.authSessionQuery.select().subscribe(state => {
      if (state.error) {
        this.overlayDialogService.openOverlayDialog('AppUpdateDialogComponent');
      }
      if (state.isSessionVerified) {
        const authState = this.authSessionQuery.getValue();

        if (this.authSessionQuery.isAuthenticated()) {
          if (inited) {
            return;
          }
          inited = true;
          if (authState.hasSession) {
            this.kIDSessionService.getUserData();
          } else if (authState.hasSinglePaymentSession) {
            this.activeTransactionService.getActiveTransaction();
            // Open a channel for active transactions.
            this.websocketService.createChannel(ChannelType.USER, `${environment.BACKEND_URL}/transactions/socket`);
            this.websocketService.createChannel(ChannelType.TRANSACTION_PRICES, `${environment.BACKEND_URL}/transaction-prices/socket`);
          }

        } else {
          this.kIDSessionService.localLogout();
          inited = false;
        }

        if (!authState.hasSession && this.kIDSessoinQuery.getUserId) {
          this.kIDSessionService.localLogout();
        }
      } else {
        inited = false;
      }
    });
  }

  ngOnDestroy() {
    this.websocketService.destroySocket();
  }

}
