import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransactionsStore } from './transactions.store';
import { InvoiceService } from '../../invoice/invoice.service';
import { InvoiceQuery } from '../../invoice/invoice.query';
import { environment } from 'src/environments/environment';
import { repeatWhen, delay, skipWhile, take, switchMap, takeWhile, tap, filter, concatMap } from 'rxjs/operators';
import { interval, throwError, timer } from 'rxjs';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import * as moment from 'moment';
import { Transaction, PricingState } from './transaction.model';

@Injectable({ providedIn: 'root' })
export class TransactionsService {
  inited = false;

  constructor(private transactionsStore: TransactionsStore,
    private invoiceService: InvoiceService,
    private invoiceQuery: InvoiceQuery,
    private http: HttpClient,
    private overlayDialogService: OverlayDialogService,
    private chargePointsQuery: ChargePointsQuery
  ) {
  }

  getMonthlyTransactions(year: number, month: number) {
    this.transactionsStore.setLoading(true);
    this.chargePointsQuery.selectLoading().subscribe(isLoading => {
      if (!isLoading) {
        this.http.get<any>(`${environment.BACKEND_URL}/transactions/${year}/${month}`)
        .subscribe((res) => {
          // Combine kesko and hubject transactions.
          this.transactionsStore.setTransactions(res);
          if (this.invoiceQuery.getAll().length === 0) {
            this.invoiceService.getMonthlyInvoices();
          }
          this.transactionsStore.setLoading(false);
        }, err => {
          if (err.status === 403) {
            this.overlayDialogService.openOverlayDialog('SessionExpiredDialogComponent');
          } else if (!err['message'].endsWith('0 Unknown Error')) {
            this.overlayDialogService.openOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo',
          'error': err, 'source': { 'component': 'transactionsService', 'function': 'get'}});
          return throwError(err);
          }
        });
      }
    });
  }

  /**
   * Gets cdr of given sessionId. Polls x times or until receives a valid response.
   * @param sessionId session to find.
   */
  getChargeDetailRecordBySessionId(sessionId: string) {
    const totalRetries = 10;
    let retries = 0;
    return this.http.get<any>(`${environment.ROAMING.BASE_URL}/cdr/user/sessions/${sessionId}`)
      .pipe(
        repeatWhen(delay(3000)),
        skipWhile(res => { retries++; return retries < totalRetries && res.length === 0; }),
        take(1),
      );
  }

  /**
   * Gets cdr of given sessionId. Polls x times or until receives a valid response.
   * @param sessionId session to find.
   */
  getSingleTransaction(id: string) {
    return this.http.get<Transaction>(`${environment.BACKEND_URL}/transactions/${id}`);
  }

  pollTransactionChargingPrice(id: string) {
    const maxAttempts = 10;
    let currentAttempts = 0;
    return timer(0, 1000).pipe(
      concatMap(() => this.getSingleTransaction(id)),
      tap(() => currentAttempts++),
      takeWhile(res => !res.transactionPrice && currentAttempts < maxAttempts, true),
      filter(tr => tr.transactionPrice != null && tr.transactionPrice != undefined),
    );
  }
  pollEndedTransaction(id: string) {
    const maxAttempts = 10;
    let currentAttempts = 0;
    return timer(0, 1000).pipe(
      concatMap(() => this.getSingleTransaction(id)),
      tap(() => currentAttempts++),
      takeWhile(res => res.pricingState != PricingState.COMPLETED && (!res.transactionPrice || res.transactionPrice.price.total == 0) && currentAttempts < maxAttempts, true),
      filter(tr => tr.pricingState == PricingState.COMPLETED || tr.transactionPrice && tr.transactionPrice.price.total != 0),
    );
  }

  updateUIState(selectedMonth: moment.Moment) {
    this.transactionsStore.update(state => {    
      if(state.ui && state.ui.selectedMonth && state.ui.selectedMonth.isSame(selectedMonth)) {
        return {
          ...state,
          ui: {
            ...state.ui,
            selectedMonth: null
          }
        };
      } else {
        return {
          ...state,
          ui: {
            ...state.ui,
            selectedMonth: selectedMonth
          }
        };
      }
    })
  }

  resetUIState() {
    this.transactionsStore.update(state => {
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedMonth: null
        }
      };
    })
  }
}
