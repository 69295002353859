import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { BehaviorSubject } from 'rxjs';
import { requestStatus } from 'src/app/helpers/requestStatuses';
import { environment } from 'src/environments/environment';
import { ErrorsService } from 'src/app/errors-handler/errors.service';
import { AppVersionService } from 'src/app/services/app-version.service';

@Component({
  selector: 'kc-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  message: string;
  error: any;
  source: any;
  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);
  development = environment.development;

  constructor(@Inject(PORTAL_DATA) public data,
  private appVersionService: AppVersionService,
  private kChargeProfileService: KChargeProfileService) { }

  ngOnInit() {
    this.message = this.data.data.message;
    this.error = this.data.data.error;
    this.source = this.data.data.source;
  }

  sendErrorReport() {
    this.requestStatus$.next(requestStatus.SEND);

    const request = {
      reason: 'appNotWorking',
      source: `${environment.development ? 'development' : 'production'}`,
      // tslint:disable-next-line:max-line-length
      description: `ERROR: ${this.error['message']}\n COMPONENT: ${this.source['component']}\n FUNCTION: ${this.source['function']}\n STACK: ${this.error['stack']}`,
      platform: this.appVersionService.getVersion()
    };

    this.kChargeProfileService.sendSupportRequest(request).subscribe(response => {
      this.requestStatus$.next(requestStatus.ACCEPTED);
    },
      err => {
        this.requestStatus$.next(requestStatus.REJECTED);
        throw err;
      });
  }

  /**
   * Close edit app settings overlay dialog
   */
  close() {
    this.data.close();
  }
}
