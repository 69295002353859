import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kc-socket-price-item',
  templateUrl: './socket-price-item.component.html',
  styleUrls: ['./socket-price-item.component.scss']
})
export class SocketPriceItemComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() price: string;
  @Input() plusSign: boolean;
  @Input() pricingPrinciple: string;
  @Input() compact?: boolean;

  constructor() { }

  ngOnInit() {
  }

}
