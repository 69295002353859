import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { environment } from 'src/environments/environment.local';
import { Transaction } from './state/transaction.model';

@Pipe({
    name: 'transactionsFilter',
})
export class TransactionsFilterPipe implements PipeTransform {
    transform(transactions: Transaction[]) {

        if (environment.development) {
            return transactions;
        }
        const filteredTransactions = transactions.filter(transaction => transaction.energy > 499
            && moment.duration(moment(transaction.timestampStop).diff(moment(transaction.timestampStart))).asMinutes() > 5);
        return filteredTransactions;
    }
}
