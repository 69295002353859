import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { SocketTypes } from '../../map/charge-boxes/charge-box-socket-types';

@Pipe({
  name: 'formatSocketTypeName'
})
@Injectable()
export class FormatSocketTypeNamePipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case SocketTypes.TYPE_1:
        return 'Type 1';
      case SocketTypes.TYPE_2:
        return 'Type 2';
      case SocketTypes.CHADEMO:
        return 'CHAdeMO';
      case SocketTypes.CCS2:
        return 'CCS';
      case SocketTypes.CCS1:
        return 'CCS 1';
      case SocketTypes.SCHUKO:
        return 'Schuko';
      case SocketTypes.GBT:
        return 'GBT';
      case 'multipleSocketTypes':
        return 'multipleSocketTypes';
      case SocketTypes.UNDEFINED:
        return 'Undefined';
      default:
        return 'Undefined';
    }
  }

}


