import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { environment } from 'src/environments/environment';
import { ErrorsService } from 'src/app/errors-handler/errors.service';
import { BehaviorSubject } from 'rxjs';
import { requestStatus } from 'src/app/helpers/requestStatuses';
import { GaService } from 'src/app/ga/ga.service';
import { AppVersionService } from 'src/app/services/app-version.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';

@Component({
  selector: 'kc-report-faulted-socket',
  templateUrl: './report-faulted-socket.component.html',
  styleUrls: ['./report-faulted-socket.component.scss']
})
export class ReportFaultedSocketComponent implements OnInit {

  chargePoint: ChargePoint;
  socket: ChargeBox;
  chargerProblemForm: UntypedFormGroup;
  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);

  problemTypes = [
    { type: 'chargerNotCharging' },
    { type: 'chargerNotResponding' },
    { type: 'otherReason' },
  ];

  constructor(@Inject(PORTAL_DATA) public data,
    public formBuilder: UntypedFormBuilder,
    private kChargeProfileService: KChargeProfileService,
    private appVersionService: AppVersionService,
    private sessionQuery: KIDSessionQuery,
    private gaService: GaService) { }

  ngOnInit() {

    this.chargePoint = this.data.data.chargePoint;
    this.socket = this.data.data.socket;

    this.chargerProblemForm = this.formBuilder.group({
      wantToBeContacted: false,
      car: [''],
      description: ['', [Validators.required]],
      reason: 'chargerError',
    }, {});

    this.chargerProblemForm.get('reason').valueChanges.subscribe(value => {
      if (value === 'chargerNotCharging') {
        this.chargerProblemForm.get('car').setValidators(Validators.required);
        this.chargerProblemForm.get('car').updateValueAndValidity();
      } else {
        this.chargerProblemForm.get('car').clearValidators();
        this.chargerProblemForm.get('car').updateValueAndValidity();
      }
    });
  }

  sendSupportRequest(form) {

    this.requestStatus$.next(requestStatus.SEND);

    const request = {
      reason: 'chargerError',
      source: `${environment.development ? 'development' : 'production'}`,
      chargePoint: this.chargePoint.name,
      uniqueId: `${this.socket.uniqueId}`,
      car: form['value']['car'],
      description: `${form['value']['description']}`,
      platform: this.appVersionService.getVersion(),
      sendResponseEmail: form['value']['wantToBeContacted']
    };
    this.kChargeProfileService.sendSupportRequest(request).subscribe(response => {
      this.requestStatus$.next(requestStatus.ACCEPTED);
      this.gaService.sendChargeEvent('Raportoi ongelma', this.socket.uniqueId);
    },
      err => {
        this.requestStatus$.next(requestStatus.PENDING);
        this.data.openNewOverlayDialog('ErrorDialogComponent', { 'message': 'somethingWentWrongInfo', 'error': err,
        'source': { 'component': 'reportFaultedSocketComponent', 'function': 'sendSupportRequest'}});
        throw err;
      });
  }

  isLoggedIn() {
    return this.sessionQuery.isLoggedIn();
  }

  /**
   * Close edit contact information overlay dialog
   */
  close() {
    this.data.close();
  }
}
