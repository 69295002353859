<div class="overlayContainer__wrapper --scrollable">
  <div class="overlayContainer__contentWrapper">
    <form class="overlayContainer__contentTemplate" [formGroup]="tosForm" (ngSubmit)="continue()">
      <div class="tosIcon">
        <img src="../../../assets/logos/k-lataus-battery-rgb.png" alt="k-charge-logo-with-battery">
      </div>
      <div class="overlayContainer__header">
        <span class="title">{{ 'greeting' | translate }} {{ firstName }}!</span>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="inputContainer --switch">
        <label for="acceptedTos">{{ 'tosAgreement' | translate }} <a href="{{ environment.K_LATAUS.TOS_URL }}"
            target="_blank">{{
            'tosAgreementLink' | translate}}</a></label>
        <div class="switch" tabindex="0" [ngClass]="{'on': agreement('acceptedTos')}"
          (click)="changeAgreement('acceptedTos')" (swipeleft)="changeAgreementSwipe('acceptedTos', false)" (swiperight)="changeAgreementSwipe('acceptedTos', true)"></div>
      </div>
      <div class="inputContainer --switch">
        <label class="--wide" for="marketing">{{ 'tosMarketingAgreement' | translate }}</label>
        <div class="switch" tabindex="0" [ngClass]="{'on': agreement('marketing')}"
          (click)="changeAgreement('marketing')" (swipeleft)="changeAgreementSwipe('marketing', false)" (swiperight)="changeAgreementSwipe('marketing', true)"></div>
      </div>
      <div class="overlayContainer__actionContainer --w100">
        <button class="overlayContainer__actionButton --orange" (click)="continue()" [disabled]="!tosForm.valid">
          <span>{{'continue' | translate | uppercase}}</span>
        </button>
        <button class="overlayContainer__actionButton --white" (click)="logout()">
          <span>{{'logout' | translate | uppercase}}</span>
        </button>
      </div>
    </form>
  </div>
</div>
