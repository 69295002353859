import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArray'
  })
  export class FilterArrayPipe implements PipeTransform {

    /***
     * Simple filter pipe that returns a filtered array of items based on the parameters.
     * @param items Collection to be filtered.
     * @param filterBy Attribute name to filter items by.
     * @param keyword Attribute value required from item to be included.
     */
    transform(items: any[], filterBy: string, keyword: any) {
      return items.filter(item => item[filterBy] === keyword);
    }

  }
