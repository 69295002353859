<div style="z-index: 102" class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <div *ngIf="activePageStatus$.value !== 0" class="backButton" (click)="previousPage()">
            <img src="../../../assets/icons/png/icon-arrow-l-left@2x.png" alt="back">
        </div>
        <img src="../../../assets/icons/png/icon-info@3x.png" alt="info">
        <span class="title">{{ 'newServiceAgreementTitle' | translate }}</span>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <ng-container [ngSwitch]="activePageStatus$ | async">
          <ng-container *ngSwitchCase="0">
              <span class="normal">{{ 'newServiceAgreementPage1Info1' | translate }}</span>
              <span class="normal bold">{{ 'newServiceAgreementPage1Info2' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="1">
              <span>{{ 'newServiceAgreementPage2Info1' | translate }}</span>
              <span class="infoText">{{ 'newServiceAgreementPage2Info2' | translate }}</span>
              <span class="infoText">{{ 'newServiceAgreementPage2Info3' | translate }}</span>
              <span class="infoText">{{ 'newServiceAgreementPage2Info4' | translate }}
                <span class="bold">{{ 'newServiceAgreementPage2Info5' | translate }}</span>
              </span>
          </ng-container>
          <ng-container *ngSwitchCase="2">
            <span class="infoText">{{ 'newServiceAgreementPage3Info1' | translate }}</span>
            <span class="infoText">
              <span class="bold">{{ 'newServiceAgreementPage3Info2' | translate }}</span> {{ 'newServiceAgreementPage3Info3' | translate }}</span>
            <span class="infoText">{{ 'newServiceAgreementPage3Info4' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase="3">
            <span class="infoText">{{ 'newServiceAgreementPage4Info1' | translate }}</span>
            <span class="infoText">{{ 'newServiceAgreementPage4Info2' | translate }}</span>
            <span class="infoText">{{ 'newServiceAgreementPage4Info3' | translate }} <a href="{{ environment.K_LATAUS.SERVICE_AGREEMENT_URL }}"
              target="_blank">{{ 'newServiceAgreementPage4Info4' | translate }}</a>.</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="activePageStatus$.value === 3 ? 'continueToApplication' : 'next'" [color]="'--white'" (onClick)="nextPage()"></kc-dialog-button>
        <div class="circleContainer" style="align-self: center">
          <div *ngFor="let item of getPages(); let i = index" class="circle" [ngClass]="activePageStatus$.value === i ? '-active':''" (click)="goToPage(i)"></div>
        </div>
      </div>
    </div>
  </div>
</div>
