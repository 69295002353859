import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ChargeBoxesStore, ChargeBoxesState } from './charge-boxes.store';
import { ChargeBox } from './charge-box.model';
import * as storage from '../charge-points/socket.storage';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChargeBoxesQuery extends QueryEntity<ChargeBoxesState, ChargeBox> {
    constructor(protected store: ChargeBoxesStore) {
        super(store);
    }
    activeChargeBox$ = this.selectActive();
    allChargeBoxes$ = this.selectAll();
    public initActiveChargeBox = storage.getActiveSocketId();

    public getChargeBoxesByChargePointId(chargePointId: string): Observable<ChargeBox[]> {
        const chargeBoxes = this.selectAll({ filterBy: entity => entity.chargePointId === chargePointId});
        return chargeBoxes;
    }
}
