/**
 * This storage handles getting, setting and removing app version data on local storage
 */

 const VERSION_NUMBER = 'versionNumber';

 /**
 * Get version number from local storage as a number type
 */
 export function getVersionNumber() {
    return +localStorage.getItem(VERSION_NUMBER);
 }

 /**
 * Set version number to local storage
 */
 export function setVersionNumber(versionNumber) {
    localStorage.setItem(VERSION_NUMBER, JSON.stringify(versionNumber));
 }

