import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Invoice, createInvoice } from './invoice.model';

export interface InvoiceState extends EntityState<Invoice> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'invoice', resettable: true })
export class InvoiceStore extends EntityStore<InvoiceState, Invoice> {

  constructor() {
    super();
  }

  addMonthlyInvoice(invoice: Partial<Invoice>) {
    invoice = createInvoice(invoice);
    this.add(invoice as Invoice);
  }

  /**
   * Updates an existing invoice by merging it with new invoice.
   * @param invoice new invoice.
   * @param existingInvoice existing invoice.
   * @param updateTransactions should override existing transactions (set false if invoice's transactions are incomplete)
   */
  updateInvoice(invoice: Partial<Invoice>, existingInvoice: Partial<Invoice>, updateTransactions = true) {
    invoice = createInvoice(invoice);

    if (!updateTransactions) {
      delete invoice.transactions;
    }

    // Merge old and new values.
    invoice = {
      ...existingInvoice,
      ...invoice,
    };
    this.update(invoice.id, invoice);
  }
}

