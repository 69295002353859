export interface Transaction {
  id: string;
  _id: string;
  chargeBoxId: string;
  chargePointId: string;
  chargePointGroupId?: string;
  chargeBoxUniqueId: number;
  chargeBoxGroupId?: string;
  chargePoint: string;
  socketType: 'Type 2' | 'CCS' | 'CHAdeMO';
  timestampStart: string;
  timestampStop?: string;
  status: string,
  transactionPrice: TransactionPrice,
  powerType: string;
  energy: number;
  roaming?: boolean;
  transactionId?: string;
  pricingState: PricingState;
}

export enum PricingState {
  NOT_CREATED = 'notCreated',
  CREATION_FAILED = 'creationFailed',
  PRICING_CREATED = 'pricingCreated',
  COMPLETION_FAILED = 'completionFailed',
  COMPLETED = 'completed',
}

export interface TransactionPrice {
  price?: Price
  chargingPrice?: ChargingPrice;
  // TO DO: extract into an interface
  discount: {
    campaignId: string;
    discountId: string;
    chargingType: 'AC' | 'DC' | 'ROAMING';
    discountPercentage: number;
    totalBeforeDiscount: number;
    priceBeforeDiscount: {
      total: number,
      base: number,
      duration: number,
      energy: number
    }
  };
};

export interface Price {
  total: number;
  base: number;
  energy: number;
  duration: number;
  vat: number;
  taxFree: number;
  
}

export interface ChargingPrice {
  active: boolean;
  base: number;
  baseTaxRate: number;
  energy: number;
  energyTaxRate: number;
  duration: number;
  durationTaxRate: number;
  freeMinutes: number;
  minuteStep: number;
}
