<div class="socketDiscount__wrapper">
  <ng-container *ngIf="(activeDiscount$ | async) as activeDiscount; else noActive">
    <div class="socketDiscount__infoContainer">
      <span class="title">{{ 'discounts' | translate }}</span>
      <div class="socketDiscount__subtitleContainer">
        <span class="discountName">{{ activeDiscount.campaign.name | uppercase }}</span>
        <span class="info"> • {{ getEnergyType() | translate }}</span>
      </div>
    </div>
    <div class="socketDiscount__rateContainer">
      <div class="rateAndInfo">
        <div class="socketDiscount__rate -orange">
          <span>-{{ socket.socketType === 'type2' ? activeDiscount.campaign.discount.ac : activeDiscount.campaign.discount.dc }}%</span>
        </div>
        <span class="info">/ {{ 'fromTransactionPrice' | translate }}</span>
      </div>
      <span *ngIf="(activeTransactions$ | async) && (activeTransactions$ | async).length === 0" class="switchDiscount" (click)="selectActive()">{{ 'switchDiscount' | translate }}</span>
    </div>
  </ng-container>
</div>

<ng-template #noActive>
  <div class="socketDiscount__infoContainer">
    <span class="title">{{ 'discounts' | translate }}</span>
    <div class="socketDiscount__subtitleContainer">
      <span class="info">{{ 'noActiveDiscount' | translate }}</span>
      <span class="info" style="margin: 0px 3px;">{{ ' • '}}</span>
      <span *ngIf="(activeTransactions$ | async) && (activeTransactions$ | async).length === 0" class="discountName" (click)="selectActive()">{{ 'chooseDiscount' | translate }}</span>
    </div>
  </div>
</ng-template>
