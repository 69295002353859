import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';

@Component({
  selector: 'kc-payment-succeeded-dialog',
  templateUrl: './payment-succeeded-dialog.component.html',
  styleUrls: ['./payment-succeeded-dialog.component.scss']
})
export class PaymentSucceededDialogComponent implements OnInit {

  constructor(@Inject(PORTAL_DATA) public data) { }

  ngOnInit() {
  }

  close() {
    this.data.close();
  }
}
