import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { getVersionNumber, setVersionNumber } from '../version-storage';



@Injectable({ providedIn: 'root' })
export class AppUpdateService {
    updateEvent;
    isDialogActive = false;
    updateInfo: string;
    public updateFlag = new Subject<boolean>();

    constructor(private http: HttpClient) {
    }

    public checkForUpdates() {
      this.http.get('/app-version').subscribe(res => {
        if (getVersionNumber() && res > getVersionNumber()) {
          setVersionNumber(res);
          this.promptUser();
        } else {
          setVersionNumber(res);
        }
      });
    }

    private promptUser(): void {
        this.updateFlag.next(true);
        this.isDialogActive = true;
    }

    /**
   * Updates the application.
   */
    public updateApplication(update: boolean) {
        this.isDialogActive = false;
        window.location.reload();

    }
}