import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { configs } from 'src/configs/config';
import { CookieService } from '../helpers/cookie.service';
import { AuthSessionState, AuthSessionStore } from './auth-session.store';

@Injectable()
export class AuthSessionQuery extends Query<AuthSessionState> {
  constructor(protected store: AuthSessionStore, private cookieService: CookieService) {
    super(store);
  }

  /**
   * Check if a cookie is present. Is authenticated if a cookie is found.
   */
  isAuthenticated() {
    return this.getValue().hasSession && this.hasSessionCookie() || this.getValue().hasSinglePaymentSession && this.hasSPSessionCookie();
  }

  /**
   * Check if a session cookies exists.
   */
  hasSessionCookie() {
    return this.cookieService.getCookie(configs.sessionCookieName) != null;
  }

  /**
   * Check if a single payment session cookies exists.
   */
  hasSPSessionCookie() {
    return this.cookieService.getCookie(configs.singlePaymentCookieName) != null;
  }

  /**
   * Is true if authentication is currently in progress.
   * @returns bool.
   */
  isAuthenticating() {
    return this.getValue().isAuthenticating;
  }

  /**
   * Is true if authentication has been completed.
   * @returns bool.
   */
  isSessionVerified() {
    return this.getValue().isSessionVerified;
  }


  /**
   * Check if the error is whitelisted and therefore can be ignored.
   * Add errors which you don't want to show to the user.
   * @param errorMessage from auth0 sdk.
   * @returns boolean.
   */
  public isErrorWhitelisted(errorMessage: String) {
    if (errorMessage.includes('The user closed the browser app and the authentication was canceled')
     || errorMessage.includes('userCancelled')) {
      return true;
    }

    return false;
  }
}
