import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChargePointsStore } from './charge-points.store';
import { ChargePoint } from './charge-point.model';
import { environment } from 'src/environments/environment';
import { WebsocketService, ChannelType } from 'src/app/websocket-service/websocket.service';
import { Observable, forkJoin, of } from 'rxjs';
import { filter, map, tap, catchError } from 'rxjs/operators';
import { ChargePointsQuery } from './charge-points.query';
import { ChargeBoxesStore } from '../charge-boxes/charge-boxes.store';
import * as storage from './socket.storage';
import { ChargeBoxesQuery } from '../charge-boxes/charge-boxes.query';
import { LoaderService } from 'src/app/loader/loader.service';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
// import * as fakeChargePoints from 'src/assets/localTesting/mockChargePoints.json';
import { RoamingChargePointsService } from '../roaming-charge-points/roaming-charge-points.service';
import { Router } from '@angular/router';
import { ChargerService } from 'src/app/charger-service/charger-service.service';
import * as moment from 'moment';
import { Price, PriceType } from '../charge-boxes/charge-box.model';

@Injectable({ providedIn: 'root' })
export class ChargePointsService {

  private chargePointStatuses$: Observable<{ chargePointId: string, chargeBoxId: string, newStatus: string }>;

  constructor(
    private chargePointsStore: ChargePointsStore,
    private http: HttpClient,
    private websocketService: WebsocketService,
    private chargePointsQuery: ChargePointsQuery,
    private chargeBoxesStore: ChargeBoxesStore,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private loaderService: LoaderService,
    private overlayDialogService: OverlayDialogService,
    private router: Router,
    private chargeBoxesService: ChargerService,
    private roamingChargePointService: RoamingChargePointsService
  ) {
    if (this.chargePointsQuery.getAll().length === 0) {
      this.loaderService.activateSpinner('chargePoints');
    }
    forkJoin([roamingChargePointService.getRoamingChargePoints(), this.getChargePoints(), this.getFutureChargePoints()]).subscribe(res => {
      const combinedChargePoints = (res[0] || []).concat((res[1] || []), (res[2] || []));
      this.chargePointsStore.addChargePoints(combinedChargePoints);
    });
    // TODO: Update to use the charge-box-status channel.
    this.chargePointStatuses$ = this.websocketService.chargePointMessages$.pipe(
      filter(msg => msg['uniqueId'] && msg['data']['status'] && msg['data']['connectorId']),
      map(msg => {
        return {
          chargePointId: msg['chargePointId'],
          chargeBoxId: msg['chargeBoxId'],
          newStatus: msg['data']['status']
        };
      })
    );
    this.chargePointStatuses$.subscribe(res => this.updateChargeBoxStatus(res));
  }

  /**
   * Get K-Lataus charge points from Plugit
   */
  getChargePoints(): Observable<Partial<ChargePoint>[]> {

    // Promise.resolve(fakeChargePoints.default).then(chargePoints => {
    //   this.loaderService.deactivateSpinner('chargePoints');
    //   this.chargePointsStore.setChargePoints(chargePoints);
    // });

    return this.http.get<Partial<ChargePoint>[]>(`${environment.BACKEND_URL}/charge-points`).pipe(
      tap(chargePoints => {
        this.loaderService.deactivateSpinner('chargePoints');
        this.loaderService.deactivateSpinner('map'); // To be safe if maps idle event did not trigger.
      }), catchError((err) => {
        this.loaderService.deactivateSpinner('chargePoints');
        this.loaderService.deactivateSpinner('map'); // To be safe if maps idle event did not trigger.
        if (!err['message'].endsWith('0 Unknown Error')) {
          this.overlayDialogService.openOverlayDialog('ErrorDialogComponent', { 'message': 'somethingWentWrongInfo',
          'error': err, 'source': { 'component': 'chargePointsService', 'function': 'getChargePoint'}});
        }
        // Emits a null value which is handled by forkJoin.
        return of(null);
      }
    ));
  }

  /**
   * Get upcoming K-Lataus charge points
   */
  getFutureChargePoints(): Observable<Partial<ChargePoint>[]> {
    return this.http.get<Partial<ChargePoint>[]>(`${environment.BACKEND_URL}/future-charge-points`);
  }

  /**
   * Change active charge point
   * @param id String charge point id
   */

  changeActiveChargePoint(id: string): void { // Figure out logic to use RxJS mergeMap etc.
    this.chargePointsQuery.selectLoading().subscribe(
      isLoading => {
        if (!isLoading) {
          const chargePoint = this.chargePointsQuery.getEntity(id);
          this.chargePointsStore.setActive(id);
          // Don't try to fetch a roaming point.
          if (id) {
            if (id.includes('roaming-')) {
              if (!chargePoint) {
                this.chargePointsStore.setActive(null);
                this.loaderService.deactivateSpinner('chargePoint');
                this.router.navigate(['/']);
                return;
              }
              const evseIds = this.chargeBoxesQuery.getAll({ filterBy: entity => entity.chargePointId === id }).map(cb => cb.EvseID);
              this.roamingChargePointService.updateRoamingChargePoint(evseIds).subscribe(
                () => {}
              );
            // Do not fetch if it's a future charge point.
            } else if(chargePoint.operative === false && (moment(chargePoint.installationDate).isAfter(moment()))) {
              return;
            } else {
              this.websocketService.createChannel(ChannelType.CHARGE_POINT, `${environment.BACKEND_URL}/charge-points/${id}/socket`);
              this.http.get<Partial<ChargePoint>>(`${environment.BACKEND_URL}/charge-points/${id}`).subscribe(
                res => {
                  this.chargePointsStore.updateChargePoint(id, res);
                  this.chargeBoxesService.updateChargeBoxes(res.chargeBoxes);
                }
              );
            }
          }
        }
      }
    );
  }

  updateChargePoint(id: string) {
    return this.http.get<Partial<ChargePoint>>(`${environment.BACKEND_URL}/charge-points/${id}`).pipe(
      tap(res => {
        this.chargePointsStore.updateChargePoint(id, res);
        return res;
      }
      ));
  }

  updateChargeBoxStatus(res: { chargePointId: string, chargeBoxId: string, newStatus: string }) {
    this.chargeBoxesQuery.selectLoading().subscribe(isLoading => {
      if (!isLoading) {
        const chargeBox = this.chargeBoxesQuery.getEntity(res.chargeBoxId);
        if (chargeBox) {
          this.chargeBoxesService.updateChargeBox(chargeBox.id, { status: res.newStatus });
        }
      }
    });
  }

  getChargePointById(id: string): Observable<ChargePoint> {
    let result: Observable<ChargePoint>;
    result = this.chargePointsQuery.selectEntity(id);
    return result;
  }

  setChargeBoxActive(id: string) {
    this.chargeBoxesStore.setActive(id);
    storage.setActiveSocket(id);
  }

  getActiveChargeBox() {
    return storage.getActiveSocketId();
  }

  updateSocketTransactionId(chargeBoxId: string, transactionId: string) {
    this.chargeBoxesQuery.selectLoading().subscribe(
      isLoading => {
        if (!isLoading) {
          const chargeBox = this.chargeBoxesQuery.getEntity(chargeBoxId);
          if (chargeBox) {
            this.chargeBoxesService.updateChargeBox(chargeBox.id, { transactionId: transactionId });
          }
        }
      }
    );
  }

  getChargePointLatestTransactions(chargePointId: string): Observable<any> {
    return this.http.get<any>(`${environment.BACKEND_URL}/charge-points/${chargePointId}/transactions`);
  }

  addChargeBoxPrice(chargeBoxId: string) {
    this.http.get<{
      priceGroupId: string;
      currency: string;
      pricingType: string;
      pricing: Price,
      schedules?: Price[];
    }>(`${environment.BACKEND_URL}/charge-boxes/${chargeBoxId}/price`).subscribe(result => {
      const priceActive = result.pricingType === PriceType.FREE ? false : true;
      this.chargeBoxesService.updateChargeBox(chargeBoxId, {price: {active: priceActive, ...result.pricing, schedules: result.schedules}});
    });
  }
}
