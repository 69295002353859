import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';

@Component({
  selector: 'kc-account-locked-dialog',
  templateUrl: './account-locked-dialog.component.html',
  styleUrls: ['./account-locked-dialog.component.scss']
})
export class AccountLockedDialogComponent implements OnInit {

  constructor(@Inject(PORTAL_DATA) public data, private kidProfileService: KChargeProfileService) { }

  ngOnInit() {}

  /**
   * Log user out.
   */
  logout() {
    this.kidProfileService.logout();
  }

}
