import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import * as storage from './cookie-consent.storage';

@Component({
  selector: 'kc-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(@Inject(PORTAL_DATA) public data) { }

  ngOnInit() {
  }

  /**
   * Close edit app settings overlay dialog
   */
  close() {
    storage.setCookieConsent(true);
    this.data.close();
  }

}
