import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterOption, FiltersState } from '../state/filter-option.model';
import { FilterOptionsService } from '../state/filter-options.service';
import { FilterOptionsQuery } from '../state/filter-options.query';
import { TopBarService } from '../../top-bar.service';
import { ChargePointsService } from '../../../map/charge-points/charge-points.service';
import { Router } from '@angular/router';
import { WindowService } from '../../../window-service/window.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { UsersFilters } from 'src/app/k-id-session/k-id-session.store';
import { first } from 'rxjs/operators';

@Component({
  selector: 'kc-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss']
})
export class FilterOptionsComponent implements OnInit {
  filterOptions$: Observable<FiltersState>;
  isOtherStationsOpen = false;
  isServicesInfoOpen = true;
  selectedTab = 'chargingFilters';

  inactiveChargingFiltersCount$: Observable<number>;
  activeServiceFilters$: Observable<number>;

  constructor(private filterOptionsQuery: FilterOptionsQuery,
              private filterOptionsService: FilterOptionsService,
              private topBarService: TopBarService,
              private chargePointsService: ChargePointsService,
              private router: Router,
              private windowService: WindowService,
              private kidSessionQuery: KIDSessionQuery,
  ) { }

  ngOnInit() {
    this.inactiveChargingFiltersCount$ = this.filterOptionsQuery.inactiveChargingFilters$;
    this.activeServiceFilters$ = this.filterOptionsQuery.activeServiceFilters$;
    this.kidSessionQuery.isLoggedIn$.subscribe((isLogged) => {
      if (isLogged) {
        this.kidSessionQuery.user$.pipe(first(user => user != null)).subscribe(user => {
          const usersFilters = user.appSettings.filters;
          if (usersFilters) {
            this.useSavedUserFilters(usersFilters);
          }
        });
      }
    });
    this.filterOptions$ = this.filterOptionsQuery.filters$;
  }

  /**
   * Uses filters saved to user's profile and update the state of local filters.
   * @param usersFilters filters from user profile.
   */
  useSavedUserFilters(usersFilters: UsersFilters) {
    // This boolean determines if the filters should be updated to the db.
    // Means that a legacy filter was used and it should be migrated.
    let legacyTagUsed = false;
    const filterState: FiltersState = JSON.parse(JSON.stringify(this.filterOptionsQuery.getValue()));
    if (usersFilters.selectedSocketTypes != null) {
      for (const filter of filterState.socketType) {
        filter.active = usersFilters.selectedSocketTypes.includes(filter.type as string);
      }
    }
    if (usersFilters.maxPower != null || usersFilters.minPower != null) {
      const { minPower, maxPower } = usersFilters;
      for (const power of filterState.power) {
        // tslint:disable-next-line: triple-equals
        power.active = (power.type == minPower || power.type == maxPower || power.tag == maxPower || power.tag == minPower) ? true : false;
        if (power.legacyTag == maxPower || power.legacyTag == minPower) {
          legacyTagUsed = true;
          power.active = true;
        }
      }
    }
    if (usersFilters.roaming != null) {
      filterState.roaming[0].active = usersFilters.roaming;
    }
    if (!usersFilters.futureChargePoints) {
      filterState.futureChargePoints[0].active = false;
    }
    this.filterOptionsService.updateFilterState(filterState);
    if (legacyTagUsed) {
      console.log('legacy filter was used');
      this.updateFilterOptions(filterState.power, 'power')
    }
  }

  updateFilterOption(filterOption: Partial<FilterOption>, key: string, filters: FilterOption[]) {
    this.resetStates();
    this.filterOptionsService.updateFilterOption(filterOption, key, filters);
  }

  updateFilterOptions(filterOptions: FilterOption[], key: string) {
    this.resetStates();
    this.filterOptionsService.updateFilterOptions(filterOptions, key);
  }

  resetStates() {
    this.chargePointsService.changeActiveChargePoint(null);
    this.chargePointsService.setChargeBoxActive(null);
    // this.mapService.setMapCenterToCurrentCenterOfMap(this.mapService.getLocationFromMapInstance(this.mapInstance));
    this.router.navigate(['/profile']);
    this.windowService.setRouteContainerOpenState(false);
  }

  trackByFn(index) {
    return index;
  }

  close() {
    this.topBarService.toggleFilterOpen(false);
  }

  toggleOtherStationsInfo() {
    this.isOtherStationsOpen = !this.isOtherStationsOpen;
  }

  toggleServicesInfo() {
    this.isServicesInfoOpen = !this.isServicesInfoOpen;
  }

  selectTab(tab: string) {
    this.selectedTab = tab;
  }
}
