import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InvoiceStore, InvoiceState } from './invoice.store';
import { Invoice, INVOICE_STATUSES } from './invoice.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InvoiceQuery extends QueryEntity<InvoiceState, Invoice> {


  unpaidInvoices$ = this.selectAll({
    filterBy: invoice => invoice.paymentStatus === INVOICE_STATUSES.UNPAID || invoice.paymentStatus === INVOICE_STATUSES.FAILED
  });

  paidInvoices$ = this.selectAll({
    filterBy: invoice => invoice.paymentStatus === INVOICE_STATUSES.SUCCEEDED
  });

  lastMonthInvoice$ = this.selectAll({
    filterBy: invoice => moment(invoice.createdAt).month() === moment().subtract(1, 'months').month()
  });

  getLastMonthInvoice() {
    const [lastMonthInvoice] = this.getAll({
      filterBy: entity => moment(entity.createdAt).month() === moment().subtract(1, 'months').month()
    });
    return lastMonthInvoice || undefined;
  }

  getUnpaidInvoices() {
    return this.getAll({
    filterBy: invoice => invoice.paymentStatus === INVOICE_STATUSES.UNPAID || invoice.paymentStatus === INVOICE_STATUSES.FAILED
    });
  }

  /**
   * Returns a single failed invoice.
   * @returns a failed invoice.
   */
  getFailedInvoice() {
    const failedInvoices = this.getAll({
      filterBy: invoice => invoice.paymentStatus === INVOICE_STATUSES.FAILED
      });

    return failedInvoices.length > 0 ? failedInvoices[0] : undefined;
  }

  getPaidInvoicesByMonth(timestamp) {
    const year = moment(timestamp).year();
    const month = moment(timestamp).month();
    return this.getAll({
      filterBy: invoice => invoice.paymentStatus === INVOICE_STATUSES.SUCCEEDED
        && moment(invoice.createdAt).year() === year && moment(invoice.createdAt).month() === month
    });
  }

  isTransactionUnpaid(transaction) {
    let isUnpaid = false;
    this.getUnpaidInvoices().forEach(invoice => {
      if (invoice.transactions) {
        // Find matching transaction id from unpaid invoice's transaction. If invoice's transaction contain hubject sessionId
        // compare it instead.
        const foundInvoice = invoice.transactions.find(tp =>
          !tp.sessionId ? tp.transactionId === transaction.id : tp.sessionId === transaction.transactionId);
        if (foundInvoice) {
          isUnpaid = true;
        }
      }
    });
    return isUnpaid;
  }

  constructor(protected store: InvoiceStore) {
    super(store);
  }

}
