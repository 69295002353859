import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'kc-transactions-invoice',
  templateUrl: './transactions-invoice.component.html',
  styleUrls: ['./transactions-invoice.component.scss']
})
export class TransactionsInvoiceComponent {
  @Input() invoice;

  constructor(private router: Router) { }

  selectMonthlyInvoice(monthlyInvoice) {
    this.router.navigate(['/invoice/', monthlyInvoice.id]);
  }

  getMonthName(date) {
    let month = ''
    if (date) {
      date = new Date(date);
      month = date.toLocaleString('en', { month: 'long' });
      month = month.toLowerCase();
    }
    return month;
  }

}
