<div class="invoiceBalanceContainer" (click)="selectMonthlyInvoice(monthlyInvoice)"
  [ngClass]="monthlyInvoice.totalPrice.gross !== 0  ? paymentStatus(monthlyInvoice.createdAt) : '-disabled'">
  <div class="invoiceBalanceContainer__iconContainer">
    <i class="fak fa-k-receipt fa-xl --icon"></i>
  </div>
  <div class="invoiceBalanceContainer__textContainer">
    <span class="--header" [ngClass]="monthlyInvoice.totalPrice.gross === 0 ? '-disabled' : ''">{{ monthlyInvoice.createdAt | localDate:'LLLL' | titlecase }} {{ 'fee' | translate }} •
      {{ monthlyInvoice.totalPrice.gross !== 0 ? monthlyInvoice.transactions.length : '0' }}
      {{ 'chargingTransactions' | translate}}</span>
    <span class="--balance" [ngClass]="monthlyInvoice.totalPrice.gross === 0 ? '-disabled' : ''">{{ monthlyInvoice.totalPrice.gross / 100 | localCurrency }}</span>
    <ng-container *ngIf="monthlyInvoice.totalPrice.gross !== 0">
      <ng-container *ngIf="(calculateDifference(monthlyInvoice.createdAt, 1) > 0 || monthlyInvoice.paymentStatus !== 'failed'); else dueTpl">
        <span class="--info">{{ 'monthlyInvoiceCharge' | translate }}
          {{ calculateDifference(monthlyInvoice.createdAt, 1) }}
          {{'monthlyInvoiceChargeDays' | translate}}</span>
      </ng-container>
    </ng-container>
    <ng-template #dueTpl>
      <ng-container *ngIf="calculateDifference(monthlyInvoice.createdAt, 14) > 0; else lockedTpl">
        <span class="--info">{{ 'monthlyInvoiceChargeOverDue' | translate }},
          {{ 'monthlyInvoiceChargeOverDueLocking' | translate }}
          {{ calculateDifference(monthlyInvoice.createdAt, 14) }}
          {{  'monthlyInvoiceChargeDays' | translate }}
        </span>
      </ng-container>
      <ng-template #lockedTpl>
        <span class="--info">{{ 'monthlyInvoiceLocked' | translate }}</span>
      </ng-template>
    </ng-template>
  </div>
</div>
