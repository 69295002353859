export const clusterStyle = [
    {
        url: '../../assets/icons/png/m1.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 15],
        textSize: 14
    },
    {
        url: '../../assets/icons/png/m2.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 11],
        textSize: 14
    },
    {
        url: '../../assets/icons/png/m3.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 5],
        textSize: 14
    },
    {
        url: '../../assets/icons/png/m4.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 0],
        textSize: 10
    },
    {
        url: '../../assets/icons/png/m5.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 0],
        textSize: 8
    }
];

export const futureClusterStyle = [
    {
        url: '../../assets/icons/png/m1_gray.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 15],
        textSize: 14
    },
    {
        url: '../../assets/icons/png/m2_gray.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 11],
        textSize: 14
    },
    {
        url: '../../assets/icons/png/m3_gray.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 5],
        textSize: 14
    },
    {
        url: '../../assets/icons/png/m4_gray.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 0],
        textSize: 10
    },
    {
        url: '../../assets/icons/png/m5_gray.png',
        height: 48,
        width: 38,
        textColor: '#FFF',
        anchor: [8, 0],
        textSize: 8
    }
];

export const mapStyle = [
    {
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#f5f5f5'
            }
        ]
    },
    {
        'elementType': 'labels.icon',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#616161'
            }
        ]
    },
    {
        'elementType': 'labels.text.stroke',
        'stylers': [
            {
                'color': '#f5f5f5'
            }
        ]
    },
    {
        'featureType': 'administrative.country',
        'elementType': 'geometry.stroke',
        'stylers': [
            {
                'color': '#5e76a5'
            },
            {
                'visibility': 'on'
            },
            {
                'weight': 3
            }
        ]
    },
    {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'administrative.land_parcel',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#bdbdbd'
            }
        ]
    },
    {
        'featureType': 'landscape',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#ffffff'
            }
        ]
    },
    {
        'featureType': 'landscape.man_made',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#f1f1f0'
            }
        ]
    },
    {
        'featureType': 'poi',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#f3f3f2'
            }
        ]
    },
    {
        'featureType': 'poi',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#757575'
            }
        ]
    },
    {
        'featureType': 'poi.business',
        'stylers': [
            {
                'color': '#eeeeee'
            },
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'poi.business',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#757575'
            },
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'poi.park',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#dbead3'
            }
        ]
    },
    {
        'featureType': 'poi.park',
        'elementType': 'labels.text',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'poi.park',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e'
            }
        ]
    },
    {
        'featureType': 'road',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#ffffff'
            }
        ]
    },
    {
        'featureType': 'road',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#25282a'
            }
        ]
    },
    {
        'featureType': 'road.arterial',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#a1a1a1'
            },
            {
                'weight': 1
            }
        ]
    },
    {
        'featureType': 'road.arterial',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#757575'
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#dadada'
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#fc6921'
            },
            {
                'weight': 1.5
            }
        ]
    },
    {
        'featureType': 'road.highway',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#616161'
            }
        ]
    },
    {
        'featureType': 'road.local',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#b4b4b4'
            },
            {
                'weight': 1
            }
        ]
    },
    {
        'featureType': 'road.local',
        'elementType': 'labels',
        'stylers': [
            {
                'visibility': 'off'
            }
        ]
    },
    {
        'featureType': 'road.local',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#9e9e9e'
            }
        ]
    },
    {
        'featureType': 'transit.line',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#e5e5e5'
            }
        ]
    },
    {
        'featureType': 'transit.station',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#eeeeee'
            }
        ]
    },
    {
        'featureType': 'water',
        'elementType': 'geometry',
        'stylers': [
            {
                'color': '#c9c9c9'
            }
        ]
    },
    {
        'featureType': 'water',
        'elementType': 'geometry.fill',
        'stylers': [
            {
                'color': '#b0c4ed'
            }
        ]
    },
    {
        'featureType': 'water',
        'elementType': 'labels.text.fill',
        'stylers': [
            {
                'color': '#ffffff'
            }
        ]
    },
    {
        'featureType': 'water',
        'elementType': 'labels.text.stroke',
        'stylers': [
            {
                'color': '#3f3f3f'
            },
            {
                'weight': 1
            }
        ]
    }
];

