
const NEW_SERVICE_AGREEMENT = 'acceptedNewServiceAgreement2';

/**
 * Set new service agreement
 * If not found, then return null as not set state.
 */
export function getServiceAgreement(): boolean {
    const cookieConsent = localStorage.getItem(NEW_SERVICE_AGREEMENT);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
}

/**
 * Set new service agreement
 * @param consent user consent to service agreement (true)
 */
export function setServiceAgreement(consent: boolean) {
    localStorage.setItem(NEW_SERVICE_AGREEMENT, JSON.stringify(consent));
}
