import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InvoiceStore } from './invoice.store';
import { Invoice, createInvoice } from './invoice.model';
import { EMPTY, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { LoaderService } from 'src/app/loader/loader.service';
import { catchError, first, tap } from 'rxjs/operators';
import { InvoiceQuery } from './invoice.query';
import * as moment from 'moment';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';

@Injectable({ providedIn: 'root' })
export class InvoiceService {

  constructor(private invoiceStore: InvoiceStore,
    private kIDSessionQuery: KIDSessionQuery,
    private http: HttpClient,
    private loaderService: LoaderService,
    private invoiceQuery: InvoiceQuery,
    private chargePointsQuery: ChargePointsQuery) {
  }

  getMonthlyInvoices() {
    if (this.kIDSessionQuery.isLoggedIn()) {
      this.invoiceStore.setLoading(true);
      // this.loaderService.activateSpinner('monthlyInvoices');
      this.http.get<Invoice[]>(`${environment.BACKEND_URL}/profile/monthly-invoices`).subscribe(
        monthlyInvoices => {
          // If there are no invoices, make one as a placeholder.
          if (monthlyInvoices.length === 0 || !monthlyInvoices.find(invoice => moment(invoice.createdAt).month() === moment().month())) {
            const partialPlaceHolder: Partial<Invoice> = {
              _id: '0',
              createdAt: moment().toDate(),
              totalPrice: {gross: 0, net: 0, vat: 0},
              paymentStatus: 'unpaid'
            };
            const placeHolderInvoice = createInvoice(partialPlaceHolder);
            monthlyInvoices.push(placeHolderInvoice);
          } else {
           this.invoiceStore.remove('0');
          }
          this.loaderService.deactivateSpinner('monthlyInvoices');
          this.addOrUpdateMultipleMonthlyInvoices(monthlyInvoices, false);
          this.invoiceStore.setLoading(false);
        }, err => {
          console.error(err);
          this.loaderService.deactivateSpinner('monthlyInvoices');
          this.invoiceStore.setLoading(false);
        });
    }
  }

  getMonthlyInvoiceById(monthlyInvoiceId: string) {
    if (this.kIDSessionQuery.isLoggedIn()) {
      this.invoiceStore.setLoading(true);
      // Charge point names are required.
      this.chargePointsQuery.selectLoading().pipe(first(loading => loading === false)).subscribe(isLoading => {
        if (!isLoading) {
          // Prevent cache, up-to date response is always required.
          const httpOptions = {
            headers: new HttpHeaders({
              'Cache-Control': 'no-cache',
              Pragma: 'no-cache'
            })
          };
          // tslint:disable-next-line:max-line-length
          this.http.get<Invoice>(`${environment.BACKEND_URL}/profile/monthly-invoices/${monthlyInvoiceId}?redirecturl=${environment.PSP['REDIRECT_URL']}`, httpOptions)
            .subscribe(
              monthlyInvoice => {
                this.invoiceStore.setLoading(false);
                this.addOrUpdateMonthlyInvoice(monthlyInvoice);
              }, err => {
                this.invoiceStore.setLoading(false);
                console.error(err);
              }
            );
        }
      });
    }
  }

  // getMonthlyInvoicesByDate(date: Date) {
  //   const timeStamp = encodeURIComponent(moment(date).format('YYYY-MM-01T00:00:00.0000').toString());
  //   if (this.kIDSessionQuery.isLoggedIn()) {
  //     // tslint:disable-next-line:max-line-length
  //     return this.http.get<Invoice>(`${environment.BACKEND_URL}/profile/monthly-invoices-by-date?date=${timeStamp}`)
  //       .pipe(
  //         tap(
  //           monthlyInvoice => {
  //             const foundInvoice = this.invoiceQuery.getEntity(monthlyInvoice._id);
  //             if (foundInvoice) {
  //               this.invoiceStore.updateInvoice(monthlyInvoice, foundInvoice);
  //             } else {
  //               this.invoiceStore.addMonthlyInvoice(monthlyInvoice);
  //             }
  //           })
  //       );
  //   } else {
  //     return EMPTY;
  //   }
  // }

  getLatestFailedMonthyInvoice() {
    if (this.kIDSessionQuery.isLoggedIn()) {
      // tslint:disable-next-line:max-line-length
      return this.http.get<Invoice>(`${environment.BACKEND_URL}/profile/latest-failed-monthly-invoice`)
        .pipe(
          tap(
            monthlyInvoice => {
              const foundInvoice = this.invoiceQuery.getEntity(monthlyInvoice._id);
              if (foundInvoice) {
                this.invoiceStore.updateInvoice(monthlyInvoice, foundInvoice);
              } else {
                this.invoiceStore.addMonthlyInvoice(monthlyInvoice);
              }
            })
        );
    } else {
      return EMPTY;
    }
  }

  orderMonthlyInvoiceReceipt(monthlyInvoiceId: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.get(`${environment.BACKEND_URL}/monthly-invoice/${monthlyInvoiceId}/order-receipt`, { responseType: 'text' as 'json' }).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  getMonthlyInvoicePaymentUrl(monthlyInvoiceId: string) {
    // tslint:disable-next-line:max-line-length
    return this.http.post(`${environment.BACKEND_URL}/profile/monthly-invoices/${monthlyInvoiceId}/payment-url?redirecturl=${environment.PSP['REDIRECT_URL']}`, {}).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  private addOrUpdateMultipleMonthlyInvoices(invoices: Invoice[], updateTransactions = true) {
    if (invoices.length !== 0) {
      invoices.forEach(invoice => {
        this.addOrUpdateMonthlyInvoice(invoice, updateTransactions);
      });
    }
  }

  private addOrUpdateMonthlyInvoice(invoice: Invoice, updateTransactions = true) {
    const foundInvoice = this.invoiceQuery.getEntity(invoice._id);

    if (foundInvoice) {
      this.invoiceStore.updateInvoice(invoice, foundInvoice, updateTransactions);
    } else {
      this.invoiceStore.addMonthlyInvoice(invoice);
    }
  }

  resetStore() {
    this.invoiceStore.reset();
  }
}
