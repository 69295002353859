import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';

@Component({
  selector: 'kc-roaming-support-contact',
  templateUrl: './roaming-support-contact.component.html',
  styleUrls: ['./roaming-support-contact.component.scss']
})
export class RoamingSupportContactComponent implements OnInit {

  chargePoint: ChargePoint;
  socket: ChargeBox;

  constructor(@Inject(PORTAL_DATA) public data) { }

  ngOnInit() {
    this.chargePoint = this.data.data.chargePoint;
    this.socket = this.data.data.socket;
  }

  /**
   * Close edit app settings overlay dialog
   */
  close() {
    this.data.close();
  }

}
