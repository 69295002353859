<div class="overlayContainer__wrapper">
  <div class="overlayContainer__contentWrapper">
    <div class="overlayContainer__contentTemplate">
      <div class="errorIcon">

        <img src="../../../../assets/icons/png/icon-exclamation@2x.png" alt="">

      </div>
      <div class="overlayContainer__header">
        <span class="title">{{ 'cantRemovePaymentCardTitle' | translate }}</span>
        <span class="info -padding">{{ 'cantRemovePaymentCardInfo' | translate }}</span>
      </div>
      <div class="overlayContainer__actionContainer --w100">
        <button class="overlayContainer__actionButton --white" (click)="close()">
          <span>{{'close' | translate | uppercase}}</span>
        </button>
      </div>
    </div>
  </div>
</div>
