import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { MapStore, MapCenterState } from './map.store';

@Injectable({providedIn: 'root'})
export class MapQuery extends Query<MapCenterState> {
    mapCenter$ = this.select((state) => state.mapCenter);

    constructor(protected store: MapStore) {
        super(store);
    }

}
