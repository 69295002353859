import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Store from './store.model';
import { StoreStore } from './store.store';

@Injectable({ providedIn: 'root' })
export class StoreService {

  constructor(private storeStore: StoreStore, private http: HttpClient) {
    this.getStores();
  }

  getStores() {
    this.http.get<Store[]>(`${environment.BACKEND_URL}/k-stores`).subscribe(stores => {this.storeStore.set(stores)});
  }
}
