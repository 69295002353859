import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from '@angular/core';
import { CdkDragEnd, CdkDragStart } from '@angular/cdk/drag-drop';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { ChargerService } from 'src/app/charger-service/charger-service.service';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { InvoicingAgreement, PaymentCard } from 'src/app/k-charge-profile/k-charge-profile.model';
import { ChargePointsService } from 'src/app/map/charge-points/charge-points.service';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { takeUntil, switchMap, filter, map, take, first } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ActiveTransactionService } from '../active-transaction/active-transaction.service';
import { ChannelType, WebsocketService } from 'src/app/websocket-service/websocket.service';
import { environment } from 'src/environments/environment';
import { WindowService } from 'src/app/window-service/window.service';
import * as moment from 'moment';
import { LANGUAGES } from 'src/assets/i18n/languages.type';
import { GaService } from 'src/app/ga/ga.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { SinglePaymentService } from 'src/app/single-payment/single-payment.service';
import { setSocketType } from '../../../map/charge-boxes/charge-box-socket-types';
import { FormatSocketTypeNamePipe } from '../format-socket-type-name.pipe';
import { DiscountQuery } from '../../discounts/state/discount.query';
import { DiscountService } from '../../discounts/state/discount.service';
import { Transaction } from '../../transactions/state/transaction.model';

@Component({
  selector: 'kc-socket',
  templateUrl: './socket.component.html',
  styleUrls: ['./socket.component.scss']
})
export class SocketComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<boolean>();
  public socket$ = this.chargeBoxesQuery.activeChargeBox$;
  public chargePoint$ = this.chargePointsQuery.activeChargePoint$;
  activeDiscount$ = this.discountQuery.selectActive();

  socketLatestTransactions$ = this.socket$.pipe(
    filter(socket => socket && socket.id && true),
    // There's a compiler error which forces the last value to be strictly of type boolean
    switchMap(socket => this.chargerService.getChargeBoxLatestTransactions(socket.id)),
    map(latestTransactions => latestTransactions.map(latestTransaction => {
      const entity = this.chargeBoxesQuery.getEntity(latestTransaction.chargeBoxId);
      if (entity) {
        return {
          uniqueId: entity.uniqueId,
          socketType: entity.socketType,
          power: entity.power,
          daysAgo: moment().diff(latestTransaction.timestampStop || latestTransaction.timestampStart, 'days'),
          timeAgo: this.calculateTimeAgo(latestTransaction.timestampStop || latestTransaction.timestampStart)
        };
      }
      return false;
    }).filter(latestTransaction => latestTransaction)));


  public socket: ChargeBox;
  public chargePoint: ChargePoint;

  loggedIn: boolean;
  paymentCard: PaymentCard;
  invoicingAgreement: InvoicingAgreement;
  socketTransactionStatus$ = new BehaviorSubject<socketStatusType>(socketStatusType.PENDING);
  timeoutTimer;
  // Default timeout 60s (Comes from charging standard) ... now increased to 90s (Kesko's demand)
  timeoutTimerTime = 90;
  priceCheckInterval;

  constructor(
    private kIDSessionQuery: KIDSessionQuery,
    private overlayDialogService: OverlayDialogService,
    private chargerService: ChargerService,
    private chargePointsService: ChargePointsService,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private chargePointsQuery: ChargePointsQuery,
    private route: ActivatedRoute,
    private router: Router,
    private activeTransactionService: ActiveTransactionService,
    private websocketService: WebsocketService,
    public windowService: WindowService,
    private cdr: ChangeDetectorRef,
    private gaService: GaService,
    private loaderService: LoaderService,
    private singlePaymentService: SinglePaymentService,
    private formatSocketTypeNamePipe: FormatSocketTypeNamePipe,
    private discountQuery: DiscountQuery,
    private discountService: DiscountService,
  ) {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.chargePointsService.changeActiveChargePoint(res['id']);
      this.chargePointsService.setChargeBoxActive(res['socketId']);
    });
  }

  ngOnInit() {
    this.kIDSessionQuery.isLoggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
    this.kIDSessionQuery.userPaymentCard$.subscribe(paymentCard => {
      this.paymentCard = paymentCard;
    });
    this.kIDSessionQuery.userInvoicingAgreement$.subscribe(invoicingAgreement => {
      this.invoicingAgreement = invoicingAgreement;
    });
    this.socket$.pipe(takeUntil(this.destroyed$)).subscribe(chargeBox => {
      this.socket = chargeBox;
    });
    this.socket$.pipe(first(chargeBox => chargeBox !== null)).subscribe(chargeBox => {
      if (!chargeBox.roaming) {
        this.chargePointsService.addChargeBoxPrice(chargeBox.id);
      }
    });
    this.chargePoint$.pipe(takeUntil(this.destroyed$)).subscribe(chargePoint => {
      this.chargePoint = chargePoint;
    });

    if (this.priceCheckInterval) {
      clearInterval(this.priceCheckInterval);
    }

    this.priceCheckInterval = setInterval(() => {
      this.checkTime();
    }, 10000);

    this.checkTime();
  }

  /**
   * Function that fires when start charging slider dragging starts
   * Sets dragging styles for slider.
   * @param event Drag event
   */
  dragStarted(event: CdkDragStart) {
    const slider = event['source']['element']['nativeElement'];
    slider.style.transition = '0s linear';
    slider.style.webkitTransition = '0s linear';
  }

  /**
   * Function that fires when start charging slider dragging ends.
   * Sets slider position and styles. If slider has been dragged to the end fires start charging function.
   * @param event Drag event
   */
  dragEnded(event: CdkDragEnd) {
    const slider = event['source']['element']['nativeElement'];
    const parent = slider.parentElement;
    slider.style.transition = '0.3s linear';
    slider.style.webkitTransition = '0.3s linear';

    event.source.reset();

    // tslint:disable-next-line:max-line-length
    if (slider.getBoundingClientRect().right > (parent.getBoundingClientRect().right - (slider.getBoundingClientRect().width))) {
      // slider.style.left = 'calc(100% - 64px)';
      this.startCharging();
    } else {
      // slider.style.left = '0px';
    }
  }

  /**
   * Checks if charge box has a fee.
   * @param chargeBox charge box to check pricing from.
   */
  hasPrice(chargeBox: ChargeBox) {
    let hasPrice;
    // If not a roaming charge box, check the price object. Else check the Pricing boolean.
    if (!chargeBox.roaming) {
      hasPrice = chargeBox.price && (chargeBox.price.base || chargeBox.price.base === 0)
      && (chargeBox.price.base + chargeBox.price.duration + chargeBox.price.energy) > 0;
    } else {
      hasPrice = chargeBox.Pricing;
    }
    return hasPrice;
  }

  /**
   * Start user charing transaction if user has logged in and has payment card.
   * If not, then open login or register dialog
   */
  startCharging() {
    this.socketTransactionStatus$.next(socketStatusType.PREPARING);
    if (
      this.loggedIn && (!this.hasPrice(this.socket) || this.hasPrice(this.socket) && this.paymentCard || this.hasPrice(this.socket) && this.invoicingAgreement) &&
      (this.socket.status === 'Available' || (this.socket.status === 'Occupied' && !this.socket.transactionId))
    ) {
      if (this.discountQuery.getActive()) {
        this.discountService.setSelectedDiscount(this.discountQuery.getActive()._id).subscribe(() => {
          this.sendStartChargingCommand();
        });
      } else {
        this.discountService.clearSelectedDiscount().subscribe(() => {
          this.sendStartChargingCommand();
        });
      }
    } else if (!this.loggedIn) {
      this.loaderService.activateSpinner('startSinglePayment');
      this.singlePaymentService.getSinglePaymentSession().pipe(takeUntil(this.destroyed$))
      .subscribe((response: { session: boolean }) => {
        this.loaderService.deactivateSpinner('startSinglePayment');
        if (response.session) {
          this.activeTransactionService.listenActiveTransaction();
          this.websocketService.createChannel(ChannelType.USER, `${environment.BACKEND_URL}/transactions/socket`);
          this.sendStartChargingCommand();
        } else {
          this.openOverlayDialog('LoginRegisterComponent', { 'chargePoint': this.chargePoint, 'chargeBoxId': this.socket.id });
          this.socketTransactionStatus$.next(socketStatusType.PENDING);
        }
      }, err => {
        this.loaderService.deactivateSpinner('startSinglePayment');
        this.openOverlayDialog('LoginRegisterComponent', { 'chargePoint': this.chargePoint, 'chargeBoxId': this.socket.id });
        this.socketTransactionStatus$.next(socketStatusType.PENDING);
      });
    } else {
      this.openOverlayDialog('LoginRegisterComponent', { 'chargePoint': this.chargePoint, 'chargeBoxId': this.socket.id });
      this.socketTransactionStatus$.next(socketStatusType.PENDING);   
    }
  }

  /***
   * Sends start charging command to charger via backend
   */
  sendStartChargingCommand() {
    this.startListenSocket();
    if (!this.chargePoint.roaming) {
      this.socketTransactionStatus$.next(socketStatusType.SENDING_COMMAND);
      this.startTimeoutTimer();
      this.chargerService.startChargingTransaction(this.chargePoint.id, this.socket.id).pipe(takeUntil(this.destroyed$)).subscribe(res => {
        if (res.status !== 'Rejected') {
          this.gaService.sendChargeEvent('Lataus aloitettu', this.socket.uniqueId, this.loggedIn ? 'K-Tunnus' : 'Kertamaksu');
          this.socketTransactionStatus$.next(socketStatusType.ACCEPTED);
        } else {
          this.clearTimeoutTimer();
          this.socketTransactionStatus$.next(socketStatusType.REJECTED);
        }
      }, err => {
        this.clearTimeoutTimer();
        this.socketTransactionStatus$.next(socketStatusType.REJECTED);
        if (err.status === 403) {
          this.socketTransactionStatus$.next(socketStatusType.USER_LOCKED);
          // this.overlayDialogService.openOverlayDialog('PaymentReminderDialogComponent', { paymentReminderStatus: 3 });
        } else if (err.status === 401) {
          this.socketTransactionStatus$.next(socketStatusType.UNAUTHORIZED);
        } else {
          this.socketTransactionStatus$.next(socketStatusType.ERROR);
        }
        throw err;
      });
    } else {
      const currentSocket = this.socket;
      if (!this.invoicingAgreement || this.invoicingAgreement.organization.allowRoaming) {
        this.socketTransactionStatus$.next(socketStatusType.SENDING_COMMAND);
        this.chargerService.startRoamingChargingTransaction(this.socket.EvseID).pipe(take(1))
        .subscribe(res => {
          if (res['Result']) {
            this.websocketService.createChannel(ChannelType.ROAMING_SESSION,
              `${environment.BACKEND_URL}/roaming/sessions/${res['SessionID']}/socket`);
            this.gaService.sendChargeEvent('Roaming lataus aloitettu', currentSocket.EvseID, this.loggedIn ? 'K-Tunnus' : 'Kertamaksu');
            this.socketTransactionStatus$.next(socketStatusType.CHARGING);
            const newTransaction = {
              chargeBoxId: currentSocket.id,
              uniqueId: currentSocket.uniqueId,
              EvseID: currentSocket.EvseID,
              timestampStart: Date.now(),
              SessionID: res['SessionID']
            };
            this.activeTransactionService.addNewTransaction(newTransaction);
            this.router.navigate(['active'], { relativeTo: this.route });
          } else {
            this.socketTransactionStatus$.next(socketStatusType.TIMEOUT);
          }
        }, err => {
          if (err.status === 403) {
            this.socketTransactionStatus$.next(socketStatusType.USER_LOCKED);
            // this.overlayDialogService.openOverlayDialog('PaymentReminderDialogComponent', { paymentReminderStatus: 3 });
          } else {
            this.socketTransactionStatus$.next(socketStatusType.ERROR);
            throw err;
          }
        });
      } else {
        this.socketTransactionStatus$.next(socketStatusType.NOT_ALLOWED);
      }
    }
  }

  startTimeoutTimer() {
    if (!this.timeoutTimer) {
      this.timeoutTimer = setInterval(() => {
        if (this.timeoutTimerTime > 0) {
          this.timeoutTimerTime--;
          this.cdr.markForCheck();
        } if (this.timeoutTimerTime === 0) {
          this.socketTransactionStatus$.next(socketStatusType.TIMEOUT);
          clearInterval(this.timeoutTimer);
          this.timeoutTimerTime = 90;
        }
      }, 1000);
    }
  }

  clearTimeoutTimer() {
    if (this.timeoutTimer) {
      clearInterval(this.timeoutTimer);
    }
    this.timeoutTimerTime = 90;
    this.timeoutTimer = undefined;
  }

  startListenSocket() {
    this.websocketService.isConnected();
    // Every web socket messages for charge point are subscribed here
    this.websocketService.userMessages$.pipe(takeUntil(this.destroyed$)).subscribe((message: Transaction) => {
      if (message.chargeBoxUniqueId.toString() === this.socket.uniqueId.toString()) {
        this.socketTransactionStatus$.next(socketStatusType.CHARGING);
       if (this.activeTransactionService.hasActiveTransactionOnChargeBox(message.chargeBoxId)) {
        this.router.navigate(['active'], { relativeTo: this.route });
       } else {
        this.activeTransactionService.activeTransaction$.pipe(takeUntil(this.destroyed$)).subscribe(activeTransactions => {
          if (activeTransactions.length > 0) {
            if (activeTransactions.findIndex(activeTransaction => activeTransaction.chargeBoxId === message['chargeBoxId']) !== -1) {
              this.router.navigate(['active'], { relativeTo: this.route });
            }
          }
        });
       }
      }
    });

    //TODO: Update to use the charge-box-status channel.
    this.websocketService.chargePointMessages$.pipe(takeUntil(this.destroyed$)).subscribe((message) => {
      if (message['chargeBoxId'] === this.socket.id && this.socketTransactionStatus$.value === socketStatusType.ACCEPTED) {
        // If for some reason charging didn't start and chargebox changes back to the available state...
        if (message['messageType'] === 'StatusNotification' && message['data']['status'] === 'Available') {
          this.socketTransactionStatus$.next(socketStatusType.ERROR);
        }
      }
    });
  }

  /**
   * Open faulted charging socket report dialog.
   */
  openReportForm() {
    if (this.chargePoint.roaming) {
      this.openOverlayDialog('RoamingSupportContactComponent', { 'chargePoint': this.chargePoint, 'socket': this.socket });
    } else {
      this.openOverlayDialog('ReportFaultedSocketComponent', { 'chargePoint': this.chargePoint, 'socket': this.socket });

    }
  }

  /**
     * Open selected component as overlay dialog
     */
  openOverlayDialog(component: string, data?: object) {
    this.overlayDialogService.openOverlayDialog(component, data);
  }

  goBack() {
    this.clearTimeoutTimer();
    if (this.socketTransactionStatus$.value === socketStatusType.PENDING) {
      this.router.navigate(['/charge-point', this.chargePoint.id]);
    } else {
      this.socketTransactionStatus$.next(socketStatusType.PENDING);
    }
  }

  calculateTimeAgo(timestampStop: string): string {
    moment.locale(localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : LANGUAGES.FI);
    return moment(timestampStop).fromNow();
  }

  removeWhitespace(text: string): string {
    text = text.toLowerCase();
    return text.replace(/\s/g, '');
  }

  ngOnDestroy() {
    this.clearTimeoutTimer();
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  getSocketsString() {
    let socketsString = '';
    for (let i = 0; i < this.socket['Plugs'].length; i++) {
      if (i === this.socket['Plugs'].length - 1) {
        socketsString = socketsString + this.formatSocketTypeNamePipe.transform(setSocketType(this.socket['Plugs'][i]));
      } else {
        socketsString = socketsString + this.formatSocketTypeNamePipe.transform(setSocketType(this.socket['Plugs'][i])) + ' • ';
      }
    }
    return socketsString;
  }

  checkTime() {
    if (this.socket && this.socket.price && this.socket.price.to) {
      let diff = moment(this.socket.price.to, 'HH.mm').diff(moment());
      if(this.socket.price && (diff < 120000 && diff > -20000)) {
        this.chargePointsService.addChargeBoxPrice(this.socket.id);
      } 
    }
  }
}

export enum socketStatusType {
  PENDING,
  PREPARING,
  ACCEPTED,
  REJECTED,
  CHARGING,
  ERROR,
  TIMEOUT,
  UNAUTHORIZED,
  USER_LOCKED,
  SENDING_COMMAND,
  NOT_ALLOWED
}
