import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import * as storage from './window.storage';
import { TopBarService } from '../top-bar/top-bar.service';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WindowService {
  private width: number;
  private height: number;
  // public target: string;

  private isRouteContainerOpenSubject = new BehaviorSubject<boolean>(storage.getRouteContainerState());
  public isRouteContainerOpen$ = this.isRouteContainerOpenSubject as Observable<boolean>;

  private deviceTargetSubject = new ReplaySubject<string>(1);
  public deviceTarget$ = this.deviceTargetSubject as Observable<string>;

  constructor(
    private topBarService: TopBarService
  ) { }

  getScreenWidth(): number {
    return this.width;
  }

  getScreenHeight(): number {
    return this.height;
  }

  setScreenDimensions(innerWidth: number, innerHeight: number): void {
    if (innerWidth > 0) {
      this.width = innerWidth;
      switch (true) {
        case this.width < 800:
          this.deviceTargetSubject.next('mobile');
          break;
        case this.width >= 800 && this.width < 1024:
          this.deviceTargetSubject.next('tablet');
          break;
        case this.width >= 1024:
          this.deviceTargetSubject.next('desktop');
          break;
        default:
          this.deviceTargetSubject.next('mobile');
          break;
      }
    }
    if (innerHeight > 0) {
      this.height = innerHeight;
    }
  }

  setRouteContainerOpenState(setRouteContainerOpen: boolean) {
    this.isRouteContainerOpen$.pipe(first()).subscribe(isOpen => {
      if (isOpen !== setRouteContainerOpen) {
        this.isRouteContainerOpenSubject.next(setRouteContainerOpen);
        storage.changeRouteContainerState(setRouteContainerOpen);

        if (setRouteContainerOpen) {
          this.topBarService.closeOpenModals();
        }
      }
    });
  }
}
