
import { guid } from '@datorama/akita';
import { ChargeBox } from '../charge-boxes/charge-box.model';
import { SocketTypes, setSocketType } from '../charge-boxes/charge-box-socket-types';
export interface ChargePoint {
  visibility: string;
  id: string;
  name: string;
  coordinate: Coordinate;
  installed?: boolean;
  installationDate?: string;
  city: string;
  postalCode: string;
  streetAddress: string;
  roaming?: boolean;
  operatorID?: string;
  operatorName?: string;
  ChargingFacilities?;
  chargeBoxes: ChargeBox[];
  socketTypes: SocketTypes[];
  operative: boolean;
}

export interface Coordinate {
  lat: number;
  lng: number;
}

/**
 * A factory function that creates ChargePoint
 */
export function createChargePoint(chargePoint: Partial<ChargePoint>): ChargePoint {
  return {
    ...chargePoint,
    name: chargePoint.name ? chargePoint.name : 'unknown',
    coordinate: chargePoint.coordinate ? convertCoordinates(chargePoint.coordinate) : { lat: 0, lng: 0 },
    city: chargePoint.city ? chargePoint.city : 'unknown',
    postalCode: chargePoint.postalCode ? chargePoint.postalCode : 'unknown',
    streetAddress: chargePoint.streetAddress ? chargePoint.streetAddress : 'unknown',
    socketTypes: setChargePointSocketTypes(chargePoint),
    installationDate: chargePoint.installationDate ? chargePoint.installationDate : 'unknown',
    operative: chargePoint.operative,
  } as ChargePoint;
}

/**
 * Converts lat and long to Number.
 */
function convertCoordinates(coordinates: Coordinate): Coordinate {
  return { lat: +coordinates.lat, lng: +coordinates.lng };
}

/**
 * Set charge point available socket types
 * @param chargePoint charge point object
 * @returns chargePointSocketTypes
 */
export function setChargePointSocketTypes(chargePoint: Partial<ChargePoint>): SocketTypes[] {

  const chargePointSocketTypes: SocketTypes[] = [];

  if (chargePoint.chargeBoxes) {
    chargePoint.chargeBoxes.forEach(chargeBox => {
      const chargeBoxSocketType = setSocketType(chargeBox.socketType ? chargeBox.socketType : chargeBox['Plugs'][0]);
      if (!chargePointSocketTypes.includes(chargeBoxSocketType)) {
        chargePointSocketTypes.push(chargeBoxSocketType);
      }
    });
  }

  return chargePointSocketTypes;
}
