import { Component, EventEmitter, Output, Input } from '@angular/core';
import { FilterOption } from './../../state/filter-option.model';

@Component({
  selector: 'kc-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss']
})
export class FilterOptionComponent {

  @Input() filterOption: FilterOption;
  @Input() portraitIcon: boolean;
  @Input() showLabel = true;
  @Output() public change = new EventEmitter<Partial<FilterOption>>();

}
