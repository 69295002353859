import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { AppUpdateService } from '../services/app-update.service';

@Component({
  selector: 'kc-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateDialogComponent implements OnInit {

  constructor(
    public appUpdateService: AppUpdateService,
    private cdr: ChangeDetectorRef) {
    }

  ngOnInit() {
    this.appUpdateService.updateFlag.subscribe(value => {
      if (value) {
        this.cdr.markForCheck();
      }
    });
  }

  onClose() {
    // this.updateDialogService.close();
  }

}
