import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { PORTAL_DATA } from '../../../overlay-dialog/overlay-dialog.storage';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { KChargeProfile } from 'src/app/k-charge-profile/k-charge-profile.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/language/language.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { requestStatus } from 'src/app/helpers/requestStatuses';

@Component({
  selector: 'kc-edit-app-settings',
  templateUrl: './edit-app-settings.component.html',
  styleUrls: ['./edit-app-settings.component.scss']
})
export class EditAppSettingsComponent implements OnInit {

  appSettingsForm: UntypedFormGroup;
  kChargeProfile$: Observable<KChargeProfile>;
  languages: Array<string>;
  originalLanguage: string;
  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);

  constructor(@Inject(PORTAL_DATA) public data,
    public formBuilder: UntypedFormBuilder,
    private kChargeProfileService: KChargeProfileService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private kIDSessionQuery: KIDSessionQuery) { }

  ngOnInit() {
    this.languages = this.translateService.langs;

    this.appSettingsForm = this.formBuilder.group({
      language: [''],
      marketing: [false]
    });

    // Get user current app settings
    // Get user current contact information
    this.kChargeProfile$ = this.kIDSessionQuery.kChargeProfile$;
    this.kChargeProfileService.get();

    this.kChargeProfile$.subscribe(profile => {
      this.originalLanguage = profile.appSettings.language;
      this.appSettingsForm.patchValue(profile['appSettings']);
      this.appSettingsForm.get('marketing').patchValue(profile.contactInfo.marketing);

      this.appSettingsForm.valueChanges.subscribe(val => {
        this.translateService.use(val.language);
        this.languageService.registerCulture(val.language);
      });
    });
  }

  /**
   * Change switch state (changes scss styling)
   */
   marketingAgreement() {
    return this.appSettingsForm.get('marketing').value;
  }

  /**
   * Change marking agreement value
   */
  changeMarketingAgreemet() {
    const marketing = !this.appSettingsForm.get('marketing').value;
    this.appSettingsForm.get('marketing').setValue(marketing);
  }

  changeMarketingAgreemetSwipe(value) {
    this.appSettingsForm.get('marketing').setValue(value);
  }

  /**
   * Update K-Charge profile app settings
   */
  save(form) {
    this.requestStatus$.next(requestStatus.SEND);

    const updatedKChargeProfile: KChargeProfile = {
      contactInfo: { marketing: form.get('marketing').value}, 
      appSettings: { language: form.get('language').value},
      tags: this.kChargeProfile$['tags']
    };

    this.kChargeProfileService.updateProfile(updatedKChargeProfile).subscribe(res => {
      this.requestStatus$.next(requestStatus.ACCEPTED);
      localStorage.setItem('language', res['appSettings']['language'].toLowerCase());
      // this.close();
    }, err => {
      this.requestStatus$.next(requestStatus.PENDING);
      this.data.openNewOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
      'source': { 'component': 'editAppSettingsComponent', 'function': 'updateProfile'}});
      throw err;
    });
  }

  /**
   * Close edit app settings overlay dialog
   */
  close() {
    this.data.close();
  }

  closeOverlay() {
    this.translateService.use(this.originalLanguage);
    this.languageService.registerCulture(this.originalLanguage);
    this.close();
  }
}
