import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KChargeProfile, createProfileContactInfo, createProfileAppSettings } from './k-charge-profile.model';
import { KIDSessionQuery } from '../k-id-session/k-id-session.query';
import { isEmpty } from 'lodash';
import { Observable, throwError} from 'rxjs';
import { tap, catchError} from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { KIDSessionStore } from '../k-id-session/k-id-session.store';
import { AuthSessionService } from '../auth/auth-session.service';
import { AppVersionService, Versions } from '../services/app-version.service';
import { LoaderService } from '../loader/loader.service';

@Injectable({ providedIn: 'root' })
export class KChargeProfileService {
  private _userDevice: String;

  constructor(
    private kIDSessionStore: KIDSessionStore,
    private kIDSessionQuery: KIDSessionQuery,
    private authSessionService: AuthSessionService,
    private appVersionService: AppVersionService,
    private http: HttpClient,
    private loaderService: LoaderService) {}

  /**
   * Get K-Charge Profile data
   */
  get() {
    // Fetch current K-ID session K-Charge profile data
    const kChargeProfile: KChargeProfile = this.kIDSessionQuery.getKChargeProfile();

    // If session doesn't have app settings, then create new app settings for session.
    if (isEmpty(kChargeProfile.appSettings)) {
      kChargeProfile.appSettings = createProfileAppSettings();
    }

    // If session doesn't have contact info data, then create new contact info data for session.
    if (isEmpty(kChargeProfile.contactInfo)) {
      kChargeProfile.contactInfo = createProfileContactInfo();
    }

    // Set fetched K-Charge data as active K-Charge Data
    this.kIDSessionStore.updateProfile(kChargeProfile);
  }

  /**
   * Update current K-ID Session K-Charge profile data and save it to storage
   * @param kChargeProfile updated K-Charge profile data
   */
  updateProfile(kChargeProfile: Partial<KChargeProfile>): Observable<KChargeProfile> {

    return this.http.patch<KChargeProfile>(`${environment.BACKEND_URL}/profile`, kChargeProfile).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      }),
      tap(res => {
        this.kIDSessionStore.updateProfile(res);
      })
    );
  }

  /**
   * Updates the status of tag order.
   * @param status updated status.
   */
  updateTagOrderedStatus(status: boolean) {
    this.kIDSessionStore.updateProfile({ tagOrdered: status });
  }

  /**
   * Get active K-charge profile tags
   */
  getTags() {
    // Fetch current K-ID session K-Charge profile data
    const kChargeProfile: KChargeProfile = this.kIDSessionQuery.getKChargeProfile();
    return kChargeProfile['tags'] ? kChargeProfile['tags'] : [];
  }

  /**
   * Hides a deactivated tag.
   * @param serialNumber of tag that was paused.
   */
  hideDeactivatedTag(serialNumber: string) {
    const kChargeProfile = this.kIDSessionQuery.getKChargeProfile();
    let tags = kChargeProfile.tags;
    tags = tags.filter((t: any) => t.serialNumber != serialNumber);
    this.kIDSessionStore.updateProfile({tags});
  }

  /**
   * Update tags
   */
  updateTags(tags) {

    return new Observable(res => {
      return res;
    });
  }

  /**
   * Order tag for profile
   */
  orderTag() {

    return this.http.post<KChargeProfile>(`${environment.BACKEND_URL}/tags`, null).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  /**
   * Deactivates a tag.
   */
  deactivateTag(tagSerialnumber: string) {

    return this.http.post<KChargeProfile>(`${environment.BACKEND_URL}/tags/deactivate`, {serialNumber: tagSerialnumber}).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  /**
   * Sends support request to Plugit ticket system
   * @param request support request
   */
  sendSupportRequest(request): Observable<any> {

    return this.http.post(`${environment.BACKEND_URL}/tickets`, request, { responseType: 'text' as 'json' }).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      })
    );
  }

  async logout() {
    this._userDevice = this.appVersionService.getVersion();
    // Handle iOS app.
    if (this._userDevice === Versions.IOS_APP) {
      const iOSBridge = this.appVersionService.getIOSBridge();
      if (!iOSBridge) {
        this.authSessionService.updateAuthState({isAuthenticating: false, isSessionVerified: false, error: 'invalidVersion'});
        return;
      }
      iOSBridge.postMessage('logout');
      this.loaderService.activateSpinner('loggingOut');
      // Handle Android app.
    } else if (this._userDevice === Versions.ANDROID_APP) {
      const androidBridge = this.appVersionService.getAndroidBridge();
      if (!androidBridge) {
        this.authSessionService.updateAuthState({isAuthenticating: false, isSessionVerified: false, error: 'invalidVersion'});
        return;
      }
      androidBridge.promptLogout();
      this.loaderService.activateSpinner('loggingOut');
    } else {
      location.href = `${environment.BACKEND_URL}/auth/logout`;
    }
  }

  login() {
    this.authSessionService.updateAuthState({isAuthenticating: true, isSessionVerified: false});
    this._userDevice = this.appVersionService.getVersion();

    // Handle iOS app.
    if (this._userDevice === Versions.IOS_APP) {
      const iOSBridge = this.appVersionService.getIOSBridge();
      if (!iOSBridge) {
        this.authSessionService.updateAuthState({isAuthenticating: false, isSessionVerified: false, error: 'invalidVersion'});
        return;
      }
      iOSBridge.postMessage('login');
      // this.loaderService.activateSpinner('loggingIn');
      // Handle Android app.
    } else if (this._userDevice === Versions.ANDROID_APP) {
      const androidBridge = this.appVersionService.getAndroidBridge();
      if (!androidBridge) {
        this.authSessionService.updateAuthState({isAuthenticating: false, isSessionVerified: false, error: 'invalidVersion'});
        return;
      }
      androidBridge.promptLogin();
      // this.loaderService.activateSpinner('loggingIn');
    } else {
      // Handle browser.
      location.href = `${environment.BACKEND_URL}/login`;
    }
  }
}
