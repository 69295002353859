<div class="overlayContainer__wrapper --scrollable">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <ng-container *ngIf="!locationsOpen; else locationsList">
        <div class="overlayContainer__header">
          <span class="title">{{ data.data.campaign.name }}</span>
          <span class="info">{{ data.data.campaign.description }}</span>
        </div>
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ 'defaultDiscount' | translate | uppercase }}</span>
        </div>
        <div class="overlayContainer__textContainer">
          <div class="defaultDiscountContainer">
            <span class="info" style="font-size: 0.9rem">{{
              "setAsDefaultDiscount" | translate
            }}</span>
            <div class="loaderAndSwitch">
              <div class="dot-flashing" *ngIf="defaultLoading"></div>
              <ng-container *ngIf="discount$ | async as discount">
                <div
                  class="switch"
                  [ngClass]="discount.defaultDiscount ? 'on' : ''"
                  (click)="switchChange(!discount.defaultDiscount)"
                  (swipeleft)="switchChange(false)"
                  (swiperight)="switchChange(true)"
                ></div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ "discount" | translate | uppercase }}</span>
        </div>
        <div class="overlayContainer__textContainer">
          <div class="rateItemWrapper" *ngIf="data.data.campaign.discount.ac">
            <div class="textAndIcons">
              <span class="info">{{ "acCharging" | translate }}</span>
              <img src="../../../assets/icons/png/icon-Type2@2x.png" />
            </div>
            <div class="rateContainer -orange">
              <span class="rate">-{{ data.data.campaign.discount.ac }} %</span>
            </div>
          </div>
          <div class="rateItemWrapper" *ngIf="data.data.campaign.discount.dc">
            <div class="textAndIcons">
              <span class="info">{{ "dcCharging" | translate }}</span>
              <div>
                <img src="../../../assets/icons/png/icon-CHAdeMO@2x.png" />
                <img src="../../../assets/icons/png/icon-CCS2@2x.png" />
              </div>
            </div>
            <div class="rateContainer -orange">
              <span class="rate">-{{ data.data.campaign.discount.dc }} %</span>
            </div>
          </div>
          <div
            class="rateItemWrapper"
            *ngIf="data.data.campaign.discount.roaming"
          >
            <div class="textAndIcons">
              <span class="info">{{ "roamingCharging" | translate }}</span>
              <img
                src="../../../assets/icons/png/icon-pumpElectricity@2x_blue.png"
              />
            </div>
            <div class="rateContainer -blue">
              <span class="rate"
                >-{{ data.data.campaign.discount.roaming }} %</span
              >
            </div>
          </div>
        </div>
        <div class="overlayContainer__contentTemplateLabel" *ngIf="!(data.data.campaign.limits.balance === 0 && data.data.campaign.limits.energy === 0 && data.data.campaign.limits.duration === 0 && data.data.campaign.limits.transaction === 0 && !data.data.campaign.ends)">
          <span>{{ 'usageLimit' | translate | uppercase }}</span>
        </div>
        <div class="overlayContainer__textContainer">
          <div
            class="progressBarContainer"
            *ngIf="data.data.campaign.limits.balance"
          >
            <span class="progressTitle">{{ "saldo" | translate }}</span>
            <span class="progressSubtitle"
              >{{ "used" | translate }}
              {{
                (data.data.campaign.limits.balance -
                  data.data.limitUsage.balance) /
                  100
              }}
              / {{ data.data.campaign.limits.balance / 100 }} €</span
            >
          </div>
          <div
            class="progressBarContainer"
            *ngIf="data.data.campaign.limits.energy"
          >
            <span class="progressTitle">{{ "energy" | translate }}</span>
            <span class="progressSubtitle"
              >{{ "used" | translate }}
              {{
                (data.data.campaign.limits.energy -
                  data.data.limitUsage.energy) /
                  1000
              }}
              / {{ data.data.campaign.limits.energy / 1000 }} kWh</span
            >
          </div>
          <div
            class="progressBarContainer"
            *ngIf="data.data.campaign.limits.duration"
          >
            <span class="progressTitle">{{ "time" | translate }}</span>
            <span class="progressSubtitle">{{ 'used' | translate }}
              {{ floor((data.data.campaign.limits.duration - data.data.limitUsage.duration) / 60) }} /
              {{ floor(data.data.campaign.limits.duration / 60) }} min</span>
          </div>
          <div
            class="progressBarContainer"
            *ngIf="data.data.campaign.limits.transaction"
          >
            <span class="progressTitle">{{
              "chargingInstances" | translate
            }}</span>
            <span class="progressSubtitle"
              >{{ "used" | translate }}
              {{
                data.data.campaign.limits.transaction -
                  data.data.limitUsage.transaction
              }}
              / {{ data.data.campaign.limits.transaction }}</span
            >
          </div>
          <div class="progressBarContainer" *ngIf="canExpire(data.data)">
            <span class="progressTitle">{{ "validUntil" | translate }}</span>
              <span class="progressSubtitle"
              >{{ getRemainingTime(expires(data.data)) }} ({{
                "ends" | translate
              }}
              {{ expires(data.data).format("DD.MM.YYYY") }})</span>
          </div>
        </div>
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ 'locations' | translate | uppercase }}</span>
        </div>
        <div class="overlayContainer__textContainer">
          <ng-container
            *ngIf="
              !data.data.campaign.specialRules.allowAllLocations;
              else allLocationsAllowed
            "
          >
            <ng-container *ngIf="locations$ | async as locations">
              <div
                class="locationItem__wrapper"
                (click)="toggleLocations(false)"
              >
                <div class="locationItem__iconAndText">
                  <img
                    class="portrait"
                    src="../../../assets/icons/png/01_Normal@2x.png"
                  />
                  <div class="textContainer">
                    <span class="title">
                      {{ "kLatausLocations" | translate }}</span
                    >
                    <span class="subtitle">
                      {{ locations.chargePoints.length }}</span
                    >
                  </div>
                </div>
                <div class="locationItem__arrow">
                  <img src="../../../assets/icons/svg/chevron-right.svg" />
                </div>
              </div>
            </ng-container>
          </ng-container>
          <!-- <span>{{ 'discountAppliesToLocations' | translate }}</span> -->
        </div>
        <div class="buttonContainer">
          <button (click)="close()">
            {{ "close" | translate | uppercase }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #locationsList>
  <div class="locationWrapper">
    <div class="mainContent">
      <div class="overlayContainer__header">
        <span class="title">{{ data.data.campaign.name }}</span>
        <span class="info">{{ "theseLocationsAllowed" | translate }}</span>
      </div>
      <ng-container *ngIf="locations$ | async as locations">
        <div class="overlayContainer__contentTemplateLabel">
          <span
            >{{ "kLatausLocations" | translate | uppercase }} •
            {{ locations.chargePoints.length }}</span
          >
        </div>
        <ng-container *ngFor="let cp of locations.chargePoints">
          <div class="locationItem__wrapper">
            <div class="locationItem__iconAndText">
              <img
                class="landscape"
                src="../../../assets/icons/png/K-Lataus@2x.png"
              />
              <div class="textContainer">
                <span class="title"> {{ cp.name }}</span>
                <span class="subtitle"> {{ cp.address.streetAddress }}</span>
              </div>
            </div>
            <!-- <div class="locationItem__arrow">
                      <img src="../../../assets/icons/svg/chevron-right.svg">
                    </div> -->
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="buttonContainer">
      <button (click)="toggleLocations(false)">
        {{ "close" | translate | uppercase }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #allLocationsAllowed>
  <div class="infoWrapper">
    <span class="info">{{ "allLocationsAllowedInfo" | translate }}</span>
  </div>
</ng-template>
