import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { KIDSessionState, KIDSessionStore } from './k-id-session.store';
import { environment } from 'src/environments/environment';
import { WebsocketService, ChannelType } from '../websocket-service/websocket.service';
import { AuthSessionQuery } from '../auth/auth-session.query';
import { InvoiceService } from '../routes/invoice/invoice.service';
import { WindowService } from '../window-service/window.service';
import { ActiveTransactionService } from '../routes/charge-point/active-transaction/active-transaction.service';
import * as windowStorage from '../window-service/window.storage';
import * as filterOpitonsStorage from '../top-bar/filter-options/state/filter-options.storage';

import { OverlayDialogService } from '../overlay-dialog/overlay-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService } from '../loader/loader.service';
import { DiscountStore } from '../routes/discounts/state/discount.store';
import { KIDSessionQuery } from './k-id-session.query';

@Injectable()
export class KIDSessionService {
  constructor(
    private kIDSessionStore: KIDSessionStore,
    private kIDSessionQuery: KIDSessionQuery,
    private http: HttpClient,
    private authSessionQuery: AuthSessionQuery,
    private invoiceService: InvoiceService,
    private websocketService: WebsocketService,
    private overlayDialogService: OverlayDialogService,
    private translateService: TranslateService,
    private windowService: WindowService,
    private activeTransactionsService: ActiveTransactionService,
    private loaderService: LoaderService,
    private discountStore: DiscountStore
  ) {}

  // Gets user data and handles missing permissions and active warnings on user.
  // The method is called after successfull login and on app init (if logged in).
  public getUserData() {
    // If profile is not fetched yet, display a loading spinner
    if (!this.kIDSessionQuery.getUserId()) {
      this.loaderService.activateSpinner('profile');
      setTimeout(() => this.loaderService.deactivateSpinner('profile'), 10000);
    }

    this.http.get<KIDSessionState>(`${environment.BACKEND_URL}/profile`).subscribe(
      kIDSession => {
        this.loaderService.deactivateSpinner('profile');
        // Save the user to the store.
        this.setUser(kIDSession);
        // Init required websocket connections.
        this.initUserConnections();
        // Open dialog for user info update if the name or email is missing.
        const hasName = kIDSession.familyName && kIDSession.familyName !== '' && kIDSession.givenName && kIDSession.givenName !== '';
        const hasEmail = kIDSession.email && kIDSession.email !== '';
        if (!hasName || !hasEmail) {
          this.overlayDialogService.openOverlayDialog('EditUserComponent', { updateName: !hasName, updateEmail: !hasEmail, forced: true });
        }
        
        if (kIDSession.invitation && kIDSession.invitation.status === 'pending') {
          this.overlayDialogService.openOverlayDialog('InvoicingInvitationComponent', {invitation: kIDSession.invitation, userId: kIDSession.id});
        }
        // Handle possible warnings.
        this.handleWarnings(kIDSession);
        if (this.authSessionQuery.getValue().isSessionVerified) {
          this.loaderService.deactivateSpinner('loggingIn');
        }
      }, err => {
        this.loaderService.deactivateSpinner('profile');
        if (this.authSessionQuery.getValue().isSessionVerified) {
          this.loaderService.deactivateSpinner('loggingIn');
        }
        console.error(err);
        throw err;
      });
  }

  private initUserConnections() {
    this.websocketService.createChannel(ChannelType.USER, `${environment.BACKEND_URL}/transactions/socket`);
    this.websocketService.createChannel(ChannelType.TRANSACTION_PRICES, `${environment.BACKEND_URL}/transaction-prices/socket`);
  }

  private handleWarnings(kIDSession) {
      // If the user is banned, display a dialog which prevents taking any actions.
      if (kIDSession.banned) {
        this.overlayDialogService.openOverlayDialog('AccountLockedDialogComponent');
      }

      // If user haven't accepted tos. Then open dialog.
      if (!kIDSession.acceptedTos) {
        this.overlayDialogService.openOverlayDialog('TosDialogComponent');
      }

      if (kIDSession.lockStatus['locked']) {
        this.overlayDialogService.openOverlayDialog('PaymentReminderDialogComponent', { paymentReminderStatus: 3 });
      } else if (kIDSession.lockStatus['secondWarning'] !== null) {
        this.overlayDialogService.openOverlayDialog('PaymentReminderDialogComponent', { paymentReminderStatus: 2 });
      } else if (kIDSession.lockStatus['firstWarning'] !== null && kIDSession.lockStatus['secondWarning'] === null) {
        this.overlayDialogService.openOverlayDialog('PaymentReminderDialogComponent', { paymentReminderStatus: 1 });
      } else {
        this.overlayDialogService.closeExistingDialog('PaymentReminderDialogComponent');
      }
  }

  // Called on logout.
  public localLogout() {
    this.clearSettings();
    this.clearUserData();

    // window.location.reload();
  }

  // Set user to store and update settings.
  private setUser(kIDSession) {
    this.kIDSessionStore.update(kIDSession);

    const language = kIDSession['appSettings']['language'] ? kIDSession['appSettings']['language'].toLowerCase() : 'fi';

    localStorage.setItem('language', language);
    this.translateService.use(language);
  }

  // Clear saved settings from local storages.
  private clearSettings() {
    this.windowService.setRouteContainerOpenState(true);
    windowStorage.clearRouteContainerState();
    filterOpitonsStorage.clearFilterOptions();
  }

  // Clear user related data from stores and cancel active connections.
  private clearUserData() {
    this.kIDSessionStore.reset();
    this.invoiceService.resetStore();
    this.websocketService.destroyChannel(ChannelType.USER);
    this.activeTransactionsService.resetTransactions();
    this.discountStore.reset();

    this.clearLegacyData();
  }

  /**
 * TO REMOVE LEGACY DATA.
 * Removes user from local storage.
 */
 private clearLegacyData() {
  const ACTIVE_USER = 'kIDSession';
  localStorage.removeItem(ACTIVE_USER);
}
}

