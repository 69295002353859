import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { openTransactions } from 'src/app/animations';
import { combineLatest, Observable } from 'rxjs';
import { TransactionsService } from '../state/transactions.service';
import { TransactionsQuery } from '../state/transactions.query';
import { InvoiceQuery } from '../../invoice/invoice.query';
import { map } from 'rxjs/operators';
import * as moment from 'moment';


@Component({
  selector: 'kc-monthly-transactions',
  templateUrl: './monthly-transactions.component.html',
  styleUrls: ['./monthly-transactions.component.scss'],
  animations: [openTransactions()],
})
export class MonthlyTransactionsComponent implements OnInit {
  @Input() month;
  @Input() year;
  @Input() isOpen = false;
  @Output() monthSelected = new EventEmitter<{month: number, year: number}>();

  transactions$: Observable<any>;
  isLoadingMonthlyTransactions$: Observable<boolean>;
  transactionsFetchedOnce = false;
  
  date: Date;

  constructor(private transactionsService: TransactionsService,
    private transactionsQuery: TransactionsQuery,
    private invoiceQuery: InvoiceQuery,
  ) { }

  ngOnInit() {
    const today = new Date();
    this.date = new Date(this.year, this.month);

    // Current month is opened by default, so fetch is done without changing UI state.
    if(moment({month: this.date.getMonth(), year: this.date.getFullYear(), day: 1}).isSame(
      moment({month: today.getMonth(), year: today.getFullYear(), day: 1})) ||
      this.isOpen) {
      this.fetchMonthlyTransactions(false);
    }

  }

  fetchMonthlyTransactions(updateUIState = true) {
    this.transactionsFetchedOnce = this.checkFetchedMonths();

    // Sends event to parent which will toggle the UI state for this element.
    if(updateUIState) {
      this.monthSelected.emit({month: this.month, year: this.year}); 
    }
      
    this.date = new Date(this.year, this.month);
    this.transactionsService.getMonthlyTransactions(this.year, this.month);
    this.isLoadingMonthlyTransactions$ = this.transactionsQuery.selectLoading();

    // Merges the transactions and invoices by months. Is updated automatically if changes happens in either query.
    this.transactions$ = combineLatest([this.transactionsQuery.transactionsByMonths$, this.invoiceQuery.paidInvoices$]).pipe(map((res => {
      let monthlyTransactions = {};

      if(res[0][0]) {
        monthlyTransactions = { 
          transactions: res[0].filter(transactions => (transactions[0] && moment(transactions[0].timestampStart).month() === moment(this.date).month()) &&
           (moment(transactions[0].timestampStart).year() === moment(this.date).year()))[0],
          invoices: this.invoiceQuery.getPaidInvoicesByMonth(this.date)
        };
      }
      return monthlyTransactions;
    })));
  }

  checkFetchedMonths(): boolean {
    return !!this.transactionsQuery.getMonths().find(value => value.year === this.year && value.month === this.month); 
  }
}
