import { ServiceTypes } from "../k-store/k-store-services";

export class ServiceIconHelper {
  static getServiceIcon(service: string) {
    switch (service) {
      case ServiceTypes.RESTAURANT: {
        return './assets/icons/png/icon-fork_knife2@2x.png';
      }
      case ServiceTypes.BATHROOM: {
        return './assets/icons/png/icon-wc.png';
      }
      case ServiceTypes.POST: {
        return './assets/icons/png/icon-mail@2x.png';
      }
      case ServiceTypes.CASH_WITHDRAWAL: {
        return './assets/icons/png/icon-payment_cash@2x.png';
      }
      case ServiceTypes.CARWASH: {
        return './assets/icons/png/icon-carwash@2x.png';
      }
      case ServiceTypes.SALADBAR: {
        return './assets/icons/png/icon-fruit_vegetables@2x.png';
      }
      case ServiceTypes.SUSHI: {
        return './assets/icons/png/icon-runeberg@2x.png';
      }
      case ServiceTypes.CAFE: {
        return './assets/icons/png/icon-cafe.png';
      }
      case ServiceTypes.ONLINE_ORDER_PICKUP: {
        return './assets/icons/png/icon-methodPickup3@2x.png';
      }
      default: {
        return './assets/icons/png/icon-info@2x.png';
      }
    }
  }
}
