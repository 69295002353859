import { setSocketType } from './charge-box-socket-types';
import { formatStatus, isChargeBoxOnline } from './charge-box-status-types';

export interface ChargeBox {
    // Mandataory charge box object values
    id: string;
    status: string;
    uniqueId: string;
    socketType: string;
    power: number;
    // Roaming charge box object values
    roaming?: boolean;
    EvseID?: string;
    AuthenticationModes;
    HotlinePhoneNumber?: string;
    evseStatus?: string;
    Status?: string;
    pricingProducts?: EvsePricingProducts[];
    Pricing?: boolean;
    // Plugit charge box object values
    chargeBoxGroupId?: string;
    connectorId?: number;
    price?: Price;
    transactionId?: string;
    latestActivity?: LatestActivity;
    operative?: boolean;
    chargePointId?: string;
}

export enum EvseReferenceUnit {
    KILOWATT_HOUR = 'KILOWATT_HOUR',
    HOUR = 'HOUR',
    MINUTE = 'MINUTE',
}

export enum EvseProductAvailabilityType {
    EVERYDAY = 'Everyday',
    WORKDAYS = 'Workdays',
    WEEKEND = 'Weekend',
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday'
}

export interface EvseAdditionalReference {
    AdditionalReferenceUnit: EvseReferenceUnit;
    PricePerAdditionalReferenceUnit: number;
    AdditionalReference: EvseAdditionalReferenceType;
}

export enum EvseAdditionalReferenceType {
    START_FEE = 'START FEE',
    FIXED_FEE = 'FIXED FEE',
    PARKING_FEE = 'PARKING FEE',
    MINIMUM_FEE = 'MINUMUM FEE',
    MAXIMUM_FEE = 'MAXIMUM FEE'
}
export interface EvseChargeBoxUpdate {
    EvseID: string;
    Status: string;
    evseID: string;
    evseStatus: string;
    pricingProducts: EvsePricingProducts[];
}

export enum EvseActiveTime {
    EVERYDAY = 'EVERYDAY'
}

export interface EvseProductAvailabilityTimes {
    on: EvseActiveTime;
    Periods:  [{begin: string, end: string}];
}

export interface EvsePricingProductDataRecords {
    IsValid24hours: boolean;
    MaximumProductChargingPower: number;
    PricePerReferenceUnit: number;
    ProductAvailabilityTimes: EvseProductAvailabilityTimes[];
    ProductPriceCurrency: string;
    ReferenceUnit: EvseReferenceUnit;
    AdditionalReferences: EvseAdditionalReference[];
}

export interface EvsePricingProducts {
    PricingDefaultPrice: number;
    PricingDefaultPriceCurrency: string;
    PricingDefaultReferenceUnit: EvseReferenceUnit;
    PricingProductDataRecords: EvsePricingProductDataRecords;
    defaultPricing: boolean;
}

export function isChargeBox(chargeBox): chargeBox is ChargeBox {
    return (chargeBox as ChargeBox).uniqueId !== undefined || (chargeBox as ChargeBox).EvseID !== undefined;
  }

export interface LatestActivity {
    messageType: string;
    timestamp: string;
}

export const PriceType = {
    STANDARD: 'standard',
    SPOT_PRICING: 'spot-pricing',
    SCHEDULED: 'scheduled',
    FREE: 'free',
  } as const;
  
export interface Price {
    base: number;
    duration: number;
    energy: number;
    freeMinutes: number;
    minuteStep: number;
    currency?: string;
    active: boolean;
    from?: string;
    to?: string;
    vat?: number;
    commission?: number;
    schedules?: Price[];
}

export function createChargeBox(chargeBox: Partial<ChargeBox>) {
    return {
        ...chargeBox,
        status: formatStatus(isChargeBoxOnline(chargeBox)),
        socketType: setSocketType(chargeBox.socketType),
    } as ChargeBox;
}

export function createRoamingChargeBox(chargeBox: Partial<ChargeBox>) {
    return {
        ...chargeBox,
        id: chargeBox['EvseID'],
        uniqueId: chargeBox['EvseID'],
        socketType: setSocketType(chargeBox['Plugs'][0] || chargeBox.socketType),
        status: formatStatus(chargeBox.evseStatus || chargeBox.status || chargeBox.Status),
        roaming: true,
    } as ChargeBox;
}

export function manipulateChargeBoxStatus(chargeBox: Partial<ChargeBox>) {
    return {
        ...chargeBox,
        status: chargeBox.roaming ? formatStatus(chargeBox.status) : isChargeBoxOnline(chargeBox),
    } as ChargeBox;
}

