import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { isEmpty } from 'lodash';

/**
 * This storage handles getting, setting and removing active socket on local storage
 */

const ACTIVE_SOCKET = 'activeSocket';

/**
 * Get active socket
 * If not found, then return null as not set state.
 */
export function getActiveSocketId(): string {
    const activeSocket = localStorage.getItem(ACTIVE_SOCKET);
    return activeSocket ? JSON.parse(activeSocket) : null;
}

/**
 * Set active socket
 * @param activeSocket selected active socket
 */
export function setActiveSocket(activeSocketId: string) {
    if (isEmpty(activeSocketId)) {
        clearActiveSocket();
    } else {
        localStorage.setItem(ACTIVE_SOCKET, JSON.stringify(activeSocketId));
    }
}

/**
 * Removes active socket from local storage
 */
export function clearActiveSocket() {
    localStorage.removeItem(ACTIVE_SOCKET);
}
