import { Component, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'kc-transactions-overview',
  templateUrl: './transactions-overview.component.html',
  styleUrls: ['./transactions-overview.component.scss']
})
export class TransactionsOverviewComponent {
  @Input() unpaidInvoices;
  @Input() invoicingAgreementInvoices;
  @ViewChild('slideContainer') slideContainer;

  onWheel(event: WheelEvent): void {
    this.slideContainer.nativeElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }

  ngOnInit() {
  }

}