<div class="overlayContainer__wrapper" [ngClass]="requestSend ? '' : '--scrollable'" *ngIf="step === 1">
  <div class="overlayContainer__header">
    <span class="title">{{ (profileTags.length === 0) ? ('orderTag' | translate) : ('tagInfo' | translate) }}</span>
  </div>

  <ng-container *ngIf="!requestSend; else requestSendTpl">

    <!-- <div *ngIf="profileTags.length === 0" class="overlayContainer__contentWrapper">
      <form class="overlayContainer__contentTemplate" [formGroup]="orderTagForm" (ngSubmit)="orderTag()">
        <div class="iconContainer">
          <div>
            <img class="k-charge-tag" src="../../../../assets/logos/k-charge-tag-2020.png" alt="k-charge-tag">
          </div>
        </div>
        <div class="overlayContainer__header">
          <span class="title">{{ 'orderTagHeader' | translate }}</span>
          <span class="info">{{ 'orderInfoText' | translate }}</span>
        </div>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ 'cancel' | translate | uppercase}}</span>
          </button>
          <button type="submit" class="overlayContainer__actionButton --orange" [disabled]="!orderTagForm.valid">
            <span>{{ 'order' | translate | uppercase}}</span>
          </button>
          <button type="submit" class="overlayContainer__actionButton --orange" *ngIf="!orderTagForm.valid" (click)="openContactInfoSettings()">
            <span>{{ 'addContactInfo' | translate | uppercase}}</span>
          </button>
        </div>
      </form>
    </div> -->

    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="iconContainer">
          <div>
            <img class="k-charge-tag" src="../../../../assets/logos/k-charge-tag.png" alt="k-charge-tag">
          </div>
        </div>
        <ng-container *ngFor="let tag of profileTags">
          <ng-container
            [ngTemplateOutlet]="(!deactivationConfirmationActive || selectedSerialNumber !== tag.serialNumber) ? tagInfo : deactivateConfirmation"
            [ngTemplateOutletContext]="{tag: tag}">
          </ng-container>
        </ng-container>
        <div class="overlayContainer__supportWrapper">
          <div class="overlayContainer__supportContainer">
            <img src="../../../assets/icons/png/support@2x.png" alt="">
            <div class="textContainer">
              <span class="header">{{'removeTagHeaderText' | translate}}</span>
              <span class="infoText">{{'removeTagInfoText' | translate}}:</span>
            </div>
          </div>
          <div class="overlayContainer__supportActionContainer">
            <a href="tel:0207-350-333">{{ 'supportCallNumber' | translate }}</a>
            <label for="" class="callPriceLabel">{{ 'supportCallPrice' | translate }}</label>
          </div>
        </div>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ 'close' | translate | uppercase}}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #requestSendTpl>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="iconContainer">
          <div>
            <img class="k-charge-tag" src="../../../../assets/logos/k-charge-tag.png" alt="k-charge-tag">
          </div>
        </div>
        <div class="successfulIcon">
          <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
        </div>
        <div class="overlayContainer__header">
          <span class="title">{{ 'tagOrderSendTitle' | translate }}</span>
          <span class="info">{{ 'tagOrderSendInfo' | translate }}</span>
        </div>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ (!deactivationConfirmationActive ? 'close' : 'cancel') | translate | uppercase}}</span>
          </button>
        </div>
      </div>

    </div>
  </ng-template>

  <ng-template #tagInfo let-tag="tag">
    <div class="overlayContainer__infoContainer manageTags__tagElement">
      <div class="overlayContainer__infoContainer">
        <img src="../../../../assets/icons/png/icon-tag@2x.png" alt="">
        <div class="infoTextContainer">
          <span class="title">{{ tag.label }}</span>
          <span class="info">ID: {{ tag.serialNumber }}</span>
        </div>
      </div>
      <span class="manageTags__deactivateButton" (click)="toggleDeactivationConfirmation(tag.serialNumber)">{{'deactivateTag' | translate}}</span>
    </div>
  </ng-template>

  <ng-template #deactivateConfirmation let-tag="tag">
    <div class="overlayContainer__infoContainer manageTags__tagElement">
      <div class="overlayContainer__infoContainer">
        <div class="infoTextContainer">
          <span class="title --orange">{{ 'tagDeactivationConfirmation' | translate }}</span>
        </div>
      </div>
      <div class="manageTags__confirmationContainer">
        <div class="manageTags__roundIconButton" [ngClass]="tagDeactivationLoading ? '--disabled' : ''" (click)="deactivateTag(tag.serialNumber)">
          <i class="fak fa-k-tick -orange"></i>
        </div>
        <div class="manageTags__roundIconButton" [ngClass]="tagDeactivationLoading ? '--disabled' : ''" (click)="toggleDeactivationConfirmation(undefined)">
          <i class="fak fa-k-close -orange"></i>
        </div>
      </div>
    </div>
  </ng-template>


  <!-- SAVED FOR LATER USE -->
  <!-- <div *ngIf="profileTags.length !== 0" class="overlayContainer__contentWrapper">
    <form [formGroup]="manageTagForm" class="overlayContainer__contentTemplate">
      <div class="overlayContainer__contentTemplateLabel">
        <span>{{'chargingTags' | translate | uppercase }}</span>
      </div>
      <div class="inputContainer --switch" *ngFor="let tag of profileTags">
        <label for="pushNotifications">{{tag.label}}</label>
        <div class="switch" tabindex="0" [ngClass]="{'on': getSwitchState(tag.id)}" (click)="changeSwitchValue(tag.id)"></div>
      </div>
      <div class="overlayContainer__actionContainer">
        <button class="overlayContainer__actionButton --white" (click)="close()">
          <span>{{'cancel' | translate}}</span>
        </button>
        <button class="overlayContainer__actionButton --orange">
          <span>{{'save' | translate}}</span>
        </button>
      </div>
    </form>
  </div> -->
</div>

<!-- Added here because of circular dependency issues -->
<div class="overlayContainer__wrapper" *ngIf="step === 2">
  <div class="overlayContainer__header">
    <span class="title">{{'deactivate' | translate }}</span>
  </div>
  <div class="overlayContainer__contentWrapper --centered">
    <div class="overlayContainer__contentTemplate">
      <div class="iconContainer">
        <i class="fak fa-k-info -orange"></i>
      </div>
      <div class="manageTags__boldInfo">
        <span>{{ 'tagDeactivationConfirmation' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="overlayContainer__actionContainer --w100">
    <button class="overlayContainer__actionButton --white" [disabled]="tagDeactivationLoading" (click)="toggleDeactivationConfirmation(undefined)">
      <span>{{ 'cancel' | translate | uppercase}}</span>
    </button>
    <button class="overlayContainer__actionButton --orange" [disabled]="tagDeactivationLoading" (click)="deactivateTag()">
      <span>{{ 'deactivate' | translate | uppercase}}</span>
    </button>
  </div>
</div>

<div class="overlayContainer__wrapper" *ngIf="step === 3">
  <div class="overlayContainer__header">
    <span class="title">{{'tagDeactivated' | translate }}</span>
  </div>
  <div class="overlayContainer__contentWrapper --centered">
    <div class="overlayContainer__contentTemplate">
      <div class="iconContainer">
        <i class="fak fa-k-tick -orange"></i>
      </div>
      <div class="overlayContainer__header">
        <span class="info">{{ 'tagDeactivatedInfo' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="overlayContainer__actionContainer --w100">
    <button class="overlayContainer__actionButton --white" (click)="close()">
      <span>{{ 'close' | translate | uppercase}}</span>
    </button>
  </div>
</div>

