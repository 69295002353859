export enum ServiceTypes  {
  RESTAURANT = 'restaurant',
  BATHROOM = 'bathroom',
  POST = 'post',
  CASH_WITHDRAWAL = 'cashWithdrawal',
  CAFE = 'cafe',
  CARWASH = 'carwash',
  SALADBAR = 'saladbar',
  SUSHI = 'sushi',
  ONLINE_ORDER_PICKUP = 'onlineOrderPickup',
}

export const keskoServices = {
  1: 'Smart menu',
  2: 'Saunalahti-Prepaid',
  3: 'Telia-Prepaid',
  4: 'DNA-Prepaid',
  5: 'AGA-nestekaasuautomaatti',
  6: 'Fonumin palvelupiste',
  7: 'Auton vuokraus',
  8: 'Pakettiauton vuokraus',
  10: 'Pikatoimitukset Woltilla',
  78:	'Alko',
  79:	'Veikkaus',
  80:	'Otto',
  81:	'Nordea SV',
  167:	'Nordea',
  9724:	'Parkkihalli',
  9725:	'Paljon pysäköintitilaa',
  9726:	'Postin pakettiautomaatti',
  9727:	'Toto-pelipiste',
  9728:	'Motorest',
  9729:	'Quick Wash',
  9730:	'Hesburger',
  9731:	'Sibylla',
  9732:	'Kukkakauppa',
  9733:	'Suutari',
  9734:	'Tax free',
  9735:	'Apteekki',
  9736:	'Kahvila-ravintola',
  9737:	'Ravintola',
  9738:	'WC',
  9739:	'Posti',
  9740:	'Asiamiesposti',
  9741:	'Kierrätys: Rinki ekopiste',
  9742:	'Veikkauksen peliautomaatit',
  9743:	'HSL:n matkakortin lataus',
  9744:	'Postilaatikko',
  9745:	'Osta&Nosta',
  9746:	'Kahvila',
  15343: 'Nordea laskuautomaatti',
  15344: 'Subway',
  15347: 'Kierrätys: SER (alle 25 cm)',
  15348: 'Kierrätys: SER (yli 25 cm)',
  15349: 'Kierrätys: paristot',
  16052: 'Nosto',
  16749: 'Salaattibaari',
  47323: 'Polttoainemyynti 24 h',
  65873: 'Autopesu 24 h',
  65874: 'Ticketmaster',
  65883: 'Lippu.fi',
  66177: 'Autopesu',
  70965: 'DNA Prepaid-liittymät',
  70967: 'Peräkärryn vuokraus',
  75411: 'Hiustalo',
  78037: 'Talletus ja Otto',
  78865: 'Verkkokaupan noutopalvelu',
  78866: 'Verkkokauppa: Kotiinkuljetus',
  88169: 'Lounasseteli',
  88173: 'Sushia',
  89269: 'Starbucks',
  91025: 'DHL-pakettiautomaatti',
  91253: 'Intersport',
  136088:	'Yliopiston Apteekki',
  151023:	'K-Lataus -asema',
  151424:	'Matkahuolto',
  156093:	'Terveyspalvelut',
  167045:	'Postnord',
  167046:	'Nouto Postin pakettiautomaatista',
  167047:	'Lähileipuri',
  170575:	'K-lahjakortit',
  172393:	'KCM Outlet',
  196217:	'Plantagen',
  204455:	'Deliporo',
  213457:	'Valokuvaamo',
  217279:	'Kotipizza',
  229549:	'Kampaamo',
  232415:	'Kierrätyspiste',
  233123:	'Burger King',
  233129:	'Paulig-kahvi',
  233133:	'Nespresso-kahvi',
  233137:	'Pelican Rouge -kahvi',
  233140:	'Koon keittiö',
  236138:	'UPS',
  240113:	'H&M',
  240115:	'KappAhl',
  240165:	'Katollamme on oma aurinkovoimala',
  267352:	'Lahjakortit',
  273224:	'Prepaid-liittymät',
  273226:	'DB Schenker',
  274818:	'Lemmikkieläintarvikeliike',
  301646:	'OttoPlus',
  301870:	'OttoPlus',
  308252:	'Postin noutopiste',
  312627:	'Paljun vuokraus',
  312789:	'Smartmile pakettiautomaatti',
  329290:	'Nestekaasu',
  336685:	'The Body Shop',
  346011:	'ResQ Club',
  346013:	'Eko Wash',
  360743:	'Helposti mukaan',
  383851:	'Pakettipiste pakettiautomaatti',
  405315:	'Kupittaan Optiikka',
  412742:	'Autopesu, itsepalvelu',
  541079:	'Alkon tilauspiste',
  557009:	'Budbee pakettiautomaatti',
  582589:	'Optikkoliike',
  259596:	'24 Pesula',
}

// This will determine which services are shown on the filters and which services are used for the filtering.
// The order of properties will determine the order on the UI (filters and charge point info)
export const keskoServicesByCategory = {
  restaurant: {
    9728:	'Motorest',
    9730:	'Hesburger',
    9731:	'Sibylla',
    9736:	'Kahvila-ravintola',
    9737:	'Ravintola',
    15344: 'Subway',
    217279:	'Kotipizza',
    233123:	'Burger King',
    233140:	'Koon keittiö',
  },
  cafe: {
    9736:	'Kahvila-ravintola',
    9746: 'Kahvila',
    89269: 'Starbucks',
  },
  bathroom: {
    9738:	'WC',
  },
  // post: {
  //   9739:	'Posti',
  //   9740:	'Asiamiesposti',
  // },
  sushi: {
    88173: 'Sushia',
  },
  saladbar: {
    16749: 'Salaattibaari',
  },
  cashWithdrawal: {
    80:	'Otto',
    9745:	'Osta&Nosta',
    16052: 'Nosto',
    78037: 'Talletus ja Otto',
    301646:	'OttoPlus',
    301870:	'OttoPlus',
  },
  carwash: {
    65873: 'Autopesu 24 h',
  },
  onlineOrderPickup: {
    78865: 'Verkkokaupan noutopalvelu',
  },
  // other: {
  //   2: 'Saunalahti-Prepaid',
  //   3: 'Telia-Prepaid',
  //   4: 'DNA-Prepaid',
  //   5: 'AGA-nestekaasuautomaatti',
  //   6: 'Fonumin palvelupiste',
  //   7: 'Auton vuokraus',
  //   8: 'Pakettiauton vuokraus',
  //   78:	'Alko',
  //   79:	'Veikkaus',
  //   81:	'Nordea SV',
  //   167:	'Nordea',
  //   9724:	'Parkkihalli',
  //   9725:	'Paljon pysäköintitilaa',
  //   9726:	'Postin pakettiautomaatti',
  //   9727:	'Toto-pelipiste',
  //   1: 'Smart menu',
  //   10: 'Pikatoimitukset Woltilla',
  //   9729:	'Quick Wash',
  //   9732:	'Kukkakauppa',
  //   9733:	'Suutari',
  //   9734:	'Tax free',
  //   9735:	'Apteekki',
  //   9741:	'Kierrätys: Rinki ekopiste',
  //   9742:	'Veikkauksen peliautomaatit',
  //   9743:	'HSL:n matkakortin lataus',
  //   9744:	'Postilaatikko',
  //   15343: 'Nordea laskuautomaatti',
  //   15347: 'Kierrätys: SER (alle 25 cm)',
  //   15348: 'Kierrätys: SER (yli 25 cm)',
  //   15349: 'Kierrätys: paristot',
  //   47323: 'Polttoainemyynti 24 h',
  //   65873: 'Autopesu 24 h',
  //   65874: 'Ticketmaster',
  //   65883: 'Lippu.fi',
  //   66177: 'Autopesu',
  //   70965: 'DNA Prepaid-liittymät',
  //   70967: 'Peräkärryn vuokraus',
  //   75411: 'Hiustalo',
  //   78865: 'Verkkokaupan noutopalvelu',
  //   78866: 'Verkkokauppa: Kotiinkuljetus',
  //   88169: 'Lounasseteli',
  //   91025: 'DHL-pakettiautomaatti',
  //   91253: 'Intersport',
  //   136088:	'Yliopiston Apteekki',
  //   151023:	'K-Lataus -asema',
  //   151424:	'Matkahuolto',
  //   156093:	'Terveyspalvelut',
  //   167045:	'Postnord',
  //   167046:	'Nouto Postin pakettiautomaatista',
  //   167047:	'Lähileipuri',
  //   170575:	'K-lahjakortit',
  //   172393:	'KCM Outlet',
  //   196217:	'Plantagen',
  //   204455:	'Deliporo',
  //   213457:	'Valokuvaamo',
  //   229549:	'Kampaamo',
  //   232415:	'Kierrätyspiste',
  //   233129:	'Paulig-kahvi',
  //   233133:	'Nespresso-kahvi',
  //   233137:	'Pelican Rouge -kahvi',
  //   236138:	'UPS',
  //   240113:	'H&M',
  //   240115:	'KappAhl',
  //   240165:	'Katollamme on oma aurinkovoimala',
  //   267352:	'Lahjakortit',
  //   273224:	'Prepaid-liittymät',
  //   273226:	'DB Schenker',
  //   274818:	'Lemmikkieläintarvikeliike',
  //   308252:	'Postin noutopiste',
  //   312627:	'Paljun vuokraus',
  //   312789:	'Smartmile pakettiautomaatti',
  //   329290:	'Nestekaasu',
  //   336685:	'The Body Shop',
  //   346011:	'ResQ Club',
  //   346013:	'Eko Wash',
  //   360743:	'Helposti mukaan',
  //   383851:	'Pakettipiste pakettiautomaatti',
  //   405315:	'Kupittaan Optiikka',
  //   412742:	'Autopesu, itsepalvelu',
  //   541079:	'Alkon tilauspiste',
  //   557009:	'Budbee pakettiautomaatti',
  //   582589:	'Optikkoliike',
  //   259596:	'24 Pesula',
  // },
}

