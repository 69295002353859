<div class="filterOptions__wrapper">
  <div class="filterOptions__header">
    <span class="--label">{{'mapFilters' | translate}}</span>
    <div class="imgContainer" (click)="close()">
      <img src="../../../../../assets/icons/png/icon-arrow-s-up@2x.png" alt="">
    </div>
  </div>
  <div class="filterOptions__tabs">
    <div 
      class="--tab"
      [ngClass]="{'active': selectedTab === 'chargingFilters'}"
      (click)="selectTab('chargingFilters')"
      >
      {{ 'chargingFilters' | translate }}
      <p class="--filterCounter">
        {{ inactiveChargingFiltersCount$ | async }}
      </p>
    </div>
    <div 
      class="--tab"
      [ngClass]="{'active': selectedTab === 'serviceFilters'}"
      (click)="selectTab('serviceFilters')"
      >
      {{ 'serviceFilters' | translate }}
      <p class="--filterCounter">
        {{ activeServiceFilters$ | async }}
      </p>
    </div>
  </div>
  <ng-container *ngIf="selectedTab === 'chargingFilters'">
    <div class="filterOptions__sectionTitle">
      <span class="--label">{{'socketTypes' | translate | uppercase }}</span>
    </div>
    <div class="filterOptions__container">
      <kc-filter-option *ngFor="let filterOption of (filterOptions$ | async).socketType as socketType; trackBy: trackByFn"
        [filterOption]="filterOption" (change)="updateFilterOption($event, 'socketType', socketType)"></kc-filter-option>
    </div>
    <div class="filterOptions__sectionTitle">
      <span class="--label">{{'chargingPower' | translate | uppercase }}</span>
    </div>
    <div class="filterOptions__container">
      <kc-filter-power-slider [setFilterOptions]="(filterOptions$ | async).power" (change)="updateFilterOptions($event, 'power')"></kc-filter-power-slider>
    </div>
    <div class="filterOptions__sectionTitle">
      <span class="--label">{{'otherChargingStations' | translate | uppercase }}</span>
      <img src="../../../../../assets/icons/png/icon-question@2x.png" alt="" (click)="toggleOtherStationsInfo()">
    </div>
    <div *ngIf="isOtherStationsOpen" class="filterOptions__container">
      <div class="filterOptions__textContainer -wide">
        <span class="infoText">{{ 'roamingInfoText1' | translate }}</span>
        <span>{{ 'roamingInfoText2' | translate }}</span>
      </div>
    </div>
    <div class="filterOptions__container">
      <kc-filter-option *ngFor="let filterOption of (filterOptions$ | async).futureChargePoints as futureChargePoints; trackBy: trackByFn"
        [filterOption]="filterOption" [portraitIcon]="true" (change)="updateFilterOption($event, 'futureChargePoints', futureChargePoints)"></kc-filter-option>
        <!-- <div class="filterOptions__textContainer">
            <span>{{'futureChargePointsInfo' | translate}}</span>
          </div> -->
    </div>
    <div class="filterOptions__container">
      <kc-filter-option *ngFor="let filterOption of (filterOptions$ | async).roaming as roaming; trackBy: trackByFn"
        [filterOption]="filterOption" [portraitIcon]="true" (change)="updateFilterOption($event, 'roaming', roaming)"></kc-filter-option>
        <div class="filterOptions__textContainer">
          <span>{{'roamingInfoText3' | translate}}</span>
        </div>
    </div>
  </ng-container>
  <ng-container *ngIf="selectedTab === 'serviceFilters'">
    <div class="filterOptions__sectionTitle">
      <span class="--label">{{'serviceFilters' | translate | uppercase }}</span>
      <img src="../../../../../assets/icons/png/icon-question@2x.png" alt="" (click)="toggleServicesInfo()">
    </div>
    <div *ngIf="isServicesInfoOpen" class="filterOptions__container">
      <div class="filterOptions__infoTextContainer -wide">
        <span class="infoText">{{ 'servicesInfoText' | translate }}</span>
      </div>
    </div>
    <div class="filterOptions__container" [ngClass]="!((filterOptions$ | async).serviceFiltersEnabled)[0].active ? '--disabled': ''">
      <kc-filter-option *ngFor="let filterOption of (filterOptions$ | async).services as serviceType; trackBy: trackByFn"
        [filterOption]="filterOption" (change)="updateFilterOption($event, 'services', serviceType)"></kc-filter-option>
    </div>
  </ng-container>
</div>
