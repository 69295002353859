import { Component, OnInit, Input } from '@angular/core';
import { Discount } from '../state/discount.model';
import { WindowService } from 'src/app/window-service/window.service';
import { LanguageService } from 'src/app/language/language.service';
import DiscountHelper from 'src/app/helpers/discount-helper';
import { locale } from 'moment';

@Component({
  selector: 'kc-discount-item',
  templateUrl: './discount-item.component.html',
  styleUrls: ['./discount-item.component.scss'],
})
export class DiscountItemComponent implements OnInit {
  @Input() discount: Discount;
  @Input() lastItem: boolean;
  @Input() listItem: boolean;

  public device$ = this.windowService.deviceTarget$;

  constructor(
    private windowService: WindowService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {}

  getExpirationDate() {
    const expirationDate = DiscountHelper.getExpirationDate(this.discount);
    if (expirationDate) { 
      if (localStorage.getItem('language') === 'en') {
        return expirationDate.format('MM.DD.YYYY'); 
      } else {
        return expirationDate.format('DD.MM.YYYY'); 
      }
      
    }
    return false;
  }
}
