<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="imageContainer">
        <div class="errorIcon">
          <img src="../../../../assets/icons/png/icon-info@2x.png" alt="">
        </div>
      </div>
      <div class="overlayContainer__header">
        <span class="title">{{ 'cookieConsentTitle' | translate }}</span>
        <span class="info">{{ 'cookieConsentText' | translate }}</span>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'accept'" [color]="'--orange'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
