import { Injectable } from '@angular/core';
import { UserLocationService } from '../user-location/user-location.service';
import { UserLocationQuery } from '../user-location/user-location.query';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Location } from '../map.component';
import { ChargePointsQuery } from '../charge-points/charge-points.query';
import { Coordinate } from '../charge-points/charge-point.model';
import { MapStore } from '../map.store';

@Injectable({ providedIn: 'root' })
export class MapService {

  triggerWhenPositionFound$: Subscription;
  private location: Location = {
    lat: window.innerWidth >= 1024 ? 66.5 : 61.5,
    lng: 24.5,
    zoom: 5
  };

  constructor(
    private userLocationQuery: UserLocationQuery,
    private userLocationService: UserLocationService,
    private chargePointsQuery: ChargePointsQuery,
    private mapStore: MapStore
  ) {

    if (navigator.geolocation) {
      this.userLocationService.getUserLocation();
    }
  }

  centerMapToUser() {
    this.triggerWhenPositionFound$ = this.userLocationQuery.isPositionFound$.subscribe(
      isPosition => {
        if (!isPosition) {
          this.userLocationService.getUserLocation();
        } else {
          this.setMapCenterToUserLocation();
          if (this.triggerWhenPositionFound$) { this.triggerWhenPositionFound$.unsubscribe(); }
        }
      }
    );
  }

  private setMapCenterToUserLocation() {
    this.userLocationQuery.userPosition$.pipe(take(1)).subscribe(
      position => {
        const user = { lat: position.coords.latitude, lng: position.coords.longitude, zoom: 12, locationSource: 'userLocation' };
        this.updateMapCenter(user);
      }
    );
  }

  /**
   * Focus map on selected point
   * @param coordinate point coordinates
   * @param zoom zoom level
   */
  setMapCenterToCoordinates(coordinate: Coordinate, zoom = 15) {
    const newCenter = { lat: coordinate.lat, lng: coordinate.lng, zoom: zoom, locationSource: 'chargePointLocation' };
    this.updateMapCenter(newCenter);
  }

  setMapCenterToCurrentCenterOfMap(location: Location) {
    const newCenter = location;
    this.updateMapCenter(newCenter);
  }

  public getLocationFromMapInstance(mapInstance: google.maps.Map): Location {
    return { lat: mapInstance.getCenter().lat(), lng: mapInstance.getCenter().lng(), zoom: mapInstance.getZoom() };
  }

  updateMapCenter(location: Location) {
    this.mapStore.updateMapCenter(location);
  }

}
