import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { ErrorsService } from 'src/app/errors-handler/errors.service';
import { environment } from 'src/environments/environment';
import { requestStatus } from 'src/app/helpers/requestStatuses';
import { AppVersionService } from 'src/app/services/app-version.service';
import { TranslateService } from '@ngx-translate/core';
import * as cities from '../../../../assets/json/fi-cities.json';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargePointAutoFillPipe } from 'src/app/top-bar/search-bar/charge-point-auto-fill.pipe';

@Component({
  selector: 'kc-support-contact',
  templateUrl: './support-contact.component.html',
  styleUrls: ['./support-contact.component.scss']
})
export class SupportContactComponent implements OnInit {

  contactSupportForm: UntypedFormGroup;
  userIdentity$: Observable<object>;
  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);
  isCancelContract = false;
  supportWizardPage = 1;
  finCities = cities;
  matchingCities = [];
  itemSelected = false;
  chargerErrorText = false;
  chargerOptions = [];
  possibleChargePoints = [];
  chargePoints: ChargePoint[];
  selectedChargePoint: ChargePoint;
  chargePointSelected = false;
  chargerSelected = false;

  supportContactTypes = [
    { type: 'paymentAndReceipts' },
    { type: 'tag' },
    { type: 'changeEmail' },
    { type: 'plussaPoints' },
    { type: 'chargerError' },
    { type: 'problemWithApp' },
    { type: 'developmentIdea' },
    { type: 'suggestLocation' },
    { type: 'complaint' },
    { type: 'other' },
  ];

  constructor(@Inject(PORTAL_DATA) public data,
    public formBuilder: UntypedFormBuilder,
    private kChargeProfileService: KChargeProfileService,
    private appVersionService: AppVersionService,
    private translateService: TranslateService,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private chargePointsQuery: ChargePointsQuery,
    private chargePointAutoFill: ChargePointAutoFillPipe) { }

  ngOnInit() {

    this.contactSupportForm = this.formBuilder.group({
      reason: ['', [Validators.required]],
      charger: ['', [this.chargerValidator.bind(this)]],
      chargePoint: ['', [this.cpValidator.bind(this)]],
      city: [''],
      description: ['', [Validators.required]],
      wantToBeContacted: false
    }, {});

    this.chargePoints = this.chargePointsQuery.getAll();

    if (this.data.data.event) {
      const reason = this.translateService.instant(this.data.data.event.reason);
      this.contactSupportForm.get('description').setValue(reason);
    }

    /* this.contactSupportForm.controls.charger.valueChanges.subscribe(value => {
      this.contactSupportForm.get('chargePoint').setValue('');
      this.chargerErrorText = false;
      if(value.length >= 4) {
        this.searchCharger(value);
      }
    }); */

    this.contactSupportForm.controls.chargePoint.valueChanges.subscribe({
      next: (text) => {
        this.possibleChargePoints = this.chargePointAutoFill.transform(text, this.chargePoints);
        /* if(this.possibleChargePoints.length > 4) {
          this.possibleChargePoints = this.possibleChargePoints.slice(0, 4);
        } */
        this.chargerSelected = false;
        this.selectedChargePoint = undefined;
        this.contactSupportForm.get('charger').setValue('');
        this.chargerErrorText = false;
      } 
    });

    /* this.contactSupportForm.controls.charger.valueChanges.subscribe(value => {
      this.chargerSelected = false;
      if(value) {
        this.searchCharger(value);
      } 
    }); */

    this.contactSupportForm.controls.reason.valueChanges.subscribe(value => {
      this.itemSelected = false;
      if(value !== 'suggestLocation') {
        this.contactSupportForm.get('city').setValue('');
      }

      if(value !== 'chargerError') {
        this.contactSupportForm.get('charger').setValue('');
        this.contactSupportForm.get('chargePoint').setValue('');
      }
    });

    this.contactSupportForm.controls.city.valueChanges.subscribe(value => {
      this.itemSelected = false;
      this.searchCity(value);
    });
  }

  sendSupportRequest(form) {
    this.requestStatus$.next(requestStatus.SEND);

    const request = {
      reason: form['value']['reason'],
      city: (form['value']['city'] !== '') ? form['value']['city'] : undefined,
      chargePoint: (form['value']['chargePoint'] !== '') ? form['value']['chargePoint'] : undefined,
      uniqueId: (form['value']['charger'] !== '') ? form['value']['charger'] : undefined,
      source: `${environment.development ? 'development' : 'production'}`,
      description: `${form['value']['description']}`,
      platform: this.appVersionService.getVersion(),
      sendResponseEmail: form['value']['wantToBeContacted']
    };
    this.kChargeProfileService.sendSupportRequest(request).subscribe(response => {
      this.requestStatus$.next(requestStatus.ACCEPTED);
    }, err => {
      this.requestStatus$.next(requestStatus.PENDING);
      this.data.openNewOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
      'source': { 'component': 'supportContactComponent', 'function': 'sendSupportRequest'}});
      throw err;
    });
  }

  cancelContract() {
    // Remove the required validation from description.
    this.contactSupportForm.controls.description.setValidators([]);
    this.contactSupportForm.controls.description.updateValueAndValidity();
    // Activate cancellation form.
    this.isCancelContract = true;
  }

  switchPage(page: number) {
    this.supportWizardPage = page;
  }

  searchCharger(value) {
    const possibleChargers = this.selectedChargePoint.chargeBoxes.filter((element) => {
      if(element) {
        if (element.uniqueId) {
          return String(element.uniqueId).startsWith(value)
        }
        return String(element.EvseID).startsWith(value)
      }
    })
    this.chargerOptions = possibleChargers;
  }

  onSelectChargePoint(chargePoint: ChargePoint) {
    this.chargerOptions = chargePoint.chargeBoxes;
    this.contactSupportForm.get('chargePoint').setValue(chargePoint.name);
    this.selectedChargePoint = chargePoint;
    this.chargerSelected = false;
  }

  selectCharger(charger: ChargeBox) {
    this.chargerSelected = true;
    /* if(charger.uniqueId) {
      this.contactSupportForm.get('charger').setValue(charger.uniqueId);
    } else {
      this.contactSupportForm.get('charger').setValue(charger.EvseID);
    } */
  }

  searchCity(value: string) {
    let cityName = value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
    this.matchingCities = [];
    if(cityName !== '') {
      let cityArray = this.finCities.filter((element) => {
        return element.city.startsWith(cityName);
      });

      this.matchingCities = cityArray.slice(0,3);
    }
  }

  selectCity(value: string) {
    this.contactSupportForm.get('city').setValue(value);
    this.itemSelected = true;
  }

  isDoneButtonDisabled() {
    if (this.contactSupportForm.get('reason').value === 'chargerError' && (!this.contactSupportForm.get('chargePoint').valid || !this.contactSupportForm.get('charger').valid)) {
      return true;
      
    } else if (this.contactSupportForm.get('reason').value === 'suggestLocation') {
      if (this.matchingCities.some(element => element.city == this.contactSupportForm.get('city').value)) {
        return false;
      }
      return true;
    }
    return false;
  }

  cpValidator(control: UntypedFormControl) {
    if(this.contactSupportForm && this.contactSupportForm.get('reason').value === 'chargerError') {
      if(control.value === '' || this.chargePointsQuery.getAll({filterBy: entity => entity.name === control.value}).length <=0) {
        return { cpValidator: false }
      }
    }
    return null;
  }

  chargerValidator(control: UntypedFormControl) {
    if(control.value.length === 0) {
      return null;
    }
    if(control.value && this.selectedChargePoint) {
      const charger = this.selectedChargePoint.chargeBoxes.filter(chargebox => {
        return String(chargebox.uniqueId) === control.value || chargebox.uniqueId === control.value;
      });

      const roamingCharger = this.selectedChargePoint.chargeBoxes.filter(chargebox => {
        return chargebox.EvseID === control.value;
      });

      if(charger.length > 0 || roamingCharger.length > 0) {
        return null;
      }
    }
    return { cpValidator: false }
  }

  /**
   * Close edit contact information overlay dialog
   */
  close() {
    this.isCancelContract = false;
    this.data.close();
  }
}
