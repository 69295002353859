import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { WebsocketService } from '../websocket-service/websocket.service';
import { ChargeBox, EvseChargeBoxUpdate } from '../map/charge-boxes/charge-box.model';
import { ChargeBoxesStore } from '../map/charge-boxes/charge-boxes.store';
import { ChargeBoxesQuery } from '../map/charge-boxes/charge-boxes.query';

@Injectable({ providedIn: 'root' })
export class ChargerService {

  constructor(private http: HttpClient, private websocketService: WebsocketService, private chargeBoxesStore: ChargeBoxesStore,
    private chargeBoxesQuery: ChargeBoxesQuery) { }

  /**
   * Remote start charging transaction
   * @param chargePointId selected charge point Id
   * @param chargeBoxId selected charger Id
   */
  startChargingTransaction(chargePointId: string, chargeBoxId: string): Observable<{status: string}> {
    this.websocketService.isConnected();
    return this.http.post<{status: string}>(
      `${environment.BACKEND_URL}/charge-points/${chargePointId}/charge-boxes/${chargeBoxId}/start`, null)
      .pipe(
        catchError(err => {
          console.error('in start charging error', err);
          return throwError(err);
        })
      );
  }

  /**
   * Remote stop charging transaction
   * @param chargePointId selected charge point Id
   * @param chargeBoxId selected charger Id
   */
  stopChargingTransaction(chargePointId: string, chargeBoxId: string): Observable<string> {
    this.websocketService.isConnected();
    return this.http.post<string>(
      `${environment.BACKEND_URL}/charge-points/${chargePointId}/charge-boxes/${chargeBoxId}/stop`,
      null, { responseType: 'text' as 'json' })
      .pipe(
        catchError(err => {
          console.error('in stop charging error', err);
          return throwError(err);
        })
      );
  }

  /**
   * Start charging command for roaming charge boxes.
   * @param evseId selected roaming charge boxes id.
   */
  startRoamingChargingTransaction(evseId: string) {
    return this.http.post<string>(
      `${environment.ROAMING.HUBJECT_URL}/${evseId}/sessions/start`, {})
      .pipe(
        catchError(err => {
          console.error('in start roaming charging error', err);
          return throwError(err);
        })
      );
  }

  /**
   * Stop charging command for roaming charge boxes.
   * @param evseId selected roaming charge boxes id.
   */
  stopRoamingChargingTransaction(evseId: string) {
    return this.http.post<string>(
      `${environment.ROAMING.HUBJECT_URL}/${evseId}/sessions/stop`, {})
      .pipe(
        catchError(err => {
          console.error('in start roaming charging error', err);
          return throwError(err);
        })
      );
  }

  /**
   * Get charger five latest transactions
   * @param chargeBoxId charge box id
   */
  getChargeBoxLatestTransactions(chargeBoxId: string): Observable<any> {
    return this.http.get(`${environment.BACKEND_URL}/public/charge-boxes/${chargeBoxId}/transactions`);
  }

  /**
   * Returns a roaming chargebox by given evseId.
   * @param evseId of roaming chargebox.
   */
  getRoamingChargeBox(evseId) {
    return this.http.get<EvseChargeBoxUpdate>(`${environment.ROAMING.HUBJECT_URL}/${evseId}`);
  }

  /**
   * Updates charge box to store.
   *
   * @param chargeBox updated charge box.
   * @param properties optional additional properties to add to charge box.
   */
  updateChargeBox(chargeBoxId: string, properties: any) {
    let chargeBox = this.chargeBoxesQuery.getEntity(chargeBoxId);
    if (chargeBox) {
      if (properties) {
        chargeBox = {
            ...chargeBox,
            ...properties
        };
    }
    this.chargeBoxesStore.updateChargeBox(chargeBox);
    } else {
      console.error(`Charge box: ${chargeBoxId} was not found`);
    }
  }

  /**
   * Use this function if evse charge box is fetched directly by evse id.
   * Overrides existing price and status properties.
   */
  updateRoamingChargeBox(chargeBoxId: string, evseChargeBoxUpdate: EvseChargeBoxUpdate) {
    let chargeBox = this.chargeBoxesQuery.getEntity(chargeBoxId);
    if (chargeBox) {
      chargeBox = {
          ...chargeBox,
    };

    // Update the status of existing charge box.
    chargeBox.status = evseChargeBoxUpdate.evseStatus || chargeBox.status;
    // If update object has an unit or additionalPaymentModifier, create an pricingProducts object.
    if (evseChargeBoxUpdate.pricingProducts && evseChargeBoxUpdate.pricingProducts.length > 0) {
      chargeBox.pricingProducts = evseChargeBoxUpdate.pricingProducts;
    }

    this.chargeBoxesStore.updateChargeBox(chargeBox);
    } else {
      console.error(`Charge box: ${chargeBoxId} was not found`);
    }
  }

  /**
   * Updates charge boxes to store.
   *
   * @param chargeBoxes updated charge boxes.
   */
  updateChargeBoxes(chargeBoxes: ChargeBox[]) {
    chargeBoxes.forEach(cb => {
      this.updateChargeBox(cb.id, cb);
    });
  }
}
