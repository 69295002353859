import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AuthSessionState, AuthSessionStore } from './auth-session.store';
import { AuthSessionQuery } from './auth-session.query';
import { GaService } from '../ga/ga.service';
import { environment } from 'src/environments/environment';
import { SinglePaymentService } from '../single-payment/single-payment.service';

@Injectable()
export class AuthSessionService {
  constructor(
    private http: HttpClient,
    private authSessionStore: AuthSessionStore,
    private authSessionQuery: AuthSessionQuery,
    private gaService: GaService,
    private singlePaymentService: SinglePaymentService
    ) {}

    public updateAuthState(authState: Partial<AuthSessionState>) {
      this.authSessionStore.update(authState);
    }

  /**
   * Check if user has an active logged in or single payment session.
   * Resolves true if has, false if not.
   */
  public verifyActiveSession(): Promise<Boolean> {
    return new Promise(async (resolve, reject) => {
      // Currently logging in, wait for the result.
      if (this.authSessionQuery.isAuthenticating()) {
          await interval(500).pipe(takeWhile(_ => this.authSessionQuery.isAuthenticating())).toPromise();
          resolve(this.authSessionQuery.isAuthenticated());
          return;
      }

      this.authSessionStore.update({isAuthenticating: true});

      // If logged in, check if the session is valid. Else check for a single payment session.
      if (this.authSessionQuery.hasSessionCookie()) {
        this.http.get<any>(`${environment.BACKEND_URL}/sessions/verify-session`).subscribe((res) => {
          this.authSessionStore.update({isSessionVerified: true, hasSession: res.session, isAuthenticating: false});
          if (res.session) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else if (this.authSessionQuery.hasSPSessionCookie()) {
        this.singlePaymentService.getHasSinglePaymentSession().subscribe((res: { session: boolean }) => {
          this.authSessionStore.update({isSessionVerified: true, hasSinglePaymentSession: res.session, isAuthenticating: false});
          if (res.session) {
            resolve(true);
          } else {
            resolve(false);
          }
        }, () => {
          resolve(false);
        });
        resolve(false);
      } else {
        this.authSessionStore.update({isSessionVerified: true, hasSinglePaymentSession: false, hasSession: false, isAuthenticating: false});
        resolve(false);
      }
  });
  }

  public async registerSession(accessToken: String) {
    try {
      await this.http.post<any>(`${environment.BACKEND_URL}/sessions/register-session`, { accessToken }).toPromise();
      this.gaService.sendUserStatus('Kirjautui sisään');
      this.authSessionStore.update({isAuthenticating: false, isSessionVerified: true, hasSession: true });
    } catch (e) {
      console.log(e);
      throw(e);
    }
  }

  public async removeSession() {
    try {
      await this.http.post<any>(`${environment.BACKEND_URL}/sessions/remove-session`, {}).toPromise();
      this.gaService.sendUserStatus('Kirjautui ulos');
      this.authSessionStore.update({isSessionVerified: true, isAuthenticating: false});
    } catch (e) {
      console.log(e);
    }
  }
}

