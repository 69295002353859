import { OverlayRef } from '@angular/cdk/overlay';
import * as storage from './overlay-dialog.storage';
import { OverlayDialogService } from './overlay-dialog.service';

export class OverlayDialogRef {
  constructor(private overlayRef: OverlayRef, private overlayDialogService: OverlayDialogService, public componentName: string,
    private data?: Object,
    private options?: Object) {}

  /**
   * This function closes opened overlay dialog and removes that dialog from the storage.
   * This can be accessed on overlay dialog component. So other close mechanisms are not needed.
   */
  close(): void {
    this.overlayDialogService.overlayDialogClosed(this);
    this.overlayRef.dispose();
    storage.removeActiveDialog();
  }

  /**
   * Opens new overlay dialog. This is how you can navigate from overlay dialog to another overlay dialog.
   * @param overlayDialog new overlay dialog reference
   */
  openNewOverlayDialog(overlayDialog, data?) {
    this.overlayDialogService.openOverlayDialog(overlayDialog, data ? data : null);
    this.overlayRef.dispose();
  }

  /* update() {
    this.overlayDialogService.updateButtonClicked(true);
  } */

}
