// webpack-translate-loader.ts
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';

// Attempt to fix translate cache issue.
export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`./../assets/i18n/${lang}.json`));
  }
}
