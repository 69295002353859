import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState, ActiveState } from '@datorama/akita';
import Store from './store.model';

export interface StoreState extends EntityState<Store>, ActiveState { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'store',
    idKey: '_id',
    resettable: true
})
export class StoreStore extends EntityStore<StoreState, Store> {

    constructor() {
        super();
    }
}
