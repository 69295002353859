import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { FiltersState, createInitialFiltersState } from './filter-option.model';


@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'filter-options' })
export class FilterStore extends Store<FiltersState> {

  constructor() {
    super(createInitialFiltersState());
  }
}

