import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { DiscountStore, DiscountState } from './discount.store';
import { Discount } from './discount.model';
import { filter } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DiscountQuery extends QueryEntity<DiscountState> {

  discounts$ = this.selectAll();
  defaultDiscount$ = this.selectAll({
    filterBy: discount => discount.defaultDiscount
  });

  constructor(protected store: DiscountStore) {
    super(store);
  }

  /**
   * Return the latest created discount.
   */
  getLatestDiscount(): Discount {
    return this.getAll().reduce((a, b) => new Date(a.createdAt) > new Date(b.createdAt) ? a : b);
  }
}
