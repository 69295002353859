<div *ngIf="(chargeBox$ | async) as chargeBox; else loading" class="chargeBox__socket"
  [ngClass]="[socketAvailableForUse(chargeBox) ? '-hoverCursor' : '-hoverDisabled', isActive ? '-active': '-listItem']">
  <div class="chargeBox__socketIcons" [ngClass]="[isActive ? '-vertical' : '-horizontal', chargeBox.EvseID ? '-roaming' : '']">
    <div class="chargeBox__socketIconHorizontal">
      <div class="chargeBox__picHorizontal" [ngClass]="'-' + (getSocketPic(chargeBox))"></div>
    </div>
    <div *ngIf="!chargeBox.EvseID" class="chargeBox__uniqueId"
      [ngClass]="[socketAvailableForUse(chargeBox)  || isActive ? '-orange' : '-gray', isActive ? '-vertical' : '-horizontal']">
      {{ chargeBox.uniqueId }}</div>
  </div>
  <div class="chargeBox__socketTitleDiv"
    [ngClass]="socketAvailableForUse(chargeBox) ? '-enabledItem' : '-disabledItem'">
    <div class="chargeBox__socketTitle">
      {{ !chargeBox.EvseID ? (chargeBox.power / 1000 + ' kW') : getSignificantChargerId(chargeBox.EvseID) }}</div>
    <div class="chargeBox__socketSubtitle" [ngClass]="{'-subtitleAvailable': (chargeBox.status === 'Available')}">
      <span *ngIf="chargeBox.roaming && chargeBox.power">{{ chargeBox.power }} kW • </span>
      <ng-container *ngIf="!isActive; else activeTransaction">
        <span *ngIf="!chargeBox.EvseID; else roamingSocketType">{{ chargeBox.socketType | translate }}</span>
        <ng-template #roamingSocketType>
            <span> {{ getRoamingSocketType(chargeBox) | translate}} </span>
        </ng-template>
      </ng-container>
      <ng-template #activeTransaction>
          <ng-container *ngIf="!chargeBox.EvseID; else noRealTimeTracking">
            {{ (energy ? energy / 1000 : 0) | localNumber:'1.2-2' }}kWh •
            {{ price | localCurrency }}
            <!-- <div style="display: inline" *ngIf="!kIDSessionQuery.isLoggedIn()"> + {{ 1 | localCurrency }} </div> -->
            <div style="display: inline">
              <span class="-bold">• {{ hours }}</span>
              <span>h</span>
              <span class="-bold">{{ minutes }}</span>
              <span>min</span>
            </div>
          </ng-container>
        </ng-template>
    </div>
  </div>
  <div class="chargeBox__socketStatus" [ngClass]="'-' + (chargeBox.status | lowercase) + 'StatusBox'">
    {{ chargeBox.status | translate | uppercase }}</div>
</div>


<ng-template #noRealTimeTracking>{{ 'noRealTimeTracking' | translate }}</ng-template>
<ng-template #loading>
  <div class="loading">Loading</div>
</ng-template>

