import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterOptionsComponent } from './filter-options/filter-options.component';
import { FilterOptionComponent } from './filter-options/filter-option/filter-option.component';
import { TranslateModule } from '@ngx-translate/core';
import { FilterArrayPipe } from './filter-options/filterArray.pipe';
import { FilterPowerSliderComponent } from './filter-power-slider/filter-power-slider.component';
import { NgxSliderModule } from 'ngx-slider-v2';

@NgModule({
  imports: [CommonModule, TranslateModule, NgxSliderModule],
  declarations: [FilterOptionsComponent, FilterOptionComponent, FilterArrayPipe, FilterPowerSliderComponent],
  exports: [FilterOptionsComponent],
  providers: [FilterArrayPipe]
})
export class FilterOptionsModule { }
