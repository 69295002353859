import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { InvoicingAgreementService } from 'src/app/services/invoicing-agreement.service';
import { PORTAL_DATA } from '../overlay-dialog.storage';

@Component({
  selector: 'kc-invitation-dialog',
  templateUrl: './invitation-dialog.component.html',
  styleUrls: ['./invitation-dialog.component.scss']
})
export class InvitationDialogComponent implements OnInit {

  confirmationToggled: boolean;

  invitationStatus = 1;
  isAccepting = false;

  constructor(@Inject(PORTAL_DATA) public data,
  private invoicingAgreementService: InvoicingAgreementService) { }

  ngOnInit() {
    this.invitationStatus = 1;
  }

  acceptInvitation() {
    this.isAccepting = true;
    this.invoicingAgreementService.acceptInvitation(this.data.data.userId, this.data.data.invitation._id).subscribe(res => {
      this.invitationStatus = 2;
      this.isAccepting = false;
    }, error => {
      this.isAccepting = false;
      // this.close();
    });
  }

  rejectInvitation() {
    this.invoicingAgreementService.rejectInvitation(this.data.data.invitation._id).subscribe(res => {
      this.invitationStatus = 4;
    }, error => {
      this.close();
    });
  }

  close() {
    this.data.close();
  }

  getTitle() {
    switch (this.invitationStatus) {
      case 1: {
        return 'newInvoicingAgreement';
      }
      case 2: {
        return 'invitationAccepted';
      }
      case 3: {
        return 'newInvoicingAgreement';
      }
      case 4: {
        return 'invitationRejected';
      }
      default: {
        break;
      }
    }
  }

  setPage(page: number) {
    this.invitationStatus = page;
  }

  formatDate(time: string) {
    if (localStorage.getItem('language') === 'en') {
      return moment(time).format('MM.DD.YYYY');
    } else {
      return moment(time).format('DD.MM.YYYY');
    }
  }

  toggleConfirmation() {
    this.confirmationToggled = !this.confirmationToggled;
  }

}
