import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare const AndroidBridge;

export enum Versions {
  APP_TEST = 'APP TEST',

  IOS_PWA = 'IOS PWA',
  IOS_APP = 'IOS APP',
  IOS_BROWSER = 'IOS BROWSER',

  ANDROID_PWA = 'ANDROID PWA',
  ANDROID_APP = 'ANDROID APP',
  ANDROID_BROWSER = 'ANDROID BROWSER',

  DESKTOP_BROWSER = 'DESKTOP BROWSER',
  MAC_BROWSER = 'MAC BROWSER'
}

@Injectable({ providedIn: 'root' })
export class AppVersionService {

  private _isNativeIOS = false;
  private _isNativeAndroid = false;

  constructor(private http: HttpClient) { }


  public setAsNativeIOS() {
    this._isNativeIOS = true;
    localStorage.setItem('appType', Versions.IOS_APP);
  }

  public setAsNativeAndroid() {
    this._isNativeAndroid = true;
    localStorage.setItem('appType', Versions.ANDROID_APP);
  }

  /**
   * Safe getter for Android JS Interface.
   * @returns AndroidBridge (JSInterface) or null.
   */
  public getAndroidBridge(): any | null {
    try {
      if (AndroidBridge) {
        return AndroidBridge;
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  }

  /**
   * Safe getter for IOS JS Interface.
   * @returns iOSBridge (JSInterface) or null.
   */
  public getIOSBridge(): any | null {
    const webkit = window['webkit'] || document['webkit'];
    if (webkit && webkit.messageHandlers) {
      try {
        if (webkit.messageHandlers.iOSBridge) {
          return webkit.messageHandlers.iOSBridge;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    }
  }

  /**
   * Setup version for error log
   */
  public getVersion(): string {
    const appType = localStorage.getItem('appType');
    if (this._isNativeIOS || appType === Versions.IOS_APP) {
      return Versions.IOS_APP;
    } else if (this._isNativeAndroid || appType === Versions.ANDROID_APP) {
      return Versions.ANDROID_APP;
    }

    const iOS = !!navigator.platform && /iP(?:hone|ad|od)/.test(navigator.platform);
    const android = /(android)/i.test(navigator.userAgent);

    if (navigator.userAgent.includes('cordova-test')) {
      return Versions.APP_TEST;
    } else if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone']) {
      if (iOS) {
        return Versions.IOS_PWA;
      } else {
        return Versions.ANDROID_PWA;
      }
    } else if (window['webkit']) {
        return Versions.IOS_APP;
    } else {
      if (iOS) {
        return Versions.IOS_BROWSER;
      } else if (navigator.platform === 'MacIntel') {
        return Versions.MAC_BROWSER;
      } else if (android) {
        return Versions.ANDROID_BROWSER;
      } else {
        return Versions.DESKTOP_BROWSER;
      }
    }
  }

  /**
   * Checks if app is an android app. For KID2 update.
   */
  public checkIfAndroidApp() {
    this.http.get(`${environment.BACKEND_URL}/version`).pipe(tap((res) => {
      if (res['version'] === 'android-app') {
        this.setAsNativeAndroid();
      }
    })).subscribe();
  }
}
