import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { FilterStore } from './filter-options.store';
import { FiltersState } from './filter-option.model';

@Injectable({
  providedIn: 'root'
})
export class FilterOptionsQuery extends Query<FiltersState> {
  filters$ = this.select();
  inactiveChargingFilters$ = this.select((state) => {
    let inactiveFilters = 0;
  
    const {socketType, roaming, futureChargePoints} = state;

    inactiveFilters += socketType.reduce((previous, current) => {
      if(!current.active) {
        previous++;
      }
      return previous;
    }, 0);

    inactiveFilters += roaming.reduce((previous, current) => {
      if(!current.active) {
        previous++;
      }
      return previous;
    }, 0);

    inactiveFilters += futureChargePoints.reduce((previous, current) => {
      if(!current.active) {
        previous++;
      }
      return previous;
    }, 0);
    
    return inactiveFilters;
  });

  inactiveServiceFilters$ = this.select((state) => {
    let inactiveFilters = 0;
  
    const {services} = state;

    inactiveFilters += services.reduce((previous, current) => {
      if(!current.active) {
        previous++;
      }
      return previous;
    }, 0);
    
    return inactiveFilters;
  })

  activeServiceFilters$ = this.select((state) => {
    let activeFilters = 0;
  
    const {services} = state;

    activeFilters += services.reduce((previous, current) => {
      if(current.active) {
        previous++;
      }
      return previous;
    }, 0);
    
    return activeFilters;
  })

  constructor(protected store: FilterStore) {
    super(store);
  }
}
