import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ChargePointsStore, ChargePointsState } from './charge-points.store';
import { ChargePoint } from './charge-point.model';
import { combineLatest } from 'rxjs';
import { FilterOptionsQuery } from 'src/app/top-bar/filter-options/state/filter-options.query';
import { FiltersState } from 'src/app/top-bar/filter-options/state/filter-option.model';
import { ChargeBoxesQuery } from '../charge-boxes/charge-boxes.query';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';
import Store from 'src/app/k-store/store.model';
import { StoreQuery } from 'src/app/k-store/store.query';
import { keskoServicesByCategory, ServiceTypes } from 'src/app/k-store/k-store-services';

const MAX_POWER = 999999999;

@Injectable({ providedIn: 'root' })
export class ChargePointsQuery extends QueryEntity<ChargePointsState, ChargePoint> {
  allChargePoints$ = this.selectAll();
  publicChargePoints$ = this.selectAll({filterBy: cp => cp.visibility != 'Private'});
  allChargePointsWithChargeBoxes$ = this.selectAll().pipe(
    map((res) => {
      return res.map((cp) => {
        const newCp = Object.assign({}, cp);
        newCp.chargeBoxes = this.chargeBoxesQuery.getAll({
          filterBy: (entity) => entity.chargePointId === cp.id,
        });
        return newCp;
      });
    })
  );
  visibleChargePoints$ = combineLatest(
    this.allChargePointsWithChargeBoxes$,
    this.filterOptionsQuery.filters$,
    this.storeQuery.stores$,
    this.getVisibleChargePoints
  );
  activeChargePoint$ = this.selectActive();
  
  futureChargePoints$ = combineLatest(
    this.selectAll(),
    this.filterOptionsQuery.filters$,
    this.isFutureChargePointVisible
  )

  constructor(
    protected store: ChargePointsStore,
    private filterOptionsQuery: FilterOptionsQuery,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private storeQuery: StoreQuery,) {
    super(store);
  }

  public getChargePointName(chargePointId) {
    return this.getEntity(chargePointId).name;
  }

  private getVisibleChargePoints(chargePoints: ChargePoint[], filters: FiltersState, kStores: Store[]): ChargePoint[] {
    // Mapataan socket typet listaksi jotta vältytään useammalta sisäkkäiseltä arrayltä
    const socketTypeMap = filters.socketType.reduce((map, socketType) => {
      const result = map;
      result[socketType.type] = socketType.active;
      return result;
    }, {});

    // A quick fix for chargers that has a power that is out of filter's max range.
    let hasMaxPowerValue = false;
    let maxPowerValueLabel;
    // Mapataan power filterit nnumber arrayksi vertailua vasten (Max ja Min arvot)
    let powerRange = filters.power.filter((power) => {
      if (power.active) {
        return true;
      }
      return false;
    }).map(power => {
      if (power.type == MAX_POWER) {
        hasMaxPowerValue = true;
        maxPowerValueLabel = Number(power.tag);
      }
      return Number(power.type)
    });

    const servicesMap = filters.services.reduce((map, service) => {
      const result = map;
      result.set(service.type, service.active);
      return result;
    }, new Map()) as Map<ServiceTypes, boolean>;

    // Flag näytetäänkö roaming pisteitä
    const filterRoamingPoints = filters.roaming[0].active;

    // The max value is set to very high, so use it's "label" value to make it work when it's set as min value as well.
    if (hasMaxPowerValue) {
      powerRange.push(maxPowerValueLabel);
    }
    const minPower = Math.min(...powerRange);
    const maxPower = Math.max(...powerRange);

    // Kädään läpi latauspisteet ja filteröidään pois filttereihinn osuvat latauspisteet..
    const filteredChargePoints = chargePoints.filter(cp => {
      // return flag boolean. Jos false latauspiste jätetään pois
      let returnBool = false;

      // Käydään läpi latauspisteenn laitteet
      for (let i = 0; i < cp.chargeBoxes.length; i++) {
        // Jos latauspisteen laitteet osuvat filttereihin näytä latauspiste

        if (!cp.roaming) {
          if (socketTypeMap[cp.chargeBoxes[i].socketType.toLowerCase()]
            && (cp.chargeBoxes[i].power <= maxPower && cp.chargeBoxes[i].power >= minPower)) {

            returnBool = true;
          }
        }

        if (cp.roaming) {
          if (cp.ChargingFacilities.length > 0) {
            for (let x = 0; x < cp.ChargingFacilities.length; x++) {
              if (socketTypeMap[cp.chargeBoxes[i].socketType.toLowerCase()]
              && (cp.ChargingFacilities[x].Power * 1000) <= maxPower
              && (cp.ChargingFacilities[x].Power * 1000) >= minPower || !cp.ChargingFacilities[x].Power) {
                returnBool = true;
              }
            }
          } else {
            returnBool = true;
          }
        }
      }

      // const enabledServiceIds = [];

      const store = kStores.find(store => store.chargePointId === cp.id);
      let requiredServiceIds = [];
      for (const category of Object.keys(keskoServicesByCategory)) {
        const isActive = servicesMap.get(category as ServiceTypes);
        if(isActive) {
          requiredServiceIds = [...Object.keys(keskoServicesByCategory[category])];
          if (!store || !store.serviceIds.some(sId => requiredServiceIds.includes(sId))) {
            returnBool = false;
          }
        }
      }

      // if (filters.serviceFiltersEnabled[0].active && enabledServiceIds.length > 0) {
      //   const store = kStores.find(store => store.chargePointId === cp.id);
      //   if(!store || !store.serviceIds.some(sId => enabledServiceIds.includes(sId) || store.serviceIds.length === 0)) {
      //     returnBool = false;
      //   }
      // }

      // Jos latauspiste on roaming piste ja withoutRemote filter päällä, pisteet joissa ei REMOTEa saatavilla jätetään pois
      if (cp.roaming) {
        if (!cp.chargeBoxes.some(chargeBox => chargeBox.AuthenticationModes.includes('REMOTE'))) {
          returnBool = false;
        }
      }

      // Jos latauspiste on roaminng piste ja roaminng filter päällä, poista se
      if (cp.roaming && !filterRoamingPoints) {
        returnBool = false;
      }

      // Show upcoming charge points
      if(moment(cp.installationDate).isAfter(moment()) && cp.operative === false) {
        returnBool = false;
      }

      // Hide private charge points
      if(cp.visibility === 'Private') {
        returnBool = false;
      }

      return returnBool;

    });
    return filteredChargePoints;
  }

  private isFutureChargePointVisible(chargePoints: ChargePoint[], filters: FiltersState): ChargePoint[] {
    if (filters.futureChargePoints[0].active) {
      const filteredCPs = chargePoints.filter(cp => {
        let returnBool = false;
        if(moment(cp.installationDate).isAfter(moment()) && cp.operative === false) {
          returnBool = true;
        }
        return returnBool;
      });

      return filteredCPs;
      /* return this.selectAll({filterBy: cp => {
        if(moment(cp.installationDate).isAfter(moment()) && cp.operative === false) {
          return true;
        }
      }}) */
    }
  }
}
