<div class="discountItem__wrapper" [ngClass]="(device$ | async) !== 'desktop' ? '' : '-desktop'">
  <div class="discountItem__iconContainer">
    <img src="../../../../assets/icons/png/icon-offer.png" />
  </div>
  <div class="discountItem__textContainer" [ngClass]="lastItem ? '-lastItem' : ''">
    <div class="discountItem__info">
      <div class="title">
        <div class="nameWrapper" [ngClass]="listItem ? '' : '-cutOff'">
          <span class="large">{{ discount.campaign.name }}</span>
        </div>
          <span class="small" *ngIf="!listItem && discount.defaultDiscount">
            • {{ "defaultDiscount" | translate }}</span>
      </div>
      <div *ngIf="discount.campaign.ends || discount.expirationDate" class="subtitle">
        <span>{{ "discountValidUntil" | translate }} </span>
        <span>{{ getExpirationDate() }}</span>
        <span> {{ "discountValidUntil2" | translate }} </span>
      </div>
    </div>
    <div class="discountItem__rates">
      <div class="rateItem" *ngIf="discount.campaign.discount.ac">
        <span class="rateTitle">{{ "acCharging" | translate }}</span>
        <div class="percentage -orange">
          -{{ discount.campaign.discount.ac }}%
        </div>
      </div>
      <div class="rateItem" *ngIf="discount.campaign.discount.dc">
        <span class="rateTitle">{{ "dcCharging" | translate }}</span>
        <div class="percentage -orange">
          -{{ discount.campaign.discount.dc }}%
        </div>
      </div>
      <div class="rateItem" *ngIf="discount.campaign.discount.roaming">
        <span class="rateTitle">{{ "roamingCharging" | translate }}</span>
        <div class="percentage -blue">
          -{{ discount.campaign.discount.roaming }}%
        </div>
      </div>
    </div>
  </div>
  <div class="discountItem__arrowContainer">
    <img src="../../../../assets/icons/svg/chevron-right.svg" />
  </div>
</div>
