<div class="overlayContainer__wrapper">
  <div class="overlayContainer__header">
    <span class="title">{{ 'deletePaymentCard' | translate }}</span>
  </div>
  <ng-container *ngIf="!requestSend; else requestSendTpl">
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="iconContainer">
          <div class="-white">
            <img src="../../../../../assets/icons/png/icon-creditCard@3x.png" alt="">
          </div>
        </div>
        <div class="overlayContainer__header">
          <span class="title">{{ 'deletePaymentCardTitle' | translate }}</span>
          <span class="info">{{ data.data.paymentMethodCode | translate }} {{ data.data.paymentMethodTitle }}</span>
        </div>
        <div class="overlayContainer__actionContainer">
          <button class="overlayContainer__actionButton --white --left" (click)="close()">
            <span>{{ 'cancel' | translate | uppercase}}</span>
          </button>
          <button type="submit" class="overlayContainer__actionButton --orange --right" (click)="deletePaymentCard()">
            <span>{{ 'delete' | translate | uppercase}}</span>
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #requestSendTpl>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="iconContainer">
          <div class="-white">
            <img src="../../../../../assets/icons/png/icon-creditCard@3x.png" alt="">
          </div>
        </div>
        <div class="successfulIcon">
          <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
        </div>
        <div class="overlayContainer__header">
          <span class="title">{{ 'paymentCardDeletedTitle' | translate }}</span>
        </div>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ 'close' | translate | uppercase}}</span>
          </button>
        </div>
      </div>

    </div>
  </ng-template>
</div>
