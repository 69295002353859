import { ID, guid } from '@datorama/akita';
import { UsersFilters } from '../k-id-session/k-id-session.store';

export interface ProfileContactInfo {
  streetAddress?: string;
  postalCode?: string;
  co?: string;
  city?: string;
  phoneNumber?: string;
  marketing?: boolean;
}

/**
 * A factory function that creates ProfileContactInfo
 */
export function createProfileContactInfo() {
  return {} as ProfileContactInfo;
}

export interface ProfileAppSettings {
  language?: string;
  filters?: UsersFilters;
}

export interface PaymentCard {
  paymentMethodCode: string;
  paymentMethodTitle: string;
  expectedValidity: string;
}

export interface InvoicingAgreement {
  expirationDate: string;
  startDate: string;
  organization: Organization;
}

export interface Organization {
  allowRoaming: boolean;
  description: string;
  name: string;
  customerServiceNumber?: string;
  customerServiceEmail?: string;
  _id: string;
}

export function createProfileAppSettings() {
  return {
    language: 'FI',
  } as ProfileAppSettings;
}

export interface KChargeProfile {
  givenName?: string;
  familyName?: string;
  appSettings: ProfileAppSettings;
  contactInfo: ProfileContactInfo;
  paymentCard?: PaymentCard;
  tags: Array<Tag>;
  acceptedTos?: boolean;
  invoicingAgreement?: InvoicingAgreement;
  tagOrdered?: boolean;
  invitation?: InvoicingInvitation;
}

export function createInitialState(): KChargeProfile {
  return {
    appSettings: createProfileAppSettings(),
    contactInfo: null,
    paymentCard: null,
    tags: [],
    acceptedTos: false,
    invoicingAgreement: null,
  };
}

export interface Tag {
  id?: string;
  serialNumber?: string;
  label?: string;
  active?: boolean;
}

export interface InvoicingInvitation {
  _id: string;
  organizationId: string;
  organizationName: string;
  email: string;
  status: any;
  registrationPlate?: string;
  startDate: Date;
  expirationDate?: Date;
  groupIdentifier?: string;
  createdAt: Date | string;
  updatedAt: Date | string;
}
