<div class="overlayContainer__wrapper">
  <ng-container *ngIf="(requestStatus$ | async) !== 2; else requestSendTpl">
    <div class="overlayContainer__header">
      <span class="title">{{ 'editAppSettings' | translate }}</span>
      <span class="info">{{ 'editAppSettingsInfoText'| translate }}</span>
    </div>
    <div class="overlayContainer__contentWrapper">
      <form class="overlayContainer__contentTemplate" [formGroup]="appSettingsForm" (ngSubmit)="save(appSettingsForm)">
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ 'languageSettings' | translate | uppercase }}</span>
        </div>
        <div class="radioGroup">
          <div class="inputContainer" *ngFor="let language of languages">
            <label for="language">{{ language | uppercase }} - {{ language | translate }}</label>
            <input type="radio" formControlName="language" value="{{ language }}">
            <span class="radioButton"></span>
          </div>
        </div>
        <div class="overlayContainer__contentTemplateLabel">
          <span>{{ 'marketing' | translate | uppercase }}</span>
        </div>
        <div class="inputContainer --switch">
          <label class="--wide" for="marketing">{{ 'marketingAgreement' | translate }}</label>
          <div class="switch" tabindex="0" [ngClass]="{'on': marketingAgreement()}" (click)="changeMarketingAgreemet()" (swipeleft)="changeMarketingAgreemetSwipe(false)" (swiperight)="changeMarketingAgreemetSwipe(true)">
          </div>
        </div>
        <div class="overlayContainer__actionContainer">
          <button class="overlayContainer__actionButton --white --left" (click)="closeOverlay()">
            <span>{{ 'cancel' | translate | uppercase}}</span>
          </button>
          <button type="submit" class="overlayContainer__actionButton --orange --right"
            [disabled]="!appSettingsForm.valid || (requestStatus$ | async) === 1">
            <ng-container *ngIf="(requestStatus$ | async) === 1; else sendTpl">
                <div class="spinner --small">
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                </div>
              <span>{{ 'saving' | translate | uppercase}}</span>
            </ng-container>
            <ng-template #sendTpl>
              <span>{{ 'save' | translate | uppercase}}</span>
            </ng-template>
          </button>
        </div>
      </form>
    </div>
  </ng-container>
</div>
<ng-template #requestSendTpl>
  <div class="overlayContainer__contentWrapper">
    <div class="overlayContainer__contentTemplate">
      <div class="overlayContainer__contentCentered">
        <div class="iconContainer">
          <div class="-white">
            <img src="../../../../../assets/icons/png/icon-wrench@3x.png" alt="">
          </div>
        </div>
        <div>
          <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="">
        </div>
        <div class="overlayContainer__header">
          <span class="title">{{ 'settingsUpdatedTitle' | translate }}</span>
        </div>
      </div>
      <div class="overlayContainer__actionContainer --w100">
        <button class="overlayContainer__actionButton --white" (click)="close()">
          <span>{{ 'close' | translate | uppercase}}</span>
        </button>
      </div>
    </div>
  </div>
</ng-template>
