import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface UserLocationState {
  userLocation: GeolocationPosition | null;
}

export function createInitialState(): UserLocationState {
  return {
    userLocation: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-location' })
export class UserLocationStore extends Store<UserLocationState> {

  constructor() {
    super(createInitialState());
  }

  positionFound(position: GeolocationPosition) {
    this.update({ userLocation: position });
  }

}

