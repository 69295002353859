import { Directive, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[kcClickOutside]'
})

/**
 * HOW TO USE:
 * Import AppDirectivesModule
 * Attach this directive to the element which is displayed on toggle. (The one with *ngIf on it).
 * And bind toggling method to (clickOutside) event.
 *
 * EXAMPLE:
 * <div *ngIf="displayDropdown" (clickOutside)="toggleDropdown($event) </div>
 */
export class ClickOutsideDirective {
  @Input() parentElement: ElementRef;
  @Input() popUpWindowActive = false;
  @Output() kcClickOutside: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
  * Listens to the global click event. Sends an emit event if clicked outside of element.
  */
  @HostListener('document:click', ['$event']) handleClick(event) {
    const path = event.path || (event.composedPath && event.composedPath()) || this.composedPath(event.target);
    if (path) {
      const elementRefInPath = path.find(e => e === this.myElement.nativeElement
        || this.parentElement && e === this.parentElement.nativeElement);
      if (!elementRefInPath && !this.popUpWindowActive) {
        this.kcClickOutside.emit(false);
        return;
      }
    }
  }

  // USED BY EDGE. Creates a path variable identical to path provided by Chrome's click event.
  composedPath(el) {
    const path = [];
    while (el) {
      path.push(el);
      if (el.tagName === 'HTML') {
        path.push(document);
        path.push(window);

        return path;
      }
      el = el.parentElement;
    }
  }

  /**
  * @param myElement element reference.
  */
  constructor(private myElement: ElementRef) { }

}
