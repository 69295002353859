
const NEW_SERVICE_AGREEMENT = 'acceptPricingUpdate';
const NEW_SERVICE_AGREEMENT_REMINDER = 'acceptPricingUpdateReminder';
const PRICING_UPDATED = 'acceptUpdatedPiricing';

/**
 * Set new service agreement
 * If not found, then return null as not set state.
 */
export function getPricingUpdateAgreement(): boolean {
    const cookieConsent = localStorage.getItem(NEW_SERVICE_AGREEMENT);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
}

export function getPricingUpdateReminderAgreement(): boolean {
    const cookieConsent = localStorage.getItem(NEW_SERVICE_AGREEMENT_REMINDER);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
}

export function getUpdatedPricingAgreement(): boolean {
    const cookieConsent = localStorage.getItem(PRICING_UPDATED);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
}

/**
 * Set new service agreement
 * @param consent user consent to service agreement (true)
 */
export function setPricingUpdateAgreement(consent: boolean) {
    localStorage.setItem(NEW_SERVICE_AGREEMENT, JSON.stringify(consent));
}

export function setPricingUpdateReminderAgreement(consent: boolean) {
    localStorage.setItem(NEW_SERVICE_AGREEMENT_REMINDER, JSON.stringify(consent));
}

export function setUpdatedPricingAgreement(consent: boolean) {
    localStorage.setItem(PRICING_UPDATED, JSON.stringify(consent));
}
