import { Injectable } from '@angular/core';
import { QueryEntity, Order, EntityUIQuery } from '@datorama/akita';
import { TransactionsState, TransactionsStore } from './transactions.store';
import { Transaction } from './transaction.model';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { InvoiceQuery } from '../../invoice/invoice.query';

@Injectable({
  providedIn: 'root'
})
export class TransactionsQuery extends QueryEntity<TransactionsState, Transaction> {
  
  transactions$ = this.selectAll({
    sortBy: 'timestampStop',
    sortByOrder: Order.DESC,
  });

  transactionsByMonths$ = this.selectAll({
    sortBy: 'timestampStop',
    sortByOrder: Order.DESC,
  }).pipe(map(() => this.getTransactionsByYearsMonths()));

  constructor(protected store: TransactionsStore, private invoiceQuery: InvoiceQuery) {
    super(store);
  }

  public getMonths(): {year: number, month: number}[] {
    const months = [];
    this.getAll({
      sortBy: 'timestampStop',
      sortByOrder: Order.DESC,
    }).forEach(t => {
      const yyyyMm = {
        year: moment(t.timestampStop).year(),
        month: moment(t.timestampStop).month(),
      };
      if (!months.find(x => x.year === yyyyMm.year && x.month === yyyyMm.month)) {
        months.push(yyyyMm);
      }
    });
    return months;
  }

  /**
   * Returns transactions grouped by year and month.
   */
  getTransactionsByYearsMonths() {
    const parsedTransactions = [];
    const dates = this.getMonths();
    for (let i = 0; i < dates.length; i++) {
      parsedTransactions[i] = this.getAll({
        sortBy: 'timestampStop',
        sortByOrder: Order.DESC,
        filterBy: entity =>
          entity.timestampStop
          && moment(entity.timestampStop).year() === dates[i].year
          && moment(entity.timestampStop).month() === dates[i].month});
    }
    return parsedTransactions;
  }

  public monthlyTransactionExists(date: Date) {
    return this.hasEntity(entity => moment(moment(entity.timestampStop).month()).isSame(date, 'month'));
  }

  // public getTransactionById(id) {
  //   // tslint:disable-next-line:max-line-length
  //   const monthlyInvoice = this.getAll({ filterBy: entity => entity.chargingTransactions.find(chargingTransaction => chargingTransaction.id === id) ? true : false });
  //   return monthlyInvoice[0].chargingTransactions.find(chargingTransaction => chargingTransaction.id === id) || undefined;
  // }
}
