import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { retryWhen, delay, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class ServerErrorsInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If the call fails, retry until 1 time (from the http service) + 3times before throwing an error
        return next.handle(request).pipe(
            retryWhen(errors =>
                errors.pipe(
                  switchMap((error, index) =>
                    index === 2 || error && error.status === 404 // no need to retry if getting a 404 error.
                    ? throwError(error)
                    : of(error).pipe( delay(1500) )
                  )
                )
              ));
    }
}
