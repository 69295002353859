<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="errorIcon">
        <img *ngIf="(requestStatus$ | async) !== 2" src="../../../../assets/icons/png/icon-exclamation@2x.png"
          alt="errorIcon">
      </div>

      <ng-container [ngSwitch]="(requestStatus$ | async)">
        <ng-container *ngSwitchCase="0">
          <div class="overlayContainer__header">
            <span class="title">{{ 'somethingWentWrongTitle' | translate }}</span>
            <span class="info -padding">{{ message | translate }}</span>
            <span *ngIf="development" class="info -padding">{{ error.message }}</span>
            <span *ngIf="message !== 'noInternetConnection'" class="info -padding"> {{ 'sendErrorReportNotifier' | translate }} </span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="1">
          <div class="routerContainerContent__transactionMessageContainer">
            <kc-local-loader [message]="'sendingErrorReport'"></kc-local-loader>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <div class="overlayContainer__contentWrapper">
            <div class="overlayContainer__contentTemplate">
              <div class="successfulIcon">
                <img src="../../../../../assets/icons/png/icon-tick@2x.png" alt="accepted">
              </div>
              <div class="overlayContainer__header">
                <span class="title">{{ 'supportRequestSendTitle' | translate }}</span>
                <span class="info -padding">{{ 'problemReportedSuccessfulInfo' | translate }}</span>
                <span class="info -padding">{{ 'sorryForTheInconvenience' | translate }}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="overlayContainer__header">
            <span class="title">{{ 'somethingWentWrongTitle' | translate }}</span>
            <span class="info -padding">{{ 'sendErrorReportFailedInfo' | translate }}</span>
            <span class="info -padding">{{ 'ifProblemPersists' | translate }}</span>
            <span *ngIf="message !== 'noInternetConnection'">
              <a href="tel:0207-350-333">{{ 'supportCallNumber' | translate }}</a>
              <label for="" class="callPriceLabel"> {{ 'supportCallPrice' | translate }}</label>
            </span>
          </div>
        </ng-container>
      </ng-container>
      <div *ngIf="(requestStatus$ | async) === 0 else onlyClose" class="overlayContainer__actionContainer --w100">
        <kc-dialog-button [buttonText]="'close'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
        <kc-dialog-button [buttonText]="'sendErrorReport'" [color]="'--white'" (onClick)="sendErrorReport()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>


<!-- only contains close button -->
<ng-template #onlyClose>
  <div class="overlayContainer__actionContainer --w100">
    <kc-dialog-button [buttonText]="'close'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
  </div>
</ng-template>
