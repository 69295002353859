import { Component, OnInit, ViewChild, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { SearchBarService } from '../state/search-bar.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MapService } from 'src/app/map/state/map.service';
import { Router } from '@angular/router';
import { ChargePointsService } from 'src/app/map/charge-points/charge-points.service';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { ChargePointAutoFillPipe } from '../charge-point-auto-fill.pipe';
import { GetSocketFromChargePointPipe } from '../get-socket-from-charge-point.pipe';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { WindowService } from 'src/app/window-service/window.service';
import { TopBarService } from '../../top-bar.service';
import { GaService } from 'src/app/ga/ga.service';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';

@Component({
  selector: 'kc-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [ChargePointAutoFillPipe, GetSocketFromChargePointPipe]
})
export class SearchBarComponent implements OnInit {
  searchForm = new UntypedFormGroup({ search: new UntypedFormControl() });
  chargePoints: ChargePoint[];
  possibleChargePoints: ChargePoint[] = [];
  public showSearchSuggestions$ = this.topBarService.isSearchSuggestionsContainerOpen$;
  showSearchSuggestions;

  searchWords = ['searchByChargepoint', 'searchByCity', 'searchByPostalcode', 'searchBySocketID', 'searchByAddress'];
  selectedSearchWord = 0;
  suggestionInterval;

  @ViewChild('input', { static: true }) inputElement: ElementRef;

  constructor(
    private searchBarService: SearchBarService,
    private mapService: MapService,
    private router: Router,
    private chargePointsService: ChargePointsService,
    private chargePointsQuery: ChargePointsQuery,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private chargePointAutoFill: ChargePointAutoFillPipe,
    public getSocketFromChargePoint: GetSocketFromChargePointPipe,
    private windowService: WindowService,
    private renderer: Renderer2,
    public topBarService: TopBarService,
    private gaService: GaService,
    private cdr: ChangeDetectorRef
  ) {
    this.topBarService.isSearchSuggestionsContainerOpen$.subscribe(isOpen => {
      this.showSearchSuggestions = isOpen;
    });
  }

  ngOnInit() {
    this.chargePointsQuery.publicChargePoints$.subscribe({
      next: (chargePoints) => {
        this.chargePoints = chargePoints;
      }
    });

    this.searchForm.controls['search'].valueChanges.subscribe({
      next: (text) => {
        this.possibleChargePoints = this.chargePointAutoFill.transform(text, this.chargePoints);
      }
    });

    this.renderer.listen(this.inputElement.nativeElement, 'focus', () => {
      this.windowService.setRouteContainerOpenState(false);
      this.topBarService.closeOpenModals();
    });

    // changes searchbar placeholder
    this.suggestionInterval = setInterval(() => {
      if (this.selectedSearchWord === 4) {
        this.selectedSearchWord = 0;
      } else {
        this.selectedSearchWord++;
      }
      this.cdr.detectChanges();
    }, 5000);
  }

  onSubmit() {
    const result = Object.assign({}, this.searchForm.value); // deep copy of form group value
    this.requestCoordinates(result['search']);
    this.inputElement.nativeElement.blur();
    this.chargePointsService.changeActiveChargePoint(null);
    this.router.navigate(['/profile']);
  }

  /**
   * Clear search forms input
   */
  erase() {
    this.searchForm.reset();
  }

  /**
   * Get position coordinates depending on the search form input
   * @param searchValue serach value
   */
  requestCoordinates(searchValue: string) {
    this.searchBarService.geocodeAddress(searchValue).subscribe(res => {
      this.handleResults(res);
    });
  }

  handleResults(res) {
    if (res['formattedAddress']) { // if res has formattedAddress it was successful and found some coordinates
      // Remove ', {country}' from returned formatted address
      const formattedAddress = (res['formattedAddress'] as string).slice(0, (res['formattedAddress'] as string).lastIndexOf(','));
      this.searchForm.controls['search'].setValue(formattedAddress); // insert formatted address to input field
      this.mapService.setMapCenterToCoordinates({ lat: res['lat'], lng: res['lng'] });
      this.gaService.sendMapInteraction('Haku', this.searchForm.controls['search'].value);
    }
  }

  /**
   * Selects charge point or charge point socket and centers map to the selected charge point
   * @param chargePoint selected charge point
   */
  selectChargePoint(chargePoint: ChargePoint) {
    this.mapService.setMapCenterToCoordinates({ lat: chargePoint.coordinate['lat'], lng: chargePoint.coordinate['lng'] });
    let chargeBox;
    if (this.searchForm.controls['search'].value.length === 4) {
      const chargeBoxes = this.chargeBoxesQuery.getAll({ filterBy: entity => entity.chargePointId === chargePoint.id });
      chargeBox = chargeBoxes.find(cb => cb.uniqueId.toString() === this.searchForm.controls['search'].value);
    }
    if (chargeBox)  {
      this.gaService.sendMapInteraction('Haku', this.searchForm.controls['search'].value);
      this.router.navigateByUrl(`/charge-point/${chargePoint.id}/socket/${chargeBox.id}`);
    } else {
      this.gaService.sendMapInteraction('Haku', this.searchForm.controls['search'].value);
      this.router.navigate([`/charge-point/`, chargePoint.id]);
    }
    this.chargePointsService.changeActiveChargePoint(chargePoint.id);
    this.windowService.setRouteContainerOpenState(true);
    this.erase();
    this.topBarService.toggleSearchbarSuggestions(false);
  }

  /**
   * Trims white spaces out from given text
   * @param text given text
   */
  removeWhitespace(text: string): string {
    return text.replace(/\s/g, '');
  }

  onFocus() {
    this.topBarService.toggleSearchbarSuggestions(true);
    clearInterval(this.suggestionInterval);
  }

  onBlur() {

    if ((this.searchForm.get('search').value === '' || this.searchForm.get('search').value === null)) {
      this.topBarService.toggleSearchbarSuggestions(false);
    }

    if (!(this.showSearchSuggestions) && (this.searchForm.get('search').value === '' || this.searchForm.get('search').value === null)) {
      this.suggestionInterval = setInterval(() => {
        if (this.selectedSearchWord === 4) {
          this.selectedSearchWord = 0;
        } else {
          this.selectedSearchWord++;
        }
        this.cdr.detectChanges();
      }, 5000);
    }
  }

  clickedOutside() {
    this.topBarService.toggleSearchbarSuggestions(false);
  }

  setInputActive() {
    this.inputElement.nativeElement.focus();
  }

  /**
   * Set right icon for selected charge point socket (charge box)
   * @param formattedType charge box socket type
   * @param status charge box socket status
   */
  getSocketPic(formattedType: string, status: string): string {
    if (status === 'Available') {
      return `${formattedType}Orange`;
    } else {
      return `${formattedType}Gray`;
    }
  }

  uniqueIdMatches(chargePoint) {
    if (this.searchForm.controls['search'].value.length === 4) {
      const [chargeBox] = this.chargeBoxesQuery.getAll(
        { filterBy: entity => entity.chargePointId === chargePoint.id
          && entity.uniqueId === this.searchForm.get('search').value  });
      return chargeBox;
    } else {
      return undefined;
    }
  }
}
