import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject, Observable, Subject, Subscription, of, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WebsocketService, ChannelType } from 'src/app/websocket-service/websocket.service';
import { catchError, take, takeUntil, tap } from 'rxjs/operators';
import { ChargePointsService } from 'src/app/map/charge-points/charge-points.service';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { pick } from 'lodash';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { DiscountQuery } from '../../discounts/state/discount.query';
import { DiscountService } from '../../discounts/state/discount.service';
import { Transaction, TransactionPrice, PricingState } from '../../transactions/state/transaction.model';
import { TransactionsService } from '../../transactions/state/transactions.service';
export interface ActiveTransaction {
  _id: string;
  chargeBoxId: string;
  chargePointId: string;
  chargePointGroupId: string;
  chargeBoxUniqueId: number;
  chargeBoxGroupId: string;
  duration: number;
  energy: number;
  timestampStart: string;
  timestampStop?: string;
  transactionId: string;
  chargePoint?: ChargePoint;
  socketType?: string;
  status?: string;
  roaming?: boolean;
  transactionPrice?: TransactionPrice;
  userId: string;
  powerType: string;
  SessionID?: string; // Hubject
  EvseID?: string; // Hubject
}

@Injectable({ providedIn: "root" })
export class ActiveTransactionService {
  private activeTransactionSubject = new ReplaySubject<ActiveTransaction[]>(1);
  public activeTransaction$ = this.activeTransactionSubject.asObservable();
  private activeTransactions: ActiveTransaction[] = [];
  private userTranasctionSubscription: Subscription;
  private transactionPriceSubscription: Subscription;
  private feedbackSubscription: Subscription;
  private feedbackTicketSubscription: Subscription;
  private previousStoppedTransactionId;
  activeDiscount;

  constructor(
    private http: HttpClient,
    private websocketService: WebsocketService,
    private chargePointsService: ChargePointsService,
    private chargePointsQuery: ChargePointsQuery,
    private kIDSessionQuery: KIDSessionQuery,
    private overlayDialogService: OverlayDialogService,
    private router: Router,
    private loaderService: LoaderService,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private discountQuery: DiscountQuery,
    private discountService: DiscountService,
    private transactionService: TransactionsService
) {
      // Listen to roaming session and update transaction.
      // Destroy channel after a message is received (transaction has ended).
      this.websocketService.roamingSessionMessages$.subscribe(res => {
        this.updateActiveTransactions(res, true);
        this.websocketService.destroyChannel(ChannelType.ROAMING_SESSION);
      });
    }

  hasActiveTransactions() {
    return this.activeTransactions && this.activeTransactions.length > 0
      ? true
      : false;
  }

  hasActiveTransactionOnChargeBox(chargeBoxId: string) {
    return (
      this.activeTransactions &&
      this.activeTransactions.findIndex((t) => t.chargeBoxId === chargeBoxId) !=
        -1
    );
  }

  getActiveTransaction() {
    const destroyAfterReady$ = new Subject<boolean>();
    forkJoin([
      this.getActiveHubjectTransactions(),
      this.getActiveKeskoTransactions(),
    ]).subscribe((result) => {
      this.chargePointsQuery
        .selectLoading()
        .pipe(takeUntil(destroyAfterReady$))
        .subscribe((isLoading) => {
          if (!isLoading) {
            let activeTransactions = result[0].concat(result[1]);
            activeTransactions = activeTransactions.filter(transaction =>
              !transaction._id || transaction._id !== this.previousStoppedTransactionId);
            for (let index = 0; index < activeTransactions.length; index++) {
              activeTransactions[index] = this.parseActiveTransaction(
                activeTransactions[index]
              );
            }
            activeTransactions = activeTransactions
              .map((activeTransaction) => {
                const foundTransation = this.activeTransactions.find(
                  (t) => t.transactionId === activeTransaction.transactionId
                );
                if (foundTransation) {
                  // tslint:disable-next-line: max-line-length
                  const upToDateEnergy =
                    foundTransation.energy > activeTransaction.energy
                      ? foundTransation.energy
                      : activeTransaction.energy || 0;
                  activeTransaction.energy = upToDateEnergy || 0;
                }

                return activeTransaction;
              })
              .filter((t) => t.chargePoint);
            this.activeTransactions = activeTransactions;
            this.listenActiveTransaction();
            this.activeTransactionSubject.next(this.activeTransactions);
            destroyAfterReady$.next(true);
            destroyAfterReady$.complete();
          }
        });
    });
  }

  getActiveKeskoTransactions(): Observable<any> {
    return this.http
      .get<ActiveTransaction[]>(
        `${environment.BACKEND_URL}/transactions/active`
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          return of([]);
        })
      );
  }

  getActiveHubjectTransactions(): Observable<any> {
    // TODO: Uncomment for roaming.
    if (this.kIDSessionQuery.isLoggedIn()) {
      return this.http
        .get(`${environment.ROAMING.BASE_URL}/sessions/active`)
        .pipe(
          tap((res) => {
            // If transaction is found, open a socket channel for it.
            if (res && res[0] && res[0].SessionID) {
              const sessionId = res[0].SessionID;
              this.websocketService.createChannel(
                ChannelType.ROAMING_SESSION,
                `${environment.BACKEND_URL}/roaming/sessions/${sessionId}/socket`
              );
            } else {
              this.websocketService.destroyChannel(ChannelType.ROAMING_SESSION);
            }
          }),
          catchError(() => of([]))
        );
    } else {
      return of([]);
    }
  }

  parseActiveTransaction(transaction: ActiveTransaction) {
    const chargeBox = this.chargeBoxesQuery.getEntity(transaction.chargeBoxId || transaction.EvseID);
    const chargePoint = this.chargePointsQuery.getEntity(transaction.chargePointId || chargeBox.chargePointId);

    const parsedTransaction = {
      _id: transaction._id || transaction.SessionID,
      chargeBoxId: transaction.chargeBoxId || chargeBox.id,
      chargePointId: transaction.chargePointId || chargePoint.id,
      chargeBoxUniqueId: transaction.chargeBoxUniqueId || chargeBox.uniqueId,
      duration: transaction.duration || 0,
      energy: transaction.energy || 0,
      socketType: chargeBox.socketType,
      timestampStart: transaction.timestampStart,
      transactionId: transaction._id || transaction.SessionID,
      status: transaction.status || 'Occupied',
      chargePoint: chargePoint,
      transactionPrice: transaction.transactionPrice,
      roaming: chargeBox.EvseID ? true : false,
    } as ActiveTransaction;

    return parsedTransaction;
  }

  updateActiveTransaction(activeTransactions, transaction: Transaction) {
    const formattedTransaction = this.formatActiveTransaction(transaction);
    // tslint:disable-next-line:max-line-length
    const foundActiveTransaction = activeTransactions.findIndex(activeTransaction => activeTransaction._id === formattedTransaction._id || activeTransaction.transactionId === formattedTransaction.transactionId);
    if (foundActiveTransaction !== -1) {
      // tslint:disable-next-line:max-line-length
      this.chargePointsService.updateSocketTransactionId(formattedTransaction.chargeBoxId, formattedTransaction.transactionId);
      if (!formattedTransaction.status) {
        formattedTransaction.status = activeTransactions[foundActiveTransaction].status;
      }
      formattedTransaction.transactionPrice = this.activeTransactions[foundActiveTransaction].transactionPrice || formattedTransaction.transactionPrice;
      activeTransactions[foundActiveTransaction] = formattedTransaction;
    } else if (!formattedTransaction.timestampStop) {
      activeTransactions.push(formattedTransaction);
    }
    return activeTransactions;
  }

  addNewTransaction(transaction) {
    transaction = this.parseActiveTransaction(transaction);
    this.activeTransactions.push(transaction);
    this.activeTransactionSubject.next(this.activeTransactions);
  }

  formatActiveTransaction(transaction: Transaction) {
    const newActiveTransaction: Partial<ActiveTransaction> = pick(transaction, [
      '_id',
      'chargeBoxId',
      'chargePointId',
      'chargeBoxGroupId',
      'duration',
      'energy',
      'socketType',
      'timestampStart',
      'timestampStop',
      'chargePoint',
      'status',
      'roaming',
      'transactionPrice',
      'userId',
    ]);

    if (transaction.roaming && !transaction.transactionPrice && transaction['price']) {
      transaction.transactionPrice = {
        price: transaction['price'].price,
        chargingPrice: undefined,
        discount: undefined,
      }
    }

    newActiveTransaction.transactionPrice = transaction.transactionPrice;
    newActiveTransaction.transactionId = transaction.id || transaction.transactionId;
    newActiveTransaction.chargeBoxUniqueId = transaction.chargeBoxUniqueId;

    if (transaction['latestMeterValues']) {
      newActiveTransaction.energy = this.calculateTransactionEnergy(
        transaction['meterStart'],
        transaction['latestMeterValues']
      );
    }

    if (!newActiveTransaction.chargePoint || newActiveTransaction.chargePoint === undefined) {
      const chargeBox = this.chargeBoxesQuery.getEntity(transaction.chargeBoxId);
      const chargePoint = this.chargePointsQuery.getEntity(transaction.chargePointId || chargeBox.chargePointId);

      newActiveTransaction.chargePoint = chargePoint;
      newActiveTransaction.socketType = chargeBox.socketType;
      newActiveTransaction.roaming = chargeBox.roaming;
    }

    return newActiveTransaction;
  }

  calculateTransactionEnergy(meterStart: number, latestMeterValues) {
    const meterNow = latestMeterValues.find(
      (meterValue) =>
        meterValue["measurand"] === "Energy.Active.Import.Register"
    );
    return meterNow["value"] - meterStart;
  }

  listenActiveTransaction() {
    if (this.userTranasctionSubscription) {
      this.userTranasctionSubscription.unsubscribe();
    }
    this.userTranasctionSubscription = this.websocketService.userMessages$.subscribe((transaction: ActiveTransaction) => {
      // Prevent transaction to be handled from transaction channel without price. Commented out because transactions socket channel is not as reliable.
      // if (transaction.timestampStop) { return; }

      const transactionsIndex = this.activeTransactions.findIndex(activeTransaction =>
        activeTransaction.transactionId === transaction._id || activeTransaction._id === transaction._id || activeTransaction.transactionId === transaction.transactionId);
      if (transactionsIndex === -1) {
        transaction = this.parseNewTransaction(transaction);
      }
      this.updateActiveTransactions(transaction, true);
    });

    if (this.transactionPriceSubscription) {
      this.transactionPriceSubscription.unsubscribe();
    }

    this.transactionPriceSubscription = this.websocketService.transactionMessages$.subscribe((transaction: ActiveTransaction) => {
      // Handle only messages with timestampStop (finished transactions)
      if (!transaction.timestampStop) { return; }

      const index = this.activeTransactions.findIndex(activeTransaction =>
        activeTransaction._id === transaction._id || activeTransaction.transactionId === transaction.transactionId);
        if (index == -1) {
          transaction = this.parseNewTransaction(transaction);
          if (!transaction.chargePoint) {
            this.chargePointsService.updateChargePoint(transaction.chargePointId).subscribe(res => {
              transaction = this.parseNewTransaction(transaction);
              this.updateActiveTransactions(transaction, true);
            });
            return;
          }
        }
      this.updateActiveTransactions(transaction, true);
    });
  }

  /**
   * Parse the newly found transaction. Adds necessary properties.
   * @param transaction to parse.
   */
  parseNewTransaction(transaction: ActiveTransaction) {
    if (!transaction.roaming && !transaction.transactionPrice) {
      const activeTransaction = this.activeTransactions.find(at => at._id === transaction._id);
      if (!activeTransaction || (activeTransaction && !activeTransaction.transactionPrice && !activeTransaction.roaming)) {
        // this.getActiveTransaction();
        
        // Poll active transaction to ensure transactionPrice is present.
        this.transactionService.pollTransactionChargingPrice(transaction._id)
          .subscribe(res => this.updateActiveTransactions(res));
      }
    }

    const chargeBox = this.chargeBoxesQuery.getEntity(transaction.chargeBoxId || transaction.EvseID);
    const chargePoint = this.chargePointsQuery.getEntity(transaction.chargePointId || chargeBox.chargePointId);
    transaction.socketType = chargeBox.socketType;
    transaction.chargePoint = chargePoint;
    transaction.status = chargeBox.status;
    if (transaction.roaming) {
      transaction.transactionId = chargeBox.EvseID;
    }
    return transaction;
  }

  /**
   * Updates, checks status and emits current active transactions.
   * @param transaction updated transaction.
   * @param transactionsIndex the index of found transaction.
   */
  private updateActiveTransactions(transaction, allowStopping?: boolean) {
    this.activeTransactions = this.updateActiveTransaction(this.activeTransactions, transaction);
    if (allowStopping === true && transaction.timestampStop) {
      this.transactionStopped(!transaction.roaming ? transaction._id : transaction.transactionId);
      return;
    }

    this.activeTransactionSubject.next(this.activeTransactions);
  }

  transactionStopped(transactionIdToRemove: string) {
    this.previousStoppedTransactionId = transactionIdToRemove;
    const isLoggedIn = this.kIDSessionQuery.isLoggedIn();
    this.activeDiscount = this.discountQuery.getActive();
    const data = this.activeTransactions.filter(e => e._id === transactionIdToRemove || e.transactionId === transactionIdToRemove)[0];
    if (!data) {
      return;
    }
    this.activeTransactions = this.activeTransactions.filter(e => e._id != data._id);
    this.activeTransactionSubject.next(this.activeTransactions);

    // The price is not shown correctly for single-payment users because of a race condition in price calculation
    // Simply redirect.
    if (data.userId && data.userId.includes('single-payment')) {
      this.loaderService.deactivateSpinner('active-transaction');
      this.router.navigate([isLoggedIn ? '/transactions' : '/profile']);
      return;
    }

    this.discountService.get().subscribe(res => {
    });

    if (!data.roaming && (!data.transactionPrice || !data.transactionPrice.price || !data.transactionPrice.price.total)) {
      this.transactionService.pollEndedTransaction(data._id).subscribe(tr => {
        this.openTransactionSummary(tr, data.chargePoint.name, tr._id);
        this.loaderService.deactivateSpinner('active-transaction');
        this.router.navigate([isLoggedIn ? '/transactions' : '/profile']);
      }, () => {
        this.loaderService.deactivateSpinner('active-transaction');
        this.router.navigate([isLoggedIn ? '/transactions' : '/profile']);
      });
    } else {
      this.openTransactionSummary(data, data.chargePoint.name, data._id);
      this.loaderService.deactivateSpinner('active-transaction');
      this.router.navigate([isLoggedIn ? '/transactions' : '/profile']);
    }
  }

  private openTransactionSummary(transaction: Transaction | ActiveTransaction, chargePointName: string, transactionId: string) {
    this.openFeedbackDialog({
      id: transactionId,
      chargePoint: chargePointName,
      chargeBoxUniqueId: transaction.chargeBoxUniqueId,
      chargePointId: transaction.chargePointId,
      chargePointGroupId: transaction.chargePointGroupId,
      chargeBoxId: transaction.chargeBoxId,
      chargeBoxGroupId: transaction.chargeBoxGroupId,
      transactionId: transactionId,
      socketType: transaction.socketType || 'Type 2',
      timestampStart: transaction.timestampStart,
      timestampStop: transaction.timestampStop,
      energy: transaction.energy,
      roaming: transaction.roaming,
      powerType: transaction.powerType,
      // tslint:disable-next-line:max-line-length
      transactionPrice: transaction.transactionPrice,
      discount: transaction.transactionPrice.discount ? {
        // tslint:disable-next-line:max-line-length
        discountPercentage: transaction.transactionPrice.discount.discountPercentage,
        totalBeforeDiscount: transaction.transactionPrice.discount.totalBeforeDiscount,
        priceBeforeDiscount: transaction.transactionPrice.discount.priceBeforeDiscount
      } : null
    })
  }

  resetTransactions() {
    this.activeTransactions = [];
    if (this.userTranasctionSubscription) {
      this.userTranasctionSubscription.unsubscribe();
    }
    this.activeTransactionSubject.next(this.activeTransactions);
  }

  getAmountDeducted(price: number, percentage: number) {
    return (percentage / 100) * price;
  }

  getReducedPrice(price: number, percentage: number) {
    return Math.round(price - this.getAmountDeducted(price, percentage));
  }

  openFeedbackDialog(transaction) {
    const componentRef = this.overlayDialogService.openOverlayDialog(
      "TransactionSummaryComponent",
      {
        transaction,
        transactionStop: true,
      }
    );
    if (componentRef && componentRef.instance.openFeedbackEvent) {
      // To prevent memory leaking
      if (this.feedbackSubscription) {
        this.feedbackSubscription.unsubscribe();
      }
      this.feedbackSubscription = componentRef.instance.openFeedbackEvent.pipe(take(1)).subscribe(() => {
        const feedbackDialogRef = this.overlayDialogService.openOverlayDialog("FeedbackDialogComponent", {
          transaction,
        });
        if (feedbackDialogRef && feedbackDialogRef.instance.openCustomerSupportEvent) {
          if (this.feedbackTicketSubscription) {
            this.feedbackTicketSubscription.unsubscribe();
          }
          this.feedbackTicketSubscription = feedbackDialogRef.instance.openCustomerSupportEvent.pipe(take(1)).subscribe(event => {
            this.overlayDialogService.openOverlayDialog('SupportContactComponent', {event});
          })
        }
      });
    }
  }
}
