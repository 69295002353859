<kc-navigation-bar></kc-navigation-bar>
<ng-container *ngIf="(loggedIn$ | async) as loggedIn">
  <div class="routerContainerContent__container">
   
    <!-- Overview component with sliding unpaid invoice cards display -->
    <ng-container *ngIf="invoicingAgreementExists; else noInvoicingAgreement">
      <ng-container 
        *ngIf="!(invoicingAgreementInvoicesLoading$ | async) || invoicingAgreementInvoicesFetchedOnce; else loadingTemplate">
        <ng-container *ngIf="(monthlyInvoicingAgreementInvoices$ | async) as monthlyInvoicingAgreementInvoices">
          <kc-transactions-overview 
          [invoicingAgreementInvoices]="monthlyInvoicingAgreementInvoices">
          </kc-transactions-overview>
        </ng-container>        
      </ng-container>
    </ng-container>

    <ng-template #noInvoicingAgreement>
      <ng-container>
        <ng-container *ngIf="!(invoicesLoading$ | async) || invoicesFetchedOnce; else loadingTemplate">
          <ng-container *ngIf="(unpaidInvoices$ | async) as unpaidInvoices">
            <kc-transactions-overview 
            [unpaidInvoices]="unpaidInvoices">
            </kc-transactions-overview>
          </ng-container>
        </ng-container> 
      </ng-container>
    </ng-template>
    
    <!-- Year selector for monthly transactions view -->
    <kc-transactions-year-selector (yearSelect)="onYearSelected($event)"></kc-transactions-year-selector>

    <!-- Monthly transactions -->
    <div class="transactionsComponent__monthlyTransactionsContainer">
      <ng-container *ngFor="let month of monthIndexes">
        <kc-monthly-transactions [month]="month" [year]="selectedYear" [isOpen]="openTransactions(month, selectedYear)" (monthSelected)="onMonthSelected($event)"></kc-monthly-transactions>
      </ng-container>
    </div>

    <ng-template #loadingTemplate>
      <kc-local-loader></kc-local-loader>
    </ng-template>
  </div>
</ng-container>
