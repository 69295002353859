import { Pipe, PipeTransform } from '@angular/core';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';

@Pipe({
  name: 'getSocketFromChargePoint'
})
export class GetSocketFromChargePointPipe implements PipeTransform {

  transform(chargers: ChargeBox[], text: string): ChargeBox {
    if (text && text.length === 4) {
      text = text.toLowerCase();
      let possibleCharger: ChargeBox;

      possibleCharger = chargers.find(charger => charger.uniqueId && charger.uniqueId.toString() === text);

      return possibleCharger;
    } else {
      return null;
    }
  }
}
