import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { LoaderStore, LoaderState } from './loader.store';
import { Loader } from './loader.model';

@Injectable({
  providedIn: 'root'
})
export class LoaderQuery extends QueryEntity<LoaderState, Loader> {

  activeLoaders$ = this.selectAll();

  constructor(protected store: LoaderStore) {
    super(store);
  }
}
