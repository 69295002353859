
<div class="monthlyTransactions__transactionsHeader --clickAble" (click)="fetchMonthlyTransactions()">
  <span>{{ date | localDate:'LLLL' | titlecase }}
    <span>{{year}}</span>
  </span>
  <div class="actionContainer">
    <div [ngClass]="isOpen ? 'icon rotate' : 'icon'">
      <i class="fak fa-k-chevron-down fa-2xs" [ngClass]="isOpen ? '--colorWhite' : '--colorOrange'"></i>
    </div>
  </div>
</div>

<div style="z-index: 1" *ngIf="isOpen" @openTransactions>

  <ng-container *ngIf="(isLoadingMonthlyTransactions$ | async) === false || transactionsFetchedOnce; else loadingTemplate">
    <ng-container *ngIf="(transactions$ | async) as transactionsByMonth;">
      <ng-container *ngIf="transactionsByMonth.transactions; else noTransactionsFoundTemplate">
        <!-- Loop invoices of month -->
        <ng-container *ngFor="let invoice of transactionsByMonth.invoices">
          <kc-transactions-invoice [invoice]="invoice"></kc-transactions-invoice>
        </ng-container>

        <!-- Loop transactions of month -->
        <ng-container *ngFor="let transaction of transactionsByMonth.transactions | transactionsFilter">
          <kc-transactions-charging-transaction [transaction]="transaction"></kc-transactions-charging-transaction>
        </ng-container>
      </ng-container>

      <ng-template #noTransactionsFoundTemplate>
        <div class="monthlyTransactions__noTransactionsFoundBlock">
          {{'noTransactionsFound' | translate}}
        </div>
      </ng-template>
    </ng-container>
  </ng-container>

  <ng-template #loadingTemplate>
    <kc-local-loader [message]="'fetchingTransactions'"></kc-local-loader>
  </ng-template>

  <div style="margin-bottom: 10px;"></div>
</div>