<ng-container *ngIf="(loggedIn$ | async) as loggedIn">
  <ng-container *ngIf="(monthlyInvoice$ | async) as monthlyInvoice">
    <!-- Invoice header -->
    <div class="routerContainerContent__headerContainer" (swipeup)="windowService.setRouteContainerOpenState(true)"
      (swipedown)="windowService.setRouteContainerOpenState(false)">
      <div class="iconButton" (click)="goBack()">
        <img src="../../../../assets/icons/png/icon-arrow-l-left@2x.png" alt="">
      </div>
      <ng-container *ngIf="monthlyInvoice.paymentStatus === 'succeeded'; else openBalanceTpl">
          <span class="title">{{ monthlyInvoice.createdAt | localDate:'LLLL y' | titlecase }} {{ 'monthlyInvoicePaidBalance' | translate }}</span>
      </ng-container>
      <ng-template #openBalanceTpl>
          <span class="title">{{ 'monthlyInvoiceOpenBalance' | translate }}
              {{ monthlyInvoice.createdAt | localDate:'LLLL y' }}</span>
      </ng-template>
    </div>

    <!-- Monthly invoice details -->
    <div class="routerContainerContent__monthlyInvoicePrice">
        <span class="-price">{{ monthlyInvoice.totalPrice.gross / 100 | localCurrency }}</span>
        <ng-container *ngIf="monthlyInvoice.paymentStatus === 'succeeded'; else invoiceTpl">
          <span class="-info -green">{{ 'monthlyInvoicePaid' | translate }}</span>
        </ng-container>
        <ng-template #invoiceTpl>
          <ng-container *ngIf="(calculateDifference(monthlyInvoice.createdAt, 1) > 0 || monthlyInvoice.paymentStatus !== 'failed'); else dueTpl">
            <span class="-info">{{ 'monthlyInvoiceCharge' | translate }}
              {{ calculateDifference(monthlyInvoice.createdAt, 1) }} {{ 'monthlyInvoiceChargeDays' | translate }}</span>
          </ng-container>
          <ng-template #dueTpl>
            <ng-container *ngIf="calculateDifference(monthlyInvoice.createdAt, 14) > 0; else lockedTpl">
              <span class="-info -red">{{ 'monthlyInvoiceChargeOverDue' | translate }} -
                {{ 'monthlyInvoiceChargeOverDueLocking' | translate }}
                {{ calculateDifference(monthlyInvoice.createdAt, 14) }}
                {{ 'monthlyInvoiceChargeDays' | translate }}
              </span>
            </ng-container>
            <ng-template #lockedTpl>
              <span class="-info -red">{{ 'monthlyInvoiceLocked' | translate }}</span>
            </ng-template>
          </ng-template>
        </ng-template>
      </div>
      <!-- Invoice payment -->
      <ng-container
        *ngIf="monthlyInvoice.paymentLink && monthlyInvoice.paymentStatus !== 'succeeded'; else noPaymentAvailableTpl">
          <button class="routerContainerContent__logoutButton"
            [ngClass]="monthlyInvoice.paymentLink && (paymentLinkActive$ | async) ? '' : 'gray'" (click)="executePayment(monthlyInvoice.id)">
            <span>{{ 'monthlyInvoicePay' | translate }}</span>
            <div *ngIf="!(paymentLinkActive$ | async)" class="spinner --small">
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
              <div class="--small"></div>
            </div>
          </button>
      </ng-container>
       <!-- No invoice payment -->
       <ng-template #noPaymentAvailableTpl>

          <!-- Not paid invoice -->
          <ng-container *ngIf="monthlyInvoice.paymentStatus !== 'succeeded'; else orderReceiptTpl">
            <button class="routerContainerContent__logoutButton gray" [disabled]="true">
              <span>{{ 'monthlyInvoicePay' | translate }}</span>
            </button>
          </ng-container>
          <!-- Paid invoice -->
          <ng-template #orderReceiptTpl>
            <button class="routerContainerContent__logoutButton" (click)="orderMonthlyInvoiceReceipt(monthlyInvoice._id)"
              [disabled]="(requestStatus$ | async) === 1 || (requestStatus$ | async) === 2">
              <ng-container *ngIf="(requestStatus$ | async) === 1">
                <div class="spinner --small">
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                  <div class="--small"></div>
                </div>
              </ng-container>
              <ng-container [ngSwitch]="(requestStatus$ | async)">
                <span *ngSwitchCase="0">{{ 'orderReceipt' | translate | uppercase}}</span>
                <span *ngSwitchCase="1">{{ 'sending' | translate | uppercase}}</span>
                <span *ngSwitchCase="2">{{ 'receiptOrdered' | translate | uppercase}}</span>
              </ng-container>
            </button>
          </ng-template>
        </ng-template>
    <div class="routerContainerContent__container -invoiceTransactions">
      <!-- Invoice transactions -->
      <ng-container *ngIf="monthlyInvoice.transactions && (isLoadingInvoice$ | async) === false; else loadingTemplate">
        <!-- TODO: CREATE OWN COMPONENT -->
        <ng-container *ngFor="let transaction of monthlyInvoice.transactions">
          <kc-invoice-transaction [invoiceTransaction]="transaction"></kc-invoice-transaction>
        </ng-container>

      </ng-container>
      <ng-template #loadingTemplate>
        <kc-local-loader [message]="'fetchingTransactions'"></kc-local-loader>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
