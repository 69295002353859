<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <div class="emptyContainer"></div>
        <span class="title">{{ 'priceList' | translate }}</span>
        <i class="fa-solid fa-xmark" (click)="close()"></i>
      </div>
      <div class="infoContainer">
        <div class="infoBoxWrapper">
          <div class="infoBox">
            <i class="fak fa-k-info"></i>
            <span>{{ 'scheduledPricingInfo' | translate }}</span>
          </div>
        </div>
        <div class="prices">
          <ng-container *ngFor="let price of schedules">
            <div class="scheduleTimeWrapper">
              <span>{{ 'priceBetween' | translate }}: {{ price.from + ' - ' + price.to }}</span>
            </div>
            <div class="priceWrapper">
              <div class="price">
                <kc-socket-price-item *ngIf="price.energy" [title]="('basePrice' | translate)"
                  [subtitle]="'appliesDuringCharging' | translate" [price]="price.energy" [pricingPrinciple]="'kWh'"
                  [compact]="true">
                </kc-socket-price-item>
                <kc-socket-price-item *ngIf="price.duration" [title]="getTimePriceTitle(price)"
                  [subtitle]="getTimePriceSubtitle(price)" [plusSign]="price.energy" [price]="price.duration"
                  [pricingPrinciple]="getTimePricePrinciple(price)" [compact]="true">
                </kc-socket-price-item>
                <!-- <kc-socket-price-item *ngIf="!(kidSessionQuery.isLoggedIn$ | async)" [title]="'startingPrice' | translate"
                  [subtitle]="'notAppliedToRegistered' | translate" [plusSign]="true" [price]="'1'"
                  [pricingPrinciple]="'chargingTransaction' | translate" [compact]="true">
                </kc-socket-price-item> -->
              </div>
              <div class="borderContainer"></div>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'close'" [color]="'--orange'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
