import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import * as moment from 'moment';
import { InvoiceService } from 'src/app/routes/invoice/invoice.service';
import { InvoiceQuery } from 'src/app/routes/invoice/invoice.query';
import { Router } from '@angular/router';
import { PaymentTimeDifferenceHelper } from 'src/app/helpers/payment-time-difference-helper';
import { Invoice } from 'src/app/routes/invoice/invoice.model';

@Component({
  selector: 'kc-payment-reminder-dialog',
  templateUrl: './payment-reminder-dialog.component.html',
  styleUrls: ['./payment-reminder-dialog.component.scss']
})
export class PaymentReminderDialogComponent implements OnInit {

  paymentReminderStatus: number; // 1: First reminder 2: "Days left" reminder 3: locked
  failedMonthlyInvoice: Invoice;
  daysUntil15th;
  totalPrice;
  currency;
  _id;

  constructor(@Inject(PORTAL_DATA) public data,
    private invoiceService: InvoiceService,
    private invoiceQuery: InvoiceQuery,
    private router: Router) {}

  ngOnInit() {
    this.paymentReminderStatus = this.data.data['paymentReminderStatus'];
    this.invoiceService.getLatestFailedMonthyInvoice()
      .subscribe(() => {
        this.failedMonthlyInvoice = this.invoiceQuery.getFailedInvoice();
        if (this.failedMonthlyInvoice) {
          this.totalPrice = this.failedMonthlyInvoice.totalPrice;
          this.currency = this.failedMonthlyInvoice.currency;
          this._id = this.failedMonthlyInvoice._id;
          this.daysUntil15th = PaymentTimeDifferenceHelper.calculateDifference(this.failedMonthlyInvoice.createdAt, 14);
        } else {
          this.close();
        }
      },
      _ => {
        this.close();
      });
  }

  close() {
    this.data.close();
  }

  continueToPayment() {
    if (this._id) {
      this.close();
      this.router.navigate([`invoice/${this._id}`]);
    }
  }

  /* daysUntil15th() {
    return PaymentTimeDifferenceHelper.calculateDifference(this.lastMonthInvoice.createdAt, 14);
  } */
}
