import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { WindowService } from 'src/app/window-service/window.service';
import { PaymentTimeDifferenceHelper } from 'src/app/helpers/payment-time-difference-helper';
import { TransactionSocketIconHelper } from 'src/app/helpers/transaction-socket-icon-helper';
import { InvoiceService } from './invoice.service';
import { Invoice } from './invoice.model';
import { InvoiceQuery } from './invoice.query';
import { requestStatus } from 'src/app/helpers/requestStatuses';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { TransactionsQuery } from '../transactions/state/transactions.query';

@Component({
  selector: 'kc-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  private destroyed$ = new Subject<boolean>();
  public loggedIn$: Observable<boolean>;
  public monthlyInvoice$: Observable<Invoice>;
  public isLoadingInvoice$: Observable<boolean>;
  public requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);
  public paymentLinkActive$ = new BehaviorSubject(true);

  isLocked$: Observable<boolean>;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private kIDSessionQuery: KIDSessionQuery,
    public windowService: WindowService,
    private invoiceQuery: InvoiceQuery,
    private overlayDialogService: OverlayDialogService,
    private transactionsQuery: TransactionsQuery) {

    this.loggedIn$ = this.kIDSessionQuery.isLoggedIn$;

    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.monthlyInvoice$ = this.invoiceQuery.selectEntity(res.id);
      this.invoiceQuery.selectEntity(res.id).subscribe(invoice => {
      });
      this.invoiceService.getMonthlyInvoiceById(res.id);
      this.isLoadingInvoice$ = this.invoiceQuery.selectLoading();
    });
  }

  ngOnInit() {
    this.isLocked$ = this.kIDSessionQuery.select(state => state.lockStatus ? state.lockStatus.locked : false);
    this.route.queryParams.subscribe(params => {
      if (params) {
        const paymentStatus = params['paymentStatus'];
        if (paymentStatus) {
          this.overlayDialogService.openOverlayDialog('PaymentSucceededDialogComponent');
        }
      }
    });
  }

  disablePaymentLink() {
    this.paymentLinkActive$.next(false);
  }

  goBack() {
    this.router.navigate(['/transactions']);
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  calculateDifference(monthlyInvoiceDate, days) {
    const difference = PaymentTimeDifferenceHelper.calculateDifference(monthlyInvoiceDate, days);
    return Math.max(0, difference);
  }



  orderMonthlyInvoiceReceipt(id: string) {
    this.requestStatus$.next(requestStatus.SEND);
    this.invoiceService.orderMonthlyInvoiceReceipt(id).subscribe(res => {
      this.requestStatus$.next(requestStatus.ACCEPTED);
    }, err => {
      this.requestStatus$.next(requestStatus.PENDING);
      this.overlayDialogService.openOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
      'source': { 'component': 'transactionSummaryComponent', 'function': 'orderTransactionReceipt'}});
      throw err;
    });
  }

  openOverlayDialog(transactionId, invoiceTransaction) {
    const transaction = this.transactionsQuery.getEntity(transactionId);
    this.overlayDialogService.openOverlayDialog('TransactionSummaryComponent', { transaction, transactionStop: false });
  }

  executePayment(monthylInvoiceId) {
    this.paymentLinkActive$.next(false);
    this.invoiceService.getMonthlyInvoicePaymentUrl(monthylInvoiceId).subscribe(paymentLink => {
      const paymentUrl =  paymentLink['url'];
      const form = document.createElement('form');
      form.setAttribute('style', 'visibility: hidden;');

      form.method = 'POST';
      form.action = paymentUrl;

      for (const obj of paymentLink['data']) {
        const elem = document.createElement('input');
        elem.name = obj.key;
        elem.value = obj.value;
        form.appendChild(elem);
      }

      document.body.appendChild(form);

      form.submit();
    });
  }
}
