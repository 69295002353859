import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { Observable } from 'rxjs';
import { Discount } from 'src/app/routes/discounts/state/discount.model';
import { DiscountService } from 'src/app/routes/discounts/state/discount.service';

@Component({
  selector: 'kc-discount-select-dialog',
  templateUrl: './discount-select-dialog.component.html',
  styleUrls: ['./discount-select-dialog.component.scss']
})
export class DiscountSelectDialogComponent implements OnInit {

  eligibleDiscounts$: Observable<any>;

  constructor(@Inject(PORTAL_DATA) public data,
              private discountService: DiscountService) { }

  ngOnInit() {
    this.eligibleDiscounts$ = this.discountService.getEligibleDiscounts(this.data.data.cpId);
  }

  selectDiscount(discount: Discount) {
    this.discountService.setActive(discount._id);
    this.close();
  }

  clearSelection() {
    this.discountService.setActive(null);
    this.close();
  }

  close() {
    this.data.close();
  }

}
