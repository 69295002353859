<div class="overlayContainer__wrapper --scrollable">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <ng-container>
        <div class="contentContainer">
          <div class="overlayContainer__header">
            <span class="title">{{ 'switchDiscount' | translate }}</span>
            <span class="info">{{ 'chooseDiscountInfo' | translate }}</span>
          </div>
          <div class="overlayContainer__contentTemplateLabel">
            <span>{{ 'discounts' | translate | uppercase }}</span>
          </div>
          <ng-container *ngIf="(eligibleDiscounts$ | async) as discounts">
            <ng-container *ngFor="let discount of discounts">
              <kc-discount-item [discount]="discount" [listItem]="true" (click)="selectDiscount(discount)"></kc-discount-item>
            </ng-container>
          </ng-container>
          <div class="clearSelection__container" (click)="clearSelection()">
            <div class="clearSelection__iconAndText">
              <div class="clearSelection__icon">
                <img src="../../../assets/icons/png/icon-crossSmall@3x.png">
              </div>
              <div class="clearSelection__text">
                <span class="title">{{ 'dontUseDiscount' | translate }}</span>
                <span class="subtitle">{{ 'saveDiscountForLater' | translate }}</span>
              </div>
            </div>
            <div class="clearSelection__arrow">
              <img src="../../../assets/icons/svg/chevron-right.svg">
            </div>
          </div>
        </div>
      </ng-container>
      <div class="buttonContainer">
        <button (click)="close()">CLOSE</button>
      </div>
    </div>
  </div>

</div>
