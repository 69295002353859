import { transition, trigger, state, style, animate, AnimationTriggerMetadata } from '@angular/animations';

export function slideRouteContainer(
    upTop = '0px',
    downTop = '0px'
): AnimationTriggerMetadata {
    return trigger('slideRouteContainer', [
        state('down', style({ bottom: `${upTop}`, maxHeight: '80px', minHeight: '80px' })),
        state('up', style({
        bottom: `${downTop}`,
        maxHeight: `calc(50vh + 100px)`,
        minHeight: `calc(50vh + 100px)`,
        height: `calc(50vh + 100px)`
      })),
        transition('* <=> *', animate('200ms linear'))
    ]);
}

export function openTransactions(): AnimationTriggerMetadata {
    return trigger('openTransactions', [
          transition(':enter', [
            style({
              height: '0px',
              opacity: 0
            }),
            animate(200)
          ]),
          transition(':leave', [
            animate(200, style({
              height: '0px',
              opacity: 0
            }))
          ]),
          state('*', style({
            height: '*',
            opacity: 1
          })),
        ]);
    }

