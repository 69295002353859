import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { KIDSessionState } from 'src/app/k-id-session/k-id-session.store';
import { PORTAL_DATA } from '../overlay-dialog.storage';

@Component({
  selector: 'kc-invoicing-agreement-dialog',
  templateUrl: './invoicing-agreement-dialog.component.html',
  styleUrls: ['./invoicing-agreement-dialog.component.scss']
})
export class InvoicingAgreementDialogComponent implements OnInit {

  user$: Observable<KIDSessionState>;

  constructor(
    @Inject(PORTAL_DATA) public data,
    private kIDsessionQuery: KIDSessionQuery
  ) { }

  ngOnInit() {
    this.user$ = this.kIDsessionQuery.user$;
  }

  close() {
    this.data.close();
  }

  formatTime(time: string) {
    if (localStorage.getItem('language') === 'en') {
      return moment(time).format('MM.DD.YYYY');
    } else {
      return moment(time).format('DD.MM.YYYY');
    }
  }

}
