import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Location } from './map.component';

export interface MapCenterState {
    mapCenter: Location | null;
}

export function createInitialState(): MapCenterState {
    return { mapCenter: {
        lat: window.innerWidth >= 1024 ? 66.5 : 61.5,
        lng: 24.5,
        zoom: 5
      } };
}
@Injectable({ providedIn: 'root'})
@StoreConfig({ name: 'map'})
export class MapStore extends Store<MapCenterState> {
    constructor() {
        super(createInitialState());
    }

    updateMapCenter(location: Location) {
        this.update({mapCenter: location});
    }
}
