import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { ChargerService } from 'src/app/charger-service/charger-service.service';
import { ActiveTransactionService, ActiveTransaction } from './active-transaction.service';
import { WebsocketService, ChannelType } from 'src/app/websocket-service/websocket.service';
import { interval, Subject, Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { ChargePointsService } from 'src/app/map/charge-points/charge-points.service';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { takeUntil, filter, first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/loader/loader.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { WindowService } from 'src/app/window-service/window.service';
import { ActiveTransactionHelper } from 'src/app/helpers/active-transaction-helper';
import { GaService } from 'src/app/ga/ga.service';
import { TransactionsService } from '../../transactions/state/transactions.service';
import { environment } from 'src/environments/environment';
import { Transaction } from '../../transactions/state/transaction.model';

@Component({
  selector: 'kc-active-transaction',
  templateUrl: './active-transaction.component.html',
  styleUrls: ['./active-transaction.component.scss']
})
export class ActiveTransactionComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>();
  private socket$ = this.chargeBoxesQuery.activeChargeBox$;
  private chargePoint$ = this.chargePointsQuery.activeChargePoint$;
  private activeTransaction$ = this.activeTransactionService.activeTransaction$;
  activeTransactionStatus$ = new BehaviorSubject<activeTransactionStatus>(activeTransactionStatus.PENDING);

  private activeInfo$ = combineLatest(
    this.socket$,
    this.chargePoint$
  );

  private intervalSubscription: Subscription;

  public socket: ChargeBox;
  private socketId: string;
  public chargePoint: ChargePoint;
  public activeTransaction: ActiveTransaction;
  private loggedIn: boolean;
  private transactionStopped = false;

  public hours = 0;
  public minutes = 0;
  public price = 0;

  constructor(
    private chargerService: ChargerService,
    private activeTransactionService: ActiveTransactionService,
    private websocketService: WebsocketService,
    private cdr: ChangeDetectorRef,
    private chargePointsService: ChargePointsService,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private chargePointsQuery: ChargePointsQuery,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private kIDSessionQuery: KIDSessionQuery,
    public windowService: WindowService,
    private gaService: GaService,
    private transactionsService: TransactionsService
  ) {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.socketId = res.socketId;
      this.chargePointsService.setChargeBoxActive(res['socketId']);
      this.chargePointsService.changeActiveChargePoint(res['id']);
      this.chargePoint$ = this.chargePointsQuery.activeChargePoint$;

      this.activeTransaction$.pipe(first()).subscribe(activeTransactions => {
        this.activeTransactionReceived(activeTransactions);
      });
    });

    this.loaderService.activateSpinner('active-transaction');
  }

  ngOnInit() {
    this.activeInfo$.pipe(
      filter(res => res[0] !== undefined && res[1] !== undefined),
      takeUntil(this.destroyed$)
    ).subscribe(
      res => {
        this.socket = res[0];
        this.socketId = this.socket.id;
        this.chargePoint = res[1];
        this.loaderService.deactivateSpinner('active-transaction');
      }
    );

    this.activeTransaction$.pipe(takeUntil(this.destroyed$)).subscribe(activeTransaction => {
      if (
        (activeTransaction.findIndex(transaction => transaction.chargeBoxId === this.socketId) === -1)
        && !this.transactionStopped
      ) {
        this.loaderService.deactivateSpinner('active-transaction');
        this.router.navigate(['../'], { relativeTo: this.route, skipLocationChange: false });
      }
      this.activeTransactionReceived(activeTransaction);
    });

    this.kIDSessionQuery.isLoggedIn$.subscribe(loggedIn => {
      this.loggedIn = loggedIn;
    });
  }

  activeTransactionReceived(activeTransactions: ActiveTransaction[]) {
    activeTransactions.map((transaction) => {
      if (transaction.chargeBoxId === this.socketId) {
        if (this.activeTransactionStatus$.value !== activeTransactionStatus.ENDED
          && this.activeTransactionStatus$.value !== activeTransactionStatus.ENDING ) {
          this.activeTransaction = transaction;
          this.startPriceTicker(transaction);
          this.activeTransactionStatus$.next(activeTransactionStatus.ACTIVE);
        }
      }
    });
  }

  private handleFailedRoamingStop() {
      this.activeTransactionService.getActiveTransaction();
      this.activeTransactionStatus$.next(activeTransactionStatus.ERROR);
  }

  stopCharging() {
    this.websocketService.isConnected();
    this.activeTransactionStatus$.next(activeTransactionStatus.ENDING);

    this.websocketService.userMessages$.pipe(takeUntil(this.destroyed$)).subscribe((message: Transaction) => {
      if (message.chargeBoxUniqueId.toString() === this.socket.uniqueId.toString() && message.timestampStop) {
        this.gaService.sendChargeEvent('Lataus lopetettu', this.socket.uniqueId, this.loggedIn ? 'K-Tunnus' : 'Kertamaksu');
        if (this.loggedIn) {
          this.activeTransactionStatus$.next(activeTransactionStatus.FETCHING_INFO);
        } else {
          this.activeTransactionStatus$.next(activeTransactionStatus.ENDED);
        }
      }
    });

    if (!this.chargePoint.roaming) {
    this.chargerService.stopChargingTransaction(this.chargePoint.id, this.socket.id).subscribe(
      () => {
        setTimeout(() => {
          this.checkIfActiveTransactions();
        }, 20000);
      }, err => {
        // Check if transaction is still active or just bugged.
        this.checkIfActiveTransactions();
        console.error('stop transaction err:', err);
        this.activeTransactionStatus$.next(activeTransactionStatus.ERROR);
      });
    } else {
      this.chargerService.stopRoamingChargingTransaction(this.socket.EvseID).subscribe(
        res => {
          if (res && res['SessionID']) {
            this.websocketService.createChannel(ChannelType.ROAMING_SESSION,
              `${environment.BACKEND_URL}/roaming/sessions/${res['SessionID']}/socket`);
            this.activeTransactionStatus$.next(activeTransactionStatus.ENDED);
          } else {
            this.handleFailedRoamingStop();
          }
        },
        err => {
          this.handleFailedRoamingStop();
          console.error('stop roaming transaction err:', err);
      });
    }
    this.transactionStopped = true;

  }

  private checkIfActiveTransactions() {
    if (this.activeTransactionService.hasActiveTransactions) {
      this.activeTransactionService.getActiveTransaction();
    }
  }

  startPriceTicker(activeTransaction: ActiveTransaction) {
    this.killOldPriceTicker();
    this.setParsedTimeElapsed(activeTransaction);
    this.price = ActiveTransactionHelper.calculatePrice(
      activeTransaction,
      activeTransaction.transactionPrice ? activeTransaction.transactionPrice.chargingPrice : undefined,
      this.kIDSessionQuery.isLoggedIn(),
      this.price,
    );
    this.intervalSubscription = interval(1000).subscribe(
      () => {
        this.loaderService.deactivateSpinner('active-transaction');
        this.price = ActiveTransactionHelper.calculatePrice(
          activeTransaction,
          activeTransaction.transactionPrice ? activeTransaction.transactionPrice.chargingPrice : undefined,
          this.kIDSessionQuery.isLoggedIn(),
          this.price,
        );
        this.setParsedTimeElapsed(activeTransaction);
        this.cdr.markForCheck();
      }
    );
  }

  setParsedTimeElapsed(activeTransaction) {
    const timeElapsed = ActiveTransactionHelper.formatChargingTime(activeTransaction.timestampStart, activeTransaction.timestampStop);
    this.hours = timeElapsed.hours;
    this.minutes = timeElapsed.minutes;
  }

  killOldPriceTicker() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  goBack() {
    this.router.navigate(['/charge-point', this.chargePoint.id]);
  }

  ngOnDestroy() {
    this.killOldPriceTicker();
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
    this.loaderService.deactivateSpinner('active-transaction');
  }
}

export enum activeTransactionStatus {
  PENDING,
  ACTIVE,
  ENDING,
  ENDED,
  ERROR,
  CDR_ERROR,
  FETCHING_INFO
}
