/**
  * Usage: price | localCurrency:'currency'
  * If no currency is provided '€' will be used.
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { LanguageService } from './language.service';

@Pipe({
    name: 'localCurrency',
})
export class LocalCurrencyPipe implements PipeTransform {

    constructor(private languageService: LanguageService, private currencyPipe: CurrencyPipe) { }

    transform(value: any, currency: string) {
        if (value == null) { return ''; } // !value would also react to zeros.
        if (!currency) { currency = 'EUR'; }

        return this.currencyPipe.transform(value, currency, 'symbol', '1.2', this.languageService.locale);

    }
}
