<div class="container" [ngClass]="selected === value ? '-border' : ''">
  <label>
    <input type="radio"
          [value]="value"
          [(ngModel)] ="modelValue"
          (change)="valueChanged(value)">
    {{_value | uppercase}}
  </label>
</div>

