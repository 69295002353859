import { ActiveTransaction } from '../routes/charge-point/active-transaction/active-transaction.service';
import * as moment from 'moment';
import { ChargingPrice } from '../routes/transactions/state/transaction.model';
export class ActiveTransactionHelper {


  /**
   * Calculates the current price of given transaction.
   * @param activeTransaction current transaction.
   * @param price chargebox price object.
   */
  static calculatePrice(activeTransaction: ActiveTransaction, price: ChargingPrice, isLoggedIn: boolean, originalPrice: number): number {
    if (!price) {
      return originalPrice;
    }
  
    let calculatedPrice = 0;

    /* if (!isLoggedIn) {
      calculatedPrice = 1;
    } */

    if (price && price.active && (price.base || price.base === 0)) {
      const totalEnergyPrice = activeTransaction.energy ? activeTransaction.energy / 1000 * price.energy : 0;
      calculatedPrice = calculatedPrice + totalEnergyPrice + this.calculateTimePrice(price, activeTransaction) + price.base;
    }

    return calculatedPrice;
  }

  /**
   * Modifies time based pricing by checking pricing delay and set time interval.
   * @param price Chargebox price object.
   * @param transaction Active transaction.
   */
  static calculateTimePrice(price: ChargingPrice, transaction: ActiveTransaction): number {
    const freeMinutes = !price.freeMinutes ? 0 : price.freeMinutes;
    const minuteStep = !price.minuteStep ? 0 : price.minuteStep;
    const durationMinutes =  this.getChargingTimeInMinutes(transaction.timestampStart, new Date().toISOString());
    const totalMinutes = durationMinutes - freeMinutes;
    let calculatedPrice = 0;

    if (totalMinutes > 0) {
      if (minuteStep !== 0) {
        // Round up to next minute step
        const units = Math.ceil((totalMinutes / minuteStep));
        calculatedPrice += units * price.duration;
      } else {
        calculatedPrice += totalMinutes * price.duration;
      }
    }
    return calculatedPrice;
  }

  /**
   * Calculates how many minutes has elapsed between start and stop dates.
   * @param timeStart date object.
   * @param timeStop date object.
   */
  private static getChargingTimeInMinutes(timestampStart: string, timestampStop: string): number {
    const timeStart: Date = new Date(timestampStart);
    const timeStop: Date = new Date(timestampStop);
    const ms = moment(timeStop).diff(moment(timeStart));
    return ms / 1000 / 60;
  }

  /**
   * Formats charging time object.
   * @param timeStart date object.
   * @param timeStop date object.
   */
  static formatChargingTime(timestampStart: string, timestampStop: string): ChargingTime {
    const timeStart: Date = new Date(timestampStart);
    const timeStop: Date = timestampStop && timestampStop !== '' ? new Date(timestampStop) : new Date();
    const chargingTime = {
      hours: 0,
      minutes: 0,
      durationInMinutes: 0
    };

    const ms = moment(timeStop).diff(moment(timeStart));
    const d = moment.duration(ms);
    chargingTime.hours = Math.floor(d.asHours());
    chargingTime.minutes = +moment.utc(ms).format('mm');
    chargingTime.durationInMinutes = ms / 1000 / 60;
    return chargingTime;
  }
}

interface ChargingTime {
  hours: number;
  minutes: number;
  durationInMinutes: number;
}
