<div class="overlayContainer__wrapper --scrollable">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="overlayContainer__header">
        <span class="title">{{ 'invoicingAgreement' | translate }}</span>
      </div>
      <div class="imageContainer">
        <img src="../../../assets/images/svg/laskutus.svg" alt="">
      </div>
      <ng-container *ngIf="(user$ | async) as user">
        <div class="infoWrapper">
          <div class="textContainer">
            <span style="font-family: SkattaSansBold;">{{ user.invoicingAgreement.organization.name }} {{
              'invoicingAgreement' | translate }}</span>
            <!-- <span>{{ 'something' }}</span> -->
          </div>
          <div class="textContainer">
            <span>{{ 'contractPeriod' | translate }}</span>
            <span class="text" *ngIf="user.invoicingAgreement.expirationDate; else appliesIndefinitely">{{
              'invoicingAppliesUntil' | translate }} {{ formatTime(user.invoicingAgreement.expirationDate) }} {{
              'discountValidUntil2' | translate }}</span>
          </div>
        </div>


        <div class="supportContainer">
          <img src="../../../assets/icons/png/icon-question.png" alt="">
          <div class="supportInfoContainer">
            <span>{{ 'invoicingSupportInfo' | translate }}</span>
            <span *ngIf="user.invoicingAgreement.organization.customerServicePhone" style="font-family: SkattaSansBold;">{{ user.invoicingAgreement.organization.customerServicePhone }}</span>
            <span *ngIf="user.invoicingAgreement.organization.customerServiceEmail" style="font-family: SkattaSansBold;">{{ user.invoicingAgreement.organization.customerServiceEmail }}</span>
          </div>
        </div>
      </ng-container>
      <div class="buttonContainer">
        <button (click)="close()">
          {{ "close" | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #appliesIndefinitely>
  <span class="text">{{ 'appliesIndefinitely' | translate }}</span>
</ng-template>