import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState, ActiveState, guid } from '@datorama/akita';
import { Discount } from './discount.model';

export interface DiscountState extends EntityState<Discount>, ActiveState { }

@Injectable({ providedIn: 'root' })
@StoreConfig({
    name: 'discount',
    idKey: '_id',
    resettable: true
})
export class DiscountStore extends EntityStore<DiscountState, Discount> {

    constructor() {
        super();
    }
}
