<ng-container *ngIf="transaction$ | async as transaction">
  <div class="routerContainerContent__infoContainer separator clickAble">
    <div class="chargePoint__socketIcon">
      <div class="chargePoint__pic" [ngClass]="setIcon(transaction)"></div>
    </div>
    <div class="routerContainerContent__infoContainerTextContainer flex">
      <span class="title">{{ getChargePointName(transaction) }}</span>
      <span class="text">{{transaction['timestampStart'] | localDate:'dd.MM.yyyy'}}</span>
    </div>
    <div class="routerContainerContent__infoContainerTextContainer -rightAligned">
      <span class="title">{{ transaction.price.total / 100 | localCurrency }}</span>
      <span class="text">{{ transaction['energy'] / 1000 | localNumber:'1.1-1' }} kWh</span>
    </div>
  </div>
</ng-container>


