import { ChargeBox } from './charge-box.model';

const MAX_INACTIVITY_TIME_MILLSECONDS = 3600000;

/**
   * Returns boolean depending if charge box is online or not (calculated from latest activity).
   * @param chargeBox chargebox
   */
export function isChargeBoxOnline(chargeBox: Partial<ChargeBox>): string {
    if (chargeBox.latestActivity && chargeBox.latestActivity.timestamp) {
        if (Date.now() - new Date(chargeBox.latestActivity.timestamp).getTime() <= MAX_INACTIVITY_TIME_MILLSECONDS) {
            return chargeBox.status;
        }
    }
    return 'Faulted';
}

export function formatStatus(status: string): string {
    switch (status) {
        case PlugitStatuses.AVAILABLE:
            return 'Available';
        case PlugitStatuses.CHARGING:
        case PlugitStatuses.PREPARING:
        case PlugitStatuses.FINISHING:
        case PlugitStatuses.SUSPENDEDEV:
        case PlugitStatuses.OCCUPIED:
        case HubjectStatuses.OCCUPIED:
        case HubjectStatuses.RESERVED:
            return 'Occupied';
        case PlugitStatuses.FAULTED:
        case PlugitStatuses.SUSPENDEDEVSE:
        case PlugitStatuses.UNAVAILABLE:
        case HubjectStatuses.OUTOFSERVICE:
            return 'Faulted';
        case HubjectStatuses.UNKNOWN:
        case HubjectStatuses.NOTFOUND:
            return 'Unknown';
        default:
            return 'Occupied';
    }
}

enum HubjectStatuses {
    AVAILABLE = 'Available',
    RESERVED = 'Reserved',
    OCCUPIED = 'Occupied',
    OUTOFSERVICE = 'OutOfService',
    UNKNOWN = 'Unknown',
    NOTFOUND = 'EvseNotFound'
}

enum PlugitStatuses {
    AVAILABLE = 'Available',
    OCCUPIED = 'Occupied',
    CHARGING = 'Charging',
    PREPARING = 'Preparing',
    FINISHING = 'Finishing',
    SUSPENDEDEV = 'SuspendedEV',
    SUSPENDEDEVSE = 'SuspendedEVSE',
    RESERVED = 'Reserved',
    FAULTED = 'Faulted',
    UNAVAILABLE = 'Unavailable'
}

