import { Component, OnInit, Input } from '@angular/core';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { SocketTypes } from 'src/app/map/charge-boxes/charge-box-socket-types';
import { DiscountQuery } from 'src/app/routes/discounts/state/discount.query';
import { DiscountService } from 'src/app/routes/discounts/state/discount.service';
import { Observable } from 'rxjs';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { ActiveTransactionService } from '../../active-transaction/active-transaction.service';

@Component({
  selector: 'kc-socket-discount',
  templateUrl: './socket-discount.component.html',
  styleUrls: ['./socket-discount.component.scss']
})
export class SocketDiscountComponent implements OnInit {

  @Input() socket: ChargeBox;
  activeDiscount$ = this.discountQuery.selectActive();
  eligibleDiscounts$: Observable<any>;
  activeTransactions$ = this.activeTransactionService.activeTransaction$;
  // defaultDiscount$ = this.discountQuery.defaultDiscount$;

  constructor(private discountQuery: DiscountQuery,
              private discountService: DiscountService,
              private overlayDialogService: OverlayDialogService,
              private activeTransactionService: ActiveTransactionService) { }

  ngOnInit() {
    this.eligibleDiscounts$ = this.discountService.getEligibleDiscounts(this.socket.chargePointId);
    this.eligibleDiscounts$.subscribe(res => {
      let activeEligible = false;
      res.forEach(discount => {
        if (discount.defaultDiscount) {
          this.discountService.setActive(discount._id);
          activeEligible = true;
        } else if (discount['_id'] === this.discountQuery.getActiveId()) {
          activeEligible = true;
        }
      });
      if (!activeEligible) {
        this.discountService.setActive(null);
      }
    });
  }

  getEnergyType() {
    if (this.socket.socketType === SocketTypes.TYPE_2) {
      return 'acCharging';
    } else {
      return 'dcCharging';
    }
  }

  sortByBoolean(collection: []) {
    return collection.sort((x, y) => (x['defaultDiscount'] === y['defaultDiscount'] ? 0 : x['defaultDiscount'] ? -1 : 1));
  }

  getLocations(discountId) {
    return this.discountService.getLocations(discountId);
  }

  filterLocations(locations: []) {
    return locations.filter(location => location['chargePointId'] === this.socket.chargePointId);
  }

  selectActive() {
    this.overlayDialogService.openOverlayDialog('DiscountSelectDialogComponent', {cpId: this.socket.chargePointId});
  }
}
