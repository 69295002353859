import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { KChargeProfile, ProfileContactInfo, ProfileAppSettings, PaymentCard, InvoicingAgreement, InvoicingInvitation } from '../k-charge-profile/k-charge-profile.model';

export interface KIDSessionState {
  id: string;
  name: string;
  familyName: string;
  givenName: string;
  email: string;
  contactInfo: ProfileContactInfo;
  tags: Array<object>;
  appSettings: ProfileAppSettings;
  paymentCard?: PaymentCard;
  invoicingAgreement?: InvoicingAgreement;
  invitation?: InvoicingInvitation;
  acceptedTos: boolean;
  isAuthenticating: boolean;
  lockStatus?: LockStatus;
}

export interface LockStatus {
  locked: boolean;
  firstWarning: Date;
  secondWarning: Date;
  lockedTimestamp?: Date;
}

export interface UsersFilters {
  selectedSocketTypes?: string[];
  minPower?: number;
  maxPower?: number;
  futureChargePoints?: boolean;
  roaming?: boolean;
}

export function createInitialState(): KIDSessionState {
  return {
    id: null,
    name: null,
    givenName: null,
    familyName: null,
    contactInfo: {},
    tags: [],
    appSettings: {},
    acceptedTos: false,
    email: null,
    paymentCard: null,
    invoicingAgreement: null,
    isAuthenticating: false,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'k-id-session', resettable: true })
export class KIDSessionStore extends Store<KIDSessionState> {

  constructor() {
    super(createInitialState());
  }

  /**
   * Updates current K ID session K Charge profile data
   * @param kChargeProfile updated K-Charge profile data.
   */
  updateProfile(kChargeProfile: Partial<KChargeProfile>) {
    const update: Partial<KIDSessionState> = {
      ...kChargeProfile,
    };

    if (kChargeProfile.familyName || kChargeProfile.givenName) {
      update.name = `${(kChargeProfile.givenName || '')} ${(kChargeProfile.familyName || '')}`;
    }

    this.update(update);
  }
}
