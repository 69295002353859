import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { ChargePointsService } from 'src/app/map/charge-points/charge-points.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { ActivatedRoute, Router } from '@angular/router';
import { ChargePoint } from 'src/app/map/charge-points/charge-point.model';
import { Price, ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { formatNumber } from '@angular/common';
import { WebsocketService, ChannelType } from 'src/app/websocket-service/websocket.service';
import { LoaderService } from 'src/app/loader/loader.service';
import { map, takeUntil, switchMap, filter } from 'rxjs/operators';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { MapService } from 'src/app/map/state/map.service';
import { ActiveTransactionService } from '../active-transaction/active-transaction.service';
import { Subject, Observable } from 'rxjs';
import * as moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/sv';
import 'moment/locale/en-gb';
import { LANGUAGES } from 'src/assets/i18n/languages.type';
import { LanguageService } from 'src/app/language/language.service';
import { WindowService } from 'src/app/window-service/window.service';
import { GaService } from 'src/app/ga/ga.service';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { ChargerService } from 'src/app/charger-service/charger-service.service';
import { StoreQuery } from 'src/app/k-store/store.query';
import Store from 'src/app/k-store/store.model';
import { keskoServicesByCategory } from 'src/app/k-store/k-store-services';
import { FilterTypes } from 'src/app/top-bar/filter-options/state/filter-option.model';
import { ServiceIconHelper } from 'src/app/helpers/service-icon-helper';

@Component({
  selector: 'kc-charge-point',
  templateUrl: './charge-point.component.html',
  styleUrls: ['./charge-point.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChargePointComponent implements OnInit, OnDestroy {

  private destroyed$ = new Subject<boolean>();
  private chargePointChanged$ = new Subject<boolean>();
  chargePoint$ = this.chargePointsQuery.activeChargePoint$;
  chargeBoxes$: Observable<any>;
  activeSocket$ = this.chargeBoxesQuery.activeChargeBox$;
  isLoggedIn$ = this.kIDSessionQuery.isLoggedIn$;
  activeTransactions$ = this.activeTransactionService.activeTransaction$;

  chargePointLatestTransactions$ = this.chargePoint$.pipe(
    filter(chargePoint => chargePoint && chargePoint.id && !chargePoint.roaming && true),
    // There's a compiler error which forces the last value to be strictly of type boolean
    switchMap(chargePoint => this.chargePointsService.getChargePointLatestTransactions(chargePoint.id)),
    map(latestTransactions => latestTransactions.map(latestTransaction => {
      const entity = this.chargeBoxesQuery.getEntity(latestTransaction.chargeBoxId);
      if (entity) {
        return {
          uniqueId: entity.uniqueId,
          socketType: entity.socketType,
          power: entity.power,
          daysAgo: moment().diff(latestTransaction.timestampStop || latestTransaction.timestampStart, 'days'),
          timeAgo: this.calculateTimeAgo(latestTransaction.timestampStop || latestTransaction.timestampStart),
          timestamp: new Date(latestTransaction.timestampStop || latestTransaction.timestampStart)
        };
      }
      return false;
    })
    .filter(latestTransaction => latestTransaction)
    ),
    map(latestTransactions => latestTransactions.sort((a, b) => b.timestamp - a.timestamp)));

  public chargePoint: ChargePoint;
  public chargeBoxes: ChargeBox[];
  private activeTransactions = [];
  store$: Observable<Store>;

  tabs = ['chargeSockets', 'information'];
  activeTab = this.tabs[0];
  hotlinePhoneNumber = undefined;

  constructor(
    private chargePointsQuery: ChargePointsQuery,
    private kIDSessionQuery: KIDSessionQuery,
    private chargePointsService: ChargePointsService,
    private route: ActivatedRoute,
    private router: Router,
    private websocketService: WebsocketService,
    private loaderService: LoaderService,
    private activeTransactionService: ActiveTransactionService,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private mapService: MapService,
    private languageService: LanguageService,
    public windowService: WindowService,
    private gaService: GaService,
    private overlayDialogService: OverlayDialogService,
    private cdr: ChangeDetectorRef,
    private chargeBoxService: ChargerService,
    private overlayDialog: OverlayDialogService,
    private storeQuery: StoreQuery,
  ) {
    this.route.params.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.websocketService.destroyChannel(ChannelType.CHARGE_POINT);
      this.chargePointsService.changeActiveChargePoint(res['id']);
      this.activeTab = 'chargeSockets';
    });
    this.chargePointsService.setChargeBoxActive(null);
  }

  ngOnInit(): void {

    this.loaderService.activateSpinner('chargePoint');
    this.chargePoint$.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if (res) {
        if (!this.chargePoint || res.id !== this.chargePoint.id) { // To prevent centering multiple times.
          this.mapService.setMapCenterToCoordinates(res['coordinate']);
        }
        this.loaderService.deactivateSpinner('chargePoint');
        this.windowService.setRouteContainerOpenState(true);
        this.chargePoint = res;
        this.chargePointChanged$.next(true);
        this.store$ = this.storeQuery.selectEntity(({chargePointId}) => chargePointId === this.chargePoint.id)
        this.chargeBoxesQuery.selectAll({filterBy: entity => entity.chargePointId === this.chargePoint.id})
        .pipe(takeUntil(this.chargePointChanged$)).subscribe(cbs => {
          if (this.chargePoint.roaming && cbs && cbs.length > 0) {
            this.hotlinePhoneNumber = cbs[0].HotlinePhoneNumber;
          } else {
            this.hotlinePhoneNumber = undefined;
          }
          this.chargeBoxes = cbs;
          this.cdr.markForCheck();
        });
      }
    });

    this.activeTransactions$.pipe(takeUntil(this.destroyed$))
      .subscribe(actives => {
        this.activeTransactions = actives;
      });
  }

  removeWhitespace(text: string): string {
    text = text.toLowerCase();
    return text.replace(/\s/g, '');
  }

  selectSocket(socket: ChargeBox) {
    if (socket.roaming && !this.kIDSessionQuery.isLoggedIn()) {
      this.overlayDialog.openOverlayDialog('LoginRegisterComponent', { 'chargePoint': this.chargePoint, 'chargeBoxId': socket.id });
      return;
    }

    if (this.hasActiveTransaction(socket)) {
      this.router.navigate(['socket', socket.id, 'active'], { relativeTo: this.route });
    } else if (this.socketAvailableForUse(socket)) {
      if (socket.roaming) {
        this.chargeBoxService.getRoamingChargeBox(socket.EvseID).subscribe(res => {
          this.chargeBoxService.updateRoamingChargeBox(res['EvseID'], res);
        }, err => console.log(err));
      }
      this.chargePointsService.setChargeBoxActive(socket.id);
      this.router.navigate(['socket', socket.id], { relativeTo: this.route });
      this.gaService.sendChargeEvent('Latauspiste valittu', socket.uniqueId);
    }
  }

  hasActiveTransaction(socket: ChargeBox) {
    if (socket.status === 'Occupied') {
      if (!socket.roaming) {
        if (this.activeTransactions && this.activeTransactions.find(at => at.transactionId === socket.transactionId)) {
          return true;
        }
      } else {
        if (this.activeTransactions && this.activeTransactions.find(at => at.chargeBoxUniqueId === socket.uniqueId)) {
          return true;
        }
      }
    }
    return false;
  }

  chargeBoxPrice(price?: Price): string {
    let priceString = '';
    if (price.energy > 0) {
      priceString = `${formatNumber(price.energy, this.languageService.locale, '1.2-2')} € / kWh`;
      if (price.duration > 0) {
        priceString += ' + ';
      } else {
        return priceString;
      }
    }
    if (price.duration > 0) {
      priceString += `${formatNumber(price.duration, this.languageService.locale, '1.2-2')} € / min`;
      return priceString;
    }
    priceString = `${formatNumber(0, this.languageService.locale, '1.2-2')} € / min`;
    return priceString;
  }



  calculateTimeAgo(timestampStop: string): string {
    moment.locale(localStorage.getItem('language') ? localStorage.getItem('language').toLowerCase() : LANGUAGES.FI);
    return moment(timestampStop).fromNow();
  }

  ngOnDestroy() {
    this.websocketService.destroyChannel(ChannelType.CHARGE_POINT);
    this.chargePointChanged$.next(true);
    this.chargePointChanged$.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  sortByAvailability(array): Object[] {
    if (array) {
      const ordering = {};
      const sortOrder = ['Available', 'Occupied', 'Unknown', 'Faulted'];
      for (let i = 0; i < sortOrder.length; i++) {
        ordering[sortOrder[i]] = i;
      }
      array = array.slice().sort(function (a, b) {
        return ordering[a.status] - ordering[b.status];
      });
    }
    return array;
  }

  /**
   * @param formattedType charge box socket type
   * @param status charge box status
   */
  getSocketPic(chargeBox: ChargeBox): string {
    if (chargeBox.status === 'Available' || (chargeBox.status === 'Occupied' && !chargeBox.transactionId)) {
      const suffix = !this.chargePoint.roaming ? 'Orange' : 'Blue';
      return `${this.removeWhitespace(chargeBox.socketType)}${suffix}`;
    } else {
      return `${this.removeWhitespace(chargeBox.socketType)}Gray`;
    }
  }

  getServiceIcon(service: string) {
    return ServiceIconHelper.getServiceIcon(service);
  }

  /**
   * Retruns boolean depending if charge box is available for use
   * @param chargeBox chargebox
   */
  socketAvailableForUse(chargeBox: ChargeBox): boolean {
    if (chargeBox.status === 'Available' || (chargeBox.status === 'Occupied' && !chargeBox.transactionId)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Open faulted charging socket report dialog.
   */
  openReportForm() {
    this.overlayDialogService.openOverlayDialog('RoamingSupportContactComponent', { chargePoint: this.chargePoint });
  }

  getGoogleMapsUrl() {
    if (!this.chargePoint) {
      return;
    }
    if (this.chargePoint.roaming) {
      return `https://www.google.com/maps/search/?api=1&query=${this.chargePoint.coordinate.lat},${this.chargePoint.coordinate.lng}`;
    } else {
      return `https://www.google.com/maps/search/?api=1&query=${this.chargePoint.name}`;
    }
  }

  trackByFn(index) {
    return index;
  }

  isFutureChargePoint(chargePoint) {
    return (!chargePoint.operative && moment(chargePoint.installationDate).isAfter(moment()));
  }

  calculateYear(chargePoint) {
    const installationDate = moment(chargePoint.installationDate);
    const installationYear = installationDate.year();

    return installationYear;
  }

  getOpeningHourText(store: Store): string {
    if (store.chargingHoursRestricted) {
      const currentDate = moment();

      // Check if special opening hours apply to current day.
      const specialOpeningHours = store.specialOpeningHours.find((oH) => {
        return currentDate.isBetween(
          moment(oH.validFrom),
          moment(oH.validThrough), 
          'minute'
        );
      });

      if (specialOpeningHours) {
        return this.parseOpeningHourText(specialOpeningHours.opens, specialOpeningHours.closes);
      }
      
      // Format current weekday to the same format as the WeekDay enum used in Store documents.
      const today = currentDate.locale('En').format('dddd');
      const openingHours = store.openingHours.find(oH => oH.dayOfWeek === today);

      if (openingHours) {
        return this.parseOpeningHourText(openingHours.opens, openingHours.closes);
      }
    }

    return '24h';
  }

  private parseOpeningHourText(opens: string, closes: string) {
    if ((!opens || opens === '') && (!closes || closes === '')) {
      return 'chargePointClosed';
    }
    return `${opens} - ${closes}`;
  }

  getAvailableServices(store: Store): string[] {
    const availableServices = [];
    if (store.serviceIds.length > 0) {
      // Leave out the 'other' prop which contains currently unused service ids.
      const {...filterableServices} = keskoServicesByCategory;
      // Iterate service types.
      for (let serviceType in filterableServices) {
        // Iterate service ids of a type.
        for (let serviceId in filterableServices[serviceType]) {
          if (store.serviceIds.includes(serviceId)) {
            availableServices.push(serviceType);
            break;
          }
        }
      }
    }
    return availableServices;
  }
}
