import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { KIDSessionStore, KIDSessionState } from './k-id-session.store';

@Injectable({ providedIn: 'root' })
export class KIDSessionQuery extends Query<KIDSessionState> {

  isLoggedIn$ = this.select((user) => toBoolean(user.id));
  name$ = this.select((user) => user.name);
  user$ = this.select((user) => user);
  userIdentity$ = this.select((user) => ({ 'name': user.name, 'email': user.email }));
  userPaymentCard$ = this.select(user => user.paymentCard);
  userInvoicingAgreement$ = this.select(user => user.invoicingAgreement)
  kChargeProfile$ = this.select(user => ({
    'givenName': user.givenName,
    'familyName': user.familyName,
    'contactInfo': user.contactInfo,
    'appSettings': user.appSettings,
    'tags': user.tags,
    'paymentCard': user.paymentCard,
    'invoicingAgreement': user.invoicingAgreement,
    'invoicingInvitation': user.invitation
  }));

  constructor(protected store: KIDSessionStore) {
    super(store);
  }

  /**
   * Check if user is logged in or not
   */
  isLoggedIn() {
    return toBoolean(this.getValue().name);
  }

  isAuthenticating() {
    return this.getValue().isAuthenticating;
  }

  /**
   * Return logged user email
   */
  getUserId() {
    return this.getValue().id;
  }

  /**
   * Get active K-ID session K-Charge profile
   * Used in K-Charge profile service to get current K-ID session K-Charge profile data
   */
  getKChargeProfile() {
    return {
      'contactInfo': this.getValue().contactInfo,
      'appSettings': this.getValue().appSettings,
      'tags': this.getValue().tags,
      'paymentCard': this.getValue().paymentCard,
    };
  }

  getKIDSession() {
    return this.getValue();
  }
}
