
const COOKIE_CONSENT = 'cookieConsent';
const IONITY_INFO_SEEN = 'ionityInfoSeen';
const NEW_IONITY_INFO_SEEN = 'newIonityInfoSeen';

/**
 * Get cookie consent
 * If not found, then return null as not set state.
 */
export function getCookieConsent(): boolean {
    const cookieConsent = localStorage.getItem(COOKIE_CONSENT);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
}

/**
 * Get ionity info seen
 * If not found, then return null as not set state.
 */
export function getIonityInfoSeen(): boolean {
    const ionityInfoSeen = localStorage.getItem(NEW_IONITY_INFO_SEEN);
    return ionityInfoSeen ? JSON.parse(ionityInfoSeen) : null;
}

/**
 * Set cookie consent
 * @param consent user consent to cookie dialog
 */
export function setCookieConsent(consent: boolean) {
    localStorage.setItem(COOKIE_CONSENT, JSON.stringify(consent));
}

/**
 * Set ionity info seen
 * @param seen user has seen the ionity info dialog
 */
 export function setIonityInfoSeen(seen: boolean) {
     localStorage.setItem(NEW_IONITY_INFO_SEEN, JSON.stringify(seen));
 }
