<ng-container *ngIf="socket$ | async as socket">
  <ng-container *ngIf="chargePoint">
    <div class="socket__headerContainer" (swipeup)="windowService.setRouteContainerOpenState(true)"
    (swipedown)="windowService.setRouteContainerOpenState(false)">
    <div class="iconButton" (click)="goBack()">
      <img src="../../../../assets/icons/png/icon-arrow-l-left@2x.png" alt="">
    </div>
    <span class="title">{{'readyToCharge' | translate}}</span>
    <span class="chargePoint">{{chargePoint.name}}</span>
  </div>
  <ng-container *ngIf="(socketTransactionStatus$ | async) === 0; else startingTransactionTpl">
    <div class="routerContainerContent__container">
      <kc-socket-status [socket]="socket" [socketTransactionStatus]="socketTransactionStatus$.value"></kc-socket-status>

      <!-- TODO: MAKE OWN COMPONENT -->
      <ng-container *ngIf="!socket.roaming">
        <div class="socket__priceContainer" *ngIf="socket.price; else fetchingPricesTpl">
          <kc-socket-price [price]="socket.price" [schedules]="socket.price.schedules"></kc-socket-price>
        </div>
      </ng-container>

      <div class="socket__priceContainer" *ngIf="socket.roaming">
        <div class="labels">
          <span class="price -blue">{{ 'socketTypes' | translate }}:</span>
          <span style="margin-bottom: 5px">{{ getSocketsString() }}</span>
        </div>
      </div>
      <kc-evse-pricing *ngIf="socket.roaming" [socket]="socket"></kc-evse-pricing>

      <!------------- DISCOUNTS ------------->
      <div class="socket__discountContainer" *ngIf="kIDSessionQuery.isLoggedIn() && !socket.roaming">
        <kc-socket-discount [socket]="socket"></kc-socket-discount>
      </div>

      <div class="socket__priceContainer" *ngIf="!(kIDSessionQuery.userInvoicingAgreement$ | async) && loggedIn" style="margin-top: 10px">
        <div class="labels">
          <span>{{'creditCard' | translate}}</span>
        </div>
        <div class="labels">
          <span class="price">{{ paymentCard?.paymentMethodCode | uppercase }}
            {{ (paymentCard && paymentCard.paymentMethodTitle) ? paymentCard.paymentMethodTitle : 'notAdded' |
              translate
              }} </span>
        </div>
      </div>




      <div class="socket__startContainer"
        [ngClass]="(socket['status'].toLowerCase() === 'available' || socket['status'].toLowerCase() === 'preparing' || socket['status'].toLowerCase() === 'sending_command' || (socket['status'].toLowerCase() === 'occupied' && !socket.transactionId)) ? '' : 'disabled'">
        <div *ngIf="!(socket.roaming && !socket.AuthenticationModes.includes('REMOTE'))" id="slider" class="slider"
          [ngClass]="chargePoint.roaming ? '--blue' : ''" cdkDragBoundary=".socket__startContainer" cdkDrag
          (cdkDragStarted)="dragStarted($event)" (cdkDragEnded)="dragEnded($event)">
          <img src="../../../../assets/icons/png/icon-arrow-s-right@2x-white.png" alt="">
        </div>
        <div class="info">
          {{ (!(socket.roaming && !socket.AuthenticationModes.includes('REMOTE')) ? 'startChargingInfo' : 'notUsableViaKLataus') | translate}}
        </div>
        <!-- <div class="activationArea"></div> -->
      </div>
      <!--Logout button on mobile-->
      <button class="routerContainerContent__logoutButton" [ngClass]="chargePoint.roaming ? '--blue' : ''" tabindex="0"
        (click)="openReportForm()" *ngIf="loggedIn">
        <span>{{ (chargePoint.roaming ? 'customerSupport' : 'reportProblem') | translate | uppercase}}</span>
      </button>

      <ng-container *ngIf="!chargePoint.roaming">
        <div class="routerContainerContent__subHeader">
          <span>{{'transactionHistory' | translate | uppercase }}</span>
        </div>
        <ng-container *ngIf="(socketLatestTransactions$ | async) as socketLatestTransactions; else loadingTemplate">
          <ng-container *ngFor="let chargeBox of socketLatestTransactions">
            <div *ngIf="chargeBox.daysAgo <= 7" class="chargePoint__socket">
              <div class="chargePoint__socketIcon">
                <div class="chargePoint__pic"
                  [ngClass]="'-' + (removeWhitespace(chargeBox.socketType) | lowercase) + 'Orange'"></div>
                <div class="chargePoint__uniqueId -orange">{{ chargeBox.uniqueId }}</div>
              </div>
              <div class="chargePoint__socketTitleDiv -enabledItem">
                <div class="chargePoint__socketTitle">{{ chargeBox.socketType | translate }} • {{ chargeBox.power / 1000 |
                  localNumber:'1.0' }} kW</div>
                <div class="chargePoint__socketSubtitle -subtitleAvailable">{{ chargeBox.timeAgo }}</div>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #loadingTemplate>
          <kc-local-loader [message]="'fetchingTransactions'"></kc-local-loader>
        </ng-template>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #startingTransactionTpl>
    <div class="routerContainerContent__container -fitToContent">
      <kc-socket-status [socket]="socket" [socketTransactionStatus]="socketTransactionStatus$.value"></kc-socket-status>

      <ng-container [ngSwitch]="socketTransactionStatus$ | async">
        <ng-container *ngSwitchCase="1">
          <div class="routerContainerContent__transactionMessageContainer">
            <kc-local-loader [message]="'checkingChargingPermission'"></kc-local-loader>
            <span class="message" *ngIf="timeoutTimerTime < 31">{{ 'timeoutIn' | translate}} {{ timeoutTimerTime }}
              {{'timeoutSeconds' | translate}}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <div class="routerContainerContent__transactionMessageContainer">
            <kc-local-loader [message]="'chargingPermissionAccepted'"></kc-local-loader>
            <span class="message" *ngIf="timeoutTimerTime < 31">{{ 'timeoutIn' | translate}} {{ timeoutTimerTime }}
              {{'timeoutSeconds' | translate}}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <div class="routerContainerContent__transactionMessageContainer">
            <span class="message">{{ 'errorOnStartCharge' | translate }}
              {{ 'rejectedByCharger' | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <div class="routerContainerContent__transactionMessageContainer">
            <kc-local-loader [message]="'chargingTransactionStarting'"></kc-local-loader>
            <span class="message" *ngIf="timeoutTimerTime < 31">{{ 'timeoutIn' | translate}} {{ timeoutTimerTime }}
              {{'timeoutSeconds' | translate}}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="6">
          <div class="routerContainerContent__transactionMessageContainer">
            <span class="message">
              {{ 'timeoutInfoText' | translate }}
            </span>
            <div class="overlayContainer__textContainer">
              <div class="infoContainer">
                <img src="../../../../../assets/icons/png/icon-info@2x.png" alt="">
                <span>{{ 'reportProblemInfo' | translate}}</span>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="7">
          <div class="routerContainerContent__transactionMessageContainer">
            <span class="message">{{ 'errorOnStartCharge' | translate }}
              {{ 'unauthorizedRequest' | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="8">
          <div class="routerContainerContent__transactionMessageContainer">
            <span class="message">{{ 'errorOnStartCharge' | translate }}
              {{ 'chargingDeniedUserLocked' | translate }}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="9">
          <div class="routerContainerContent__transactionMessageContainer">
            <kc-local-loader [message]="'sendingCommand'"></kc-local-loader>
            <span class="message" *ngIf="timeoutTimerTime < 31">{{ 'timeoutIn' | translate}} {{ timeoutTimerTime }}
              {{'timeoutSeconds' | translate}}</span>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="10">
          <div class="routerContainerContent__transactionMessageContainer">
            <div class="routerContainerContent__transactionMessageContainer">
              <span class="message">{{ 'errorOnStartCharge' | translate }}
                {{ 'chargingNotAllowed' | translate }}</span>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <div class="routerContainerContent__transactionMessageContainer -noHeight">
            <span class="message">{{ 'errorOnStartCharge' | translate }}</span>
            <div class="overlayContainer__textContainer">
              <div class="infoContainer">
                <img src="../../../../../assets/icons/png/icon-info@2x.png" alt="">
                <span>{{ 'reportProblemInfo' | translate}}</span>
              </div>
            </div>
            <button class="routerContainerContent__logoutButton " [ngClass]="chargePoint.roaming ? '--blue' : ''"
              tabindex="0" (click)="openReportForm()">
              <span>{{ (chargePoint.roaming ? 'customerSupport' : 'reportProblem') | translate | uppercase}}</span>
            </button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
  </ng-container>
</ng-container>

<ng-template #fetchingPricesTpl>
    <kc-local-loader [message]="'loadingPrices'"></kc-local-loader>
</ng-template>
