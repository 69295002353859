import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FilterStore } from "./filter-options.store";
import { FilterOption, FiltersState } from "./filter-option.model";
import { KChargeProfileService } from "src/app/k-charge-profile/k-charge-profile.service";
import { FilterOptionsQuery } from "./filter-options.query";
import { UsersFilters } from "src/app/k-id-session/k-id-session.store";
import { Subject, timer } from "rxjs";
import { debounce } from "rxjs/operators";
import { KIDSessionQuery } from "src/app/k-id-session/k-id-session.query";

const MAX_POWER = 999999999;

@Injectable({ providedIn: "root" })
export class FilterOptionsService {
  private saveFiltersDebounce$ = new Subject();

  constructor(
    private filterStore: FilterStore,
    private filtersQuery: FilterOptionsQuery,
    private http: HttpClient,
    private profileService: KChargeProfileService,
    private kIDSessionQuery: KIDSessionQuery
  ) {
    // Listen to filters updates and save them by using debouncing (to reduce load to the server).
    this.saveFiltersDebounce$
      .pipe(debounce(() => timer(2000)))
      .subscribe((_) => this.saveFiltersToServer());
  }

  updateFilterOption(
    filterOption: Partial<FilterOption>,
    key: string,
    filterOptions: FilterOption[]
  ) {
    filterOptions = filterOptions.map((filter) => {
      const filterCopy = JSON.parse(JSON.stringify(filter));
      if (filterCopy.id === filterOption.id) {
        filterCopy.active = filterOption.active;
        return filterCopy;
      }
      return filterCopy;
    });
    this.filterStore.update({ [key]: filterOptions });
    this.saveFiltersDebounce$.next(true); // Trigger save event.
  }

  updateFilterOptions(filterOptions: FilterOption[], key: string) {
    this.filterStore.update({ [key]: filterOptions });
    this.saveFiltersDebounce$.next(true); // Trigger save event.
  }

  /**
   * Saves updated filter option to the server. Parses it into more compact form.
   * @param key of filter.
   * @param filterOptions filter options array.
   * @param filterOption updated filter option.
   */
  private saveFiltersToServer() {
    if (this.kIDSessionQuery.isLoggedIn()) {
      // Only save if the user is logged in.
      const usersFilters: UsersFilters = {};
      const filters: FiltersState = this.filtersQuery.getValue();
      // Parse filters into UsersFilters for backend.
      for (const key of Object.keys(filters)) {
        const filterOption =
          filters[key].length === 1 ? filters[key][0] : filters[key];
        // Get active socket types.
        if (key === "socketType") {
          const selectedSocketTypes = filterOption.reduce((res, option) => {
            if (option.active) {
              res.push(option.type);
            }
            return res;
          }, []);
          usersFilters.selectedSocketTypes = selectedSocketTypes;
          // Get min and max power from selected power range.
        } else if (key === "power") {
          let maxPowerTag;
          const powerRange: number[] = filterOption
            .reduce((result, option: FilterOption) => {
              if (option.active) {
                result.push(option.type);
              }
              if (option.type == MAX_POWER) {
                maxPowerTag = option.tag;
              }
              return result;
            }, [])
            .sort((a: number, b: number) => a - b);
          let minMaxIdentical = powerRange[0] == MAX_POWER;
          const minPower = minMaxIdentical ? maxPowerTag : powerRange[0];
          const maxPower = powerRange[powerRange.length - 1];
          usersFilters.minPower = minPower;
          usersFilters.maxPower = maxPower;
        } else if (key === "roaming") {
          usersFilters.roaming = filterOption.active;
        } else if (key === "futureChargePoints") {
          usersFilters.futureChargePoints = filterOption.active;
        }
      }
      this.profileService
        .updateProfile({ appSettings: { filters: usersFilters } })
        .subscribe();
    }
  }

  /**
   * Updates entire filter state.
   * @param filterState updated state.
   */
  updateFilterState(filterState: FiltersState) {
    this.filterStore.update(filterState);
  }
}
