import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { StoreStore, StoreState } from './store.store';
import Store from './store.model';
import { filter } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StoreQuery extends QueryEntity<StoreState> {

  stores$ = this.selectAll();

  constructor(protected store: StoreStore) {
    super(store);
  }

  getByChargePoint(cpId: string): Observable<Store> {
    return this.selectEntity(({chargePointId}) => chargePointId === cpId);
  }
}
