import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class InvoiceTransactionService {
    constructor(private http: HttpClient) {}

    getInvoiceTransaction(id, isRoaming) {
        let body;
        if (isRoaming) {
            body = { sessionId: id }
        } else {
            body = { transactionId: id }
        }
        return this.http.post<any>(`${environment.BACKEND_URL}/invoice-transactions`, body);
    }
}
