import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, Injectable } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpClientJsonpModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { TopBarModule } from './top-bar/top-bar.module';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MapComponent } from './map/map.component';
import { AppRoutingModule } from './app-routing.module';
import { ProfileComponent } from './routes/profile/profile.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ChargePointComponent } from './routes/charge-point/charge-point/charge-point.component';
import { EditUserComponent } from './routes/profile/edit-user/edit-user.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EditAppSettingsComponent } from './routes/profile/edit-app-settings/edit-app-settings.component';
import { ManageTagsComponent } from './routes/profile/manage-tags/manage-tags.component';
import { SupportContactComponent } from './routes/profile/support-contact/support-contact.component';
import { TransactionsComponent } from './routes/transactions/transactions.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { TransactionSummaryComponent } from './routes/transactions/transaction-summary/transaction-summary.component';
import { registerLocaleData } from '@angular/common';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv-FI';
import localeEn from '@angular/common/locales/en-GB';
import { SocketComponent } from './routes/charge-point/socket/socket.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LoginRegisterComponent } from './routes/charge-point/socket/login-register/login-register.component';
import { ReportFaultedSocketComponent } from './routes/charge-point/socket/report-faulted-socket/report-faulted-socket.component';
import { ErrorDialogComponent } from './overlay-dialog/error-dialog/error-dialog.component';
import { TosDialogComponent } from './overlay-dialog/tos-dialog/tos-dialog.component';
import { LoaderComponent } from './loader/loader.component';
import { ActiveTransactionComponent } from './routes/charge-point/active-transaction/active-transaction.component';
import { ErrorsHandler } from './errors-handler/errors-handler';
import { ServerErrorsInterceptor } from './errors-handler/server-errors.interceptor';
import * as moment from 'moment';
import { UpdateDialogService } from './update-dialog/update-dialog.service';
import { UpdateDialogModule } from './update-dialog/update-dialog.module';
import { CookieConsentComponent } from './overlay-dialog/cookie-consent/cookie-consent.component';
import { LocalLoaderComponent } from './loader/local-loader/local-loader.component';
import { LocalPipesModule } from './language/local-pipes.module';
import { DeletePaymentCardComponent } from './routes/profile/delete-payment-card/delete-payment-card.component';
import { SortAlphabeticallyPipe } from './map/sort-alphabetically.pipe';
import { SocialLoginErrorComponent } from './overlay-dialog/social-login-error/social-login-error.component';
import { PaymentInfoDialogComponent } from './overlay-dialog/payment-info-dialog/payment-info-dialog.component';
import { CantRemovePaymentCardComponent } from './routes/profile/cant-remove-payment-card/cant-remove-payment-card.component';
import { RoamingSupportContactComponent } from './roaming/roaming-support-contact/roaming-support-contact.component';
import { TransactionsFilterPipe } from './routes/transactions/transactions-filter.pipe';
import { SessionExpiredDialogComponent } from './overlay-dialog/session-expired-dialog/session-expired-dialog.component';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { InvoiceComponent } from './routes/invoice/invoice.component';
import { NewTosDialogComponent } from './overlay-dialog/new-tos-dialog/new-tos-dialog.component';
import { PaymentReminderDialogComponent } from './overlay-dialog/payment-reminder-dialog/payment-reminder-dialog.component';
import { EvsePricingComponent } from './routes/charge-point/socket/evse-pricing/evse-pricing.component';
// tslint:disable-next-line:max-line-length
import { TransactionsChargingTransactionComponent } from './routes/transactions/monthly-transactions/transactions-charging-transaction/transactions-charging-transaction.component';
import { InvoiceBalanceComponent } from './routes/invoice/invoice-balance/invoice-balance.component';
import { PaymentSucceededDialogComponent } from './overlay-dialog/payment-succeeded-dialog/payment-succeeded-dialog.component';
import { MaintenanceDialogComponent } from './overlay-dialog/maintenance-dialog/maintenance-dialog.component';
import { ChargePointStatusesComponent } from './map/charge-point-statuses/charge-point-statuses.component';
import { SocketStatusComponent } from './routes/charge-point/socket/socket-status/socket-status.component';
import { ChargePointChargerModule } from './routes/charge-point/charge-point-charger/charge-point-charger.module';
import { MonthlyTransactionsComponent } from './routes/transactions/monthly-transactions/monthly-transactions.component';
import { TransactionsInvoiceComponent } from './routes/transactions/monthly-transactions/transactions-invoice/transactions-invoice.component';
import { InvoiceTransactionComponent } from './routes/invoice/invoice-transaction/invoice-transaction.component';
import { IonityDialogComponent } from './overlay-dialog/ionity-dialog/ionity-dialog.component';
import { FormatSocketTypeNamePipe } from './routes/charge-point/format-socket-type-name.pipe';
import { UpdatedPricingDialogComponent } from './overlay-dialog/updated-pricing-dialog/updated-pricing-dialog.component';
import { CustomTranslateLoader } from './custom-translate-loader';
import { UpdatedPricingReminderDialogComponent } from './overlay-dialog/updated-pricing-reminder-dialog/updated-pricing-reminder-dialog.component';
import { DialogButtonComponent } from './overlay-dialog/dialog-button/dialog-button.component';
import { PricingUpdatedDialogComponent } from './overlay-dialog/pricing-updated-dialog/pricing-updated-dialog.component';
import { AccountLockedDialogComponent } from './overlay-dialog/account-locked-dialog/account-locked-dialog.component';
import { SocketPriceComponent } from './routes/charge-point/socket/socket-price/socket-price.component';
import { SocketPriceItemComponent } from './routes/charge-point/socket/socket-price/socket-price-item/socket-price-item.component';
import { DiscountComponent } from './routes/discounts/discount/discount.component';
import { DiscountItemComponent } from './routes/discounts/discount-item/discount-item.component';
import { DiscountDialogComponent } from './overlay-dialog/discount-dialog/discount-dialog.component';
import { ProgressBarComponent } from './routes/discounts/progress-bar/progress-bar.component';
import { AddDiscountDialogComponent } from './overlay-dialog/add-discount-dialog/add-discount-dialog.component';
import { SocketDiscountComponent } from './routes/charge-point/socket/socket-discount/socket-discount.component';
import { DiscountSelectDialogComponent } from './overlay-dialog/discount-select-dialog/discount-select-dialog.component';
import { InvoicingAgreementDialogComponent } from './overlay-dialog/invoicing-agreement-dialog/invoicing-agreement-dialog.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { KIDSessionService } from './k-id-session/k-id-session.service';
import { AuthSessionService } from './auth/auth-session.service';
import { AuthSessionQuery } from './auth/auth-session.query';
import { AppUpdateDialogComponent } from './overlay-dialog/app-update-dialog/app-update-dialog.component';
import { TransactionsOverviewComponent } from './routes/transactions/transactions-overview/transactions-overview.component';
import { TransactionsYearSelectorComponent } from './routes/transactions/transactions-year-selector/transactions-year-selector.component';
import { InvoicingAgreementInvoiceComponent } from './routes/invoicing-agreement-invoice/invoicing-agreement-invoice.component';
import { FeedbackDialogComponent } from './overlay-dialog/feedback-dialog/feedback-dialog.component';
import { AttentionBoxComponent } from './shared/attention-box/attention-box.component';
import { GoogleMap, GoogleMapsModule } from '@angular/google-maps';
import { LanguageSelectionButtonComponent } from './routes/profile/language-selection-button/language-selection-button.component';
import { InvitationDialogComponent } from './overlay-dialog/invitation-dialog/invitation-dialog.component';
import { ScheduleItemComponent } from './routes/charge-point/socket/socket-price/schedule-item/schedule-item.component';
import { PricingDialogComponent } from './overlay-dialog/pricing-dialog/pricing-dialog.component';
import { ChargePointAutoFillPipe } from './top-bar/search-bar/charge-point-auto-fill.pipe';
import { OrderTagDialogComponent } from './routes/profile/order-tag-dialog/order-tag-dialog.component';
import { StoreService } from './k-store/store.service';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'swipe': { direction: Hammer.DIRECTION_ALL },
    'pinch': { enable: false },
    'rotate': { enable: false }
  };
}

switch (localStorage.getItem('language')) {
  case 'fi': {
    registerLocaleData(localeFi, 'fi');
    moment.locale('fi');
    break;
  }
  case 'sv': {
    registerLocaleData(localeSv, 'sv');
    moment.locale('sv');
    break;
  }
  case 'en':
  default: {
    registerLocaleData(localeEn, 'en');
    moment.locale('en');
    break;
  }
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
    declarations: [
        AppComponent,
        MapComponent,
        ProfileComponent,
        EditUserComponent,
        ChargePointComponent,
        EditAppSettingsComponent,
        ManageTagsComponent,
        SupportContactComponent,
        TransactionsComponent,
        NavigationBarComponent,
        TransactionSummaryComponent,
        SocketComponent,
        LoginRegisterComponent,
        ReportFaultedSocketComponent,
        ErrorDialogComponent,
        ActiveTransactionComponent,
        TosDialogComponent,
        LoaderComponent,
        CookieConsentComponent,
        LocalLoaderComponent,
        DeletePaymentCardComponent,
        SortAlphabeticallyPipe,
        SocialLoginErrorComponent,
        PaymentInfoDialogComponent,
        CantRemovePaymentCardComponent,
        TransactionsFilterPipe,
        SessionExpiredDialogComponent,
        RoamingSupportContactComponent,
        InvoiceComponent,
        NewTosDialogComponent,
        PaymentReminderDialogComponent,
        EvsePricingComponent,
        TransactionsChargingTransactionComponent,
        InvoiceBalanceComponent,
        PaymentSucceededDialogComponent,
        MaintenanceDialogComponent,
        ChargePointStatusesComponent,
        SocketStatusComponent,
        MonthlyTransactionsComponent,
        TransactionsInvoiceComponent,
        InvoiceTransactionComponent,
        IonityDialogComponent,
        FormatSocketTypeNamePipe,
        UpdatedPricingDialogComponent,
        UpdatedPricingReminderDialogComponent,
        SocketStatusComponent,
        DialogButtonComponent,
        AccountLockedDialogComponent,
        PricingUpdatedDialogComponent,
        AccountLockedDialogComponent,
        PricingUpdatedDialogComponent,
        SocketPriceComponent,
        SocketPriceItemComponent,
        DiscountComponent,
        DiscountItemComponent,
        DiscountDialogComponent,
        ProgressBarComponent,
        AddDiscountDialogComponent,
        SocketDiscountComponent,
        DiscountSelectDialogComponent,
        InvoicingAgreementDialogComponent,
        AppUpdateDialogComponent,
        TransactionsOverviewComponent,
        TransactionsYearSelectorComponent,
        InvoicingAgreementInvoiceComponent,
        FeedbackDialogComponent,
        AttentionBoxComponent,
        LanguageSelectionButtonComponent,
        InvitationDialogComponent,
        ScheduleItemComponent,
        PricingDialogComponent,
        ChargePointAutoFillPipe,
        OrderTagDialogComponent
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TopBarModule,
        ChargePointChargerModule,
        // AgmOverlays,
        // AgmCoreModule.forRoot({ apiKey: environment.GOOGLE.MAPS_API_KEY }),
        BrowserAnimationsModule,
        AppRoutingModule,
        OverlayModule,
        ReactiveFormsModule,
        FormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: CustomTranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        // AgmJsMarkerClustererModule,
        DragDropModule,
        UpdateDialogModule,
        LocalPipesModule,
        AkitaNgDevtools.forRoot(),
        GoogleMapsModule,
        HttpClientJsonpModule,
    ],
    providers: [
        FormatSocketTypeNamePipe,
        UpdateDialogService,
        KIDSessionService,
        AuthSessionService,
        StoreService,
        AuthSessionQuery,
        { provide: 'Window', useValue: window },
        { provide: LOCALE_ID, useValue: 'fi' },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig
        },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerErrorsInterceptor,
            multi: true,
        },
        GoogleMap,
        ChargePointAutoFillPipe,
    ],
    exports: [
        AppRoutingModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
