export class TransactionSocketIconHelper {
    static setIcon(socketType: string, isRoaming: boolean) {
        let socketTypeStyle = '';
        let _socketType = socketType.toLowerCase();
        _socketType = _socketType.replace(/\s/g, '');

        switch (_socketType) {
            case 'type2':
                socketTypeStyle = '-type2';
                break;

            case 'chademo':
                socketTypeStyle = '-chademo';
                break;

            case 'ccs2':
                socketTypeStyle = '-ccs2';
                break;
            default:
                socketTypeStyle = '-default';
        }

        if (!isRoaming) {
            socketTypeStyle += 'Orange';
        } else {
            socketTypeStyle += 'Blue';
        }

        return socketTypeStyle;
    }
}
