<div class="filterOption__container" [ngClass]="portraitIcon ? '--portrait' : ''">
  <ng-container *ngIf="filterOption.icon">
    <img src="{{filterOption.icon}}" alt="">
  </ng-container>
  <div class="filterOption__titleContainer">
    <span class="filterOption__label" *ngIf="showLabel">{{filterOption.type | translate}}</span>
    <span class="filterOption__label --small" *ngIf="filterOption.description"> {{filterOption.description | translate}}</span>
  </div>
  <div class="switch" tabindex="0" [ngClass]="{'on': filterOption.active, '--blue' : filterOption.description === 'thirdPartyStations' || filterOption.description === 'notUsableViaKLataus'}"
  (click)="change.emit({ id: filterOption.id, active: !filterOption.active })"
  (swipeleft)="change.emit({ id: filterOption.id, active: false })" (swiperight)="change.emit({ id: filterOption.id, active: true })"></div>
</div>