import { Component, OnInit, Input } from '@angular/core';
import { TransactionSocketIconHelper } from '../../../../helpers/transaction-socket-icon-helper';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';
import { InvoiceQuery } from '../../../invoice/invoice.query';
import { Transaction } from '../../state/transaction.model';
@Component({
  selector: 'kc-transactions-charging-transaction',
  templateUrl: './transactions-charging-transaction.component.html',
  styleUrls: ['./transactions-charging-transaction.component.scss']
})
export class TransactionsChargingTransactionComponent implements OnInit {

  @Input() set transaction(_chargingTransaction: Transaction) {
    this.chargingTransaction  = _chargingTransaction;
  }

  chargingTransaction: Transaction;
  isUnpaid: boolean;

  constructor(private overlayDialogService: OverlayDialogService, private invoiceQuery: InvoiceQuery) { }

  ngOnInit() {
    this.isUnpaid = this.invoiceQuery.isTransactionUnpaid(this.chargingTransaction);
  }

  setIcon(transaction: Transaction) {
    const socketTypeStyle = TransactionSocketIconHelper.setIcon('default', transaction.roaming);
    return socketTypeStyle;
  }

    /**
     * Open selected component as overlay dialog
     */
    openOverlayDialog(transaction) {
      const componentRef = this.overlayDialogService.openOverlayDialog('TransactionSummaryComponent', { transaction, transactionStop: false });
      if(componentRef && componentRef.instance.openFeedbackEvent) {
        componentRef.instance.openFeedbackEvent.subscribe(() => {
          this.overlayDialogService.openOverlayDialog('FeedbackDialogComponent', { transaction });
        })
      }
    }

    getReducedPrice(price: number) {
      if (this.transaction.transactionPrice.discount) {
        return price - this.getAmountDeducted(price, this.transaction.transactionPrice.discount.discountPercentage);
      } else {
        return price;
      }
    }

    getAmountDeducted(price: number, percentage: number) {
      return (percentage / 100) * price;
    }

}
