import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Price } from 'src/app/map/charge-boxes/charge-box.model';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { OverlayDialogService } from 'src/app/overlay-dialog/overlay-dialog.service';

@Component({
  selector: 'kc-socket-price',
  templateUrl: './socket-price.component.html',
  styleUrls: ['./socket-price.component.scss']
})
export class SocketPriceComponent implements OnInit {

  // Chargebox price object
  @Input() price: Price;
  @Input() schedules?: Price[];
  isLoggedIn: boolean;

  constructor(private translate: TranslateService,
    private kidSessionQuery: KIDSessionQuery,
    private overlayDialogService: OverlayDialogService) { }

  ngOnInit() {
    this.isLoggedIn = this.kidSessionQuery.isLoggedIn();
  }

  getTimePriceTitle(): string {
    let text: string;
    if (this.price.freeMinutes) {
      const formattedTime = this.getFormattedTime(this.price.freeMinutes);
      const titlePart1 = this.translate.instant('priceTitlePart1');
      const titlePart2 = this.translate.instant('priceTitlePart2');
      let units: string;
      if (formattedTime.value === 1) {
        units = formattedTime.unit === 'hours' ? this.translate.instant('titleHour') : this.translate.instant('titleMinute');
      } else {
        units = formattedTime.unit === 'hours' ? this.translate.instant('titleHours') : this.translate.instant('titleMinutes');
      }
      text = `${titlePart1} ${formattedTime.value} ${units} ${titlePart2}`;
    } else if (!this.price.energy) {
      text = this.translate.instant('price');
    } else {
      text = this.translate.instant('timeBasedAdditionalFee');
    }
    return text;
  }

  getTimePriceSubtitle(): string {
    let text: string;

    if (this.price.freeMinutes) {
      const formattedTime = this.getFormattedTime(this.price.freeMinutes);
      let units: string;
      if (formattedTime.value === 1) {
        units = formattedTime.unit === 'hours' ? this.translate.instant('subtitleHour') : this.translate.instant('subtitleMinute');
      } else {
        units = formattedTime.unit === 'hours' ? this.translate.instant('subtitleHours') : this.translate.instant('subtitleMinutes');
      }
      text = `${this.translate.instant('doesNotApply')} ${formattedTime.value > 1 ? formattedTime.value : ''} ${units}`;
    } else {
      text = this.translate.instant('appliesUntil');
    }
    return text;
  }

  getTimePricePrinciple(): string {
    let text: string;
    if (this.price.minuteStep) {
      const formattedTime = this.getFormattedTime(this.price.minuteStep);
      if (formattedTime.value === 1) {
        text = formattedTime.unit === 'hours' ? this.translate.instant('startingHour') : this.translate.instant('startingMinute');
      } else {
        text = `${this.translate.instant('starting')} ${formattedTime.value} ${formattedTime.unit === 'hours' ? 'h' : 'min'}`;
      }
    } else {
      text = 'min';
    }

    return text;
  }


  getFormattedTime(duration: number): {value: number, unit: string} {
    let time = { value: 0, unit: 'minutes'};
    if (duration % 60 === 0) {
      time = { value: duration / 60, unit: 'hours'};
    } else {
      time = { value: duration, unit: 'minutes'};
    }
    return time;
  }

  getOtherSchedule() {
    // Find the next schedule.
    let schedule = this.schedules.find(s => s.from == this.price.to);

    if (!schedule) {
      schedule = this.schedules.find(schedule => {
        return schedule.from !== this.price.from;
      });
    }

    return schedule;
  }

  openPricingDialog() {
    this.overlayDialogService.openOverlayDialog('PricingDialogComponent', this.schedules);
  }

}
