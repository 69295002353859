import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoaderQuery } from './loader.query';
import { Observable } from 'rxjs';
import { Loader } from './loader.model';
@Component({
  selector: 'kc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  public environment = environment;
  public activeLoaders$: Observable<Loader[]>;
  constructor(private loaderQuery: LoaderQuery) {}

  ngOnInit() {
    this.activeLoaders$ = this.loaderQuery.activeLoaders$;
  }

}
