<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <img src="../../../assets/icons/png/icon-info@3x.png" alt="info">
        <span class="title">{{ 'accountLockedHeader1' | translate }}</span>
        <span class="title">{{ 'accountLockedHeader2' | translate }}</span>
        <div class="divider"></div>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <span class="infoText">{{ 'accountLockedInfo1' | translate }}</span>
        <span class="infoText">{{ 'accountLockedInfo2' | translate }}</span>

        <div class="divider"></div>
    
        <span class="phoneNumber">{{ 'supportCallNumber' | translate }}</span>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'logout'" (onClick)="logout()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>