<div>
  <span class="label" *ngIf="minValueLabel !== maxValueLabel">{{ minValueLabel }} - </span>
  <span class="label">{{ maxValueLabel }}{{ isMaxValue ? '+' : '' }} kW</span>
</div>
<div class="custom-slider">
  <ngx-slider [(value)]="value"
  [(highValue)]="highValue"
  [options]="options"
  (userChangeEnd)="onUserChangeEnd($event, filterOptions)"
  [manualRefresh]="manualRefresh"></ngx-slider>
</div>
