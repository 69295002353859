import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'kc-dialog-button',
  templateUrl: './dialog-button.component.html',
  styleUrls: ['./dialog-button.component.scss']
})
export class DialogButtonComponent implements OnInit {

  @Input() buttonText: string;
  @Input() color: string;
  @Input() disabled: boolean;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onButtonClick(event) {
    this.onClick.emit(event);
  }

}
