import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { Observable } from 'rxjs';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { environment } from 'src/environments/environment';
import * as newTosStorage from '../new-tos-dialog/new-tos-dialog.storage';
@Component({
  selector: 'kc-tos-dialog',
  templateUrl: './tos-dialog.component.html',
  styleUrls: ['./tos-dialog.component.scss']
})
export class TosDialogComponent implements OnInit {

  firstName: string;
  tosForm: UntypedFormGroup;
  environment = environment;

  constructor(
    @Inject(PORTAL_DATA) public data,
    private kIDSessionQuery: KIDSessionQuery,
    public formBuilder: UntypedFormBuilder,
    private kChargeProfileService: KChargeProfileService
  ) { }

  ngOnInit() {
    this.kIDSessionQuery.name$.subscribe(name => {
      if (name) {
        this.firstName = name.split(' ')[0];
      } else {
        this.firstName = 'K-Lataaja';
      }
    });

    this.tosForm = this.formBuilder.group({
      acceptedTos: [false, [Validators.requiredTrue]],
      marketing: [false]
    });
  }

  /**
  * Change switch state (changes scss styling)
  */
  agreement(field) {
    return this.tosForm.get(field).value;
  }
  /**
   * Change marking agreement value
   */
  changeAgreement(field) {
    this.tosForm.get(field).setValue(!this.tosForm.get(field).value);
  }

  changeAgreementSwipe(field, value) {
    this.tosForm.get(field).setValue(value);
  }


  /**
   * Close edit app settings overlay dialog
   */
  continue() {

    this.kChargeProfileService.updateProfile(
      {
        'acceptedTos': this.tosForm.get('acceptedTos').value,
        'contactInfo': { 'marketing': this.tosForm.get('marketing').value }
      }
    ).subscribe(response => {
      // Add this 30.8 -> Remove 14.9
      newTosStorage.setServiceAgreement(true);
      this.data.close();
    },
      err => {
        this.data.openNewOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
        'source': { 'component': 'tosDialogComponent', 'function': 'updateProfile'}});
        throw err;
      });
  }

  logout() {
    this.kChargeProfileService.logout();
    this.data.close();
  }
}
