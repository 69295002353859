import { forwardRef } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kc-language-selection-button',
  templateUrl: './language-selection-button.component.html',
  styleUrls: ['./language-selection-button.component.scss'],
  providers: [
    {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => LanguageSelectionButtonComponent),
        multi: true
    }
],
})
export class LanguageSelectionButtonComponent implements ControlValueAccessor {

  @Input() selected: string;
  @Input('value') _value;

  modelValue: any;

  get value() {
    return this._value;
  }

  set value(value) {
    
    if(!!value){
      this._value = value; 
      this.onChange(value);
      this.onTouched();
    }
  }

  onChange: any = (onchanges => {
  });

  onTouched: any = () => {
  };

  registerOnChange(fn) {
   this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.modelValue = value;
  }

  valueChanged(event){
    this.onChange(this.modelValue);
  }

}
