  <div class="searchBar__container">
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="searchBar__form">
      <p (click)="setInputActive()"
        *ngIf="!(showSearchSuggestions$ | async) && (searchForm.get('search').value === '' || searchForm.get('search').value === null)"
        class="placeholder">{{ searchWords[selectedSearchWord] | translate }}</p>
      <input #input type="search" formControlName="search" (blur)="onBlur()" (focus)="onFocus()">
    </form>
  </div>

  <div (kcClickOutside)="clickedOutside()" [parentElement]="inputElement" id="suggestions"
    class="searchBar__autoSuggestionWrapper"
    *ngIf="possibleChargePoints?.length > 0 && (showSearchSuggestions$ | async)">
    <div class="searchBar__autoSuggestionContainer">
      <div class="chargePoint" *ngFor="let chargePoint of possibleChargePoints"
        (click)="selectChargePoint(chargePoint)">
        <div class="info">
          <span class="name">{{chargePoint.name}}</span>
          <div class="streetAddress">
            <span>{{chargePoint.streetAddress}},</span>
            <span>{{chargePoint.city}}</span>
            <span>{{chargePoint.postalCode}}</span>
          </div>
        </div>
        <div class="socket" *ngIf="uniqueIdMatches(chargePoint) as foundChargeBox">
          <div class="chargePoint__socketIcon">
            <div class="chargePoint__pic"
              [ngClass]="'-' + (getSocketPic(removeWhitespace(foundChargeBox.socketType) | lowercase, foundChargeBox.status))">
            </div>
            <div class="chargePoint__uniqueId"
              [ngClass]="(foundChargeBox.status === 'Available') ? '-orange' : '-gray'">{{
                foundChargeBox.uniqueId }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
