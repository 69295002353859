import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'kc-transactions-year-selector',
  templateUrl: './transactions-year-selector.component.html',
  styleUrls: ['./transactions-year-selector.component.scss']
})
export class TransactionsYearSelectorComponent implements OnInit {
  @Output() yearSelect = new EventEmitter<number>();
  @ViewChild('slideContainer') slideContainer;
  selectedYear: number;
  availableYears: number[] = [];
  // Earliest year available for the user to look for transactions from.
  startingYear = 2018;

  selectYear(year: number) {
    this.selectedYear = year;
    this.yearSelect.emit(year);
  }

  ngOnInit() {
    let currentYear = new Date().getFullYear();
    for(let i = currentYear; i >= this.startingYear; i--) {
      this.availableYears.push(i);
    }
    this.selectYear(currentYear);
  }

  onWheel(event: WheelEvent): void {
    this.slideContainer.nativeElement.scrollLeft += event.deltaY;
    event.preventDefault();
  }
}