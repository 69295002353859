<div class="wrapper" [ngClass]="active ? '-active' : ''">
  <div class="iconContainer">
    <i [ngClass]="getTimeOfDayIcon(price)"></i>
  </div>
  <ng-container *ngIf="price">
    <div class="textContainer">
      <span>{{ price.from + ' - ' + price.to }}</span>
      <span *ngIf="active"> {{ 'active' | translate }}</span>
    </div>
  </ng-container>
</div>
