import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Price } from 'src/app/map/charge-boxes/charge-box.model';

@Component({
  selector: 'kc-schedule-item',
  templateUrl: './schedule-item.component.html',
  styleUrls: ['./schedule-item.component.scss']
})
export class ScheduleItemComponent implements OnInit {

  @Input() active: boolean;
  @Input() price: Price;

  constructor() { }

  ngOnInit() {
  }

  getTimeOfDayIcon (price: Price) {
    let startHour = moment(price.from, 'HH.mm').hour();
    let endHour = moment(price.to, 'HH.mm').hour();

    if(startHour > endHour) {
      return 'fa-regular fa-moon'
    } else {
      return 'fa-regular fa-sun'
    }
  }
}
