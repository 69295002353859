import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopBarComponent } from './top-bar.component';
import { SearchBarModule } from './search-bar/search-bar.module';
import { FilterOptionsModule } from './filter-options/filter-options.module';
import { TranslateModule } from '@ngx-translate/core';
import { LocalPipesModule } from '../language/local-pipes.module';
import { ActiveTransactionContainerComponent } from './active-transaction-container/active-transaction-container.component';
import { ChargePointChargerModule } from '../routes/charge-point/charge-point-charger/charge-point-charger.module';

@NgModule({
  declarations: [
    TopBarComponent,
    ActiveTransactionContainerComponent
  ],
  imports: [
    CommonModule,
    SearchBarModule,
    FilterOptionsModule,
    TranslateModule,
    LocalPipesModule,
    ChargePointChargerModule,
  ],
  exports: [
    TopBarComponent
  ]
})
export class TopBarModule { }
