import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { KIDSessionQuery } from '../k-id-session/k-id-session.query';
import { KIDSessionState } from '../k-id-session/k-id-session.store';
import { AppVersionService } from '../services/app-version.service';

@Injectable({ providedIn: 'root' })
export class ErrorsService {
  constructor(
    private http: HttpClient,
    private kIDSessionQuery: KIDSessionQuery,
    private appVersionService: AppVersionService
  ) { }

  /**
   * Send and log happened error to stackdriver
   * @param error error which needs to be logged on stackdriver
   */
  logErrorToStackdrive(error: Error | HttpErrorResponse) {

    const newErrorEvent = this.generateErrorEvent(error);

    if (environment.production) {
      this.http.post(
        `https://clouderrorreporting.googleapis.com/v1beta1/projects/${environment.LOGGING.PROJECT_NAME}/events:report?key=${environment.LOGGING.WEB_KEY}`,
        newErrorEvent
      ).subscribe(res => res);
    }
  }

  /**
   * Generates error event for stackdriver
   * @param error error object
   */
  private generateErrorEvent(error: Error | HttpErrorResponse): ReportedErrorEvent {

    let errorEvent: ReportedErrorEvent;
    let message: string;

    // Create message for the error event.
    if (error instanceof HttpErrorResponse) {
      message = new Error(JSON.stringify(error)).stack;
    } else {
      message = error['stack'];
    }

    errorEvent = {
      serviceContext: {
        service: environment.LOGGING.SERVICE_NAME,
        version: this.appVersionService.getVersion(),
      },
      message: message,
      context: this.generateErrorContext(error)
    };

    return errorEvent;

  }

  /**
   * Generates error context for error event
   * @param error error object
   */
  private generateErrorContext(error: Error | HttpErrorResponse): ErrorContext {

    let errorContext: ErrorContext;
    const customUserAgent: CustomUserAgent = {
      'kIdSession': undefined,
      'cookiesEnabled': undefined,
      'platform': undefined,
      'userAgent': undefined
    };

    customUserAgent['userAgent'] = navigator.userAgent ? navigator.userAgent : '';
    customUserAgent['platform'] = navigator.platform ? navigator.platform : '';
    customUserAgent['cookiesEnabled'] = navigator.cookieEnabled ? navigator.cookieEnabled : undefined;

    errorContext = {
      httpRequest: {
        method: '',
        url: error['url'] || 'no url, internal run time error',
        userAgent: JSON.stringify(customUserAgent),
        referrer: error['stack'],
        responseStatusCode: error['status'] || 500,
        remoteIp: ''
      },
      user: this.kIDSessionQuery.isLoggedIn() ? this.kIDSessionQuery.getUserId() : 'unregistered user',
      reportLocation: {
        filePath: 'app',
        lineNumber: 0,
        functionName: 'error'
      }
    };

    return errorContext;
  }
}

/**
 * Google stackdriver Error event object
 *
 * An error event which is reported to the Error Reporting system.
 *
 * https://cloud.google.com/error-reporting/reference/rest/v1beta1/projects.events/report
 */

export interface ReportedErrorEvent {
  // tslint:disable-next-line:max-line-length
  'eventTime'?: string; // Optional. Time when the event occurred. If not provided, the time when the event was received by the Error Reporting system will be used.
  'serviceContext': ServiceContext;
  'message': string; // Runtime error message.
  'context'?: ErrorContext; // Optional.
}

/**
 * Google stackdriver Service context object
 *
 *  Describes a running service that sends errors. Its version changes over time and multiple versions can run in parallel.
 *
 * https://cloud.google.com/error-reporting/reference/rest/v1beta1/ServiceContext
 */

export interface ServiceContext {
  'service': string;
  'version': string;
  'resourceType'?: string; // Value is set automatically for incoming errors and must not be set when reporting errors.
}

/**
 * Google stackdriver Error context object
 *
 * A description of the context in which an error occurred.
 * This data should be provided by the application when reporting an error,
 * unless the error report has been generated automatically from Google App Engine logs.
 *
 * https://cloud.google.com/error-reporting/reference/rest/v1beta1/ErrorContext#HttpRequestContext
 */

export interface ErrorContext {
  'httpRequest'?: HttpRequestContext;
  'user': string;
  'reportLocation'?: SourceLocation;
  'sourceReferences'?: SourceReference[];
}

export interface HttpRequestContext {
  'method': string;
  'url': string;
  'userAgent': string;
  'referrer': string;
  'responseStatusCode': number;
  'remoteIp': string;
}

export interface SourceLocation {
  'filePath': string;
  'lineNumber': number;
  'functionName': string;
}

export interface SourceReference {
  'repository': string;
  'revisionId': string;
}

export interface CustomUserAgent {
  'kIdSession': KIDSessionState;
  'userAgent': string;
  'platform': string;
  'cookiesEnabled': boolean;
}

