<div class="mapComponent__wrapper">
  <ng-container *ngIf="environment.development">
      <div class="devRibbon">
          <span >{{ 'DEV' }}</span>
        </div>
  </ng-container>
  <kc-top-bar></kc-top-bar>
  <kc-map *ngIf="apiLoaded | async"></kc-map>
</div>

<div class="routing__container" *ngIf="(device$ | async) !== 'desktop'; else desktopRouteContainer"
  [@slideRouteContainer]="(slideRouteContainer$ | async) ? 'up' : 'down'">
  <div class="routing__swipeContainer" [ngClass]="roamingSlider ? '--blue' : ''" (swipeup)="windowService.setRouteContainerOpenState(true)" (swipedown)="windowService.setRouteContainerOpenState(false)">
    <div class="routing__openButton" [ngClass]="roamingSlider ? '--roaming' : ''" (click)="toggleSlideRouteContainer()" tabindex="0"></div>
    <span class="sliderText" *ngIf="(slideRouteContainer$ | async) && roamingSlider">{{ 'roamingStation' | translate }}</span>
  </div>
  <router-outlet></router-outlet>
</div>

<ng-template #desktopRouteContainer>
  <div class="routing__container -desktop">
    <div class="routing__openButton"></div>
    <router-outlet></router-outlet>
  </div>
</ng-template>
<ng-container *ngIf="appUpdateService.isDialogActive">
  <kc-update-dialog></kc-update-dialog>
</ng-container>
<kc-loader *ngIf="isLoading"></kc-loader>
