import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoaderService } from 'src/app/loader/loader.service';
import { environment } from 'src/environments/environment';
import { ChargePoint } from '../charge-points/charge-point.model';
import { tap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EvseChargeBoxUpdate } from '../charge-boxes/charge-box.model';
import { ChargerService } from 'src/app/charger-service/charger-service.service';

@Injectable({ providedIn: 'root' })
export class RoamingChargePointsService {

  constructor(private http: HttpClient, private loaderService: LoaderService,
    private chargeBoxService: ChargerService) {}


  /**
   * Get roaming charge points from the hubject
   */
  getRoamingChargePoints(): Observable<Partial<ChargePoint>[]> {
    // return of([]);

    // TODO: Uncomment for roaming.
    // this.loaderService.activateSpinner('roamingChargePoints');
    return this.http.get<Partial<ChargePoint[]>>(`${environment.ROAMING.HUBJECT_URL}/country?pageSize=-1`)
    .pipe(tap(() => {
      this.loaderService.deactivateSpinner('roamingChargePoints');
    }), catchError(err => {
        this.loaderService.deactivateSpinner('roamingChargePoints');
        // Emits a null value which is handled by forkJoin.
        return of(null);
    }));
  }

  updateRoamingChargePoint(evseIds: string[]) {
    return this.http.get<EvseChargeBoxUpdate[]>(`${environment.ROAMING.BASE_URL}/evse/statuses?evseIds=${JSON.stringify(evseIds)}`)
      .pipe(tap(res => {
        res.forEach(cb => {
          this.chargeBoxService.updateRoamingChargeBox(cb['EvseID'], cb);
        });
      }));
  }
}
