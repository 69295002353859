import { Component, OnInit, Inject } from '@angular/core';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { Observable, throwError } from 'rxjs';
import { KChargeProfile, Tag } from 'src/app/k-charge-profile/k-charge-profile.model';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { GaService } from 'src/app/ga/ga.service';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'kc-manage-tags',
  templateUrl: './manage-tags.component.html',
  styleUrls: ['./manage-tags.component.scss']
})
export class ManageTagsComponent implements OnInit {

  orderTagForm: UntypedFormGroup;
  manageTagForm: UntypedFormGroup;
  kChargeProfile$: Observable<KChargeProfile>;
  profileTags: Array<Tag>;
  requestSend: boolean;
  deactivationConfirmationActive = false;
  selectedSerialNumber;
  tagDeactivationLoading = false;
  tagDeactivated = false;
  step = 1;

  constructor(@Inject(PORTAL_DATA) public data,
    private kChargeProfileService: KChargeProfileService,
    private kIDSessionQuery: KIDSessionQuery,
    public formBuilder: UntypedFormBuilder,
    private gaService: GaService) { }

  ngOnInit() {
    this.step = 1;
    this.requestSend = false;

    this.profileTags = this.kChargeProfileService.getTags();

    if (this.profileTags.length === 0) {

      this.orderTagForm = this.formBuilder.group({
        streetAddress: ['', [Validators.required]],
        postalCode: ['', [Validators.required]],
        city: ['', [Validators.required]],
        phoneNumber: ['', [Validators.required]],
        marketing: ['']
      }, {});

      // Get user current contact information
      this.kChargeProfile$ = this.kIDSessionQuery.kChargeProfile$;
      this.kChargeProfileService.get();

      this.kChargeProfile$.subscribe(profile => {
        this.orderTagForm.patchValue(profile['contactInfo']);
      });
    } else {
      // Create tags form... This allows input switch manipulate array object values.
      // If not injected to form switch gives object read only error and switch won't work.
      this.manageTagForm = this.formBuilder.group({}, {});

      for (let i = 0; i < this.profileTags.length; i++) {
        const tagControl = new UntypedFormControl(this.profileTags[i].active);
        this.manageTagForm.addControl(this.profileTags[i].id, tagControl);
      }
    }
  }

   /**
   * Change switch state (changes scss styling)
   */
  getSwitchState(input) {
    return this.manageTagForm.get(input).value;
  }

  /**
   * Change marking agreement value
   */
  changeSwitchValue(input) {
    this.manageTagForm.get(input).setValue(!this.manageTagForm.get(input).value);
  }

  /**
  * Orders tag
  * @param form K-charge profile contact information
  */
  orderTag() {

    this.kChargeProfileService.orderTag().subscribe(res => {
      this.gaService.sendUserProfileEvent('Lataustunniste tilattu');
      this.requestSend = true;
      this.kChargeProfileService.updateTagOrderedStatus(true);
    }, err => {
      this.data.openNewOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
      'source': { 'component': 'manageTagsComponent', 'function': 'orderTag'}});
      throw err;
    });
  }

  openContactInfoSettings() {
    this.data.openNewOverlayDialog('EditUserComponent');
  }

  /**
   * Updates k-charge profile tags
   * @param form tags
   */
  save(form) {
    this.kChargeProfileService.updateTags(form.value).subscribe(res => {
      this.close();
    }, err => {});
  }

  /**
   * Close edit contact information overlay dialog
   */
  close() {
    this.data.close();
  }

  /**
   * Toggles the visibility of tag's deactivation confirmation view.
   * @param tagSerialnumber of tag to deactivate.
   */
  toggleDeactivationConfirmation(tagSerialNumber: string) {
    this.selectedSerialNumber = tagSerialNumber;
    this.deactivationConfirmationActive = !this.deactivationConfirmationActive;
    this.step = this.deactivationConfirmationActive ? 2 : 1;
  }

  /**
   * Sends deactivation request. Deactivates confirmation buttons and changes view on success.
   * @param tagSerialnumber of tag to deactivate.
   */
  deactivateTag() {
    this.tagDeactivationLoading = true;

    this.kChargeProfileService.deactivateTag(this.selectedSerialNumber)
    .pipe(catchError(err => {
      this.tagDeactivationLoading = false;
      return throwError(err);
    }))
    .subscribe(_ => {
      this.tagDeactivationLoading = false;
      this.step = 3;
      this.kChargeProfileService.hideDeactivatedTag(this.selectedSerialNumber);
    });
    this.kChargeProfileService.get();
  }
}
