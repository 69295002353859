import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Loader } from './loader.model';

export interface LoaderState extends EntityState<Loader> {}



@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'loader' })
export class LoaderStore extends EntityStore<LoaderState, Loader> {

  constructor() {
    super();
  }

  /**
   * Adds new loader object to store
   * @param loader Loader object
   */
  addNewLoader(loader: Loader) {
    this.add(loader);
  }

  /**
   * Remove loader from store
   * @param id Selected loader name
   */
  removeLoader(id: string) {
    this.remove(id);
  }
}
