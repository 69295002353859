<div class="transactionsOverview">
  <div class="transactionsOverview__invoicesContainer">
      <div class="transactionsOverview__invoicesContainerTitle">
          <span>{{'payments' | translate}}</span>
      </div>
    
      <div class="transactionsOverview__invoicesContainerAction">
        <div class="transactionsOverview__invoicesContainerActionIcon">
            <!-- <i class="fak fa-k-info fa-xl --colorOrange"></i> -->
        </div>     
      </div>
  </div>
  
  <div #slideContainer class="transactionsOverview__slideContainer" (wheel)="onWheel($event)"> 
    <ng-container *ngIf="(unpaidInvoices) || invoicingAgreementInvoices; else noInvoicesFoundBlock">

      <ng-container *ngIf="invoicingAgreementInvoices; else unpaidInvoicesBlock">
        <ng-container *ngFor="let invoice of invoicingAgreementInvoices">
          <kc-invoicing-agreement-invoice [monthlyInvoice]="invoice" class="singleDisplay"></kc-invoicing-agreement-invoice>
        </ng-container>
      
      </ng-container>

      <ng-template #unpaidInvoicesBlock>
        <ng-container *ngFor="let unpaidInvoice of unpaidInvoices">
          <kc-invoice-balance [monthlyInvoice]="unpaidInvoice" [ngClass]="{'singleDisplay': unpaidInvoices.length === 1}"></kc-invoice-balance>
        </ng-container>
      </ng-template>
      
    </ng-container>
    <ng-template #noInvoicesFoundBlock>
      <div class="transactionsOverview__noInvoicesFoundBlock">
        <span>{{'noUnpaidInvoices' | translate}}</span>  
      </div>      
    </ng-template>
  </div>
</div>


