import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';;
import { environment } from 'src/environments/environment';
import { InvoicingAgreement } from '../k-charge-profile/k-charge-profile.model';

export enum TransactionFeedbackType {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
}

export enum TransactionFeedbackReason {
  INSUFFICIENT_POWER = 'insufficientPower',
  CHARGING_INTERRUPTED = 'chargingInterrupted',
  CANNOT_START_CHARGING = 'cannotStartCharging',
}

export enum TransactionFeedbackPowerType {
  AC = 'AC',
  DC = 'DC',
  UNKNOWN = 'UNKNOWN'
}

// userId field is excluded in this interface because it is added from session in backend.
export default interface TransactionFeedback {
  chargePointGroupId: string;
  chargePointId: string;
  chargeBoxGroupId: string;
  chargeBoxId: string;
  transactionId: string;
  type: TransactionFeedbackType;
  powerType: TransactionFeedbackPowerType;
  reason?: TransactionFeedbackReason;
  additionalInfo?: string;
}

@Injectable({ providedIn: 'root' })
export class TransactionFeedbackService {

  constructor(private http: HttpClient) { }

  public sendTransactionFeedback(feedback: TransactionFeedback) {
    return this.http.post<{status: string}>(`${environment.BACKEND_URL}/transaction-feedback`, feedback)
  }

}
