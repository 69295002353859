
export interface Loader {
  id: string;
  active: boolean;
}

/**
 * A factory function that creates Loader
 */
export function createLoader(id: string) {
  return {
    id: id,
  } as Loader;
}
