<div class="overlayContainer__wrapper --h100 --scrollable">
  <div class="overlayContainer__contentWrapper --h100">
    <div class="overlayContainer__contentTemplate">
      <div class="headerContainer">
        <img src="../../../../assets/icons/png/icon-info@3x.png" alt="">
        <span class="title"> {{ 'newIonityInfoTitle' | translate }} </span>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <span class="infoText" [innerHTML]="'newIonityInfoText1' | translate "></span>
        <span class="infoText">{{ 'newIonityInfoText2' | translate }}</span>
        <span class="infoText" [innerHTML]="'newIonityInfoText3' | translate "></span>
        <span class="infoText">{{ 'ionityLinkText1' | translate }} <a href="https://k-lataus.fi" target="_blank">{{ 'ionityLinkText2' | translate }}</a>.</span>
        <span class="infoText" [innerHTML]="'energeticRestOfTheYear' | translate "></span>
        <a href=""></a>
      </div>
      <div class="overlayContainer__actionContainer --w100">
        <button class="overlayContainer__actionButton --white" (click)="close()">
          <span>{{'continueToApplication' | translate | uppercase}}</span>
        </button>
      </div>
    </div>
  </div>
</div>