import { Component, OnInit, Inject, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { KIDSessionService } from 'src/app/k-id-session/k-id-session.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { Observable, Subscription, interval } from 'rxjs';
import { MapService } from 'src/app/map/state/map.service';
import { Router } from '@angular/router';
import { WindowService } from 'src/app/window-service/window.service';
import { ActiveTransactionService, ActiveTransaction } from 'src/app/routes/charge-point/active-transaction/active-transaction.service';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { UserLocationQuery } from 'src/app/map/user-location/user-location.query';
import { TopBarService } from './top-bar.service';
import { ChargeBoxesQuery } from '../map/charge-boxes/charge-boxes.query';
import { ActiveTransactionHelper } from '../helpers/active-transaction-helper';
import { ChargePointsService } from '../map/charge-points/charge-points.service';
import { FilterOptionsQuery } from './filter-options/state/filter-options.query';
import { KChargeProfileService } from '../k-charge-profile/k-charge-profile.service';

@Component({
  selector: 'kc-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit, AfterViewInit, OnDestroy {

  name$: Observable<string>;
  isUserLocationFound$: Observable<boolean>;
  activeTransactions: ActiveTransaction[] = [];
  activeTransactionInfos = {};
  public showActiveTransactions$ = this.topBarService.isActiveTransactionsContainerOpen$;
  private isActiveTransactionsOpen: boolean;
  public showFilter$ = this.topBarService.isFilterContainerOpen$;
  private isFilterOpen: boolean;
  filtersTotalLength = 0;
  activeFiltersLength = 0;

  constructor(@Inject('Window') window: Window,
    public kIDSessionQuery: KIDSessionQuery,
    private mapService: MapService,
    private router: Router,
    private windowService: WindowService,
    private activeTransactionService: ActiveTransactionService,
    private topBarService: TopBarService,
    private userLocationQuery: UserLocationQuery,
    private cdr: ChangeDetectorRef,
    private chargePointsService: ChargePointsService,
    private filterOptionsQuery: FilterOptionsQuery,
    private kChargeProfileService: KChargeProfileService) {

    this.showActiveTransactions$.subscribe(isOpen => {
      this.isActiveTransactionsOpen = isOpen;
    });

    this.showFilter$.subscribe(isOpen => {
      this.isFilterOpen = isOpen;
    });

    this.filterOptionsQuery.filters$.subscribe(activeFilters => {
      this.activeFiltersLength = 0;
      this.filtersTotalLength  = 0;
      Object.keys(activeFilters).forEach(activeFilter => {
        activeFilters[activeFilter].forEach(af => {
          this.filtersTotalLength += 1;
          if (af.active) {
            this.activeFiltersLength += 1;
          }
        });
      });
    });
  }
  private transacionIntervals: Subscription[] = [];

  ngOnInit() {
    this.activeTransactionService.activeTransaction$.subscribe(activeTransactions => {
      this.activeTransactions = activeTransactions;
      this.cancelTransactionIntervals();
      this.activeTransactions.forEach(activeTransaction => {
        this.startTransacionInterval(activeTransaction);
      });
      this.cdr.markForCheck();
    });
    this.activeTransactionService.getActiveTransaction();
  }

  ngAfterViewInit(): void {
    this.name$ = this.kIDSessionQuery.name$;
    this.isUserLocationFound$ = this.userLocationQuery.isPositionFound$;
  }

  /**
   * Open filter settings and closes routing container
   */
  openFilterTemplate() {

    this.topBarService.toggleFilterOpen(!this.isFilterOpen);
    this.topBarService.toggleActiveTransactionsOpen(false);
    this.windowService.setRouteContainerOpenState(false);
  }

  /**
   * Opens K-ID widget for login/logout and navigation on desktop and tablet
   */
  openWidget() {
    this.kChargeProfileService.login();
  }

  /**
   * open user profile
   */
  openProfile() {
    this.router.navigate(['profile']);
    this.windowService.setRouteContainerOpenState(true);
  }

  /**
   * Center map to user current location
   */
  centerMapToUser() {
    this.windowService.setRouteContainerOpenState(false);
    this.mapService.centerMapToUser();
    this.router.navigate(['/profile']);
    this.chargePointsService.changeActiveChargePoint(null);
    this.chargePointsService.setChargeBoxActive(null);
  }

  openActiveTransactions() {
    this.topBarService.toggleFilterOpen(false);
    this.topBarService.toggleActiveTransactionsOpen(!this.isActiveTransactionsOpen);
    this.windowService.setRouteContainerOpenState(false);
  }

  changeStatus(status: string) {
    switch (status.toLowerCase()) {
      case 'occupied':
        return 'charging';
      default:
        return status;
    }
  }

  /**
   * Starts an interval to calculate transactions price and elapsed time.
   */
  private startTransacionInterval(activeTransaction: ActiveTransaction) {
    this.activeTransactionInfos[activeTransaction.chargeBoxId] = {};
    this.setParsedChargingTime(activeTransaction);
    this.setTransactionPrice(activeTransaction);
    this.cdr.markForCheck();
    this.transacionIntervals.push(interval(1000).subscribe(() => {
      this.setTransactionPrice(activeTransaction);
      this.setParsedChargingTime(activeTransaction);
      this.cdr.markForCheck();
    }));
  }

  /**
   * Sets a price to transaction.
   * @param activeTransaction ctive transaction to set price to.
   */
  private setTransactionPrice(activeTransaction: ActiveTransaction) {
    const price = ActiveTransactionHelper.calculatePrice(
      activeTransaction,
      activeTransaction.transactionPrice ? activeTransaction.transactionPrice.chargingPrice : undefined,
      this.kIDSessionQuery.isLoggedIn(),
      0
    );
    this.activeTransactionInfos[activeTransaction.chargeBoxId].price = price;
  }

  /**
   * Sets parsed charging time (hours, minutes) to active transaction.
   * @param activeTransaction active transaction to set parsed time to.
   */
  private setParsedChargingTime(activeTransaction) {
    const timeElapsed = ActiveTransactionHelper.formatChargingTime(activeTransaction.timestampStart, activeTransaction.timestampStop);
    this.activeTransactionInfos[activeTransaction.chargeBoxId].hours = timeElapsed.hours;
    this.activeTransactionInfos[activeTransaction.chargeBoxId].minutes = timeElapsed.minutes;
  }

  /**
   * Cancels current intervals.
   */
  private cancelTransactionIntervals() {
    this.activeTransactionInfos = {};
    this.transacionIntervals.forEach(x => {
      x.unsubscribe();
    });
  }

  ngOnDestroy() {
    this.cancelTransactionIntervals();
  }
}
