<div style="z-index: 102" class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <span class="title" style="font-family: SkattaSansBold; margin-top: 5px;">{{ 'newInvoicingAgreement' | translate }}</span>
      </div>
      <ng-container [ngSwitch]="invitationStatus">
        <ng-container *ngSwitchCase="1">
          <div class="infoContainer">
            <i class="fak fa-k-invoice"></i>
            <div class="textContainer">
              <span style="font-size: 0.875rem; margin-bottom: 20px;">{{ 'invitationForNewInvoicingAgreement' | translate }}</span>
              <span style="font-family: SkattaSansBold; margin-bottom: 10px;">{{ data.data.invitation.organizationName + ' ' + ('invoicingAgreement' | translate | lowercase) }}</span>
              <span>{{ ('availableTill' | translate) + ' ' + formatDate(data.data.invitation.startDate) + ' - ' + formatDate(data.data.invitation.expirationDate) }}</span>
            </div>
          </div>
          <div class="overlayContainer__actionContainer">
            <ng-container *ngIf="!isAccepting">
              <kc-dialog-button [buttonText]="'accept'" [color]="'--orange'" (onClick)="acceptInvitation()"></kc-dialog-button>
              <kc-dialog-button [buttonText]="'reject'" [color]="'--white'" (onClick)="setPage(3)"></kc-dialog-button>
            </ng-container>
            <div class="invitationDialog__loaderContainer" *ngIf="isAccepting">
              <kc-local-loader></kc-local-loader>
            </div>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <div class="infoContainer">
            <i class="fa-solid fa-check"></i>
            <div class="textContainer">
              <span>{{ 'invitationAcceptedInfo' | translate }}</span>
            </div>
          </div>
          <div class="overlayContainer__actionContainer">
            <kc-dialog-button [buttonText]="'accept'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <div class="infoContainer">
            <i class="fak fa-k-info"></i>
            <div class="textContainer">
              <span style="font-family: SkattaSansBold; margin-bottom: 10px;">{{ 'invitationRejectConfirmation' | translate }}</span>
            </div>
          </div>
          <div class="overlayContainer__actionContainer">
            <kc-dialog-button [buttonText]="'rejectInvitation'" [color]="'--orange'" (onClick)="rejectInvitation()"></kc-dialog-button>
            <kc-dialog-button [buttonText]="'cancel'" [color]="'--white'" (onClick)="setPage(1)"></kc-dialog-button>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="4">
          <div class="infoContainer">
            <i class="fa-solid fa-check"></i>
            <div class="textContainer">
              <span>{{ 'invitationRejectedInfo' | translate }}</span>
            </div>
          </div>
          <div class="overlayContainer__actionContainer">
            <kc-dialog-button [buttonText]="'accept'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>