import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import * as storage from '../updated-pricing-dialog/updated-pricing-dialog.storage';

@Component({
  selector: 'kc-pricing-updated-dialog',
  templateUrl: './pricing-updated-dialog.component.html',
  styleUrls: ['./pricing-updated-dialog.component.scss']
})
export class PricingUpdatedDialogComponent implements OnInit {

  constructor(@Inject(PORTAL_DATA) public data) { }

  ngOnInit() {
  }

  close() {
    storage.setUpdatedPricingAgreement(true);
    this.data.close();
  }
}
