<ng-container *ngIf="(router.isActive('/charge-point', false)) || (loggedIn$ | async); else login">
<div class="navigationBar__container" [ngStyle]="{'display': (router.isActive('/charge-point', false) && isFutureChargePoint()) ? 'none' : 'flex'}"
(swipeup)="windowService.setRouteContainerOpenState(true)"
(swipedown)="windowService.setRouteContainerOpenState(false)"
(swipeleft)="navigate(false)"
(swiperight)="navigate(right)">
    <div class="navigationBar__navigationButtonContainer">
      <ng-container *ngIf="routerLinkImplemented; else routerLinkDisabled">
        <a *ngFor="let tab of tabs" class="navigationBar__navigationButton" [routerLink]="'/' + tab" routerLinkActive="active"
          (click)="openRouteContainer()">
          <span>{{ tab | translate }}</span>
          <div class="--arrow"></div>
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>

<!--Login button on mobile-->
<ng-template #login>
  <div class="navigationBar__loginButton" tabindex="0" (click)="openWidget()" (swipeup)="windowService.setRouteContainerOpenState(true)"
  (swipedown)="windowService.setRouteContainerOpenState(false)">
    <span>{{'login' | translate | uppercase }}</span>
  </div>
</ng-template>

<ng-template #routerLinkDisabled>
  <a *ngFor="let tab of tabs" class="navigationBar__navigationButton" (click)="tabClicked(tab)" [ngClass]="getTabClass(tab)">
    <span>{{ tab | translate }}</span>
    <div class="--arrow"></div>
  </a>
</ng-template>