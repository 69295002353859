import { createInitialFiltersState, FilterTypes, FilterOption, FiltersState } from './filter-option.model';

/**
 * This storage handles getting, setting and removing K-ID session data on local storage
 */

const FILTER_OPTIONS = 'filterOptions';

/**
 * Get active filter options
 * If there is no active filter options, then return new filter options.
 */
export function getFilterOptions(): FiltersState {
    const storedFilterOptions = localStorage.getItem(FILTER_OPTIONS);

    // If filter options not found, then create ones.
    if (!storedFilterOptions) {
        return createInitialFiltersState();
    }

    // Check if filter options are valid ones
    const filterOptions = JSON.parse(storedFilterOptions);
    if (filterOptions.filter(
        (filterOption) => Object.values(FilterTypes).includes(filterOption.type )).length !== Object.keys(FilterTypes).length) {
        return createInitialFiltersState();
    }

    return filterOptions;
}

/**
 * Removes filter options from local storage.
 */
export function clearFilterOptions() {
    localStorage.removeItem(FILTER_OPTIONS);
}
