import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import { environment } from 'src/environments/environment';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { Router } from '@angular/router';
import { ErrorsService } from 'src/app/errors-handler/errors.service';
import { SinglePaymentService } from 'src/app/single-payment/single-payment.service';
import { BlueCommerceService } from 'src/app/blue-commerce/blue-commerce.service';
import { KChargeProfileService } from 'src/app/k-charge-profile/k-charge-profile.service';
import { AppVersionService } from 'src/app/services/app-version.service';

@Component({
  selector: 'kc-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  loggedIn$: Observable<boolean>;
  environment = environment;
  paymentInitData: any;
  singlePayment: boolean;
  singlePaymentForm: UntypedFormGroup;
  singlePaymentInitData: any;
  pspUrl: string;
  userDevice: string;
  roaming: boolean;
  isAddCardDisabled = false;

  constructor(@Inject(PORTAL_DATA) public data,
    public formBuilder: UntypedFormBuilder,
    private kIDSessionQuery: KIDSessionQuery,
    private router: Router,
    private appVersionService: AppVersionService,
    private kChargeProfileService: KChargeProfileService,
    private singlePaymentService: SinglePaymentService,
    private blueCommerceService: BlueCommerceService) {

  }

  ngOnInit() {
    this.singlePayment = false;
    this.userDevice = this.appVersionService.getVersion();
    this.roaming = this.data.data.chargePoint.roaming;

    this.loggedIn$ = this.kIDSessionQuery.isLoggedIn$;

    if (this.loggedIn$) {
      this.blueCommerceService.getSaveCardInitData(this.router.routerState.snapshot.url).subscribe(response => {
        this.paymentInitData = response['data'];
        this.pspUrl = response['url'];
      });
    }

    this.singlePaymentForm = this.formBuilder.group({
      'email': ['', [Validators.required, Validators.email]],
      'chargePoint': [this.data.data.chargePoint.id, [Validators.required]],
      'chargeBoxId': [this.data.data.chargeBoxId, [Validators.required]],
      'acceptedTos': [false, [Validators.requiredTrue]]
    }, {});
  }

  toggleSinglePayment() {
    this.singlePayment = !this.singlePayment;
  }


  /**
  * Change switch state (changes scss styling)
  */
  agreement(field) {
    return this.singlePaymentForm.get(field).value;
  }

  /**
   * Change marking agreement value
   */
  changeAgreement(field) {
    this.singlePaymentForm.get(field).setValue(!this.singlePaymentForm.get(field).value);
  }

  changeAgreementSwipe(field, value) {
    this.singlePaymentForm.get(field).setValue(value);
  }

  /**
   * Close edit contact information overlay dialog
   */
  close() {
    this.data.close();
  }

  /**
   * Opens K-ID widget for login/logout and navigation on desktop and tablet
   */
  openWidget() {
    this.kChargeProfileService.login();
    this.close();
  }

  save(form) {
    this.singlePaymentService.getSinglePaymentInitData(form.value.email, form.value.chargePoint, form.value.chargeBoxId).subscribe(
      response => {
        this.singlePaymentInitData = response['data'];
        this.pspUrl = response['url'];
      },
      err => {
        this.data.openNewOverlayDialog('ErrorDialogComponent', { 'message': 'somethingWentWrongInfo', 'error': err,
        'source': { 'component': 'loginRegisterComponent', 'function': 'getSinglePaymentInitData'}});
        throw err;
      });
  }

  disableAddCard() {
    this.isAddCardDisabled = true;
  }
}
