import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { MonthlyInvoicingAgreementInvoice} from './invoicing-agreement-invoice.model';
import { InvoicingAgreementInvoiceState, InvoicingAgreementInvoiceStore } from './invoicing-agreement-invoice.store';

@Injectable({
  providedIn: 'root'
})
export class InvoicingAgreementInvoiceQuery extends QueryEntity<InvoicingAgreementInvoiceState, MonthlyInvoicingAgreementInvoice> {

  constructor(protected store: InvoicingAgreementInvoiceStore) {
    super(store);
  }

}
