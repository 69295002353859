import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WindowService } from '../window-service/window.service';
import { KIDSessionQuery } from '../k-id-session/k-id-session.query';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ErrorsService } from '../errors-handler/errors.service';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { ChargePoint } from '../map/charge-points/charge-point.model';
import { KChargeProfileService } from '../k-charge-profile/k-charge-profile.service';
import { AppVersionService } from '../services/app-version.service';
import * as moment from 'moment';

@Component({
  selector: 'kc-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit {
  private _tabs = ['profile', 'transactions']; // initial value for tabs
  chargePoint$ = this.chargePointsQuery.activeChargePoint$;
  @Input() routerLinkImplemented = true;
  @Input()
  set tabs(value: string[]) {
    this._tabs = value;
    this.activeTab = this._tabs[0];
  }
  get tabs(): string[] {
    return this._tabs;
  }

  @Input() disabledTabs: string[];
  @Input() defaultTab: string;

  @Output() activatedTab = new EventEmitter<string>();

  loggedIn$: Observable<boolean>;
  activeTab: string;
  userDevice: string;
  chargePoint: ChargePoint;

  constructor(public windowService: WindowService,
    private kIDSessionQuery: KIDSessionQuery,
    private chargePointsQuery: ChargePointsQuery,
    private appVersionService: AppVersionService,
    private kChargeProfileService: KChargeProfileService,
    public router: Router) {

      if (this.router.url.substring(1) === 'profile' || 'transactions') {
        this.activeTab = this.router.url.substring(1);
      } else {
        this.activeTab = this._tabs[0];
      }

      this.userDevice = this.appVersionService.getVersion();
  }

  ngOnInit() {
    this.loggedIn$ = this.kIDSessionQuery.isLoggedIn$;
    this.chargePoint$.subscribe(res => {
      if (res) {
        if (!this.chargePoint || this.chargePoint.id !== res.id) {
          this.activeTab = 'chargeSockets';
        }
        this.chargePoint = res;
      }
    });
  }

  openRouteContainer() {
    this.windowService.setRouteContainerOpenState(true);
  }

  /**
     * Opens K-ID widget for login/logout and navigation on desktop and tablet
     */
  openWidget() {
    this.kChargeProfileService.login();
  }

  tabClicked(tab: string) {
    this.activeTab = tab;
    this.openRouteContainer();
    this.activatedTab.emit(this.activeTab);
  }

  navigate(direction: boolean) {

    const currentTabIndex = this._tabs.indexOf(this.activeTab);

    if (direction) {
      if (currentTabIndex !== this._tabs.length) {
        this.activeTab = this._tabs[currentTabIndex + 1];
      } else {
        this.activeTab = this._tabs[0];
      }
    } else {
      if (currentTabIndex !== 0) {
        this.activeTab = this._tabs[currentTabIndex - 1];
      } else {
        this.activeTab = this._tabs[length];
      }
    }

    this.activatedTab.emit(this.activeTab);

    if (this.activeTab === 'profile' || this.activeTab === 'transactions') {
      this.router.navigate([`/${this.activeTab}`]);
    }
  }

  getTabClass(tab) {
   
    let tabClass = '';

    if(this.defaultTab === tab) {
      this.activeTab = tab;
      this.activatedTab.emit(tab);
    }

    if (tab === this.activeTab) {
      tabClass = 'active';
    }
    if (this.chargePoint.roaming) {
      tabClass += ' --blue';
    }
    if(this.disabledTabs.find(val => val === tab)) {
      tabClass = '--disabled';
    }
    return tabClass;
  }

  isFutureChargePoint() {
    if(this.chargePoint) {
      return moment(this.chargePoint.installationDate).isAfter(moment()) && this.chargePoint.operative === false
    }
  }
}
