import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, Observable } from "rxjs";
import { GaService } from "src/app/ga/ga.service";
import { requestStatus } from "src/app/helpers/requestStatuses";
import { KChargeProfile } from "src/app/k-charge-profile/k-charge-profile.model";
import { KChargeProfileService } from "src/app/k-charge-profile/k-charge-profile.service";
import { KIDSessionQuery } from "src/app/k-id-session/k-id-session.query";
import { PORTAL_DATA } from "src/app/overlay-dialog/overlay-dialog.storage";
import { environment } from "src/environments/environment";

@Component({
  selector: "kc-order-tag-dialog",
  templateUrl: "./order-tag-dialog.component.html",
  styleUrls: ["./order-tag-dialog.component.scss"],
})
export class OrderTagDialogComponent implements OnInit {
  environment = environment;

  requestStatus$ = new BehaviorSubject<requestStatus>(requestStatus.PENDING);
  // user contact info form
  userContactInfoForm: UntypedFormGroup;
  kChargeProfile$: Observable<KChargeProfile>;
  forceUpdate = false;
  // contactInfoRequired = true;
  updateName = false;
  updateEmail = false;

  constructor(
    @Inject(PORTAL_DATA) public data,
    private kChargeProfileService: KChargeProfileService,
    private kIDSessionQuery: KIDSessionQuery,
    public formBuilder: UntypedFormBuilder,
    private gaService: GaService
  ) {}

  ngOnInit() {
    this.initForm();

    // Get user current contact information
    this.kChargeProfile$ = this.kIDSessionQuery.kChargeProfile$;
    this.kChargeProfileService.get();

    this.kChargeProfile$.subscribe(profile => {
      this.userContactInfoForm.patchValue(profile);
    });
  }

  private initForm() {
    this.forceUpdate = this.data.data.forced;
    this.updateName = this.data.data.updateName;
    this.updateEmail = this.data.data.updateEmail;
    const requiredValidator = Validators.required;

    this.userContactInfoForm = this.formBuilder.group({
      givenName: ['', [Validators.required]],
      familyName:  ['', [Validators.required]],
      // email: ['', [Validators.required, Validators.email]],
      contactInfo: this.formBuilder.group({
        streetAddress: ['', [requiredValidator]],
        co: [''],
        postalCode: [
          '',
          [Validators.maxLength(10),
          Validators.pattern('^[0-9]*$'),
          requiredValidator]
        ],
        city: ['', [requiredValidator]],
        phoneNumber: [
          '',
          [Validators.pattern('\\+[\\d\\s]+'),
          Validators.minLength(5),
          Validators.maxLength(20),
          requiredValidator]
        ],
        
      })
    }, {});
  }

  get givenName() { return this.userContactInfoForm.get('givenName'); }
  get familyName() { return this.userContactInfoForm.get('familyName'); }
  get email() { return this.userContactInfoForm.get('email'); }
  get streetAddress() { return this.userContactInfoForm.get('contactInfo').get('streetAddress'); }
  get postalCode() { return this.userContactInfoForm.get('contactInfo').get('postalCode'); }
  get city() { return this.userContactInfoForm.get('contactInfo').get('city'); }
  get phoneNumber() { return this.userContactInfoForm.get('contactInfo').get('phoneNumber'); }
  get co() { return this.userContactInfoForm.get('contactInfo').get('co'); }


  /**
   * Update K-Charge profile information
   */
   save(form) {
    this.requestStatus$.next(requestStatus.SEND);

    this.kChargeProfileService.updateProfile(form.value).subscribe(res => {
      this.kChargeProfileService.orderTag().subscribe(res => {
        this.requestStatus$.next(requestStatus.ACCEPTED);
        this.gaService.sendUserProfileEvent('Lataustunniste tilattu');
        this.kChargeProfileService.updateTagOrderedStatus(true);
      }, err => {
        this.data.openNewOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
        'source': { 'component': 'manageTagsComponent', 'function': 'orderTag'}});
        throw err;
      });
    }, err => {
      this.requestStatus$.next(requestStatus.PENDING);
      this.data.openNewOverlayDialog('ErrorDialogComponent', { 'message': 'somethingWentWrongInfo', 'error': err,
        'source': { 'component': 'EditUserComponent', 'function': 'updateProfile'}});
      throw err;
    });
  }

  

  /**
   * If there's no phone number, helps out by filling in the plus sign.
   */
   insertPlusSign() {
    if (this.phoneNumber.value.length === 0) {
      this.phoneNumber.patchValue('+');
    }
  }

  /**
   * Close edit contact information overlay dialog
   */
   close() {
    this.data.close();
  }
}
