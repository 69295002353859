<div class="socketPriceItem__wrapper" [ngClass]="compact ? '-compact' : ''">
  <div class="socketPriceItem__titleContainer">
    <span class="title">{{ title }}</span>
    <span class="subtitle">{{ subtitle }}</span>
  </div>
  <div class="socketPriceItem__priceContainer">
    <span class="price">{{plusSign ? '+' : ''}}{{ price | localCurrency }}</span>
    <span class="pricingPrinciple">{{ pricingPrinciple }}</span>
  </div>
</div>
