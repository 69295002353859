import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig, EntityState, ActiveState } from '@datorama/akita';
import { ChargeBox, createChargeBox, createRoamingChargeBox } from './charge-box.model';

export interface ChargeBoxesState extends EntityState<ChargeBox>, ActiveState { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'charge-boxes' })
export class ChargeBoxesStore extends EntityStore<ChargeBoxesState, ChargeBox> {
    constructor() {
        super();
    }

    /**
     * Add one charge box to the store
     * @param chargeBox Charge box object
     */
    addChargeBox(chargeBox: Partial<ChargeBox>) {
        if (chargeBox.operative) {
            this.add(createChargeBox(chargeBox));
        }
    }

    /**
     * Add Hubject charge box object to the store
     * @param chargeBox Charge box object
     */
    addRoamingChargeBox(chargeBox: Partial<ChargeBox>) {
        this.add(createRoamingChargeBox(chargeBox));
    }

    /**
     * Update one charge box on store
     * @param chargeBox Charge box object
     * @param properties Additional properties to update chargeBox with.
     */
    updateChargeBox(chargeBox: Partial<ChargeBox>) {
        this.update(chargeBox.id, chargeBox.roaming ? createRoamingChargeBox(chargeBox) : createChargeBox(chargeBox));
    }

    /**
     * Update arrays of charge boxes on store
     * @param chargeBoxes Array of charge box objects
     */
    updateChargeBoxes(chargeBoxes: Partial<ChargeBox[]>) {
        chargeBoxes.forEach(chargeBox => {
            this.updateChargeBox(chargeBox);
        });
    }

    /**
     * Set an array of charge boxes to store.
     * @param chargeBoxes to set to store.
     */
    setChargeBoxes(chargeBoxes: ChargeBox[]) {
        this.set(chargeBoxes);
    }
}
