import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './routes/profile/profile.component';
import { ChargePointComponent } from './routes/charge-point/charge-point/charge-point.component';
import { TransactionsComponent } from './routes/transactions/transactions.component';
import { SocketComponent } from './routes/charge-point/socket/socket.component';
import { ActiveTransactionComponent } from './routes/charge-point/active-transaction/active-transaction.component';
import { AuthGuardService } from './auth-guard.service';
import { InvoiceComponent } from './routes/invoice/invoice.component';

const routes: Routes = [
  // { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'charge-point/:id', component: ChargePointComponent },
  { path: 'charge-point/:id/socket/:socketId', component: SocketComponent },
  { path: 'charge-point/:id/socket/:socketId/active', component: ActiveTransactionComponent },
  { path: 'profile', component: ProfileComponent },
  { path: 'transactions', component: TransactionsComponent, canActivate: [AuthGuardService] },
  { path: 'invoice/:id', component: InvoiceComponent, canActivate: [AuthGuardService] },
  { path: '**', redirectTo: 'profile', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: false } // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
