import { Component, OnInit, Input } from '@angular/core';
import { TransactionSocketIconHelper } from 'src/app/helpers/transaction-socket-icon-helper';
import { BehaviorSubject } from 'rxjs';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';

@Component({
  selector: 'kc-invoice-transaction',
  templateUrl: './invoice-transaction.component.html',
  styleUrls: ['./invoice-transaction.component.scss']
})
export class InvoiceTransactionComponent implements OnInit {

  @Input() set invoiceTransaction(value: any) {
    this.transaction$.next(value);
  }

  transaction$ = new BehaviorSubject<any>(null);

  constructor(
    private chargePointsQuery: ChargePointsQuery
  ) { }

  ngOnInit() {
  }


  setIcon(transaction) {
    const roaming = transaction.evseId ? true : false;
    const style = TransactionSocketIconHelper.setIcon('', roaming);
    return style;
  }

  getChargePointName(transaction) {
    if (transaction.chargePointId) {
      return this.chargePointsQuery.getChargePointName(transaction.chargePointId);
    } else {
      return transaction.evseId;
    }
  }

}
