import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { UserLocationStore, UserLocationState } from './user-location.store';

@Injectable({ providedIn: 'root' })
export class UserLocationQuery extends Query<UserLocationState> {
  isPositionFound$ = this.select((state) => toBoolean(state.userLocation));
  userPosition$ = this.select((state) => state.userLocation);

  constructor(protected store: UserLocationStore) {
    super(store);
  }

}
