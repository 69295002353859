import {
  Component,
  ViewChild,
  ChangeDetectionStrategy,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { UserLocationQuery } from "./user-location/user-location.query";
import { ChargePoint } from "./charge-points/charge-point.model";
import { ChargePointsQuery } from "./charge-points/charge-points.query";
import { ChargePointsService } from "./charge-points/charge-points.service";
import { MapService } from "./state/map.service";
import { Router } from "@angular/router";
import { WindowService } from "../window-service/window.service";
import { combineLatest, Observable, Subject } from "rxjs";
import { map, first } from "rxjs/operators";
import { clusterStyle, futureClusterStyle, mapStyle } from "./map.styles";
import { LoaderService } from "../loader/loader.service";
import { TopBarService } from "../top-bar/top-bar.service";
import { GaService } from "../ga/ga.service";
import { MapQuery } from "./map.query";
import * as moment from "moment";
import { GoogleMap } from "@angular/google-maps";

export interface Location {
  lat: number;
  lng: number;
  viewport?: Object;
  zoom: number;
  address_level_1?: string;
  address_level_2?: string;
  address_country?: string;
  address_zip?: string;
  address_state?: string;
  locationSource?: string;
}

@Component({
  selector: "kc-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnInit, OnDestroy {

  @ViewChild(GoogleMap, { static: true }) map: GoogleMap;

  private pixelToCoordinateRatio = 0.0001641 * Math.pow(2, 12);
  // This isn't the exact ratio but close enough. Do better logic if you have time and brain to it.

  public clusterStyle;
  public futureClusterStyle;
  public zoomLevel: number;
  protected mapInstance: google.maps.Map;
  public options: google.maps.MapOptions = {
    disableDefaultUI: true,
    streetViewControl: false,
    zoomControl: false,
    maxZoom: 16,
    minZoom: 5,
    gestureHandling: "greedy",
    styles: mapStyle,
  };
  
  public userMarkerOptions: google.maps.MarkerOptions = {
    icon: {
      url: "../../assets/icons/svg/circle.svg",
      scaledSize: new google.maps.Size(45, 45),
      anchor: new google.maps.Point( 18, 18),
    },
    zIndex: 11,
    clickable: false
  }
  // center: Location;
  userPosition$: Observable<GeolocationPosition>;
  isPositionFound$: Observable<boolean>;
  chargePoints$: Observable<ChargePoint[]>;
  futureChargePoints$: Observable<ChargePoint[]>;
  activeChargePoint$ = this.chargePointsQuery.activeChargePoint$;
  chargePointChanged$ = new Subject<boolean>();
  loadingChargePoints$: Observable<boolean>;
  mapCenter$: Observable<Location>;
  mapCenter: Location;
  isRouteContainerOpen$: Observable<boolean>;
  device$: Observable<string>;
  activeChargePoint: ChargePoint;
  private routerContainerIsOpen: boolean;
  private oldLat: number;
  private oldLng: number;
  private oldRouterContainerState: boolean;
  private mapCentered = false;
  apiLoaded: Observable<unknown>;

  constructor(
    private userLocationQuery: UserLocationQuery,
    public chargePointsQuery: ChargePointsQuery,
    private chargePointsService: ChargePointsService,
    public mapService: MapService,
    private router: Router,
    private windowService: WindowService,
    private cdr: ChangeDetectorRef,
    private topBarService: TopBarService,
    private gaService: GaService,
    private mapQuery: MapQuery,
    private loaderService: LoaderService,
  ) {
    this.clusterStyle = clusterStyle;
    this.futureClusterStyle = futureClusterStyle;
  }

  ngOnInit() {
    this.loadingChargePoints$ = this.chargePointsQuery.selectLoading();
    this.userPosition$ = this.userLocationQuery.userPosition$;
    this.chargePoints$ = this.chargePointsQuery.visibleChargePoints$;
    this.futureChargePoints$ = this.chargePointsQuery.futureChargePoints$;
    this.activeChargePoint$.subscribe((res) => {
      this.activeChargePoint = res;
      if (!res) {
        return;
      }
      this.cdr.markForCheck();
    });

    this.mapCenter$ = this.mapQuery.mapCenter$;
    this.isRouteContainerOpen$ = this.windowService.isRouteContainerOpen$;
    this.device$ = this.windowService.deviceTarget$;

    combineLatest([this.mapCenter$, this.isRouteContainerOpen$, this.device$])
      .pipe(
        map(([center, isOpen, device]) => {
          return { center, isOpen, device };
        })
      )
      .subscribe((res) => {
        // Triggers on all changes in any of the Observables.
        let center = res.center;
        if (center && center.locationSource) {
          this.mapCentered = true;
        }
        if (this.mapInstance) {
          if (
            (!this.chargePointsQuery.getActive() &&
              center.locationSource !== "userLocation" &&
              center.locationSource !== "chargePointLocation") ||
            this.routerContainerIsOpen !== res.isOpen
          ) {
            center = this.mapService.getLocationFromMapInstance(
              this.mapInstance
            );
          }
        }
        this.routerContainerIsOpen = res.isOpen;
        this.configurateMap(center, res.isOpen, res.device);
      });

    // If map has not already been centered, center it to the user after receiving first coordinates.
    this.userPosition$
      .pipe(first((userPosition) => userPosition != null))
      .subscribe((_) => {
        if (!this.mapCentered) {
          this.mapService.centerMapToUser();
        }
      });
    this.loaderService.activateSpinner("map");
  }

  chargePointClicked(chargePointId: string) {
    this.chargePointsService.setChargeBoxActive(null);
    this.router.navigate([`charge-point`, chargePointId]);
    this.gaService.sendMapInteraction(
      "Kartta",
      "Latausasema valittu",
      chargePointId
    );
  }

  mapReady(mapInstance) {
    this.mapInstance = mapInstance;
    this.panMapToCenter();
  }

  onIdle() {
    this.loaderService.deactivateSpinner("map");
  }

  configurateMap(
    center: Location,
    isRouteContainerOpen: boolean,
    device: string
  ) {
    this.setCenterPoint(center, isRouteContainerOpen, device);
    if (this.mapInstance) {
      this.panMapToCenter();
    }
  }

  setCenterPoint(
    center: Location,
    isRouteContainerOpen: boolean,
    device: string
  ) {
    // This only works with routing container being set to half way of screen.
    let verticalPixels = this.windowService.getScreenHeight() / 4 + 20; // Fix this when routing container is super flexible.
    let horizontalPixels = 360;
    if (device !== "desktop") {
      horizontalPixels = 0;
      // if (device === "mobile") {
      //   // To keep Google trademarks visible on mobile devices.
      //   verticalPixels = (this.windowService.getScreenHeight() - 80) / 4 + 20;
      // }
    } else {
      verticalPixels = 0;
    }
    this.mapCenter = {
      lat: this.setLatitude(
        center,
        isRouteContainerOpen,
        device,
        verticalPixels
      ),
      lng: this.setLongitude(
        center,
        isRouteContainerOpen,
        device,
        horizontalPixels
      ),
      zoom: center.zoom,
    };
    this.oldRouterContainerState = isRouteContainerOpen;
    this.cdr.markForCheck();
  }

  setLatitude(
    center: Location,
    isRouteContainerOpen: boolean,
    device: string,
    verticalPixels: number
  ): number {
    let lat = center.lat;

    if (isRouteContainerOpen) {
      lat =
        center.lat -
        (verticalPixels * this.pixelToCoordinateRatio) /
          Math.pow(2, center.zoom);
    } else {
      lat =
        center.lat +
        (verticalPixels * this.pixelToCoordinateRatio) /
          Math.pow(2, center.zoom);
    }
    if (
      center.locationSource === "userLocation" ||
      this.oldRouterContainerState === isRouteContainerOpen
    ) {
      lat = center.lat;
    }

    if (
      center.locationSource === "chargePointLocation" &&
      isRouteContainerOpen
    ) {
      lat =
        center.lat -
        (verticalPixels * this.pixelToCoordinateRatio) /
          Math.pow(2, center.zoom);
    }

    this.oldLat = lat;

    return lat;
  }

  setLongitude(
    center: Location,
    isRouteContainerOpen: boolean,
    device: string,
    horizontalPixels: number
  ): number {
    let lng =
      center.lng -
      (horizontalPixels * this.pixelToCoordinateRatio) /
        Math.pow(2, center.zoom);

    if (Math.abs(center.lng - this.oldLng) < 0.000001) {
      lng = center.lng;
    }

    this.oldLng = lng;

    return lng;
  }

  panMapToCenter() {
    this.mapInstance.panTo({
      lat: this.mapCenter.lat,
      lng: this.mapCenter.lng,
    });
    this.mapInstance.setZoom(this.mapQuery.getValue().mapCenter.zoom);
  }

  mapClick() {
    this.chargePointsService.changeActiveChargePoint(null);
    this.chargePointsService.setChargeBoxActive(null);
    // this.mapService.setMapCenterToCurrentCenterOfMap(this.mapService.getLocationFromMapInstance(this.mapInstance));
    this.router.navigate(["/profile"]);
    this.windowService.setRouteContainerOpenState(false);
    this.topBarService.closeOpenModals();
  }

  onZoomChange() {
    this.zoomLevel = this.mapInstance.getZoom();
  }

  getIconUrl(chargePoint: ChargePoint, activeChargePoint: ChargePoint) {
    if (activeChargePoint && chargePoint.id === activeChargePoint.id) {
      if (chargePoint.roaming) {
        if (chargePoint.operatorID === "DE*ION" || chargePoint.operatorID === "DE*IOY") {
          return "../../../assets/icons/png/icon_IonitySelected@2x_blue.png";
        }
        return "../../../assets/icons/png/02_OtherSelected@2x.png";
      }
      if (
        moment(chargePoint.installationDate).isAfter(moment()) &&
        !chargePoint.operative
      ) {
        return "../../../assets/icons/png/02_Selected_gray@2x.png";
      }
      return "../../../assets/icons/png/02_Selected@2x.png";
    } else if (chargePoint.roaming) {
      if (chargePoint.operatorID === "DE*ION" || chargePoint.operatorID === "DE*IOY") {
        return "../../../assets/icons/png/icon_Ionity@2x_blue.png";
      }
      return "../../../assets/icons/png/02_other_circle@2x.png";
    } else if (
      moment(chargePoint.installationDate).isAfter(moment()) &&
      !chargePoint.operative
    ) {
      return "../../../assets/icons/png/01_Normal_gray@2x.png";
    } else {
      return "../../../assets/icons/png/01_Normal@2x.png";
    }
  }

  trackByFn(index) {
    return index;
  }

  ngOnDestroy() {
    this.chargePointChanged$.next(true);
    this.chargePointChanged$.unsubscribe();
  }
}
