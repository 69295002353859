import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import 'hammerjs';
import { debounceTime } from 'rxjs/operators';


if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

// Init persist state storage. Debounce will reduce the amount of state updates and increases the performace a lot.
// https://datorama.github.io/akita/docs/enhancers/persist-state/
const storage = persistState({include:
  ['charge-points', 'charge-boxes', 'discount', 'k-id-session', 'store'], preStorageUpdateOperator: () => debounceTime(2000) });
const providers = [{ provide: 'persistStorage', useValue: storage}];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}
