<!-- This component loops through complex pricingProduct object of Hubject charge box -->
<!-- Handles default pricing, pricing product data records and additional references if they exists -->
<ng-container *ngIf="socket.pricingProducts && socket.pricingProducts.length > 0; else priceNotFoundContainer">
  <div class="evsePricing__wrapper" *ngFor="let pricingProduct of socket.pricingProducts; let ind = index">
    <div style="margin-bottom: 5px" class="socket__priceContainer">
      <!-- Display the labels of charge boxes' additional prices (Fixed fee etc.). (Not displayed if defaultPricing is active) -->
      <div class="labels">
        <!-- Display labels for price availability times -->
        <ng-container *ngIf="displayProductAvailabilityTimes(pricingProduct)">
          <span class="-blue"> {{ 'priceAvailabilityTime' | translate }} </span>
          <ng-container *ngIf="displayProductAvailabilityTimes(pricingProduct)">
            <ng-container *ngFor="let date of pricingProduct.PricingProductDataRecords.ProductAvailabilityTimes">
              <div class="-blue">
                {{ date.on | lowercase | translate }} •
                <ng-container *ngFor="let timePeriod of date.Periods; let i = index">
                  {{ timePeriod.begin }} - {{ timePeriod.end }} {{ date['Periods'].length > 1 && i <  date['Periods'].length - 1 ? '&' : ''}}
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="socket__priceContainer">
      <!-- Display the labels of charge boxes' additional prices (Fixed fee etc.). (Not displayed if defaultPricing is active) -->
      <div class="labels">
        <!-- Display labels for prices -->
        <span>{{'pricingPrinciple' | translate}}:</span>
        <ng-container *ngIf="displayAdditionalPrices(pricingProduct)">
          <ng-container
            *ngFor="let additionalReference of pricingProduct.PricingProductDataRecords.AdditionalReferences">
            <span>{{getPricePrefix(additionalReference) | translate}}:</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="labels">
        <!-- Display the base price of hubject charge box. Is in format of 1€ / kWh -->
        <span>
          <span
            class="price">{{ getPricePerReferenceUnit(pricingProduct) | localCurrency:getProductPriceCurrency(pricingProduct)}}
          </span>
          <span class="unit">{{ getPriceUnit(pricingProduct) }}</span>
        </span>
        <!-- Display the additional price values of hubject charge box. Are in format of 1€ / kWh. (Not displayed if defaultPricing is active) -->
        <ng-container *ngIf="displayAdditionalPrices(pricingProduct)">
          <ng-container
            *ngFor="let additionalReference of pricingProduct.PricingProductDataRecords.AdditionalReferences">
            <span>
              <span class="price">{{ (additionalReference['PricePerAdditionalReferenceUnit'] || 0) |
                        localCurrency:pricingProduct['PricingProductDataRecords']['ProductPriceCurrency'] }} </span>
              <span class="unit">{{ getAdditionalPriceUnit(additionalReference, true) }}</span>
            </span>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
<!-- If no pricing products are found, display default priceNotFound label -->
<ng-template #priceNotFoundContainer>
  <kc-local-loader [message]="'loadingPrices'"></kc-local-loader>
  <!-- <div class="socket__priceContainer">
    <div class="labels">
      <span>{{'pricingPrinciple' | translate}}:</span>
    </div>
    <div class="labels">
      <span>
        <span class="unit">{{'priceNotFound' | translate}}</span>
      </span>
    </div>
  </div> -->
</ng-template>
