import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kc-invoicing-agreement-invoice',
  templateUrl: './invoicing-agreement-invoice.component.html',
  styleUrls: ['./invoicing-agreement-invoice.component.scss'],
})
export class InvoicingAgreementInvoiceComponent implements OnInit {
  @Input() monthlyInvoice;

  constructor() { }

  ngOnInit() {}
}
