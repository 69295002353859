import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  exports: [UpdateDialogComponent],
  declarations: [UpdateDialogComponent]
})
export class UpdateDialogModule { }
