<div class="topBar__container">
  <!--K-Charge logo on desktop-->
  <div class="topBar__logoContainer">
    <img src="../../../assets/logos/k-charge-logo-big.svg" alt="k-charge-logo">
  </div>
  <!--Center map to user location button-->
  <div *ngIf="(activeTransactions.length === 0); else activeTransactionTpl" class="topBar__actionButton --centerMap"
  [ngClass]="(isUserLocationFound$ | async) ? '-active' : '-inactive'"
    tabindex="0" (click)="centerMapToUser()">
    <img [src]="(isUserLocationFound$ | async) ? '../../../assets/icons/png/icon-center@2x.png' : '../../../assets/icons/png/icon-center@2x-gray.png'" alt="grey if no location">
  </div>

  <!--Open active transactions component-->
  <ng-template #activeTransactionTpl>
    <div class="topBar__actionButton --centerMap" tabindex="0" (click)="openActiveTransactions()">
      <div class="activeInfoCounter --transactions" *ngIf="activeTransactions.length > 1">{{ activeTransactions.length }}</div>
      <div class=spinner></div>
      <img class="battery" src="../../../assets/icons/png/K-Lataus@2x.png">
    </div>
  </ng-template>
  <!--Search bar component-->
  <kc-search-bar></kc-search-bar>
  <!--User container on desktop when user logged in-->
  <div class="topBar__userContainer" tabindex="0" *ngIf="(name$ | async) as name; else login" (click)="openProfile()">
    <img src="../../../assets/icons/png/icon-profile@2x.png" alt="">
    <div class="--user">
      <span>{{ name }}</span>
    </div>
  </div>
  <!--Login button on desktop-->
  <ng-template #login>
    <div class="topBar__loginButton" tabindex="0" (click)="openWidget()">
      <span>{{ 'login'| translate | uppercase }}</span>
    </div>
  </ng-template>

  <!--Open filter button-->
  <div class="topBar__actionButton --openFilters" tabindex="0" (click)="openFilterTemplate()">
    <!-- <div class="activeInfoCounter" *ngIf="activeFiltersLength < filtersTotalLength">{{filtersTotalLength - activeFiltersLength}}</div> -->
    <img src="../../../assets/icons/png/icon-settings@2x.png" alt="">
  </div>
  <kc-filter-options [hidden]="!(showFilter$ | async)"></kc-filter-options>
</div>

<ng-container *ngIf="(activeTransactions.length > 0 && (showActiveTransactions$ | async))">
  <kc-active-transaction-container [activeTransactions]="activeTransactions"></kc-active-transaction-container>
</ng-container>
