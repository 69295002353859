import { NgModule } from '@angular/core';
import { ChargePointChargerComponent } from './charge-point-charger.component';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocalPipesModule } from 'src/app/language/local-pipes.module';
import { ChargerPriceComponent } from './charger-price/charger-price.component';


@NgModule({
    declarations: [
      ChargePointChargerComponent,
      ChargerPriceComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        LocalPipesModule
    ],
    exports: [
      ChargePointChargerComponent
    ]
  })
  export class ChargePointChargerModule { }
