import { Component, OnInit, Input } from '@angular/core';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { socketStatusType } from '../socket.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'kc-socket-status',
  templateUrl: './socket-status.component.html',
  styleUrls: ['./socket-status.component.scss']
})
export class SocketStatusComponent implements OnInit {

  @Input() socket: ChargeBox;
  @Input() socketTransactionStatus?: socketStatusType;

  isCharging = false;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {}

  socketStatus(status) {

    if (this.socketTransactionStatus === socketStatusType.ERROR) {
      this.isCharging = false;
      return '--faulted';
    }

    if (this.activatedRoute.routeConfig.component.name === 'ActiveTransactionComponent') {
      this.isCharging = true;
      return '--charging';
    }

    switch (status.toLowerCase()) {
      case 'available':
        this.isCharging = false;
        return '--available';
        break;

      case 'occupied':
        this.isCharging = false;
        return '--occupied';
        break;

      case 'faulted':
        this.isCharging = false;
        return '--faulted';
        break;

      default:
        this.isCharging = false;
        return '--occupied';
        break;
    }
  }

}
