import { Component, OnInit, Input } from '@angular/core';
import { ActiveTransaction } from 'src/app/routes/charge-point/active-transaction/active-transaction.service';
import { ChargePointsQuery } from 'src/app/map/charge-points/charge-points.query';
import { MapService } from 'src/app/map/state/map.service';
import { Router } from '@angular/router';
import { WindowService } from 'src/app/window-service/window.service';
import { TopBarService } from '../top-bar.service';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';

@Component({
  selector: 'kc-active-transaction-container',
  templateUrl: './active-transaction-container.component.html',
  styleUrls: ['./active-transaction-container.component.scss']
})
export class ActiveTransactionContainerComponent implements OnInit {

  @Input() activeTransactions: ActiveTransaction[];

  constructor(private chargePointsQuery: ChargePointsQuery,
              private mapService: MapService,
              private router: Router,
              private windowService: WindowService,
              private topBarService: TopBarService,
              private chargeBoxesQuery: ChargeBoxesQuery) { }

  ngOnInit() {
  }

  /**
   * Focus map on selected active transaction charge point and opens active transaction router container
   * @param activeTransaction selected active transaction
   */
  selectActiveTransaction(activeTransaction: ActiveTransaction) {

    const chargePoint = this.chargePointsQuery.getEntity(activeTransaction.chargePointId);
    if (chargePoint.coordinate) {
      this.mapService.setMapCenterToCoordinates(chargePoint.coordinate);
    }
    this.router.navigate(['charge-point', activeTransaction.chargePointId, 'socket', activeTransaction.chargeBoxId, 'active']);
    this.windowService.setRouteContainerOpenState(true);
    this.topBarService.closeOpenModals();
  }

  /**
   * Get right socket icon for active transaction
   * @param socketType charge box socket type string
   * @param roaming roaming flag boolean
   */
  getSocketPic(socketType: string, roaming: boolean): string {
    let picture: string;
    if (socketType) {
      picture = `-${socketType.replace(/\s/g, '')}${!roaming ? 'Orange' : 'Blue'}`;
    } else {
      picture = '';
    }
    return picture;
  }

  getChargeBox(id) {
    const chargeBox = this.chargeBoxesQuery.getEntity(id);
    return chargeBox;
  }

}
