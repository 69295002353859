import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FilterOption } from '../state/filter-option.model';
import { Options, CustomStepDefinition, ChangeContext } from 'ngx-slider-v2';
import { TopBarService } from '../../top-bar.service';

const MAX_VALUE = 999999999;

@Component({
  selector: 'kc-filter-power-slider',
  templateUrl: './filter-power-slider.component.html',
  styleUrls: ['./filter-power-slider.component.scss']
})
export class FilterPowerSliderComponent implements OnInit {
  isMaxValue = false;
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();

  @Input() set setFilterOptions(filterOptions: FilterOption[]) {

    if (filterOptions) {
      this.filterOptions = filterOptions;
      this.numbers = filterOptions.map(option => ((option.tag ? option.tag as number : option.type as number) / 1000));
      this.value = this.getMinValue(filterOptions);
      this.highValue = this.getMaxValue(filterOptions);

      const stepsArray = this.numbers.map((number: number): CustomStepDefinition => ({ value: number, legend: number.toString() }));
      stepsArray[stepsArray.length -1].legend += '+';
      this.options = {
        stepsArray,
        showTicks: true,
      };
      this.setSliderRangeLabel(this.value, this.highValue, this.filterOptions);
    }
  }

  @Output() public change = new EventEmitter<FilterOption[]>();

  filterOptions: FilterOption[];
  numbers: number[] = [];
  value: number;
  highValue: number;
  options: Options;
  minValueLabel: string;
  maxValueLabel: string;

  constructor(private topBarService: TopBarService) { }

  ngOnInit() {
    // trigger manual refresh to fix Safari bug
    this.topBarService.isFilterContainerOpen$.subscribe(res => {
      if (res) {
        this.manualRefresh.emit();
      }
    });
  }

   /**
   * Get max value from the given power filter options
   * @param options power filter options array
   */
  getMinValue(options: FilterOption[]) {
    const powerRange = options.filter(option => option.active).map(option => option.type as number);
    return Math.min(...powerRange) / 1000;
  }

  /**
   * Get max value from the given power filter options
   * @param options power filter options array
   */
  getMaxValue(options: FilterOption[]) {
    const powerRange = options.filter(option => option.active).map(option => option.type as number);
    return Math.max(...powerRange) / 1000;
  }

  getFilterObjectValue(value: number, options: FilterOption[]) {
    return options.find(option => option.tag == Number(value) || option.type == Number(value));
  }

  /**
   * Triggers when user ends drag on slider.. this calls update function if there are something changed..
   * @param changeContext event from slider. Contains value and highValue
   */
  onUserChangeEnd(changeContext: ChangeContext, filterOptions: FilterOption[]): void {

    const newMinValue = changeContext.value;
    const newMaxValue = changeContext.highValue;
    const oldMinValue = this.getMinValue(filterOptions);
    const oldMaxValue = this.getMaxValue(filterOptions);

    this.setSliderRangeLabel(newMinValue, newMaxValue, this.filterOptions);


    if (newMinValue !== oldMinValue || newMaxValue !== oldMaxValue) {
      filterOptions = filterOptions.map(option => {
        if (Number(option.tag || option.type) < (newMinValue * 1000) || Number(option.tag || option.type) > (newMaxValue * 1000)) {
          option.active = false;
          return option;
        }
        option.active = true;
        return option;
      });

      this.change.emit(filterOptions);
    }
  }

  private setSliderRangeLabel(minValue, maxValue, filterOptions) {
    const minObject = this.getFilterObjectValue(minValue * 1000, filterOptions);
    const maxObject = this.getFilterObjectValue(maxValue * 1000, filterOptions);

    if (minObject) {
      this.minValueLabel = ((minObject.tag || minObject.type) as number / 1000).toString();
    }
    if (maxObject) {
      this.maxValueLabel = ((maxObject.tag || maxObject.type) as number / 1000).toString();
      if (maxObject.type == MAX_VALUE) {
        this.isMaxValue = true;
      } else {
        this.isMaxValue = false;
      }
    }
  }
}
