
const PAYMENT_INFO = 'paymentInfo';

/**
 * Get cookie consent
 * If not found, then return null as not set state.
 */
export function getPaymentInfoDialog(): boolean {
    const cookieConsent = localStorage.getItem(PAYMENT_INFO);
    return cookieConsent ? JSON.parse(cookieConsent) : null;
}

/**
 * Set cookie consent
 * @param consent user consent to cookie dialog
 */
export function setPaymentInfoDialog(consent: boolean) {
    localStorage.setItem(PAYMENT_INFO, JSON.stringify(consent));
}

export function clearPaymentInfoDialog() {
    if (getPaymentInfoDialog()) {
        localStorage.removeItem(PAYMENT_INFO);
    }
}
