import { TransactionFeedbackPowerType } from "../services/transaction-feedback.service";

export class ConverterHelper {
  static convertPowerType(chargeBoxPowerType: string) {
    let powerType = TransactionFeedbackPowerType.UNKNOWN;

    if (chargeBoxPowerType.toLowerCase().includes('ac')) {
      powerType = TransactionFeedbackPowerType.AC;
    } else if (chargeBoxPowerType.toLowerCase().includes('dc')) {
      powerType = TransactionFeedbackPowerType.DC;
    }

    return powerType;
  }
}