import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFinnish from '@angular/common/locales/fi';
import localeSwedish from '@angular/common/locales/sv-FI';
import localeEnglish from '@angular/common/locales/en-GB';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  private _locale: string;

  set locale(value: string) {
    this._locale = value;
  }

  get locale(): string {
    if (!this._locale) {
      this.registerCulture(localStorage.getItem('language') || 'en');
    }
    return this._locale;
  }

  registerCulture(culture: string) {
    if (!culture) {
      return;
    }

    switch (culture) {
      case 'fi': {
        this.locale = 'fi';
        registerLocaleData(localeFinnish);
        break;
      }
      case 'sv': {
        this.locale = 'sv-FI';
        registerLocaleData(localeSwedish);
        break;
      }
      case 'en':
      default: {
        this.locale = 'en-GB';
        registerLocaleData(localeEnglish);
        break;
      }
    }
  }
}
