import { Component, Input } from '@angular/core';
import {
  ChargeBox, EvseReferenceUnit,
  EvseAdditionalReference,
  EvseAdditionalReferenceType,
  EvsePricingProducts } from 'src/app/map/charge-boxes/charge-box.model';

@Component({
  selector: 'kc-evse-pricing',
  templateUrl: './evse-pricing.component.html',
  styleUrls: ['./evse-pricing.component.scss']
})
export class EvsePricingComponent {
  @Input() socket: ChargeBox;

  constructor() { }

  /**
   * Returns currency.
   * Returns either the currency of PricingProductDataRecords or DefaultPrice.
   * @param pricingProduct hubject pricing product object.
   */
  getProductPriceCurrency(pricingProduct: EvsePricingProducts) {
    return !pricingProduct.defaultPricing ? pricingProduct.PricingProductDataRecords.ProductPriceCurrency
    : pricingProduct.PricingDefaultPriceCurrency;
  }

  /**
   * Returns price value.
   * Returns either the price of PricingProductDataRecords or DefaultPrice.
   * @param pricingProduct hubject pricing product object.
   */
  getPricePerReferenceUnit(pricingProduct: EvsePricingProducts) {
    return (!pricingProduct.defaultPricing ? (pricingProduct.PricingProductDataRecords.PricePerReferenceUnit || 0)
    : pricingProduct.PricingDefaultPrice);
  }

  /**
   * Return boolean if availability times should be displayed.
   * Return false if defaultPricing is on or if availability times do not exist.
   * @param pricingProduct hubject pricing product object.
   */
  displayProductAvailabilityTimes(pricingProduct: EvsePricingProducts) {
    return !pricingProduct.defaultPricing
      && pricingProduct.PricingProductDataRecords
      && pricingProduct.PricingProductDataRecords.ProductAvailabilityTimes;
  }

  /**
   * Return boolean if additional prices should be displayed.
   * Return false if defaultPricing is on or if additional references do not exist.
   * @param pricingProduct hubject pricing product object.
   */
  displayAdditionalPrices(pricingProduct: EvsePricingProducts) {
    return !pricingProduct.defaultPricing
      && pricingProduct.PricingProductDataRecords
      && pricingProduct.PricingProductDataRecords.AdditionalReferences;
  }

  /**
   * Formats hubject's price unit into desired format.
   * @param pricingProductUnit unit to format.
   */
  private formatPriceUnit(pricingProductUnit: EvseReferenceUnit) {
    let unit = '';
    switch (pricingProductUnit) {
      case EvseReferenceUnit.HOUR: {
        unit = ' / h';
        break;
      }
      case EvseReferenceUnit.MINUTE: {
        unit = ' / min';
        break;
      }
      case EvseReferenceUnit.KILOWATT_HOUR: {
        unit = ' / kWh';
        break;
      }
    }
    return unit;
  }

  /**
   * Returns formatted price unit. Picks correct reference unit based on the state of defaultPricing boolean.
   * @param pricingProduct hubject pricing product object.
   */
  getPriceUnit(pricingProduct: EvsePricingProducts) {
    const pricingProductUnit = !pricingProduct.defaultPricing ? pricingProduct.PricingProductDataRecords.ReferenceUnit
      : pricingProduct.PricingDefaultReferenceUnit;

    return this.formatPriceUnit(pricingProductUnit);
  }

  /**
   * Returns formatted price unit. Formats unit if the AdditionalReference meets given condition.
   * @param pricingProduct hubject pricing product object.
   */
  getAdditionalPriceUnit(pricingProducts: EvseAdditionalReference) {
    const pricingProductUnit = pricingProducts.AdditionalReferenceUnit;
    let unit = '';
    // Only format price unit if it's not any of the following types. Otherwise returns am empty string.
    if (pricingProducts.AdditionalReference !== EvseAdditionalReferenceType.START_FEE
        && pricingProducts.AdditionalReference !== EvseAdditionalReferenceType.FIXED_FEE) {
      unit = this.formatPriceUnit(pricingProductUnit);
    }

    return unit;
  }

  /**
   * Returns and formats prefix for hubject's additional price. (Fixed fee, Starting fee etc.)
   * @param additionalReference of hubject pricing product.
   */
  getPricePrefix(additionalReference: EvseAdditionalReference) {
    let prefix = '';
    if (additionalReference && additionalReference.AdditionalReference) {
      switch (additionalReference.AdditionalReference) {
        case EvseAdditionalReferenceType.FIXED_FEE: {
          prefix = 'fixedFee';
          break;
        }
        case EvseAdditionalReferenceType.MAXIMUM_FEE: {
          prefix = 'maximumFee';
          break;
        }
        case EvseAdditionalReferenceType.MINIMUM_FEE: {
          prefix = 'minimumFee';
          break;
        }
        case EvseAdditionalReferenceType.PARKING_FEE: {
          prefix = 'parkingFee';
          break;
        }
        case EvseAdditionalReferenceType.START_FEE: {
          prefix = 'startingPrice';
          break;
        }
        default: {
          prefix = additionalReference.AdditionalReference;
          break;
        }
      }
    }
    return prefix;
  }
}
