<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="errorIcon">
        <img src="../../../../assets/icons/png/icon-info@2x.png" alt="">
      </div>
      <div class="overlayContainer__header">
        <span class="info -padding">{{ 'paymentInfoTitle' | translate }}</span>
        <span class="info -padding">{{ 'paymentInfoText' | translate }}</span>
        <span class="info -padding">{{ 'paymentInfoText2' | translate }}</span>
        <span class="info -padding">{{ 'paymentInfoText3' | translate }}
            <a href="{{ environment.K_LATAUS.BASE_URL }}">{{ 'paymentInfoWebsite' | translate }}</a>
              {{ 'paymentInfoText4' | translate }}
        </span>
      </div>
      <div class="overlayContainer__actionContainer">
          <kc-dialog-button [buttonText]="'continue'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
