<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="imageContainer">
        <div class="errorIcon">
          <img src="../../../../assets/icons/png/icon-info@2x.png" alt="">
        </div>
      </div>
      <div class="overlayContainer__header">
        <span class="title">Sovelluspäivitys vaaditaan</span>
      </div>
      <!-- <div class="border">//////////////////////////////////////////////////////////////////////////////////</div> -->
      <div class="overlayContainer__infoContainer">
        <span class="infoText">K-Tunnus-palvelun uudistuksen myötä K-Lataus-sovellus <span class="-bold">tulee päivittää uusimpaan versioon</span> ennen jatkamista.</span>
      </div>
      <div class="overlayContainer__infoContainer" *ngIf="isIosApp()">
        <span class="infoText">Voitte päivittää sovelluksen <span class="-bold">App Storesta</span></span>
      </div>
      <div class="overlayContainer__infoContainer" *ngIf="isAndroidApp()">
        <span class="infoText">Voitte päivittää sovelluksen <span class="-bold">Play Storesta</span></span>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'close'" [color]="'--orange'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>


<!-- <div class="overlayContainer__wrapper --h100 --scrollable">
    <div class="overlayContainer__contentWrapper --h100">
      <div class="overlayContainer__contentTemplate">
        <div class="headerContainer">
          <div class="errorIcon">
            <img src="../../../../assets/icons/png/icon-info@2x.png" alt="">
          </div>
          <span class="title">Sovelluspäivitys vaaditaan</span>
          <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
        </div>
        <div class="infoContainer">
          <span class="infoText">K-Tunnus-palvelun uudistuksen myötä K-Lataus-sovellus <span class="bold">tulee päivittää uusimpaan versioon</span> ennen jatkamista.</span>
        </div>
        <div class="infoContainer" *ngIf="isIosApp()">
          <span class="infoText">Voitte päivittää sovelluksen <span class="bold">App Storesta</span></span>
        </div>
        <div class="infoContainer" *ngIf="isAndroidApp()">
          <span class="infoText">Voitte päivittää sovelluksen <span class="bold">Play Storesta</span></span>
        </div>
        <div class="overlayContainer__actionContainer">
          <kc-dialog-button [buttonText]="'accept'" [color]="'--orange'" (onClick)="close()"></kc-dialog-button>
        </div>
      </div>
    </div>
  </div> -->
