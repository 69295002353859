import { Injectable } from '@angular/core';
import {MapGeocoder} from '@angular/google-maps';
import { Observable, of, from } from 'rxjs';
import { tap, map, switchMap } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class SearchBarService {

  constructor(private geocoder: MapGeocoder) { }

  geocodeAddress(location: string): Observable<any> {
    return new Observable(observer => {
      this.geocoder.geocode({ 'address': location, 'region': 'fi' }).subscribe((response) => {
        if (response.status === google.maps.GeocoderStatus.OK) {
          observer.next({
            formattedAddress: response.results[0].formatted_address,
            lat: response.results[0].geometry.location.lat(),
            lng: response.results[0].geometry.location.lng()
          });
        } else {
          console.error('Error - ', response.results, ' & Status - ', status);
          observer.next({ lat: null, lng: null });
        }
        observer.complete();
      })
    })
  }
}
