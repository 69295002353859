<div class="invoiceBalanceContainer">
  <div class="invoiceBalanceContainer__iconContainer">
    <i class="fak fa-k-receipt fa-xl --icon"></i>
  </div>
  <div class="invoiceBalanceContainer__textContainer">
    <span class="--header" [ngClass]="monthlyInvoice.totalPrice === 0 ? '-disabled' : ''">{{ monthlyInvoice.month | localDate:'LLLL' | titlecase }} {{ 'fee' | translate }} •
      {{ monthlyInvoice.totalPrice !== 0 ? monthlyInvoice.transactions : '0' }}
      {{ 'chargingTransactions' | translate}}</span>
    <span class="--balance" [ngClass]="monthlyInvoice.totalPrice === 0 ? '-disabled' : ''">{{ monthlyInvoice.totalPrice / 100 | localCurrency }}</span> 
  </div>
</div>