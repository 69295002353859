import * as moment from 'moment';

export class PaymentTimeDifferenceHelper {
    static calculateDifference(monthlyInvoiceDate, days) {
        const now = moment();
       
        // Use moment to calculate first monday of month for billing.
        let tillDate = moment(monthlyInvoiceDate).date(1).add(1, 'month').day(8);
        if (tillDate.date() > 7)
        tillDate.day(-6);
        
        const difference = moment.duration(tillDate.diff(now)).asDays();
        return Math.round(difference);
    }
}
