import { ID, guid } from '@datorama/akita';
import { ServiceIconHelper } from 'src/app/helpers/service-icon-helper';
import { keskoServicesByCategory, ServiceTypes } from 'src/app/k-store/k-store-services';
import { SocketTypes } from '../../../map/charge-boxes/charge-box-socket-types';

const IONITY_HUBJECT_ID = 'FI*001';
const ROAMING_STATIONS = 'roamingStations';
const WITHOUT_REMOTE = 'otherLocations';
const FUTURE_CHARGE_POINTS = 'futureChargePoints'


export const FilterTypes = {
  TYPE2: SocketTypes.TYPE_2,
  CCS2: SocketTypes.CCS2,
  CHADEMO: SocketTypes.CHADEMO,
  // SCHUKO: SocketTypes.SCHUKO,
  // TYPE1: SocketTypes.TYPE_1,
  FUTURE_CHARGE_POINTS: FUTURE_CHARGE_POINTS,
  ROAMING_STATIONS: ROAMING_STATIONS,
  IONITY: IONITY_HUBJECT_ID,
  WITHOUT_REMOTE: WITHOUT_REMOTE,
  RESTAURANT: ServiceTypes.RESTAURANT,
  BATHROOM: ServiceTypes.BATHROOM,
  POST: ServiceTypes.POST,
  CASH_WITHDRAWAL: ServiceTypes.CASH_WITHDRAWAL,
  CAFE: ServiceTypes.CAFE,
  CARWASH: ServiceTypes.CARWASH,
  SALADBAR: ServiceTypes.SALADBAR,
  SUSHI: ServiceTypes.SUSHI,
  ONLINE_ORDER_PICKUP: ServiceTypes.ONLINE_ORDER_PICKUP,
  SERVICE_FILTERS_ENABLED: 'serviceFiltersEnabled',
};

export interface FiltersState {
  power: FilterOption[];
  socketType: FilterOption[];
  roaming: FilterOption[];
  futureChargePoints: FilterOption[];
  services: FilterOption[];
  serviceFiltersEnabled: FilterOption[];
}

export interface ChargeBoxFilters {
  power: FilterOption[];
  socketType: FilterOption[];
}

export interface FilterOption {
  id: ID;
  type: string | number;
  description?: string;
  icon?: string;
  active: boolean;
  tag?: string | number;
  legacyTag?: string | number;
}

export interface ChargePointFilters {
  roaming: FilterOption[];
}

/**
 * A factory function that creates FilterOptions
 */
export function createFilterOption(filters: Partial<FilterOption>) {
  return {
    id: guid(),
    active: true,
    ...filters
  } as FilterOption;
}

/**
 * Creates new filter options array
 */
export function createInitialFiltersState() {

  const filters: FiltersState = {
    power: [
      // createFilterOption({ type: 0, description: 'Wh' }),
      createFilterOption({ type: 11000, description: 'Wh'}),
      createFilterOption({ type: 25000, description: 'Wh' }),
      createFilterOption({ type: 50000, description: 'Wh' }),
      createFilterOption({ type: 125000, description: 'Wh' }),
      // always keep the lagacyTag as 350000
      createFilterOption({ type: 999999999, description: 'Wh', tag: 350000, legacyTag: 350000 }),
    ],
    socketType: [
      createFilterOption({ type: FilterTypes.TYPE2, icon: '../../../../../assets/icons/png/icon-Type2@2x.png' }),
      createFilterOption({ type: FilterTypes.CCS2, icon: '../../../../../assets/icons/png/icon-CCS2@2x.png' }),
      createFilterOption({ type: FilterTypes.CHADEMO, icon: '../../../../../assets/icons/png/icon-CHAdeMO@2x.png' }),
      // createFilterOption({ type: FilterTypes.TYPE1, icon: '../../../../../assets/icons/png/icon_type1@2x.png' }),
      // createFilterOption({ type: FilterTypes.SCHUKO, active: false, icon: '../../../../../assets/icons/png/icon-schuko@2x.png' }),
    ],
    roaming: [
      // tslint:disable-next-line:max-line-length
      createFilterOption({ type: FilterTypes.ROAMING_STATIONS, description: 'thirdPartyStations', icon: '../../../../../assets/icons/png/POI_Roaming@2x.png' }),
    ],
    futureChargePoints: [
      createFilterOption({ type: FilterTypes.FUTURE_CHARGE_POINTS, description: 'futureChargePointsBrief', icon: '../../../../../assets/icons/png/01_Normal_gray@2x.png'})
    ],
    /* ionity: [
      // tslint:disable-next-line:max-line-length
      createFilterOption({ type: FilterTypes.IONITY, description: 'ionityStations', icon: '../../../../../assets/icons/png/POI_Roaming@2x.png' }),
    ], */
    services: Object.keys(keskoServicesByCategory).map(key => createFilterOption({ type: key, icon: ServiceIconHelper.getServiceIcon(key), active: false})),
    // services: [
      // createFilterOption({ type: FilterTypes.RESTAURANT, icon: ServiceIconHelper.getServiceIcon(FilterTypes.RESTAURANT), active: false}),
      // createFilterOption({ type: FilterTypes.CAFE, icon: ServiceIconHelper.getServiceIcon(FilterTypes.CAFE), active: false}),
      // createFilterOption({ type: FilterTypes.BATHROOM, icon: ServiceIconHelper.getServiceIcon(FilterTypes.BATHROOM), active: false}),
      // // createFilterOption({ type: FilterTypes.POST, icon: ServiceIconHelper.getServiceIcon(FilterTypes.POST), active: false}),
      // createFilterOption({ type: FilterTypes.CASH_WITHDRAWAL, icon: ServiceIconHelper.getServiceIcon(FilterTypes.CASH_WITHDRAWAL), active: false}),
    // ]
    serviceFiltersEnabled: [createFilterOption({type: FilterTypes.SERVICE_FILTERS_ENABLED, active: true})]
  };

  return filters;
}

