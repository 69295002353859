import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import * as storage from '../updated-pricing-dialog/updated-pricing-dialog.storage';

@Component({
  selector: 'kc-updated-pricing-reminder-dialog',
  templateUrl: './updated-pricing-reminder-dialog.component.html',
  styleUrls: ['./updated-pricing-reminder-dialog.component.scss']
})
export class UpdatedPricingReminderDialogComponent implements OnInit {

  constructor(@Inject(PORTAL_DATA) public data) { }

  ngOnInit() {
  }

  close() {
    storage.setPricingUpdateReminderAgreement(true);
    this.data.close();
  }

}
