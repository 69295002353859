import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { HttpClient } from '@angular/common/http';
import { KIDSessionQuery } from '../k-id-session/k-id-session.query';
import { Observable, EMPTY, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { KIDSessionStore } from '../k-id-session/k-id-session.store';
import { catchError, tap, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class BlueCommerceService {

  constructor(private kIDSessionQuery: KIDSessionQuery,
    private http: HttpClient,
    private kIDSessionStore: KIDSessionStore) {
  }

  /**
  * Get blueCommerce payment meta data for credit card page
  */
  getSaveCardInitData(redirectPath: string): Observable<string> {

    if (this.kIDSessionQuery.isLoggedIn()) {
      // ${environment.BACKEND_URL}/profile/payment-init-data?redirecturl=${environment.PSP['REDIRECT_URL']}${redirectPath}
      // tslint:disable-next-line:max-line-length
      return this.http.post<any>(`${environment.BACKEND_URL}/profile/save-card-init-data`, '');
      /* catchError(err => {
        console.error(err);
        return throwError(err);
      }) */
    } else {
      return EMPTY;
    }
  }

  /**
   * Removes payment card from profile
   */
  deletePaymentCard(): Observable<any> {

    return this.http.delete<any>(`${environment.BACKEND_URL}/profile/payment-method`, { responseType: 'text' as 'json' }).pipe(
      catchError(err => {
        console.error(err);
        return throwError(err);
      }),
      tap(() => {
        const profile = this.kIDSessionQuery.getKChargeProfile();
        profile.paymentCard = null;
        this.kIDSessionStore.updateProfile(profile);
      }));
  }
}
