import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TopBarService {

  private isFilterContainerOpenSubject = new BehaviorSubject<boolean>(false);
  public isFilterContainerOpen$ = this.isFilterContainerOpenSubject as Observable<boolean>;

  private isActiveTransactionsContainerOpenSubject = new BehaviorSubject<boolean>(false);
  public isActiveTransactionsContainerOpen$ = this.isActiveTransactionsContainerOpenSubject as Observable<boolean>;

  private isSearchSuggestionsContainerOpenSubject = new BehaviorSubject<boolean>(false);
  public isSearchSuggestionsContainerOpen$ = this.isSearchSuggestionsContainerOpenSubject as Observable<boolean>;

  constructor() {
  }

  toggleFilterOpen(isOpen: boolean) {
    this.isFilterContainerOpenSubject.next(isOpen);
  }

  toggleActiveTransactionsOpen(isOpen: boolean) {
    this.isActiveTransactionsContainerOpenSubject.next(isOpen);
  }

  toggleSearchbarSuggestions(isOpen: boolean) {
    this.isSearchSuggestionsContainerOpenSubject.next(isOpen);
  }

  closeOpenModals() {
    this.toggleActiveTransactionsOpen(false);
    this.toggleFilterOpen(false);
  }
}
