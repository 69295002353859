<div class="overlayContainer__wrapper --scrollable">
  <ng-container *ngIf="(loggedIn$ | async); else notLoggedInHeader">
    <div class="overlayContainer__header">
      <span class="title">{{ 'addPaymentCardHeader' | translate }}</span>
    </div>
  </ng-container>
  <ng-template #notLoggedInHeader>
    <div class="overlayContainer__header">
      <span class="title">{{ (!roaming ? 'loginOrChargeWithSinglepayment' : 'login') | translate }}</span>
    </div>
  </ng-template>
  <ng-container *ngIf="!singlePayment; else singlePaymentTpl">
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="iconContainer">
          <div>
            <img class="k-id-widget" src="../../../../../assets/logos/k-id-login-widget.png" alt="k-id-login-widget">
          </div>
        </div>
        <ng-container *ngIf="(loggedIn$ | async); else notLoggedInTitle">
          <div class="overlayContainer__header">
            <span class="title">{{ 'addPaymentCardTitle' | translate }}</span>
            <span class="info">{{ 'addPaymentCardInfo' | translate }}</span>
          </div>
        </ng-container>
        <ng-template #notLoggedInTitle>
          <div class="overlayContainer__header">
            <span class="title">{{ 'createKID' | translate }}</span>
            <span class="info">{{ 'createKIDinfo' | translate }}</span>
          </div>
        </ng-template>
        <div class="overlayContainer__actionContainer --w100">
          <button class="overlayContainer__actionButton --white" (click)="close()">
            <span>{{ 'cancel' | translate | uppercase}}</span>
          </button>

          <button *ngIf="!roaming && !(loggedIn$ | async)" class="overlayContainer__actionButton --white"
          (click)="toggleSinglePayment()">
            <span>{{ 'chargeWithSinglePayment' | translate | uppercase}}</span>
          </button>
          
          <button class="overlayContainer__actionButton --orange" (click)="openWidget()"
            *ngIf="!(loggedIn$ | async) as loggedIn">
            <span>{{ 'loginOrCreateKID' | translate | uppercase}}</span>
          </button>
          
          <form style="width: 100%" *ngIf="(loggedIn$ | async)" ngNoForm action="{{pspUrl}}" method="POST">
            <input class="test" *ngFor="let obj of paymentInitData" name={{obj.key}} value="{{obj.value}}">
            <button (click)="disableAddCard()" [disabled]="!paymentInitData" class="overlayContainer__actionButton --orange" [ngClass]="isAddCardDisabled ? '-addCardDisabled' : ''">
              <span>{{ 'addPaymentCard' | translate | uppercase}}</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-template #singlePaymentTpl>
    <div class="overlayContainer__contentWrapper">
      <div class="overlayContainer__contentTemplate">
        <div class="iconContainer">
          <div class="-white">
            <img src="../../../../../assets/icons/png/icon-creditCard@3x.png" alt="">
          </div>
        </div>
        <div class="overlayContainer__header --botMargin">
          <span class="title">{{ 'addEmailTitle' | translate }}</span>
          <span class="info">{{ 'addEmailInfo' | translate }}</span>
        </div>
        <form *ngIf="!singlePaymentInitData" class="overlayContainer__contentTemplate" [formGroup]="singlePaymentForm"
          (ngSubmit)="save(singlePaymentForm)">
          <div class="inputContainer">
            <label for="email">{{ 'email' | translate }}</label>
            <input type="text" id="email" placeholder="xxx@xxx.com" formControlName="email">
            <div *ngIf="email?.valid && (email.dirty || email.touched)" class="validation --alert">
              <img src="../../../../assets/icons/png/icon-exclamation@2x_red.png" alt="">
            </div>
            <div *ngIf="email?.valid" class="validation --ok">
              <img src="../../../../assets/icons/png/icon-tick@2x_green.png" alt="">
            </div>
          </div>
          <div class="inputContainer --switch">
            <label for="acceptedTos">{{ 'tosAgreement' | translate }} <a href="{{ environment.K_LATAUS.TOS_URL }}"
                target="_blank">{{
                'tosAgreementLink' | translate}}</a></label>
            <div class="switch" tabindex="0" [ngClass]="{'on': agreement('acceptedTos')}"
              (click)="changeAgreement('acceptedTos')" (swipeleft)="changeAgreementSwipe('acceptedTos', false)" (swiperight)="changeAgreementSwipe('acceptedTos', true)"></div>
          </div>
          <div class="overlayContainer__actionContainer --w100">
            <button class="overlayContainer__actionButton --white" (click)="toggleSinglePayment()">
              <span>{{ 'cancel' | translate | uppercase}}</span>
            </button>
            <button type="submit" class="overlayContainer__actionButton --orange" [disabled]="!singlePaymentForm.valid">
              <span>{{ 'addEmail' | translate | uppercase}}</span>
            </button>
          </div>
        </form>
        <form id="addCardForm" class="overlayContainer__contentTemplate" [hidden]="!singlePaymentInitData" ngNoForm
          action="{{pspUrl}}" method="POST">
          <input class="test" *ngFor="let obj of singlePaymentInitData" name={{obj.key}} value="{{obj.value}}">
          <div class="overlayContainer__actionContainer --w100">
            <button class="overlayContainer__actionButton --white" (click)="toggleSinglePayment()">
              <span>{{ 'cancel' | translate | uppercase}}</span>
            </button>
            <button (click)="disableAddCard()" type="submit" class="overlayContainer__actionButton --orange" [ngClass]="isAddCardDisabled ? '-addCardDisabled' : ''" [disabled]="!singlePaymentInitData">
              <span>{{ 'addPaymentCard' | translate | uppercase}}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
