import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { environment } from 'src/environments/environment';
import * as storage from './updated-pricing-dialog.storage';

@Component({
  selector: 'kc-updated-pricing-dialog',
  templateUrl: './updated-pricing-dialog.component.html',
  styleUrls: ['./updated-pricing-dialog.component.scss']
})
export class UpdatedPricingDialogComponent implements OnInit {

  public activePageStatus$ = new BehaviorSubject<Pages>(Pages.first);
  public environment = environment;

  constructor(@Inject(PORTAL_DATA) public data,
  private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  nextPage() {
    if (this.activePageStatus$.value === (Object.keys(Pages).length / 2) - 1) {
      this.close();
    }
    this.activePageStatus$.next(this.activePageStatus$.value + 1);
    this.cdr.markForCheck();
  }

  previousPage() {
    if (this.activePageStatus$.value !== 0) {
      this.activePageStatus$.next(this.activePageStatus$.value - 1);
    }
    this.cdr.markForCheck();
  }

  goToPage(index: number) {
    this.activePageStatus$.next(index);
    this.cdr.markForCheck();
  }

  getPages() {
    const pages = Object.keys(Pages);
    return pages.slice(pages.length / 2);
  }

  getPagesLength() {
    return this.getPages().length - 1;
  }

  close() {
    storage.setPricingUpdateAgreement(true);
    this.data.close();
  }
}

enum Pages {
  first,
  second,
}
