import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from '../overlay-dialog.storage';
import { DiscountService } from 'src/app/routes/discounts/state/discount.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { DiscountQuery } from 'src/app/routes/discounts/state/discount.query';

@Component({
  selector: 'kc-add-discount-dialog',
  templateUrl: './add-discount-dialog.component.html',
  styleUrls: ['./add-discount-dialog.component.scss']
})
export class AddDiscountDialogComponent implements OnInit {
  loading = false;

  discountForm = new UntypedFormGroup({
    discountCode: new UntypedFormControl('', [Validators.required, Validators.minLength(1)])
  });

  redeemError: boolean;

  constructor(@Inject(PORTAL_DATA) public data,
              private discountService: DiscountService, private discountQuery: DiscountQuery) { }

  ngOnInit() {
    this.redeemError = false;
  }

  addDiscount() {
    if (this.discountForm.valid) {
      this.redeemError = false;
      this.loading = true;
      this.discountService.redeemDiscountCode(this.discountForm.get('discountCode').value)
      .subscribe(res => {
        if (res && res['success']) {
          this.discountService.get()
          .pipe(
            finalize(() => {
            this.loading = false;
          }))
          .subscribe(_ => {
            const discount = this.discountQuery.getLatestDiscount();
            if (discount) {
              this.data.openNewOverlayDialog('DiscountDialogComponent', discount);
            }
            this.close();
          });
        } else {
          this.loading = false;
          this.redeemError = true;
        }
      },
      _ => this.loading = false);
    } else {

    }
  }

  close() {
    this.data.close();
  }

}
