import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthSessionService } from './auth-session.service';
import { AuthSessionQuery } from './auth-session.query';

/**
 * Intercepts requests and confirms the auth session before making the request (if using a private route).
 */
@Injectable({providedIn: 'root'})
export class AuthInterceptor implements HttpInterceptor {
  authSessionService: AuthSessionService;
  authSessionQuery: AuthSessionQuery;

  constructor(private injector: Injector) {
    this.authSessionService = this.injector.get(AuthSessionService);
    this.authSessionQuery = this.injector.get(AuthSessionQuery);
  }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.url.includes('/backend/')
      && !request.url.includes('/public')
        && !request.url.includes('/backend/sessions')
        && !request.url.includes('/single-payment')
        && !request.url.includes('charge-points')
        && !request.url.includes('login')
        && !request.url.includes('logout')
        && !request.url.includes('backend/version')
        && !request.url.includes('roaming')
        && !request.url.includes('backend/k-stores')
        && !request.url.includes('backend/tickets')
        && !request.url.includes('/price')
        ) {
        return from(this.authHandle(request, next));
      } else {
        return next.handle(request);
      }
    }

    async authHandle(req: HttpRequest<any>, next: HttpHandler) {
      // If session has not been verified yet, check it.
      if (!this.authSessionQuery.isSessionVerified()) {
        await this.authSessionService.verifyActiveSession();
      }

      // Not logged in, cancel the request by creating an empty observable.
      if (!this.authSessionQuery.isAuthenticated()) {
        return Observable.create();
      }

      return next.handle(req).toPromise();
    }
}
