<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="errorIcon">
        <img src="../../../../assets/icons/png/icon-exclamation@2x.png" alt="">
      </div>
      <div class="overlayContainer__header">
        <span class="title">{{ 'sessionExpiredReLogin' | translate }}</span>
        <span class="info -padding">{{ 'sessionExpiredReLoginInfo' | translate }}</span>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'ok'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
