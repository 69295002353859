import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { LoaderService } from 'src/app/loader/loader.service';
import { InvoiceQuery } from '../invoice/invoice.query';
import { InvoiceService } from '../invoice/invoice.service';
import { MonthlyTransactionsComponent } from './monthly-transactions/monthly-transactions.component';
import { TransactionsService } from './state/transactions.service';
import * as moment from 'moment';
import { TransactionsQuery } from './state/transactions.query';
import { InvoicingAgreementInvoiceService } from '../invoicing-agreement-invoice/state/invoicing-agreement-invoice.service';
import { InvoicingAgreementInvoiceQuery } from '../invoicing-agreement-invoice/state/invoicing-agreement-invoice.query';
import { InvoicingAgreementService } from 'src/app/services/invoicing-agreement.service';

@Component({
  selector: 'kc-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionsComponent implements OnInit {

  loggedIn$: Observable<boolean>;

  monthlytransactionsComponents: MonthlyTransactionsComponent[];

  transactions$: Observable<any>;
  isLoadingMonthlyTransactions$: Observable<boolean>;

  
  unpaidInvoices$: Observable<any>;
  invoicesLoading$: Observable<boolean>;

  monthlyInvoicingAgreementInvoices$: Observable<any>;
  monthlyInvoicingAgreementInvoicesLoading$: Observable<boolean>;

  selectedYear: number;
  numberOfMonths: number;
  monthIndexes: number[];
  
  invoicesFetchedOnce = false;
  invoicingAgreementInvoicesFetchedOnce = false;
  invoicingAgreementExists = false;
  
  constructor(private kIDSessionQuery: KIDSessionQuery,
    private loaderService: LoaderService,
    private invoiceQuery: InvoiceQuery,
    private invoiceService: InvoiceService,
    private transactionService: TransactionsService,
    private transactionQuery: TransactionsQuery,
    private invoicingAgreementInvoiceService: InvoicingAgreementInvoiceService,
    private invoicingAgreementInvoiceQuery: InvoicingAgreementInvoiceQuery,
    private invoicingAgreementService: InvoicingAgreementService) { }

  ngOnInit() {
    
    this.invoicesFetchedOnce = this.checkInvoicesFetched();
  
    this.loaderService.activateSpinner('transactions');
    this.loggedIn$ = this.kIDSessionQuery.isLoggedIn$;

    this.invoiceService.getMonthlyInvoices();
    this.unpaidInvoices$ = this.invoiceQuery.unpaidInvoices$;
    this.invoicesLoading$ = this.invoiceQuery.selectLoading();

    if(this.invoicingAgreementService.checkInvoicingAgreement()) {
      this.invoicingAgreementExists = true;
      this.invoicingAgreementInvoicesFetchedOnce = this.checkInvoicingAgreementInvoicesFetched();
      this.invoicingAgreementInvoiceService.getInvoicingAgreementInvoices();
      this.monthlyInvoicingAgreementInvoices$ = this.invoicingAgreementInvoiceQuery.selectAll();
      this.monthlyInvoicingAgreementInvoicesLoading$ = this.invoicingAgreementInvoiceQuery.selectLoading();
    }

    this.loaderService.deactivateSpinner('transactions');
    this.transactionService.resetUIState();
    const today = new Date();
    this.transactionService.updateUIState(moment({month: today.getMonth(), year: today.getFullYear(), day: 1}));
  }

  trackByFn(index) {
    return index;
  }

  onYearSelected(year: number) {
    this.selectedYear = year;
    this.numberOfMonths = 12;
    this.monthIndexes = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1, 0]

    // If selected year is current, use only months up to current month.
    const today = new Date();
    if(year === today.getFullYear()) {
      this.monthIndexes = [];
      this.numberOfMonths = today.getMonth() + 1;
      for(let i = this.numberOfMonths - 1; i >= 0; i--) {
        this.monthIndexes.push(i);
      }
    }
  }

  // Update UI state when month is selected
  onMonthSelected(event: {month: number, year: number}) {
    this.transactionService.updateUIState(moment({year: event.year, month: event.month, day: 1}));
  }

  openTransactions(month: number, year: number) {
    const momentDate = moment({year: year, month: month, day: 1});
    const storedDate = this.transactionQuery.getValue().ui.selectedMonth;
    
    if (momentDate.isSame(storedDate)) {
      return true;
    } else {
      return false;
    }
  }

  checkInvoicesFetched(): boolean {
    return this.invoiceQuery.getAll().length > 0; 
  }

  checkInvoicingAgreementInvoicesFetched(): boolean {
    return this.invoicingAgreementInvoiceQuery.getAll().length > 0;
  }
}
