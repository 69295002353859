import { Injectable } from '@angular/core';
import { KIDSessionQuery } from './k-id-session/k-id-session.query';
import { Router } from '@angular/router';


@Injectable({ providedIn: 'root' })
export class AuthGuardService  {

  constructor(private kIDSessionQuery: KIDSessionQuery, private router: Router) {}

  /**
   * Checks if user has active session with K-ID, else returns user back to root
   */
  canActivate(): boolean {
    if (!this.kIDSessionQuery.isLoggedIn()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
