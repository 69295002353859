import { Component, OnInit, Inject } from '@angular/core';
import { PORTAL_DATA } from 'src/app/overlay-dialog/overlay-dialog.storage';
import { BlueCommerceService } from 'src/app/blue-commerce/blue-commerce.service';

@Component({
  selector: 'kc-delete-payment-card',
  templateUrl: './delete-payment-card.component.html',
  styleUrls: ['./delete-payment-card.component.scss']
})
export class DeletePaymentCardComponent implements OnInit {

  requestSend: boolean;
  deletingPaymentCard = false;

  constructor(@Inject(PORTAL_DATA) public data,
    private blueCommerceService: BlueCommerceService) {
     }

  ngOnInit() {
  }

 /**
 */
  deletePaymentCard() {
    if (this.deletingPaymentCard) {
      return;
    }
    this.deletingPaymentCard = true;
    this.blueCommerceService.deletePaymentCard().subscribe(res => {
      this.deletingPaymentCard = false;
      this.requestSend = true;
    }, err => {
      this.deletingPaymentCard = false;
      this.data.openNewOverlayDialog('ErrorDialogComponent', {'message': 'somethingWentWrongInfo', 'error': err,
      'source': { 'component': 'deletePaymentCardComponent', 'function': 'deletePaymentCard'}});
      throw err;
    });
  }

  /**
 * Close edit app settings overlay dialog
 */
  close() {
    this.data.close();
  }
}
