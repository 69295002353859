import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { OverlayDialogRef } from './overlay-dialog-ref';
import { PORTAL_DATA } from './overlay-dialog.storage';

import { EditUserComponent } from '../routes/profile/edit-user/edit-user.component';
import { EditAppSettingsComponent } from '../routes/profile/edit-app-settings/edit-app-settings.component';
import { ManageTagsComponent } from '../routes/profile/manage-tags/manage-tags.component';
import { SupportContactComponent } from '../routes/profile/support-contact/support-contact.component';

import * as storage from './overlay-dialog.storage';
import { TransactionSummaryComponent } from '../routes/transactions/transaction-summary/transaction-summary.component';
import { LoginRegisterComponent } from '../routes/charge-point/socket/login-register/login-register.component';
import { ReportFaultedSocketComponent } from '../routes/charge-point/socket/report-faulted-socket/report-faulted-socket.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { TosDialogComponent } from './tos-dialog/tos-dialog.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { DeletePaymentCardComponent } from '../routes/profile/delete-payment-card/delete-payment-card.component';
import { CantRemovePaymentCardComponent } from '../routes/profile/cant-remove-payment-card/cant-remove-payment-card.component';
import { PaymentInfoDialogComponent } from './payment-info-dialog/payment-info-dialog.component';
import { SessionExpiredDialogComponent } from './session-expired-dialog/session-expired-dialog.component';
import { RoamingSupportContactComponent } from '../roaming/roaming-support-contact/roaming-support-contact.component';
import { NewTosDialogComponent } from './new-tos-dialog/new-tos-dialog.component';
import { PaymentReminderDialogComponent } from './payment-reminder-dialog/payment-reminder-dialog.component';
import { PaymentSucceededDialogComponent } from './payment-succeeded-dialog/payment-succeeded-dialog.component';
import { MaintenanceDialogComponent } from './maintenance-dialog/maintenance-dialog.component';
import { IonityDialogComponent } from './ionity-dialog/ionity-dialog.component';
import { UpdatedPricingDialogComponent } from './updated-pricing-dialog/updated-pricing-dialog.component';
import { UpdatedPricingReminderDialogComponent } from './updated-pricing-reminder-dialog/updated-pricing-reminder-dialog.component';
import { PricingUpdatedDialogComponent } from './pricing-updated-dialog/pricing-updated-dialog.component';
import { AccountLockedDialogComponent } from './account-locked-dialog/account-locked-dialog.component';
import { DiscountDialogComponent } from './discount-dialog/discount-dialog.component';
import { AddDiscountDialogComponent } from './add-discount-dialog/add-discount-dialog.component';
import { DiscountSelectDialogComponent } from './discount-select-dialog/discount-select-dialog.component';
import { InvoicingAgreementDialogComponent } from './invoicing-agreement-dialog/invoicing-agreement-dialog.component';
import { AppUpdateDialogComponent } from './app-update-dialog/app-update-dialog.component';
import { FeedbackDialogComponent } from './feedback-dialog/feedback-dialog.component';
import { InvitationDialogComponent } from './invitation-dialog/invitation-dialog.component';
import { PricingDialogComponent } from './pricing-dialog/pricing-dialog.component';
import { OrderTagDialogComponent } from '../routes/profile/order-tag-dialog/order-tag-dialog.component';

interface OverlayDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

// Default config for overlay dialog. This can be over written if wanted.
const DEFAULT_CONFIG: OverlayDialogConfig = {
  hasBackdrop: true,
};

@Injectable({ providedIn: 'root' })
export class OverlayDialogService {

  // This is instance of this service.
  static instance: OverlayDialogService;

  private currentyOpenDialogs: OverlayDialogRef[] = [];

  constructor(public overlay: Overlay, private injector: Injector) {

    // Reference service to refer itself
    OverlayDialogService.instance = this;

    // Check if edit user template is open. If not, sets default value false (Closed)
    // This prevents edit user template to close on refresh.
    if (storage.getActiveDialog()) {

      const activeDialog = storage.getActiveDialog();

      this.openOverlayDialog(activeDialog['dialog'], activeDialog['data']);
    }
  }

  /**
   * Opens edit user contact info template
   * @param userContactInfo user contact infos
   * @param data data which will be passed to opened component
   */
  openOverlayDialog(componentName: string, data?: object, options?: object) {

    // Don't open another overlay, if the account locked dialog is found.
    if (this.getExistingOverlay('AccountLockedDialogComponent')) {
      return;
    }

    // Setup overlay dialog confing
    const dialogConfig = DEFAULT_CONFIG;

    // Create new overlay dialog reference
    const overlayRef = this.createOverLay(dialogConfig);

    let selectedComponent;

    // Create new dialog reference

    const dialogRef = new OverlayDialogRef(overlayRef, OverlayDialogService.instance, componentName, (data ? data : {}), (options ? options : {}));

    switch (componentName) {
      case 'AccountLockedDialogComponent':
      // Set edit user component as showing component
      selectedComponent = new ComponentPortal(AccountLockedDialogComponent, null, this.createInjector(dialogRef));
      break;

      case 'EditUserComponent':
        // Set edit user component as showing component
        selectedComponent = new ComponentPortal(EditUserComponent, null, this.createInjector(dialogRef));
        break;

      case 'EditAppSettingsComponent':
        // Set edit app settings component as showing component
        selectedComponent = new ComponentPortal(EditAppSettingsComponent, null, this.createInjector(dialogRef));
        break;

      case 'ManageTagsComponent':
        // Set manage tags component as showing component
        selectedComponent = new ComponentPortal(ManageTagsComponent, null, this.createInjector(dialogRef));
        break;

      case 'SupportContactComponent':
        // Set support contact component as showing component
        selectedComponent = new ComponentPortal(SupportContactComponent, null, this.createInjector(dialogRef));
        break;

      case 'TransactionSummaryComponent':
        // Set transaction summary component as showing component
        selectedComponent = new ComponentPortal(TransactionSummaryComponent, null, this.createInjector(dialogRef));
        break;
      case 'LoginRegisterComponent':
        // Set login/register component as showing component
        selectedComponent = new ComponentPortal(LoginRegisterComponent, null, this.createInjector(dialogRef));
        break;

      case 'ReportFaultedSocketComponent':
        // Set report faulted socket component as showing component
        selectedComponent = new ComponentPortal(ReportFaultedSocketComponent, null, this.createInjector(dialogRef));
        break;

      case 'ErrorDialogComponent':
        selectedComponent = new ComponentPortal(ErrorDialogComponent, null, this.createInjector(dialogRef));
        break;

      case 'TosDialogComponent':
        selectedComponent = new ComponentPortal(TosDialogComponent, null, this.createInjector(dialogRef));
        break;

      case 'CookieConsentComponent':
        selectedComponent = new ComponentPortal(CookieConsentComponent, null, this.createInjector(dialogRef));
        break;

      case 'DeletePaymentCardComponent':
        selectedComponent = new ComponentPortal(DeletePaymentCardComponent, null, this.createInjector(dialogRef));
        break;
      case 'CantRemovePaymentCardComponent':
        selectedComponent = new ComponentPortal(CantRemovePaymentCardComponent, null, this.createInjector(dialogRef));
        break;

      case 'SocialLoginErrorComponent':
        // selectedComponent = new ComponentPortal(SocialLoginErrorComponent, null, this.createInjector(dialogRef));
        break;

      case 'PaymentInfoDialogComponent':
        selectedComponent = new ComponentPortal(PaymentInfoDialogComponent, null, this.createInjector(dialogRef));
        break;

      case 'SessionExpiredDialogComponent':
        selectedComponent = new ComponentPortal(SessionExpiredDialogComponent, null, this.createInjector(dialogRef));
        break;

      case 'RoamingSupportContactComponent':
        selectedComponent = new ComponentPortal(RoamingSupportContactComponent, null, this.createInjector(dialogRef));
        break;

      case 'NewTosDialogComponent':
          selectedComponent = new ComponentPortal(NewTosDialogComponent, null, this.createInjector(dialogRef));
          break;

      case 'PaymentReminderDialogComponent':
        selectedComponent = new ComponentPortal(PaymentReminderDialogComponent, null, this.createInjector(dialogRef));
        break;

      case 'RoamingSupportContactComponent':
        selectedComponent = new ComponentPortal(RoamingSupportContactComponent, null, this.createInjector(dialogRef));
        break;

      case 'PaymentSucceededDialogComponent':
          selectedComponent = new ComponentPortal(PaymentSucceededDialogComponent, null, this.createInjector(dialogRef));
          break;

      case 'MaintenanceDialogComponent':
          selectedComponent = new ComponentPortal(MaintenanceDialogComponent, null, this.createInjector(dialogRef));
          break;
      case 'IonityDialogComponent':
          selectedComponent = new ComponentPortal(IonityDialogComponent, null, this.createInjector(dialogRef));
          break;
      case 'UpdatedPricingDialogComponent':
          selectedComponent = new ComponentPortal(UpdatedPricingDialogComponent, null, this.createInjector(dialogRef));
          break;
      case 'UpdatedPricingReminderDialogComponent':
          selectedComponent = new ComponentPortal(UpdatedPricingReminderDialogComponent, null, this.createInjector(dialogRef));
          break;
      case 'PricingUpdatedDialogComponent':
        selectedComponent = new ComponentPortal(PricingUpdatedDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'DiscountDialogComponent':
        selectedComponent = new ComponentPortal(DiscountDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'AddDiscountDialogComponent':
        selectedComponent = new ComponentPortal(AddDiscountDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'DiscountSelectDialogComponent':
        selectedComponent = new ComponentPortal(DiscountSelectDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'InvoicingAgreementDialogComponent':
        selectedComponent = new ComponentPortal(InvoicingAgreementDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'FeedbackDialogComponent':
        selectedComponent = new ComponentPortal(FeedbackDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'AppUpdateDialogComponent':
        selectedComponent = new ComponentPortal(AppUpdateDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'InvoicingInvitationComponent':
        selectedComponent = new ComponentPortal(InvitationDialogComponent, null, this.createInjector(dialogRef));
        break;
      case 'PricingDialogComponent':
          selectedComponent = new ComponentPortal(PricingDialogComponent, null, this.createInjector(dialogRef));
          break;
      case 'OrderTagDialogComponent':
        selectedComponent = new ComponentPortal(OrderTagDialogComponent, null, this.createInjector(dialogRef));
        break;
      default:
        selectedComponent = new ComponentPortal(ErrorDialogComponent, null, this.createInjector(dialogRef));
        break;
    }

    // Following code prevents multiple overlay dialogs of same type from opening.
    const duplicateDialog = this.getExistingOverlay(componentName);
    if (duplicateDialog) {
      duplicateDialog.close();
    }
    // Attach selected component to overlay
    const componentRef = overlayRef.attach(selectedComponent);
    this.currentyOpenDialogs.push(dialogRef);

    // Set selected component as active overlay dialog
    // This if check fixes login register component single payment bug. (When returning app, overlay dialog is open)
    if (componentName !== 'LoginRegisterComponent' && componentName !== 'SessionExpiredDialogComponent'
        && componentName !== 'AccountLockedDialogComponent' && componentName !== 'PaymentReminderDialogComponent') {
      storage.setActiveDialog(componentName, data);
    }

    return componentRef;
  }

  /**
   * This is called when dialog is closing.
   * @param dialogRef caller dialog.
   */
  overlayDialogClosed(dialogRef: OverlayDialogRef) {
    this.currentyOpenDialogs.splice(this.currentyOpenDialogs.indexOf(dialogRef), 1);
  }

  closeExistingDialog(dialogName: string) {
    const dialog = this.getExistingOverlay(dialogName);
    if (dialog) {
      dialog.close();
    }
  }

  /**
   * Returns overlayRef by component name.
   * @param componentName to find.
   */
  private getExistingOverlay(componentName: string) {
    return this.currentyOpenDialogs.find(dialog => dialog.componentName === componentName);
  }

  /**
   * Create new overlay dialog
   * @param config OverlayConfig
   */
  private createOverLay(config) {

    // Setup overlay dialog config
    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });

    return this.overlay.create(overlayConfig);
  }

  /**
   * Inject data from service to selected overlay dialog component
   * This is how you pass data without circular dependencies
   * @param data selected data object
   */
  private createInjector(data): PortalInjector {

    const injectorTokens = new WeakMap<any, any>([
      [PORTAL_DATA, data],
    ]);

    return new PortalInjector(this.injector, injectorTokens);
  }
}
