<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <img src="../../../assets/icons/png/icon-info@3x.png" alt="info">
        <span class="title">{{ 'pricingUpdatedTitle' | translate }}</span>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <span class="infoText" [innerHTML]="'priceUpdatedInfo1' | translate "></span>
        <span class="infoText" style="font-family: SkattaSansBold">{{ 'priceUpdatedInfo2' | translate }}</span>
        <span class="infoText">{{ 'updatedPricingAgreementPage2Info2' | translate }}</span>
        <span class="infoText" style="font-family: SkattaSansBold">{{ 'priceUpdatedInfo3' | translate }}</span>
        <span class="infoText">{{ 'priceUpdatedInfo4' | translate }}</span>
        <span class="infoText">{{ 'readMore' | translate }} <a href="https://k-lataus.fi" target="_blank">{{ 'fromWebPage' | translate }}</a>.</span>
        <span class="infoText" [innerHTML]="'updatedPricingAgreementPage2Info3' | translate "></span>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'continueToApplication'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
