<ng-container *ngIf="chargePoint$ | async as chargePoint">
  <div class="chargePoint__title" (swipeup)="windowService.setRouteContainerOpenState(true)" (swipedown)="windowService.setRouteContainerOpenState(false)">
    <div class="chargePoint__titleDiv">
      <div class="chargePoint__titleText">{{ chargePoint.name }}</div>
      <ng-container *ngIf="(store$ | async) as store">
        <ng-container *ngIf="!store.hideOpeningHours">
          <ng-container *ngIf="store.chargingHoursRestricted; else alwaysOpenTpl">
            <span class="chargePoint__subtitleText">{{ 'chargingHoursRestricted' | translate }}:</span>
            <span class="chargePoint__subtitleText -colored">{{ getOpeningHourText(store) | translate }}</span>
          </ng-container>
        </ng-container>
      </ng-container>
      <div class="chargePoint__infoTags">
      </div>
    </div>
    <!-- <div class="-heart chargePoint__icon"></div> -->
    <ng-container *ngIf="!isFutureChargePoint(chargePoint)">
      <a class="chargePoint__navigate" [ngClass]="chargePoint.roaming ? '-blue' : '-orange'" href="{{ getGoogleMapsUrl() }}" target="_blank">
        <div class="chargePoint__icon" [ngClass]="chargePoint.roaming ? '-routeBlue' : '-routeOrange'"></div>
        <span>{{ 'navigateTo' | translate }}</span>
      </a>
    </ng-container>  
  </div>
  <kc-navigation-bar 
    [routerLinkImplemented]="false" 
    [tabs]="tabs"
    [disabledTabs]="isFutureChargePoint(chargePoint) ? ['chargeSockets'] : []"
    [defaultTab]="isFutureChargePoint(chargePoint) ? 'information' : ''"
    (activatedTab)="activeTab = $event">
  </kc-navigation-bar>
  <div class="routerContainerContent__container -subNavigation" [ngClass]="(activeTab === 'information' && chargePoint.roaming) ? '-roaming' : ''">
    <ng-container *ngIf="activeTab === 'chargeSockets'">
      <ng-container *ngFor="let chargeBox of sortByAvailability(chargeBoxes)">
        <kc-charge-point-charger [chargeBoxId]="chargeBox['id']" [isActive]="false" (click)="selectSocket(chargeBox)"></kc-charge-point-charger>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="activeTab === 'information'">

      <ng-container *ngIf="isFutureChargePoint(chargePoint); else kInfo">
        <div class="chargePoint__upcomingInfoContainer">
          <div class="chargePoint__upcomingInfo">
            <i class="fak fa-k-info fa-xl -orange"></i>
            <div class="chargePoint__upcomingInfoText">
              <div class="--title">
                {{ 'futureChargePoint' | translate }}
              </div>
              <!-- <div>
                {{ 'chargePointWillBeIntroduced' | translate :  {year: calculateYear(chargePoint)} }}
              </div> -->
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #kInfo>
        <div class="addressContainer">
          <span class="addressHeader">{{ 'address' | translate }}: </span><span class="address">{{chargePoint.streetAddress + ', ' + chargePoint.postalCode + ' ' + chargePoint.city}}</span>
        </div>
        <ng-container *ngIf="!chargePoint.roaming; else roamingInfo">
          <ng-container *ngIf="(store$ | async) as store;">
            <div class="chargePoint__openingHourContainer">
              <kc-attention-box *ngIf="store.chargingHoursRestricted && !store.hideOpeningHours" [subtitle]="'chargingHoursRestricted'"></kc-attention-box>
              <div class="chargePoint__openingHourDiv" *ngIf="!store.hideOpeningHours">
                <div class="chargePoint__openingHourText">{{ (store.chargingHoursRestricted ? 'storeOpeningHours' : 'chargingStationAvailable') | translate }}</div>
                <div class="chargePoint__openingHourText -orange -large">{{ getOpeningHourText(store) | translate }}</div>
              </div>
            </div>

            <ng-container *ngIf="store.locationInfo">
              <div class="chargePoint__locationInfoDiv">
                <div class="icon">
                  <img src="../../../../assets/icons/png/icon-car-charge.png" alt="">
                </div>
                <div class="text">
                  <div class="-title">{{ 'chargePointLocation' | translate }}</div>
                  <div class="-content">{{ store.locationInfo }}</div>
                </div>
              </div>
            </ng-container>
            

            <div class="routerContainerContent__subHeader -chargePointPage -bold"><span class="-bold">{{ 'services' | translate |
              uppercase }}</span></div>
  
            <ng-container *ngFor="let service of getAvailableServices(store); trackBy: trackByFn">
              <div class="chargePoint__service">
                <div class="chargePoint__serviceIcon">
                  <img [src]="getServiceIcon(service)" alt="">
                </div>
                <div class="chargePoint__serviceTitleDiv -enabledItem">
                  <div class="chargePoint__serviceTitle">{{ service | translate }} </div>
                </div>
              </div>
            </ng-container>

            <div class="chargePoint__linkDiv">
              <span>{{ 'showAll' | translate }} </span>
              <a href="{{store.kRuokaUrl}}" class="chargePoint__linkText -orange" target="_blank">
                {{ 'servicesAndOpeningHours' | translate }}.
              </a>
            </div>

            
          </ng-container>

                <div class="routerContainerContent__subHeader -chargePointPage"><span>{{ 'previousTransactions' | translate |
                    uppercase }}</span></div>
                <ng-container *ngIf="(chargePointLatestTransactions$ | async) as chargePointLatestTransactions; else loadingTemplate">
                  <ng-container *ngFor="let chargeBox of chargePointLatestTransactions; trackBy: trackByFn">
                    <div *ngIf="chargeBox.daysAgo <= 7" class="chargePoint__socket">
                      <div class="chargePoint__socketIcon">
                        <div class="chargePoint__pic" [ngClass]="'-' + (removeWhitespace(chargeBox.socketType) | lowercase) + 'Orange'"></div>
                        <div class="chargePoint__uniqueId -orange">{{ chargeBox.uniqueId }}</div>
                      </div>
                      <div class="chargePoint__socketTitleDiv -enabledItem">
                        <div class="chargePoint__socketTitle">{{ chargeBox.socketType | translate }} • {{ chargeBox.power / 1000 |
                          localNumber:'1.0' }} kW</div>
                        <div class="chargePoint__socketSubtitle -subtitleAvailable">{{ chargeBox.timeAgo }}</div>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-template #loadingTemplate>
                  <kc-local-loader [message]="'fetchingTransactions'"></kc-local-loader>
                </ng-template>
            </ng-container>
          
      </ng-template>

      <ng-template #roamingInfo> 
        <div class="routerContainerContent__subHeader -chargePointPage"><span>{{ 'chargingStationInfo' | translate |
            uppercase }}</span>
        </div>
        <div class="chargePoint__socketTitleDiv -enabledItem -roaming" style="margin-bottom: 20px">
          <div class="chargePoint__picHorizontal" [ngClass]="'-chargingStationIcon'">
            <img src="../../../../assets/icons/png/icon-pumpElectricity@2x_blue.png" alt="">
          </div>
          <div class="chargePoint__titleContainer">
                <div class="chargePoint__headerContainer">
                    <div class="chargePoint__socketTitle">{{ chargePoint.name }}</div>
                    <div class="chargePoint__socketSubtitle -subtitleAvailable">{{ chargePoint.streetAddress }} {{ chargePoint.postalCode ? chargePoint.postalCode : '•'  }} {{ chargePoint.city }}</div>
                </div>
          </div>
          <div class="chargePoint__socketSubtitle -subtitleAvailable -padding">{{ 'roamingStationInfoText1' | translate }} {{chargePoint.operatorName | uppercase }} {{ 'roamingStationInfoText2' | translate }} {{ 'roamingStationInfoText3' | translate }}</div>
          <div class="chargePoint__socketSubtitle -subtitleAvailable -padding">{{ 'inCaseOfProblems' | translate}}</div>
        </div>
        <ng-container>
          <div class="overlayContainer__contentWrapper">
            <div class="overlayContainer__contentTemplate">
              <div class="overlayContainer__contentTemplateLabel">
                <span>{{ 'customerSupport' | translate | uppercase }}</span>
              </div>
              <div class="overlayContainer__header">
                  <ng-container *ngIf="hotlinePhoneNumber">
                      <div class="iconContainer">
                        <img src="../../../../../assets/icons/png/icon-phone@2x_blue.png" alt="">
                      </div>
                      <span class="info -marginBottom -px10 -bold -blue -rem12">{{ hotlinePhoneNumber }}</span>
                      <span class="info -blue -phoneNumberInfo">{{ 'customerSupportNumberInfo' | translate }}</span>
                  </ng-container>
                  <span *ngIf="!hotlinePhoneNumber" class="info -marginBottom -px10">{{ 'numberNotAvailable' | translate }}</span>
              </div>
            </div>
          </div>
        </ng-container>       
      </ng-template>
    </ng-container>
  </div>
</ng-container>

<ng-template #alwaysOpenTpl>
  <div style="display: flex">
    <span class="chargePoint__subtitleText" style="margin-right: 3px;">{{ 'chargingStationAvailable' | translate }}</span>
    <span class="chargePoint__subtitleText -colored">24/7</span>
  </div>
</ng-template>
