import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConverterHelper } from 'src/app/helpers/converter.helper';
import { Transaction } from 'src/app/routes/transactions/state/transaction.model';
import TransactionFeedback, { TransactionFeedbackService, TransactionFeedbackType } from 'src/app/services/transaction-feedback.service';
import { PORTAL_DATA } from '../overlay-dialog.storage';

@Component({
  selector: 'kc-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  feedbackSent: boolean;
  isSending: boolean = false;
  showErrors = false;
  transaction: Transaction;
  @Output() openCustomerSupportEvent = new EventEmitter<{reason: string}>();

  feedbackForm = new UntypedFormGroup(
    {
      feedbackNature: new UntypedFormControl('negative'),
      negativeCategory: new UntypedFormControl('startChargingProblem'),
      freeFeedback: new UntypedFormControl('')
    }
  )

  constructor(
    @Inject(PORTAL_DATA) public data,
    private transactionFeedbackService: TransactionFeedbackService
  ) { }

  ngOnInit() {
    this.transaction = this.data['data']['transaction'];
  }

  setNegativeFeedbackCategory(category: string) {
    this.feedbackForm.get('negativeCategory').setValue(category);
  }

  customerSupportClicked() {
    this.openCustomerSupportEvent.emit({reason: this.feedbackForm.get('negativeCategory').value});
    this.close();
  }

  submitButtonClicked() {
    if(this.feedbackSent) {
      this.data.close();
      return;
    }

    this.isSending = true;
    this.showErrors = false;

    const transactionFeedback: TransactionFeedback = {
      chargePointId: this.transaction.chargePointId,
      chargePointGroupId: this.transaction.chargePointGroupId,
      chargeBoxId: this.transaction.chargeBoxId,
      chargeBoxGroupId: this.transaction.chargeBoxGroupId,
      transactionId: this.transaction.transactionId,
      reason: this.feedbackForm.value.negativeCategory,
      type: TransactionFeedbackType.NEGATIVE,
      powerType: ConverterHelper.convertPowerType(this.transaction.powerType || ''),
      additionalInfo: this.feedbackForm.value.freeFeedback,
    }

    this.transactionFeedbackService.sendTransactionFeedback(transactionFeedback).subscribe(res => {
      if(res.status && res.status === 'OK') {
        this.feedbackSent = true;
      } else {
        this.showErrors = true;
      }
      this.isSending = false;
    }, _error => {
      this.showErrors = true;
      this.isSending = false;
    });
  }

  sendPositiveFeedback() {
    const transactionFeedback: TransactionFeedback = {
      chargePointId: this.transaction.chargePointId,
      chargePointGroupId: this.transaction.chargePointGroupId,
      chargeBoxId: this.transaction.chargeBoxId,
      chargeBoxGroupId: this.transaction.chargeBoxGroupId,
      transactionId: this.transaction.id,
      type: TransactionFeedbackType.POSITIVE,
      powerType: ConverterHelper.convertPowerType(this.transaction.powerType),
    }

    this.transactionFeedbackService.sendTransactionFeedback(transactionFeedback).subscribe();
    this.close();
  }

  close() {
    this.data.close();
  }

}
