import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ChargeBox } from 'src/app/map/charge-boxes/charge-box.model';
import { LanguageService } from 'src/app/language/language.service';
import { KIDSessionQuery } from 'src/app/k-id-session/k-id-session.query';
import { ChargeBoxesQuery } from 'src/app/map/charge-boxes/charge-boxes.query';
import { ActiveTransactionService, ActiveTransaction } from '../active-transaction/active-transaction.service';
import { takeUntil } from 'rxjs/operators';
import { ActiveTransactionHelper } from 'src/app/helpers/active-transaction-helper';
import { Subscription, interval, Observable, Subject, combineLatest } from 'rxjs';
import { setSocketType } from '../../../map/charge-boxes/charge-box-socket-types';
import { FormatSocketTypeNamePipe } from '../format-socket-type-name.pipe';


@Component({
  selector: 'kc-charge-point-charger',
  templateUrl: './charge-point-charger.component.html',
  styleUrls: ['./charge-point-charger.component.scss']
})
export class ChargePointChargerComponent implements OnInit {

  @Input() set chargeBoxId(id) {
    if (id) {
      this.chargeBox$ = this.chargeBoxesQuery.selectEntity(id);
    }
  }

  /**
   * Whether component should use list item or active transaction layouts.
   */
  @Input() isActive;
  private destroyed$ = new Subject<boolean>();
  private activeTransaction$ = this.activeTransactionService.activeTransaction$;
  activeTransaction;
  energy;
  price;
  chargeBox$: Observable<ChargeBox>;
  hours;
  minutes;

  private intervalSubscription: Subscription;

  constructor(
    private languageService: LanguageService,
    private kIDSessionQuery: KIDSessionQuery,
    private chargeBoxesQuery: ChargeBoxesQuery,
    private activeTransactionService: ActiveTransactionService,
    private cdr: ChangeDetectorRef,
    private FormatSocketNamePipe: FormatSocketTypeNamePipe
  ) { }

  ngOnInit() {
    if (this.isActive) {
      combineLatest([
        this.activeTransaction$,
        this.chargeBox$
      ]).pipe(takeUntil(this.destroyed$)).subscribe(response => {
        if (response[0].length !== 0) {
          this.activeTransactionReceived(response[0], response[1]);
        }
      });
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  socketAvailableForUse(chargeBox: ChargeBox): boolean {
    if (chargeBox.status === 'Available' || (chargeBox.status === 'Occupied' && !chargeBox.transactionId)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @param formattedType charge box socket type
   * @param status charge box status
   */
  getSocketPic(charger: ChargeBox): any {
    if (charger.status === 'Available' || (charger.status === 'Occupied' && !charger.transactionId) || this.isActive) {
      if (charger.roaming && !charger.AuthenticationModes.includes('REMOTE')) {
        return `${this.removeWhitespace(charger.socketType)}Gray`;
      }
      if (charger['Plugs'] && charger['Plugs'].length > 1) {
        return 'multipleBlue';
      }
      const suffix = !charger.EvseID ? 'Orange' : 'Blue';
      return `${this.removeWhitespace(charger.socketType)}${suffix}`;
    } else {
      return `${this.removeWhitespace(charger.socketType)}Gray`;
    }
  }

  removeWhitespace(text: string): string {
    text = text.toLowerCase();
    return text.replace(/\s/g, '');
  }

  activeTransactionReceived(activeTransactions: ActiveTransaction[], chargeBox: ChargeBox) {
    activeTransactions.map((transaction) => {
      if (transaction.chargeBoxId === chargeBox.id) {
        this.activeTransaction = transaction;
        this.startPriceTicker(transaction);
      }
    });
  }

  startPriceTicker(activeTransaction: ActiveTransaction) {
    this.killOldPriceTicker();
    this.energy = activeTransaction.energy;
    this.setParsedTimeElapsed(activeTransaction);
    this.price = ActiveTransactionHelper.calculatePrice(
      activeTransaction,
      activeTransaction.transactionPrice ? activeTransaction.transactionPrice.chargingPrice : undefined,
      this.kIDSessionQuery.isLoggedIn(),
      this.price
    );
    this.intervalSubscription = interval(1000).subscribe(
      () => {
        this.price = ActiveTransactionHelper.calculatePrice(
          activeTransaction,
          activeTransaction.transactionPrice ? activeTransaction.transactionPrice.chargingPrice : undefined,
          this.kIDSessionQuery.isLoggedIn(),
          this.price
        );
        this.setParsedTimeElapsed(activeTransaction);
        this.cdr.markForCheck();
      }
    );
  }

  killOldPriceTicker() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  setParsedTimeElapsed(activeTransaction) {
    const timeElapsed = ActiveTransactionHelper.formatChargingTime(activeTransaction.timestampStart, activeTransaction.timestampStop);
    this.hours = timeElapsed.hours;
    this.minutes = timeElapsed.minutes;
  }

  hasRemoteStart(charger: ChargeBox) {
    if (charger.roaming && !charger.AuthenticationModes.includes('REMOTE')) {
      return false;
    } else {
      return true;
    }
  }

  getSignificantChargerId(chargerID: string) {
    if (chargerID.startsWith('+')) {
      return chargerID;
    } else {
      const splitIndex = chargerID.indexOf('*', chargerID.indexOf('*') + 1);
      return chargerID.substring(splitIndex + 2);
    }
  }

  getRoamingSocketType(charger: ChargeBox) {
    if (charger['Plugs'].length === 1) {
      return  this.FormatSocketNamePipe.transform(setSocketType(charger['Plugs'][0]));
    } else {
      return 'multipleSocketTypes';
    }
  }
}
