<div class="socket__headerContainer" (swipeup)="windowService.setRouteContainerOpenState(true)" (swipedown)="windowService.setRouteContainerOpenState(false)">
  <div class="iconButton" (click)="goBack()">
    <img src="../../../../assets/icons/png/icon-arrow-l-left@2x.png" alt="">
  </div>
  <span class="title">{{ 'chargeOngoing' | translate }}</span>
  <span class="chargePoint">{{ chargePoint?.name }}</span>
</div>
<div class="routerContainerContent__container -fitToContent" *ngIf="socket && chargePoint">
  <kc-socket-status [socket]="socket"></kc-socket-status>
  <ng-container *ngIf="(activeTransactionStatus$ | async) === 1; else transactionStateTpl">
    <div class="activeTransactionInfo" *ngIf="!chargePoint.roaming else roamingTransactionStatus">
      <div class="activeTransactionInfo__title">{{ 'charged' | translate | uppercase }}</div>
      <div class="activeTransactionInfo__row">
        <span class="-bold">{{ (activeTransaction.energy ? activeTransaction.energy / 1000 : 0) | localNumber:'1.2-2' }}</span>
        <span>kWh</span>
        <span style="margin: 0 5px;">•</span>
        <span class="-bold">{{ price | localCurrency }}</span>
      </div>
      <div class="activeTransactionInfo__row">
        <span class="-bold">{{ hours }}</span>
        <span>h</span>
        <span class="-bold">{{ minutes }}</span>
        <span>min</span>
      </div>
    </div>
    <div class="activeTransactionStopCharging">
      <button class="activeTransactionStopCharging__button" [ngClass]="chargePoint.roaming ? '-blue' : ''" tabindex="0" (click)="stopCharging()">
        <span>{{ 'stopCharging'| translate | uppercase }}</span>
      </button>
    </div>
  </ng-container>
  <ng-template #transactionStateTpl>
    <ng-container [ngSwitch]="activeTransactionStatus$ | async">
      <ng-container *ngSwitchCase="2">
        <div class="routerContainerContent__transactionMessageContainer">
          <kc-local-loader [message]="'endingActiveTransaction'"></kc-local-loader>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="3">
        <div class="routerContainerContent__transactionMessageContainer">
          <kc-local-loader [message]="!chargePoint.roaming ? 'activeTransactionEnded' : 'activeRoamingTransactionEnded'"></kc-local-loader>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="4">
        <span>{{'errorEndingTransaction' | translate}}</span>
      </ng-container>
      <ng-container *ngSwitchCase="5">
        <span>{{'errorFetchingCDR' | translate}}</span>
      </ng-container>
      <ng-container *ngSwitchCase="6">
        <div class="routerContainerContent__transactionMessageContainer">
          <kc-local-loader [message]="'gettingActiveTransactionInfo'"></kc-local-loader>
        </div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <div class="routerContainerContent__transactionMessageContainer">
          <kc-local-loader [message]="'gettingActiveTransactionInfo'"></kc-local-loader>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>
<ng-template #roamingTransactionStatus>
  <div class="activeTransactionStatus --roaming">
    <span> {{ 'roamingTransactionStatusText' | translate }}</span>
  </div>
</ng-template>
<ng-template #roamingTransactionBox>
  <div class="status -charging">
    <img src="../../../../assets/icons/png/icon-pumpElectricity@2x_blue.png" alt="">
    <span class="info">{{socket['uniqueId']}}</span>
  </div>
</ng-template>
