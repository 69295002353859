export enum SocketTypes {
    TYPE_1 = 'type1',
    TYPE_2 = 'type2',
    CHADEMO = 'chademo',
    CCS2 = 'ccs2',
    CCS1 = 'ccs1',
    SCHUKO = 'schuko',
    GBT = 'gbt',
    UNDEFINED = 'undefined'
}

enum PlugitSocketTypes {
    TYPE_2 = 'TYPE2',
    TYPE_1 = 'TYPE1',
    CCS1 = 'CCS1',
    CSS2 = 'CCS2',
    GBT = 'GBT',
    CHADEMO = 'CHADEMO',
}

enum HubjecSocketTypes {
    SMALL_PADDLE_INDUCTIVE = 'Small Paddle Inductive',
    LARGE_PADDLE_INDUCTIVE = 'Large Paddle Inductive',
    AVCON_CONNECTOR = 'AVCON Connector',
    TESLA_CONNECTOR = 'Tesla Connector',
    NEMA = 'NEMA 5- 20',
    TYPE_E = 'Type E French Standard',
    TYPE_F = 'Type F Schuko',
    TYPE_G = 'Type G British Standard',
    TYPE_J = 'Type J Swiss Standard',
    TYPE_1 = 'Type 1 Connector (Cable Attached)',
    TYPE_2 = 'Type 2 Outlet',
    TYPE_2_CABLE = 'Type 2 Connector (Cable Attached)',
    TYPE_3 = 'Type 3 Outlet',
    SINGLE_PAHSE = 'IEC 60309 Single Phase',
    THREE_PHASE = 'IEC 60309 Three Phase',
    CCS2 = 'CCS Combo 2 Plug (Cable Attached)',
    CCS1 = 'CCS Combo 1 Plug (Cable Attached)',
    CHADEMO = 'CHAdeMO',
    UNDEFINED = 'Unspecified'
}

export function setSocketType(socketType: string) {
    switch (socketType) {
        case PlugitSocketTypes.TYPE_2:
        case HubjecSocketTypes.TYPE_2_CABLE:
        case HubjecSocketTypes.TYPE_2:
        case SocketTypes.TYPE_2:
        // REMOVE AND FIX THIS TO BACKEND AFTER HUBJECT UPDATE (USE PLUGIT TYPE INSTEAD)
        case 'Type 2':
            return SocketTypes.TYPE_2;
        case PlugitSocketTypes.TYPE_1:
        case HubjecSocketTypes.TYPE_1:
        case SocketTypes.TYPE_1:
            return SocketTypes.TYPE_1;
        case PlugitSocketTypes.CHADEMO:
        case HubjecSocketTypes.CHADEMO:
        case SocketTypes.CHADEMO:
        // REMOVE AND FIX THIS TO BACKEND AFTER HUBJECT UPDATE (USE PLUGIT TYPE INSTEAD)
        case 'CHAdeMO':
            return SocketTypes.CHADEMO;
        case PlugitSocketTypes.CCS1:
        case HubjecSocketTypes.CCS1:
        case SocketTypes.CCS1:
            return SocketTypes.CCS1;
        case PlugitSocketTypes.CSS2:
        case HubjecSocketTypes.CCS2:
        case SocketTypes.CCS2:
        // REMOVE AND FIX THIS TO BACKEND AFTER HUBJECT UPDATE (USE PLUGIT TYPE INSTEAD)
        case 'CCS':
            return SocketTypes.CCS2;
        case PlugitSocketTypes.GBT:
        case SocketTypes.GBT:
            return SocketTypes.GBT;
        case SocketTypes.SCHUKO:
        case 'Type F Schuko':
        case 'Schuko':
            return SocketTypes.SCHUKO;
        default:
            return SocketTypes.UNDEFINED;
    }
}
