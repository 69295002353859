import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface AuthSessionState {
  hasSession: boolean;
  hasSinglePaymentSession: boolean;
  isAuthenticating: boolean;
  isSessionVerified: boolean;
  error: string | null;
}

export function createInitialState(): AuthSessionState {
  return {
    hasSession: false,
    hasSinglePaymentSession: false,
    isAuthenticating: false,
    isSessionVerified: false,
    error: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth-session', resettable: true })
export class AuthSessionStore extends Store<AuthSessionState> {

  constructor() {
    super(createInitialState());
  }
}
