<div class="updateDialogContainer --update-dialog">
    <div class="bg"></div>
    <div class="overlayContainer__wrapper --update-dialog">
      <div class="overlayContainer__contentWrapper">
        <div class="overlayContainer__contentTemplate">
          <div class="overlayContainer__header">
            <span class="title">{{ 'updateAvailableTitle' | translate }}</span>
          </div>
          <div class="overlayContainer__actionContainer --w100">
            <button class="overlayContainer__actionButton --orange" (click)="appUpdateService.updateApplication(true)">
              <span>{{'reloadPage' | translate | uppercase}}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
