import { Component, OnInit, Inject } from '@angular/core';
import { AppVersionService, Versions } from 'src/app/services/app-version.service';
import { PORTAL_DATA } from '../overlay-dialog.storage';

@Component({
  selector: 'kc-app-update-dialog',
  templateUrl: './app-update-dialog.component.html',
  styleUrls: ['./app-update-dialog.component.scss']
})
export class AppUpdateDialogComponent implements OnInit {
  appType: string;

  constructor(@Inject(PORTAL_DATA) public data, private appVersionService: AppVersionService) { }

  ngOnInit() {
    this.appType = this.appVersionService.getVersion();
  }

  isIosApp() {
    return this.appType === Versions.IOS_APP;
  }

  isAndroidApp() {
    return this.appType === Versions.ANDROID_APP;
  }

  close() {
    this.data.close();
  }

}
