<div class="overlayContainer__wrapper">
  <div class="overlayContainer__middleWrapper">
    <div class="overlayContainer__contentPane">
      <div class="headerContainer">
        <img src="../../../assets/icons/png/icon-info@3x.png" alt="">
        <ng-container [ngSwitch]="paymentReminderStatus">
          <ng-container *ngSwitchCase=1>
              <span class="title">{{ 'paymentReminderBalancePart1' | translate }} {{ totalPrice ? ((totalPrice.gross ? totalPrice.gross / 100 : 0) | localCurrency: currency) : '-' }} {{ 'paymentReminderBalancePart2' | translate }}</span>
            <span class="title">{{ 'howeverPaymentDidNotSucceed' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase=2>
            <span class="title">{{ 'previousMonthsPaymentFailedTitle' | translate }}</span>
            <span class="title">{{ 'youHaveXDaysToPayPart1' | translate }} {{ daysUntil15th }} {{ 'youHaveXDaysToPayPart2' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase=3>
            <span class="title">{{ 'previousMonthsPaymentFailedTitle' | translate }}</span>
            <span class="title">{{ 'accountLockedTitle' | translate }}</span>
          </ng-container>
        </ng-container>
        <div class="border">//////////////////////////////////////////////////////////////////////////////////</div>
      </div>
      <div class="infoContainer">
        <ng-container [ngSwitch]="paymentReminderStatus">
          <ng-container *ngSwitchCase=1>
              <span class="infoText">{{ 'paymentReminderInfo1' | translate }}</span>
              <span class="infoText">{{ 'paymentReminderInfo2' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase=2>
              <span class="infoText">{{ 'paymentReminderInfo1' | translate }}</span>
              <span class="infoText">{{ 'paymentReminderInfo2' | translate }}</span>
          </ng-container>
          <ng-container *ngSwitchCase=3>
            <span class="infoText">{{ 'paymentReminderAccountLocked1' | translate }}</span>
            <span class="infoText">{{ 'paymentReminderAccountLocked2' | translate }}</span>
            <span class="infoText">{{ 'paymentReminderAccountLocked3' | translate }}</span>
          </ng-container>
        </ng-container>
      </div>
      <div class="overlayContainer__actionContainer">
        <kc-dialog-button [buttonText]="'continueToPayment'" [color]="'--orange'" [disabled]="!_id" (onClick)="continueToPayment()"></kc-dialog-button>
        <kc-dialog-button *ngIf="paymentReminderStatus !== 3" [buttonText]="'skipPayment'" [color]="'--white'" (onClick)="close()"></kc-dialog-button>
      </div>
    </div>
  </div>
</div>
