<div class="transactionsInvoice separator clickAble" (click)="selectMonthlyInvoice(invoice)">
  <span class="transactionsInvoice__tabBorder" [ngClass]="invoice.paymentStatus === 'succeeded' ? '--colorGreen' : '--colorGray'"></span>
  <div class="transactionsInvoice__socketIcon">
    <i class="fak fa-k-receipt fa-xl" [ngClass]="invoice.paymentStatus === 'succeeded' ? '--colorGreen' : '--colorGray'"></i>
  </div>
  <div class="transactionsInvoice__textContainer flex">
    <span class="title">{{ (getMonthName(invoice.createdAt) + 'Payment') | translate }}</span>
    <span class="text">{{ invoice.createdAt | localDate:'dd.MM.yyyy'}}</span>
  </div>
  <div class="transactionsInvoice__textContainer -rightAligned">
    <span class="title">-{{ invoice.totalPrice.gross / 100 | localCurrency }}</span>
    <span class="text">{{ invoice.transactions.length }} kpl</span>
  </div>
</div>