import { Injectable, OnDestroy } from '@angular/core';
import { UserLocationStore } from './user-location.store';

@Injectable({ providedIn: 'root' })
export class UserLocationService implements OnDestroy {
  watchId;

  constructor(private userLocationStore: UserLocationStore) { }

  getUserLocation() {
    if (navigator.geolocation) {
      // Clear the previous watcher.
      if (this.watchId) {
        navigator.geolocation.clearWatch(this.watchId);
      }
      const options: PositionOptions = { enableHighAccuracy: true , timeout: 10000, maximumAge: Infinity};
      // navigator.geolocation.getCurrentPosition(pos => this.onLocationFound(pos), null, options);

      this.watchId = navigator.geolocation.watchPosition(
        (position) => this.onLocationFound(position), (error) => {
          if (error.code === geolocationErrorCodes.PERMISSION_DENIED) {
            console.error('USER DENIED GEOLOCATION');
          }
          if (error.code === geolocationErrorCodes.TIMEOUT) {
            console.error('GEOLOCATION TIMED OUT');
            // this.getUserLocation();
          }
          if (error.code === geolocationErrorCodes.POSITION_UNAVAILABLE) {
            console.error('GEOLOCATION UNAVAILABLE');
          }
          console.error(error);
        }, options);
    }
  }

  private onLocationFound(position: GeolocationPosition) {
    this.userLocationStore.positionFound(position);
  }

  ngOnDestroy() {
    if (this.watchId) {
      navigator.geolocation.clearWatch(this.watchId);
    }
  }
}

/**
 * UNDEFINED: This is only for setting numbering right,,, (Not valid error)
 * PERMISSION_DENIED: 1 - The user chose to deny access to geolocation.
 * POSITION_UNAVAILABLE: 2 - It wasn’t possible to get a location.
 * TIMEOUT: 3 - It took too long to get the user’s location.
 */
export enum geolocationErrorCodes {
  UNDEFINED,
  PERMISSION_DENIED,
  POSITION_UNAVAILABLE,
  TIMEOUT
}

